/* Base button */
.mdc-button{
    min-width: 48px;
    min-height: 44px;

  &.text-ellipsis {
    .mdc-button__label {
      overflow: hidden !important;
      text-overflow: ellipsis !important;
    }
  }
}



/* FAB buttons */
.mat-mdc-fab {
    max-height: 56px;

    &:not(.mdc-fab--extended) {
        height: 50px;
        width: 50px;
        border-radius: 50% !important;

         .mdc-fab__ripple {
            border-radius: inherit!important;
         }
    }

    &.mdc-fab--extended {
        border-radius: 24px !important;

        .mdc-fab__ripple {
            border-radius: inherit!important;
        }
    }
}

/* Mini FAB buttons */
.mat-mdc-mini-fab {

    &:not(.mdc-fab--extended) {
        border-radius: 50% !important;

         .mdc-fab__ripple {
            border-radius: inherit!important;
         }
    }
}

/* Rounded design */
.mat-mdc-raised-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button {
    padding: 0 20px;
    border-radius: 6px !important;

    &.icon-only{
        padding: 0;
    }
}

