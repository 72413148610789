@import 'node_modules/quill/dist/quill.core.css';
@import 'node_modules/quill/dist/quill.snow.css';
@import 'node_modules/quill-mention/dist/quill.mention.css';
@import 'node_modules/angular-calendar/css/angular-calendar.css';
@import 'node_modules/highlight.js/styles/github.css';
@import 'node_modules/tui-color-picker/dist/tui-color-picker.css';
.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-drag-preview .mat-ripple-element, .cdk-drag-placeholder .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, var(--mat-app-background, transparent));
  color: var(--mat-app-text-color, var(--mat-app-on-background, inherit));
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: var(--mat-app-elevation-shadow-level-0, none);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: var(--mat-app-elevation-shadow-level-1, none);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: var(--mat-app-elevation-shadow-level-2, none);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: var(--mat-app-elevation-shadow-level-3, none);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: var(--mat-app-elevation-shadow-level-4, none);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: var(--mat-app-elevation-shadow-level-5, none);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: var(--mat-app-elevation-shadow-level-6, none);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: var(--mat-app-elevation-shadow-level-7, none);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: var(--mat-app-elevation-shadow-level-8, none);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: var(--mat-app-elevation-shadow-level-9, none);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: var(--mat-app-elevation-shadow-level-10, none);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: var(--mat-app-elevation-shadow-level-11, none);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: var(--mat-app-elevation-shadow-level-12, none);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: var(--mat-app-elevation-shadow-level-13, none);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: var(--mat-app-elevation-shadow-level-14, none);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: var(--mat-app-elevation-shadow-level-15, none);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: var(--mat-app-elevation-shadow-level-16, none);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: var(--mat-app-elevation-shadow-level-17, none);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: var(--mat-app-elevation-shadow-level-18, none);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: var(--mat-app-elevation-shadow-level-19, none);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: var(--mat-app-elevation-shadow-level-20, none);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: var(--mat-app-elevation-shadow-level-21, none);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: var(--mat-app-elevation-shadow-level-22, none);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: var(--mat-app-elevation-shadow-level-23, none);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: var(--mat-app-elevation-shadow-level-24, none);
}

.mat-theme-loaded-marker {
  display: none;
}

html {
  --mat-option-label-text-font: Roboto;
  --mat-option-label-text-line-height: 1.5rem;
  --mat-option-label-text-size: 0.875rem;
  --mat-option-label-text-tracking: normal;
  --mat-option-label-text-weight: 400;
}

html {
  --mat-optgroup-label-text-font: Roboto;
  --mat-optgroup-label-text-line-height: 1.5rem;
  --mat-optgroup-label-text-size: 0.875rem;
  --mat-optgroup-label-text-tracking: normal;
  --mat-optgroup-label-text-weight: 400;
}

html {
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
}

html {
  --mat-card-title-text-font: Roboto;
  --mat-card-title-text-line-height: 1.5rem;
  --mat-card-title-text-size: 0.875rem;
  --mat-card-title-text-tracking: normal;
  --mat-card-title-text-weight: 400;
  --mat-card-subtitle-text-font: Roboto;
  --mat-card-subtitle-text-line-height: 1.25rem;
  --mat-card-subtitle-text-size: 0.875rem;
  --mat-card-subtitle-text-tracking: normal;
  --mat-card-subtitle-text-weight: 600;
}

html {
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
}

html {
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
}

html {
  --mdc-plain-tooltip-supporting-text-font: Roboto;
  --mdc-plain-tooltip-supporting-text-size: 0.75rem;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: normal;
}

html {
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
}

html {
  --mat-form-field-container-height: 56px;
  --mat-form-field-filled-label-display: block;
  --mat-form-field-container-vertical-padding: 16px;
  --mat-form-field-filled-with-label-container-padding-top: 24px;
  --mat-form-field-filled-with-label-container-padding-bottom: 8px;
}

html {
  --mdc-filled-text-field-label-text-font: Roboto;
  --mdc-filled-text-field-label-text-size: 0.875rem;
  --mdc-filled-text-field-label-text-tracking: normal;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-label-text-font: Roboto;
  --mdc-outlined-text-field-label-text-size: 0.875rem;
  --mdc-outlined-text-field-label-text-tracking: normal;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mat-form-field-container-text-font: Roboto;
  --mat-form-field-container-text-line-height: 1.5rem;
  --mat-form-field-container-text-size: 0.875rem;
  --mat-form-field-container-text-tracking: normal;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-outlined-label-text-populated-size: 0.875rem;
  --mat-form-field-subscript-text-font: Roboto;
  --mat-form-field-subscript-text-line-height: 1rem;
  --mat-form-field-subscript-text-size: 0.75rem;
  --mat-form-field-subscript-text-tracking: normal;
  --mat-form-field-subscript-text-weight: 400;
}

html {
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-select-arrow-transform: translateY(-8px);
}

html {
  --mat-select-trigger-text-font: Roboto;
  --mat-select-trigger-text-line-height: 1.5rem;
  --mat-select-trigger-text-size: 0.875rem;
  --mat-select-trigger-text-tracking: normal;
  --mat-select-trigger-text-weight: 400;
}

html {
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
}

html {
  --mdc-dialog-subhead-font: Roboto;
  --mdc-dialog-subhead-line-height: 1.5rem;
  --mdc-dialog-subhead-size: 0.875rem;
  --mdc-dialog-subhead-weight: 400;
  --mdc-dialog-subhead-tracking: normal;
  --mdc-dialog-supporting-text-font: Roboto;
  --mdc-dialog-supporting-text-line-height: 1.5rem;
  --mdc-dialog-supporting-text-size: 0.875rem;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: normal;
}

.mat-mdc-standard-chip {
  --mdc-chip-container-shape-radius: 16px;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}

.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: Roboto;
  --mdc-chip-label-text-line-height: 1.5rem;
  --mdc-chip-label-text-size: 0.875rem;
  --mdc-chip-label-text-tracking: normal;
  --mdc-chip-label-text-weight: 400;
}

html {
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
}
html .mat-mdc-slide-toggle {
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-selected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}

html {
  --mdc-switch-state-layer-size: 40px;
}
html .mat-mdc-slide-toggle {
  --mat-switch-label-text-font: Roboto;
  --mat-switch-label-text-line-height: 1.5rem;
  --mat-switch-label-text-size: 0.875rem;
  --mat-switch-label-text-tracking: normal;
  --mat-switch-label-text-weight: 400;
}

html {
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
}

html {
  --mdc-radio-state-layer-size: 40px;
  --mat-radio-touch-target-display: block;
}

html {
  --mat-radio-label-text-font: Roboto;
  --mat-radio-label-text-line-height: 1.5rem;
  --mat-radio-label-text-size: 0.875rem;
  --mat-radio-label-text-tracking: normal;
  --mat-radio-label-text-weight: 400;
}

html {
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
}

html {
  --mdc-slider-label-label-text-font: Roboto;
  --mdc-slider-label-label-text-size: 0.875rem;
  --mdc-slider-label-label-text-line-height: 1.25rem;
  --mdc-slider-label-label-text-tracking: normal;
  --mdc-slider-label-label-text-weight: 600;
}

html {
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-base-elevation-level: 8;
}

html {
  --mat-menu-item-label-text-font: Roboto;
  --mat-menu-item-label-text-size: 0.875rem;
  --mat-menu-item-label-text-tracking: normal;
  --mat-menu-item-label-text-line-height: 1.5rem;
  --mat-menu-item-label-text-weight: 400;
}

html {
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 4px;
}

html {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
  --mat-list-list-item-leading-icon-start-space: 16px;
  --mat-list-list-item-leading-icon-end-space: 32px;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

html {
  --mdc-list-list-item-label-text-font: Roboto;
  --mdc-list-list-item-label-text-line-height: 1.5rem;
  --mdc-list-list-item-label-text-size: 0.875rem;
  --mdc-list-list-item-label-text-tracking: normal;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: Roboto;
  --mdc-list-list-item-supporting-text-line-height: 1.5rem;
  --mdc-list-list-item-supporting-text-size: 0.875rem;
  --mdc-list-list-item-supporting-text-tracking: normal;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: Roboto;
  --mdc-list-list-item-trailing-supporting-text-line-height: 1rem;
  --mdc-list-list-item-trailing-supporting-text-size: 0.75rem;
  --mdc-list-list-item-trailing-supporting-text-tracking: normal;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}

.mdc-list-group__subheader {
  font: 400 1rem / 1.75rem Roboto;
  letter-spacing: normal;
}

html {
  --mat-paginator-container-size: 56px;
  --mat-paginator-form-field-container-height: 40px;
  --mat-paginator-form-field-container-vertical-padding: 8px;
  --mat-paginator-touch-target-display: block;
}

html {
  --mat-paginator-container-text-font: Roboto;
  --mat-paginator-container-text-line-height: 1rem;
  --mat-paginator-container-text-size: 0.75rem;
  --mat-paginator-container-text-tracking: normal;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-select-trigger-text-size: 0.75rem;
}

html {
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Roboto;
  --mat-tab-header-label-text-size: 0.875rem;
  --mat-tab-header-label-text-tracking: normal;
  --mat-tab-header-label-text-line-height: 0.875rem;
  --mat-tab-header-label-text-weight: 500;
}

html {
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
}

html {
  --mdc-checkbox-state-layer-size: 40px;
  --mat-checkbox-touch-target-display: block;
}

html {
  --mat-checkbox-label-text-font: Roboto;
  --mat-checkbox-label-text-line-height: 1.5rem;
  --mat-checkbox-label-text-size: 0.875rem;
  --mat-checkbox-label-text-tracking: normal;
  --mat-checkbox-label-text-weight: 400;
}

html {
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
}

html {
  --mdc-text-button-container-height: 36px;
  --mdc-filled-button-container-height: 36px;
  --mdc-protected-button-container-height: 36px;
  --mdc-outlined-button-container-height: 36px;
  --mat-text-button-touch-target-display: block;
  --mat-filled-button-touch-target-display: block;
  --mat-protected-button-touch-target-display: block;
  --mat-outlined-button-touch-target-display: block;
}

html {
  --mdc-text-button-label-text-font: Roboto;
  --mdc-text-button-label-text-size: 0.875rem;
  --mdc-text-button-label-text-tracking: normal;
  --mdc-text-button-label-text-weight: 500;
  --mdc-text-button-label-text-transform: none;
  --mdc-filled-button-label-text-font: Roboto;
  --mdc-filled-button-label-text-size: 0.875rem;
  --mdc-filled-button-label-text-tracking: normal;
  --mdc-filled-button-label-text-weight: 500;
  --mdc-filled-button-label-text-transform: none;
  --mdc-protected-button-label-text-font: Roboto;
  --mdc-protected-button-label-text-size: 0.875rem;
  --mdc-protected-button-label-text-tracking: normal;
  --mdc-protected-button-label-text-weight: 500;
  --mdc-protected-button-label-text-transform: none;
  --mdc-outlined-button-label-text-font: Roboto;
  --mdc-outlined-button-label-text-size: 0.875rem;
  --mdc-outlined-button-label-text-tracking: normal;
  --mdc-outlined-button-label-text-weight: 500;
  --mdc-outlined-button-label-text-transform: none;
}

html {
  --mdc-icon-button-icon-size: 24px;
}

html {
  --mat-icon-button-touch-target-display: block;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

html {
  --mdc-fab-container-shape: 50%;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

html {
  --mat-fab-touch-target-display: block;
  --mat-fab-small-touch-target-display: block;
}

html {
  --mdc-extended-fab-label-text-font: Roboto;
  --mdc-extended-fab-label-text-size: 0.875rem;
  --mdc-extended-fab-label-text-tracking: normal;
  --mdc-extended-fab-label-text-weight: 500;
}

html {
  --mdc-snackbar-container-shape: 4px;
}

html {
  --mdc-snackbar-supporting-text-font: Roboto;
  --mdc-snackbar-supporting-text-line-height: 1.5rem;
  --mdc-snackbar-supporting-text-size: 0.875rem;
  --mdc-snackbar-supporting-text-weight: 400;
}

html {
  --mat-table-row-item-outline-width: 1px;
}

html {
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
}

html {
  --mat-table-header-headline-font: Roboto;
  --mat-table-header-headline-line-height: 1.25rem;
  --mat-table-header-headline-size: 0.875rem;
  --mat-table-header-headline-weight: 600;
  --mat-table-header-headline-tracking: normal;
  --mat-table-row-item-label-text-font: Roboto;
  --mat-table-row-item-label-text-line-height: 1.5rem;
  --mat-table-row-item-label-text-size: 0.875rem;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: normal;
  --mat-table-footer-supporting-text-font: Roboto;
  --mat-table-footer-supporting-text-line-height: 1.5rem;
  --mat-table-footer-supporting-text-size: 0.875rem;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: normal;
}

html {
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
}

html {
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
}

html {
  --mat-badge-text-font: Roboto;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-large-size-text-size: 24px;
}

html {
  --mat-bottom-sheet-container-shape: 4px;
}

html {
  --mat-bottom-sheet-container-text-font: Roboto;
  --mat-bottom-sheet-container-text-line-height: 1.5rem;
  --mat-bottom-sheet-container-text-size: 0.875rem;
  --mat-bottom-sheet-container-text-tracking: normal;
  --mat-bottom-sheet-container-text-weight: 400;
}

html {
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
}

html {
  --mat-standard-button-toggle-height: 48px;
}

html {
  --mat-legacy-button-toggle-label-text-font: Roboto;
  --mat-legacy-button-toggle-label-text-line-height: 1.5rem;
  --mat-legacy-button-toggle-label-text-size: 0.875rem;
  --mat-legacy-button-toggle-label-text-tracking: normal;
  --mat-legacy-button-toggle-label-text-weight: 400;
  --mat-standard-button-toggle-label-text-font: Roboto;
  --mat-standard-button-toggle-label-text-line-height: 1.5rem;
  --mat-standard-button-toggle-label-text-size: 0.875rem;
  --mat-standard-button-toggle-label-text-tracking: normal;
  --mat-standard-button-toggle-label-text-weight: 400;
}

html {
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-calendar-controls {
  --mat-icon-button-touch-target-display: none;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}

html {
  --mat-datepicker-calendar-text-font: Roboto;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 0.875rem;
  --mat-datepicker-calendar-body-label-text-weight: 500;
  --mat-datepicker-calendar-period-button-text-size: 0.875rem;
  --mat-datepicker-calendar-period-button-text-weight: 500;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
}

html {
  --mat-divider-width: 1px;
}

html {
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
}

html {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
}

html {
  --mat-expansion-header-text-font: Roboto;
  --mat-expansion-header-text-size: 0.875rem;
  --mat-expansion-header-text-weight: 600;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: Roboto;
  --mat-expansion-container-text-line-height: 1.5rem;
  --mat-expansion-container-text-size: 0.875rem;
  --mat-expansion-container-text-tracking: normal;
  --mat-expansion-container-text-weight: 400;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 0.875rem;
  --mat-grid-list-tile-header-secondary-text-size: 0.75rem;
  --mat-grid-list-tile-footer-primary-text-size: 0.875rem;
  --mat-grid-list-tile-footer-secondary-text-size: 0.75rem;
}

html {
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
}

html {
  --mat-stepper-header-height: 72px;
}

html {
  --mat-stepper-container-text-font: Roboto;
  --mat-stepper-header-label-text-font: Roboto;
  --mat-stepper-header-label-text-size: 0.875rem;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 0.875rem;
  --mat-stepper-header-selected-state-label-text-size: 0.875rem;
  --mat-stepper-header-selected-state-label-text-weight: 400;
}

html {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}

html {
  --mat-toolbar-title-text-font: Roboto;
  --mat-toolbar-title-text-line-height: 1.5rem;
  --mat-toolbar-title-text-size: 0.875rem;
  --mat-toolbar-title-text-tracking: normal;
  --mat-toolbar-title-text-weight: 400;
}

html {
  --mat-tree-node-min-height: 48px;
}

html {
  --mat-tree-node-text-font: Roboto;
  --mat-tree-node-text-size: 0.875rem;
  --mat-tree-node-text-weight: 400;
}

.svc-default {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-option-selected-state-label-text-color: #3b82f6;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-full-pseudo-checkbox-selected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #64748b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mdc-filled-text-field-caret-color: #3b82f6;
  --mdc-filled-text-field-focus-active-indicator-color: #3b82f6;
  --mdc-filled-text-field-focus-label-text-color: rgba(59, 130, 246, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #c00000;
  --mdc-filled-text-field-error-focus-label-text-color: #c00000;
  --mdc-filled-text-field-error-label-text-color: #c00000;
  --mdc-filled-text-field-error-caret-color: #c00000;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #c00000;
  --mdc-filled-text-field-error-focus-active-indicator-color: #c00000;
  --mdc-filled-text-field-error-hover-active-indicator-color: #c00000;
  --mdc-outlined-text-field-caret-color: #3b82f6;
  --mdc-outlined-text-field-focus-outline-color: #3b82f6;
  --mdc-outlined-text-field-focus-label-text-color: rgba(59, 130, 246, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #c00000;
  --mdc-outlined-text-field-error-focus-label-text-color: #c00000;
  --mdc-outlined-text-field-error-label-text-color: #c00000;
  --mdc-outlined-text-field-error-hover-label-text-color: #c00000;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #c00000;
  --mdc-outlined-text-field-error-hover-outline-color: #c00000;
  --mdc-outlined-text-field-error-outline-color: #c00000;
  --mat-form-field-focus-select-arrow-color: rgba(59, 130, 246, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #c00000;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(59, 130, 246, 0.87);
  --mat-select-invalid-arrow-color: rgba(192, 0, 0, 0.87);
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: white;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-selected-focus-state-layer-color: #2563eb;
  --mdc-switch-selected-handle-color: #2563eb;
  --mdc-switch-selected-hover-state-layer-color: #2563eb;
  --mdc-switch-selected-pressed-state-layer-color: #2563eb;
  --mdc-switch-selected-focus-handle-color: #1e3a8a;
  --mdc-switch-selected-hover-handle-color: #1e3a8a;
  --mdc-switch-selected-pressed-handle-color: #1e3a8a;
  --mdc-switch-selected-focus-track-color: #93c5fd;
  --mdc-switch-selected-hover-track-color: #93c5fd;
  --mdc-switch-selected-pressed-track-color: #93c5fd;
  --mdc-switch-selected-track-color: #93c5fd;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: #fff;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-handle-color: #3b82f6;
  --mdc-slider-focus-handle-color: #3b82f6;
  --mdc-slider-hover-handle-color: #3b82f6;
  --mdc-slider-active-track-color: #3b82f6;
  --mdc-slider-inactive-track-color: #3b82f6;
  --mdc-slider-with-tick-marks-inactive-container-color: #3b82f6;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-slider-ripple-color: #3b82f6;
  --mat-slider-hover-state-layer-color: rgba(59, 130, 246, 0.05);
  --mat-slider-focus-state-layer-color: rgba(59, 130, 246, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-base-elevation-level: 8;
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: rgba(0, 0, 0, 0.12);
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 4px;
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #64748b;
  --mdc-checkbox-selected-hover-icon-color: #64748b;
  --mdc-checkbox-selected-icon-color: #64748b;
  --mdc-checkbox-selected-pressed-icon-color: #64748b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #64748b;
  --mdc-checkbox-selected-hover-state-layer-color: #64748b;
  --mdc-checkbox-selected-pressed-state-layer-color: #64748b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-checkbox-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
  --mdc-text-button-label-text-color: black;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
  --mdc-icon-button-icon-size: 24px;
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
  --mdc-fab-container-shape: 50%;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-color: white;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-fab-small-container-color: white;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #64748b;
  --mat-table-row-item-outline-width: 1px;
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mdc-circular-progress-active-indicator-color: #3b82f6;
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
  --mat-badge-background-color: #3b82f6;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-bottom-sheet-container-shape: 4px;
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #3b82f6;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(59, 130, 246, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(59, 130, 246, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(59, 130, 246, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #3b82f6;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(59, 130, 246, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-text-color: black;
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-divider-width: 1px;
  --mat-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
  --mat-icon-color: inherit;
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #3b82f6;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #3b82f6;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #3b82f6;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #c00000;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #c00000;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #757575;
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: rgba(0, 0, 0, 0.87);
}
.svc-default .mat-accent {
  --mat-option-selected-state-label-text-color: #64748b;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.svc-default .mat-warn {
  --mat-option-selected-state-label-text-color: #c00000;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.svc-default .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #3b82f6;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #3b82f6;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.svc-default .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #64748b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.svc-default .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #c00000;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #c00000;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.svc-default .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #3b82f6;
  --mdc-linear-progress-track-color: rgba(59, 130, 246, 0.25);
}
.svc-default .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #64748b;
  --mdc-linear-progress-track-color: rgba(100, 116, 139, 0.25);
}
.svc-default .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #c00000;
  --mdc-linear-progress-track-color: rgba(192, 0, 0, 0.25);
}
.svc-default .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #64748b;
  --mdc-filled-text-field-focus-active-indicator-color: #64748b;
  --mdc-filled-text-field-focus-label-text-color: rgba(100, 116, 139, 0.87);
  --mdc-outlined-text-field-caret-color: #64748b;
  --mdc-outlined-text-field-focus-outline-color: #64748b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(100, 116, 139, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(100, 116, 139, 0.87);
}
.svc-default .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #c00000;
  --mdc-filled-text-field-focus-active-indicator-color: #c00000;
  --mdc-filled-text-field-focus-label-text-color: rgba(192, 0, 0, 0.87);
  --mdc-outlined-text-field-caret-color: #c00000;
  --mdc-outlined-text-field-focus-outline-color: #c00000;
  --mdc-outlined-text-field-focus-label-text-color: rgba(192, 0, 0, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(192, 0, 0, 0.87);
}
.svc-default .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(100, 116, 139, 0.87);
  --mat-select-invalid-arrow-color: rgba(192, 0, 0, 0.87);
}
.svc-default .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(192, 0, 0, 0.87);
  --mat-select-invalid-arrow-color: rgba(192, 0, 0, 0.87);
}
.svc-default .mat-mdc-standard-chip {
  --mdc-chip-container-shape-radius: 16px;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}
.svc-default .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.svc-default .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .svc-default .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #3b82f6;
  --mdc-chip-elevated-selected-container-color: #3b82f6;
  --mdc-chip-elevated-disabled-container-color: #3b82f6;
  --mdc-chip-flat-disabled-selected-container-color: #3b82f6;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.svc-default .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .svc-default .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #64748b;
  --mdc-chip-elevated-selected-container-color: #64748b;
  --mdc-chip-elevated-disabled-container-color: #64748b;
  --mdc-chip-flat-disabled-selected-container-color: #64748b;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.svc-default .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .svc-default .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: #fef2f2;
  --mdc-chip-elevated-container-color: #c00000;
  --mdc-chip-elevated-selected-container-color: #c00000;
  --mdc-chip-elevated-disabled-container-color: #c00000;
  --mdc-chip-flat-disabled-selected-container-color: #c00000;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #fef2f2;
  --mdc-chip-selected-label-text-color: #fef2f2;
  --mdc-chip-with-icon-icon-color: #fef2f2;
  --mdc-chip-with-icon-disabled-icon-color: #fef2f2;
  --mdc-chip-with-icon-selected-icon-color: #fef2f2;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fef2f2;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fef2f2;
  --mat-chip-selected-disabled-trailing-icon-color: #fef2f2;
  --mat-chip-selected-trailing-icon-color: #fef2f2;
}
.svc-default .mat-mdc-slide-toggle {
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-selected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}
.svc-default .mat-mdc-slide-toggle {
  --mat-switch-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-default .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #475569;
  --mdc-switch-selected-handle-color: #475569;
  --mdc-switch-selected-hover-state-layer-color: #475569;
  --mdc-switch-selected-pressed-state-layer-color: #475569;
  --mdc-switch-selected-focus-handle-color: #0f172a;
  --mdc-switch-selected-hover-handle-color: #0f172a;
  --mdc-switch-selected-pressed-handle-color: #0f172a;
  --mdc-switch-selected-focus-track-color: #cbd5e1;
  --mdc-switch-selected-hover-track-color: #cbd5e1;
  --mdc-switch-selected-pressed-track-color: #cbd5e1;
  --mdc-switch-selected-track-color: #cbd5e1;
}
.svc-default .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #dc2626;
  --mdc-switch-selected-handle-color: #dc2626;
  --mdc-switch-selected-hover-state-layer-color: #dc2626;
  --mdc-switch-selected-pressed-state-layer-color: #dc2626;
  --mdc-switch-selected-focus-handle-color: #7f1d1d;
  --mdc-switch-selected-hover-handle-color: #7f1d1d;
  --mdc-switch-selected-pressed-handle-color: #7f1d1d;
  --mdc-switch-selected-focus-track-color: #fca5a5;
  --mdc-switch-selected-hover-track-color: #fca5a5;
  --mdc-switch-selected-pressed-track-color: #fca5a5;
  --mdc-switch-selected-track-color: #fca5a5;
}
.svc-default .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #3b82f6;
  --mdc-radio-selected-hover-icon-color: #3b82f6;
  --mdc-radio-selected-icon-color: #3b82f6;
  --mdc-radio-selected-pressed-icon-color: #3b82f6;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #3b82f6;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-default .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #64748b;
  --mdc-radio-selected-hover-icon-color: #64748b;
  --mdc-radio-selected-icon-color: #64748b;
  --mdc-radio-selected-pressed-icon-color: #64748b;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #64748b;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-default .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #c00000;
  --mdc-radio-selected-hover-icon-color: #c00000;
  --mdc-radio-selected-icon-color: #c00000;
  --mdc-radio-selected-pressed-icon-color: #c00000;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #c00000;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-default .mat-accent {
  --mdc-slider-handle-color: #64748b;
  --mdc-slider-focus-handle-color: #64748b;
  --mdc-slider-hover-handle-color: #64748b;
  --mdc-slider-active-track-color: #64748b;
  --mdc-slider-inactive-track-color: #64748b;
  --mdc-slider-with-tick-marks-inactive-container-color: #64748b;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mat-slider-ripple-color: #64748b;
  --mat-slider-hover-state-layer-color: rgba(100, 116, 139, 0.05);
  --mat-slider-focus-state-layer-color: rgba(100, 116, 139, 0.2);
}
.svc-default .mat-warn {
  --mdc-slider-handle-color: #c00000;
  --mdc-slider-focus-handle-color: #c00000;
  --mdc-slider-hover-handle-color: #c00000;
  --mdc-slider-active-track-color: #c00000;
  --mdc-slider-inactive-track-color: #c00000;
  --mdc-slider-with-tick-marks-inactive-container-color: #c00000;
  --mdc-slider-with-tick-marks-active-container-color: #fef2f2;
  --mat-slider-ripple-color: #c00000;
  --mat-slider-hover-state-layer-color: rgba(192, 0, 0, 0.05);
  --mat-slider-focus-state-layer-color: rgba(192, 0, 0, 0.2);
}
.svc-default .mdc-list-item__start,
.svc-default .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #3b82f6;
  --mdc-radio-selected-hover-icon-color: #3b82f6;
  --mdc-radio-selected-icon-color: #3b82f6;
  --mdc-radio-selected-pressed-icon-color: #3b82f6;
}
.svc-default .mat-accent .mdc-list-item__start,
.svc-default .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #64748b;
  --mdc-radio-selected-hover-icon-color: #64748b;
  --mdc-radio-selected-icon-color: #64748b;
  --mdc-radio-selected-pressed-icon-color: #64748b;
}
.svc-default .mat-warn .mdc-list-item__start,
.svc-default .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #c00000;
  --mdc-radio-selected-hover-icon-color: #c00000;
  --mdc-radio-selected-icon-color: #c00000;
  --mdc-radio-selected-pressed-icon-color: #c00000;
}
.svc-default .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #3b82f6;
  --mdc-checkbox-selected-hover-icon-color: #3b82f6;
  --mdc-checkbox-selected-icon-color: #3b82f6;
  --mdc-checkbox-selected-pressed-icon-color: #3b82f6;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #3b82f6;
  --mdc-checkbox-selected-hover-state-layer-color: #3b82f6;
  --mdc-checkbox-selected-pressed-state-layer-color: #3b82f6;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-default .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #64748b;
  --mdc-checkbox-selected-hover-icon-color: #64748b;
  --mdc-checkbox-selected-icon-color: #64748b;
  --mdc-checkbox-selected-pressed-icon-color: #64748b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #64748b;
  --mdc-checkbox-selected-hover-state-layer-color: #64748b;
  --mdc-checkbox-selected-pressed-state-layer-color: #64748b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-default .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fef2f2;
  --mdc-checkbox-selected-focus-icon-color: #c00000;
  --mdc-checkbox-selected-hover-icon-color: #c00000;
  --mdc-checkbox-selected-icon-color: #c00000;
  --mdc-checkbox-selected-pressed-icon-color: #c00000;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #c00000;
  --mdc-checkbox-selected-hover-state-layer-color: #c00000;
  --mdc-checkbox-selected-pressed-state-layer-color: #c00000;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-default .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.svc-default .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__start,
.svc-default .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.svc-default .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__start {
  color: #3b82f6;
}
.svc-default .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.svc-default .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.svc-default .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.svc-default .mat-mdc-tab-group, .svc-default .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #3b82f6;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #3b82f6;
  --mat-tab-header-active-ripple-color: #3b82f6;
  --mat-tab-header-inactive-ripple-color: #3b82f6;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #3b82f6;
  --mat-tab-header-active-hover-label-text-color: #3b82f6;
  --mat-tab-header-active-focus-indicator-color: #3b82f6;
  --mat-tab-header-active-hover-indicator-color: #3b82f6;
}
.svc-default .mat-mdc-tab-group.mat-accent, .svc-default .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #64748b;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #64748b;
  --mat-tab-header-active-ripple-color: #64748b;
  --mat-tab-header-inactive-ripple-color: #64748b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #64748b;
  --mat-tab-header-active-hover-label-text-color: #64748b;
  --mat-tab-header-active-focus-indicator-color: #64748b;
  --mat-tab-header-active-hover-indicator-color: #64748b;
}
.svc-default .mat-mdc-tab-group.mat-warn, .svc-default .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #c00000;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #c00000;
  --mat-tab-header-active-ripple-color: #c00000;
  --mat-tab-header-inactive-ripple-color: #c00000;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #c00000;
  --mat-tab-header-active-hover-label-text-color: #c00000;
  --mat-tab-header-active-focus-indicator-color: #c00000;
  --mat-tab-header-active-hover-indicator-color: #c00000;
}
.svc-default .mat-mdc-tab-group.mat-background-primary, .svc-default .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #3b82f6;
  --mat-tab-header-with-background-foreground-color: white;
}
.svc-default .mat-mdc-tab-group.mat-background-accent, .svc-default .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #64748b;
  --mat-tab-header-with-background-foreground-color: white;
}
.svc-default .mat-mdc-tab-group.mat-background-warn, .svc-default .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #c00000;
  --mat-tab-header-with-background-foreground-color: #fef2f2;
}
.svc-default .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #3b82f6;
  --mdc-checkbox-selected-hover-icon-color: #3b82f6;
  --mdc-checkbox-selected-icon-color: #3b82f6;
  --mdc-checkbox-selected-pressed-icon-color: #3b82f6;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #3b82f6;
  --mdc-checkbox-selected-hover-state-layer-color: #3b82f6;
  --mdc-checkbox-selected-pressed-state-layer-color: #3b82f6;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-default .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fef2f2;
  --mdc-checkbox-selected-focus-icon-color: #c00000;
  --mdc-checkbox-selected-hover-icon-color: #c00000;
  --mdc-checkbox-selected-icon-color: #c00000;
  --mdc-checkbox-selected-pressed-icon-color: #c00000;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #c00000;
  --mdc-checkbox-selected-hover-state-layer-color: #c00000;
  --mdc-checkbox-selected-pressed-state-layer-color: #c00000;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-default .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #3b82f6;
  --mat-text-button-state-layer-color: #3b82f6;
  --mat-text-button-ripple-color: rgba(59, 130, 246, 0.1);
}
.svc-default .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #64748b;
  --mat-text-button-state-layer-color: #64748b;
  --mat-text-button-ripple-color: rgba(100, 116, 139, 0.1);
}
.svc-default .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #c00000;
  --mat-text-button-state-layer-color: #c00000;
  --mat-text-button-ripple-color: rgba(192, 0, 0, 0.1);
}
.svc-default .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #3b82f6;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-default .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #64748b;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-default .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #c00000;
  --mdc-filled-button-label-text-color: #fef2f2;
  --mat-filled-button-state-layer-color: #fef2f2;
  --mat-filled-button-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-default .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #3b82f6;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-default .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #64748b;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-default .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #c00000;
  --mdc-protected-button-label-text-color: #fef2f2;
  --mat-protected-button-state-layer-color: #fef2f2;
  --mat-protected-button-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-default .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #3b82f6;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #3b82f6;
  --mat-outlined-button-ripple-color: rgba(59, 130, 246, 0.1);
}
.svc-default .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #64748b;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #64748b;
  --mat-outlined-button-ripple-color: rgba(100, 116, 139, 0.1);
}
.svc-default .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #c00000;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #c00000;
  --mat-outlined-button-ripple-color: rgba(192, 0, 0, 0.1);
}
.svc-default .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #3b82f6;
  --mat-icon-button-state-layer-color: #3b82f6;
  --mat-icon-button-ripple-color: rgba(59, 130, 246, 0.1);
}
.svc-default .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #64748b;
  --mat-icon-button-state-layer-color: #64748b;
  --mat-icon-button-ripple-color: rgba(100, 116, 139, 0.1);
}
.svc-default .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #c00000;
  --mat-icon-button-state-layer-color: #c00000;
  --mat-icon-button-ripple-color: rgba(192, 0, 0, 0.1);
}
.svc-default .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #3b82f6;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-default .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #64748b;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-default .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #c00000;
  --mat-fab-foreground-color: #fef2f2;
  --mat-fab-state-layer-color: #fef2f2;
  --mat-fab-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-default .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #3b82f6;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-default .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #64748b;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-default .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #c00000;
  --mat-fab-small-foreground-color: #fef2f2;
  --mat-fab-small-state-layer-color: #fef2f2;
  --mat-fab-small-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-default .mat-accent {
  --mdc-circular-progress-active-indicator-color: #64748b;
}
.svc-default .mat-warn {
  --mdc-circular-progress-active-indicator-color: #c00000;
}
.svc-default .mat-badge-accent {
  --mat-badge-background-color: #64748b;
  --mat-badge-text-color: white;
}
.svc-default .mat-badge-warn {
  --mat-badge-background-color: #c00000;
  --mat-badge-text-color: #fef2f2;
}
.svc-default .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #64748b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(100, 116, 139, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(100, 116, 139, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(100, 116, 139, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(100, 116, 139, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.svc-default .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: #fef2f2;
  --mat-datepicker-calendar-date-selected-state-background-color: #c00000;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(192, 0, 0, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #fef2f2;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(192, 0, 0, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(192, 0, 0, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(192, 0, 0, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.svc-default .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #64748b;
}
.svc-default .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #c00000;
}
.svc-default .mat-icon.mat-primary {
  --mat-icon-color: #3b82f6;
}
.svc-default .mat-icon.mat-accent {
  --mat-icon-color: #64748b;
}
.svc-default .mat-icon.mat-warn {
  --mat-icon-color: #c00000;
}
.svc-default .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #64748b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #64748b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #64748b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.svc-default .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: #fef2f2;
  --mat-stepper-header-selected-state-icon-background-color: #c00000;
  --mat-stepper-header-selected-state-icon-foreground-color: #fef2f2;
  --mat-stepper-header-done-state-icon-background-color: #c00000;
  --mat-stepper-header-done-state-icon-foreground-color: #fef2f2;
  --mat-stepper-header-edit-state-icon-background-color: #c00000;
  --mat-stepper-header-edit-state-icon-foreground-color: #fef2f2;
}
.svc-default .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #3b82f6;
  --mat-toolbar-container-text-color: white;
}
.svc-default .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #64748b;
  --mat-toolbar-container-text-color: white;
}
.svc-default .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #c00000;
  --mat-toolbar-container-text-color: #fef2f2;
}

.svc-00274C {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-option-selected-state-label-text-color: #00274c;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-full-pseudo-checkbox-selected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #64748b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mdc-filled-text-field-caret-color: #00274c;
  --mdc-filled-text-field-focus-active-indicator-color: #00274c;
  --mdc-filled-text-field-focus-label-text-color: rgba(0, 39, 76, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #c00000;
  --mdc-filled-text-field-error-focus-label-text-color: #c00000;
  --mdc-filled-text-field-error-label-text-color: #c00000;
  --mdc-filled-text-field-error-caret-color: #c00000;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #c00000;
  --mdc-filled-text-field-error-focus-active-indicator-color: #c00000;
  --mdc-filled-text-field-error-hover-active-indicator-color: #c00000;
  --mdc-outlined-text-field-caret-color: #00274c;
  --mdc-outlined-text-field-focus-outline-color: #00274c;
  --mdc-outlined-text-field-focus-label-text-color: rgba(0, 39, 76, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #c00000;
  --mdc-outlined-text-field-error-focus-label-text-color: #c00000;
  --mdc-outlined-text-field-error-label-text-color: #c00000;
  --mdc-outlined-text-field-error-hover-label-text-color: #c00000;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #c00000;
  --mdc-outlined-text-field-error-hover-outline-color: #c00000;
  --mdc-outlined-text-field-error-outline-color: #c00000;
  --mat-form-field-focus-select-arrow-color: rgba(0, 39, 76, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #c00000;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(0, 39, 76, 0.87);
  --mat-select-invalid-arrow-color: rgba(192, 0, 0, 0.87);
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: white;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-selected-focus-state-layer-color: #001f3d;
  --mdc-switch-selected-handle-color: #001f3d;
  --mdc-switch-selected-hover-state-layer-color: #001f3d;
  --mdc-switch-selected-pressed-state-layer-color: #001f3d;
  --mdc-switch-selected-focus-handle-color: #00080f;
  --mdc-switch-selected-hover-handle-color: #00080f;
  --mdc-switch-selected-pressed-handle-color: #00080f;
  --mdc-switch-selected-focus-track-color: #667d94;
  --mdc-switch-selected-hover-track-color: #667d94;
  --mdc-switch-selected-pressed-track-color: #667d94;
  --mdc-switch-selected-track-color: #667d94;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: #fff;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-handle-color: #00274c;
  --mdc-slider-focus-handle-color: #00274c;
  --mdc-slider-hover-handle-color: #00274c;
  --mdc-slider-active-track-color: #00274c;
  --mdc-slider-inactive-track-color: #00274c;
  --mdc-slider-with-tick-marks-inactive-container-color: #00274c;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-slider-ripple-color: #00274c;
  --mat-slider-hover-state-layer-color: rgba(0, 39, 76, 0.05);
  --mat-slider-focus-state-layer-color: rgba(0, 39, 76, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-base-elevation-level: 8;
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: rgba(0, 0, 0, 0.12);
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 4px;
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #64748b;
  --mdc-checkbox-selected-hover-icon-color: #64748b;
  --mdc-checkbox-selected-icon-color: #64748b;
  --mdc-checkbox-selected-pressed-icon-color: #64748b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #64748b;
  --mdc-checkbox-selected-hover-state-layer-color: #64748b;
  --mdc-checkbox-selected-pressed-state-layer-color: #64748b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-checkbox-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
  --mdc-text-button-label-text-color: black;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
  --mdc-icon-button-icon-size: 24px;
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
  --mdc-fab-container-shape: 50%;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-color: white;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-fab-small-container-color: white;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #64748b;
  --mat-table-row-item-outline-width: 1px;
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mdc-circular-progress-active-indicator-color: #00274c;
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
  --mat-badge-background-color: #00274c;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-bottom-sheet-container-shape: 4px;
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #00274c;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(0, 39, 76, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(0, 39, 76, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(0, 39, 76, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #00274c;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(0, 39, 76, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-text-color: black;
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-divider-width: 1px;
  --mat-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
  --mat-icon-color: inherit;
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #00274c;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #00274c;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #00274c;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #c00000;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #c00000;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #757575;
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: rgba(0, 0, 0, 0.87);
}
.svc-00274C .mat-accent {
  --mat-option-selected-state-label-text-color: #64748b;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.svc-00274C .mat-warn {
  --mat-option-selected-state-label-text-color: #c00000;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.svc-00274C .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #00274c;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #00274c;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.svc-00274C .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #64748b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.svc-00274C .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #c00000;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #c00000;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.svc-00274C .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #00274c;
  --mdc-linear-progress-track-color: rgba(0, 39, 76, 0.25);
}
.svc-00274C .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #64748b;
  --mdc-linear-progress-track-color: rgba(100, 116, 139, 0.25);
}
.svc-00274C .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #c00000;
  --mdc-linear-progress-track-color: rgba(192, 0, 0, 0.25);
}
.svc-00274C .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #64748b;
  --mdc-filled-text-field-focus-active-indicator-color: #64748b;
  --mdc-filled-text-field-focus-label-text-color: rgba(100, 116, 139, 0.87);
  --mdc-outlined-text-field-caret-color: #64748b;
  --mdc-outlined-text-field-focus-outline-color: #64748b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(100, 116, 139, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(100, 116, 139, 0.87);
}
.svc-00274C .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #c00000;
  --mdc-filled-text-field-focus-active-indicator-color: #c00000;
  --mdc-filled-text-field-focus-label-text-color: rgba(192, 0, 0, 0.87);
  --mdc-outlined-text-field-caret-color: #c00000;
  --mdc-outlined-text-field-focus-outline-color: #c00000;
  --mdc-outlined-text-field-focus-label-text-color: rgba(192, 0, 0, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(192, 0, 0, 0.87);
}
.svc-00274C .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(100, 116, 139, 0.87);
  --mat-select-invalid-arrow-color: rgba(192, 0, 0, 0.87);
}
.svc-00274C .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(192, 0, 0, 0.87);
  --mat-select-invalid-arrow-color: rgba(192, 0, 0, 0.87);
}
.svc-00274C .mat-mdc-standard-chip {
  --mdc-chip-container-shape-radius: 16px;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}
.svc-00274C .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.svc-00274C .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .svc-00274C .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #00274c;
  --mdc-chip-elevated-selected-container-color: #00274c;
  --mdc-chip-elevated-disabled-container-color: #00274c;
  --mdc-chip-flat-disabled-selected-container-color: #00274c;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.svc-00274C .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .svc-00274C .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #64748b;
  --mdc-chip-elevated-selected-container-color: #64748b;
  --mdc-chip-elevated-disabled-container-color: #64748b;
  --mdc-chip-flat-disabled-selected-container-color: #64748b;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.svc-00274C .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .svc-00274C .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: #fef2f2;
  --mdc-chip-elevated-container-color: #c00000;
  --mdc-chip-elevated-selected-container-color: #c00000;
  --mdc-chip-elevated-disabled-container-color: #c00000;
  --mdc-chip-flat-disabled-selected-container-color: #c00000;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #fef2f2;
  --mdc-chip-selected-label-text-color: #fef2f2;
  --mdc-chip-with-icon-icon-color: #fef2f2;
  --mdc-chip-with-icon-disabled-icon-color: #fef2f2;
  --mdc-chip-with-icon-selected-icon-color: #fef2f2;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fef2f2;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fef2f2;
  --mat-chip-selected-disabled-trailing-icon-color: #fef2f2;
  --mat-chip-selected-trailing-icon-color: #fef2f2;
}
.svc-00274C .mat-mdc-slide-toggle {
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-selected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}
.svc-00274C .mat-mdc-slide-toggle {
  --mat-switch-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-00274C .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #475569;
  --mdc-switch-selected-handle-color: #475569;
  --mdc-switch-selected-hover-state-layer-color: #475569;
  --mdc-switch-selected-pressed-state-layer-color: #475569;
  --mdc-switch-selected-focus-handle-color: #0f172a;
  --mdc-switch-selected-hover-handle-color: #0f172a;
  --mdc-switch-selected-pressed-handle-color: #0f172a;
  --mdc-switch-selected-focus-track-color: #cbd5e1;
  --mdc-switch-selected-hover-track-color: #cbd5e1;
  --mdc-switch-selected-pressed-track-color: #cbd5e1;
  --mdc-switch-selected-track-color: #cbd5e1;
}
.svc-00274C .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #dc2626;
  --mdc-switch-selected-handle-color: #dc2626;
  --mdc-switch-selected-hover-state-layer-color: #dc2626;
  --mdc-switch-selected-pressed-state-layer-color: #dc2626;
  --mdc-switch-selected-focus-handle-color: #7f1d1d;
  --mdc-switch-selected-hover-handle-color: #7f1d1d;
  --mdc-switch-selected-pressed-handle-color: #7f1d1d;
  --mdc-switch-selected-focus-track-color: #fca5a5;
  --mdc-switch-selected-hover-track-color: #fca5a5;
  --mdc-switch-selected-pressed-track-color: #fca5a5;
  --mdc-switch-selected-track-color: #fca5a5;
}
.svc-00274C .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #00274c;
  --mdc-radio-selected-hover-icon-color: #00274c;
  --mdc-radio-selected-icon-color: #00274c;
  --mdc-radio-selected-pressed-icon-color: #00274c;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #00274c;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-00274C .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #64748b;
  --mdc-radio-selected-hover-icon-color: #64748b;
  --mdc-radio-selected-icon-color: #64748b;
  --mdc-radio-selected-pressed-icon-color: #64748b;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #64748b;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-00274C .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #c00000;
  --mdc-radio-selected-hover-icon-color: #c00000;
  --mdc-radio-selected-icon-color: #c00000;
  --mdc-radio-selected-pressed-icon-color: #c00000;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #c00000;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-00274C .mat-accent {
  --mdc-slider-handle-color: #64748b;
  --mdc-slider-focus-handle-color: #64748b;
  --mdc-slider-hover-handle-color: #64748b;
  --mdc-slider-active-track-color: #64748b;
  --mdc-slider-inactive-track-color: #64748b;
  --mdc-slider-with-tick-marks-inactive-container-color: #64748b;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mat-slider-ripple-color: #64748b;
  --mat-slider-hover-state-layer-color: rgba(100, 116, 139, 0.05);
  --mat-slider-focus-state-layer-color: rgba(100, 116, 139, 0.2);
}
.svc-00274C .mat-warn {
  --mdc-slider-handle-color: #c00000;
  --mdc-slider-focus-handle-color: #c00000;
  --mdc-slider-hover-handle-color: #c00000;
  --mdc-slider-active-track-color: #c00000;
  --mdc-slider-inactive-track-color: #c00000;
  --mdc-slider-with-tick-marks-inactive-container-color: #c00000;
  --mdc-slider-with-tick-marks-active-container-color: #fef2f2;
  --mat-slider-ripple-color: #c00000;
  --mat-slider-hover-state-layer-color: rgba(192, 0, 0, 0.05);
  --mat-slider-focus-state-layer-color: rgba(192, 0, 0, 0.2);
}
.svc-00274C .mdc-list-item__start,
.svc-00274C .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #00274c;
  --mdc-radio-selected-hover-icon-color: #00274c;
  --mdc-radio-selected-icon-color: #00274c;
  --mdc-radio-selected-pressed-icon-color: #00274c;
}
.svc-00274C .mat-accent .mdc-list-item__start,
.svc-00274C .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #64748b;
  --mdc-radio-selected-hover-icon-color: #64748b;
  --mdc-radio-selected-icon-color: #64748b;
  --mdc-radio-selected-pressed-icon-color: #64748b;
}
.svc-00274C .mat-warn .mdc-list-item__start,
.svc-00274C .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #c00000;
  --mdc-radio-selected-hover-icon-color: #c00000;
  --mdc-radio-selected-icon-color: #c00000;
  --mdc-radio-selected-pressed-icon-color: #c00000;
}
.svc-00274C .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #00274c;
  --mdc-checkbox-selected-hover-icon-color: #00274c;
  --mdc-checkbox-selected-icon-color: #00274c;
  --mdc-checkbox-selected-pressed-icon-color: #00274c;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #00274c;
  --mdc-checkbox-selected-hover-state-layer-color: #00274c;
  --mdc-checkbox-selected-pressed-state-layer-color: #00274c;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-00274C .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #64748b;
  --mdc-checkbox-selected-hover-icon-color: #64748b;
  --mdc-checkbox-selected-icon-color: #64748b;
  --mdc-checkbox-selected-pressed-icon-color: #64748b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #64748b;
  --mdc-checkbox-selected-hover-state-layer-color: #64748b;
  --mdc-checkbox-selected-pressed-state-layer-color: #64748b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-00274C .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fef2f2;
  --mdc-checkbox-selected-focus-icon-color: #c00000;
  --mdc-checkbox-selected-hover-icon-color: #c00000;
  --mdc-checkbox-selected-icon-color: #c00000;
  --mdc-checkbox-selected-pressed-icon-color: #c00000;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #c00000;
  --mdc-checkbox-selected-hover-state-layer-color: #c00000;
  --mdc-checkbox-selected-pressed-state-layer-color: #c00000;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-00274C .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.svc-00274C .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__start,
.svc-00274C .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.svc-00274C .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__start {
  color: #00274c;
}
.svc-00274C .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.svc-00274C .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.svc-00274C .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.svc-00274C .mat-mdc-tab-group, .svc-00274C .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #00274c;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #00274c;
  --mat-tab-header-active-ripple-color: #00274c;
  --mat-tab-header-inactive-ripple-color: #00274c;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #00274c;
  --mat-tab-header-active-hover-label-text-color: #00274c;
  --mat-tab-header-active-focus-indicator-color: #00274c;
  --mat-tab-header-active-hover-indicator-color: #00274c;
}
.svc-00274C .mat-mdc-tab-group.mat-accent, .svc-00274C .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #64748b;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #64748b;
  --mat-tab-header-active-ripple-color: #64748b;
  --mat-tab-header-inactive-ripple-color: #64748b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #64748b;
  --mat-tab-header-active-hover-label-text-color: #64748b;
  --mat-tab-header-active-focus-indicator-color: #64748b;
  --mat-tab-header-active-hover-indicator-color: #64748b;
}
.svc-00274C .mat-mdc-tab-group.mat-warn, .svc-00274C .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #c00000;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #c00000;
  --mat-tab-header-active-ripple-color: #c00000;
  --mat-tab-header-inactive-ripple-color: #c00000;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #c00000;
  --mat-tab-header-active-hover-label-text-color: #c00000;
  --mat-tab-header-active-focus-indicator-color: #c00000;
  --mat-tab-header-active-hover-indicator-color: #c00000;
}
.svc-00274C .mat-mdc-tab-group.mat-background-primary, .svc-00274C .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #00274c;
  --mat-tab-header-with-background-foreground-color: white;
}
.svc-00274C .mat-mdc-tab-group.mat-background-accent, .svc-00274C .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #64748b;
  --mat-tab-header-with-background-foreground-color: white;
}
.svc-00274C .mat-mdc-tab-group.mat-background-warn, .svc-00274C .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #c00000;
  --mat-tab-header-with-background-foreground-color: #fef2f2;
}
.svc-00274C .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #00274c;
  --mdc-checkbox-selected-hover-icon-color: #00274c;
  --mdc-checkbox-selected-icon-color: #00274c;
  --mdc-checkbox-selected-pressed-icon-color: #00274c;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #00274c;
  --mdc-checkbox-selected-hover-state-layer-color: #00274c;
  --mdc-checkbox-selected-pressed-state-layer-color: #00274c;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-00274C .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fef2f2;
  --mdc-checkbox-selected-focus-icon-color: #c00000;
  --mdc-checkbox-selected-hover-icon-color: #c00000;
  --mdc-checkbox-selected-icon-color: #c00000;
  --mdc-checkbox-selected-pressed-icon-color: #c00000;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #c00000;
  --mdc-checkbox-selected-hover-state-layer-color: #c00000;
  --mdc-checkbox-selected-pressed-state-layer-color: #c00000;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-00274C .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #00274c;
  --mat-text-button-state-layer-color: #00274c;
  --mat-text-button-ripple-color: rgba(0, 39, 76, 0.1);
}
.svc-00274C .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #64748b;
  --mat-text-button-state-layer-color: #64748b;
  --mat-text-button-ripple-color: rgba(100, 116, 139, 0.1);
}
.svc-00274C .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #c00000;
  --mat-text-button-state-layer-color: #c00000;
  --mat-text-button-ripple-color: rgba(192, 0, 0, 0.1);
}
.svc-00274C .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #00274c;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-00274C .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #64748b;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-00274C .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #c00000;
  --mdc-filled-button-label-text-color: #fef2f2;
  --mat-filled-button-state-layer-color: #fef2f2;
  --mat-filled-button-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-00274C .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #00274c;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-00274C .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #64748b;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-00274C .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #c00000;
  --mdc-protected-button-label-text-color: #fef2f2;
  --mat-protected-button-state-layer-color: #fef2f2;
  --mat-protected-button-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-00274C .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #00274c;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #00274c;
  --mat-outlined-button-ripple-color: rgba(0, 39, 76, 0.1);
}
.svc-00274C .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #64748b;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #64748b;
  --mat-outlined-button-ripple-color: rgba(100, 116, 139, 0.1);
}
.svc-00274C .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #c00000;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #c00000;
  --mat-outlined-button-ripple-color: rgba(192, 0, 0, 0.1);
}
.svc-00274C .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #00274c;
  --mat-icon-button-state-layer-color: #00274c;
  --mat-icon-button-ripple-color: rgba(0, 39, 76, 0.1);
}
.svc-00274C .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #64748b;
  --mat-icon-button-state-layer-color: #64748b;
  --mat-icon-button-ripple-color: rgba(100, 116, 139, 0.1);
}
.svc-00274C .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #c00000;
  --mat-icon-button-state-layer-color: #c00000;
  --mat-icon-button-ripple-color: rgba(192, 0, 0, 0.1);
}
.svc-00274C .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #00274c;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-00274C .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #64748b;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-00274C .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #c00000;
  --mat-fab-foreground-color: #fef2f2;
  --mat-fab-state-layer-color: #fef2f2;
  --mat-fab-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-00274C .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #00274c;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-00274C .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #64748b;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-00274C .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #c00000;
  --mat-fab-small-foreground-color: #fef2f2;
  --mat-fab-small-state-layer-color: #fef2f2;
  --mat-fab-small-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-00274C .mat-accent {
  --mdc-circular-progress-active-indicator-color: #64748b;
}
.svc-00274C .mat-warn {
  --mdc-circular-progress-active-indicator-color: #c00000;
}
.svc-00274C .mat-badge-accent {
  --mat-badge-background-color: #64748b;
  --mat-badge-text-color: white;
}
.svc-00274C .mat-badge-warn {
  --mat-badge-background-color: #c00000;
  --mat-badge-text-color: #fef2f2;
}
.svc-00274C .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #64748b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(100, 116, 139, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(100, 116, 139, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(100, 116, 139, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(100, 116, 139, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.svc-00274C .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: #fef2f2;
  --mat-datepicker-calendar-date-selected-state-background-color: #c00000;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(192, 0, 0, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #fef2f2;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(192, 0, 0, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(192, 0, 0, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(192, 0, 0, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.svc-00274C .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #64748b;
}
.svc-00274C .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #c00000;
}
.svc-00274C .mat-icon.mat-primary {
  --mat-icon-color: #00274c;
}
.svc-00274C .mat-icon.mat-accent {
  --mat-icon-color: #64748b;
}
.svc-00274C .mat-icon.mat-warn {
  --mat-icon-color: #c00000;
}
.svc-00274C .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #64748b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #64748b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #64748b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.svc-00274C .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: #fef2f2;
  --mat-stepper-header-selected-state-icon-background-color: #c00000;
  --mat-stepper-header-selected-state-icon-foreground-color: #fef2f2;
  --mat-stepper-header-done-state-icon-background-color: #c00000;
  --mat-stepper-header-done-state-icon-foreground-color: #fef2f2;
  --mat-stepper-header-edit-state-icon-background-color: #c00000;
  --mat-stepper-header-edit-state-icon-foreground-color: #fef2f2;
}
.svc-00274C .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #00274c;
  --mat-toolbar-container-text-color: white;
}
.svc-00274C .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #64748b;
  --mat-toolbar-container-text-color: white;
}
.svc-00274C .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #c00000;
  --mat-toolbar-container-text-color: #fef2f2;
}

.svc-003366 {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-option-selected-state-label-text-color: #003366;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-full-pseudo-checkbox-selected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #64748b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mdc-filled-text-field-caret-color: #003366;
  --mdc-filled-text-field-focus-active-indicator-color: #003366;
  --mdc-filled-text-field-focus-label-text-color: rgba(0, 51, 102, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #c00000;
  --mdc-filled-text-field-error-focus-label-text-color: #c00000;
  --mdc-filled-text-field-error-label-text-color: #c00000;
  --mdc-filled-text-field-error-caret-color: #c00000;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #c00000;
  --mdc-filled-text-field-error-focus-active-indicator-color: #c00000;
  --mdc-filled-text-field-error-hover-active-indicator-color: #c00000;
  --mdc-outlined-text-field-caret-color: #003366;
  --mdc-outlined-text-field-focus-outline-color: #003366;
  --mdc-outlined-text-field-focus-label-text-color: rgba(0, 51, 102, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #c00000;
  --mdc-outlined-text-field-error-focus-label-text-color: #c00000;
  --mdc-outlined-text-field-error-label-text-color: #c00000;
  --mdc-outlined-text-field-error-hover-label-text-color: #c00000;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #c00000;
  --mdc-outlined-text-field-error-hover-outline-color: #c00000;
  --mdc-outlined-text-field-error-outline-color: #c00000;
  --mat-form-field-focus-select-arrow-color: rgba(0, 51, 102, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #c00000;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(0, 51, 102, 0.87);
  --mat-select-invalid-arrow-color: rgba(192, 0, 0, 0.87);
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: white;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-selected-focus-state-layer-color: #002952;
  --mdc-switch-selected-handle-color: #002952;
  --mdc-switch-selected-hover-state-layer-color: #002952;
  --mdc-switch-selected-pressed-state-layer-color: #002952;
  --mdc-switch-selected-focus-handle-color: #000a14;
  --mdc-switch-selected-hover-handle-color: #000a14;
  --mdc-switch-selected-pressed-handle-color: #000a14;
  --mdc-switch-selected-focus-track-color: #6685a3;
  --mdc-switch-selected-hover-track-color: #6685a3;
  --mdc-switch-selected-pressed-track-color: #6685a3;
  --mdc-switch-selected-track-color: #6685a3;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: #fff;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-handle-color: #003366;
  --mdc-slider-focus-handle-color: #003366;
  --mdc-slider-hover-handle-color: #003366;
  --mdc-slider-active-track-color: #003366;
  --mdc-slider-inactive-track-color: #003366;
  --mdc-slider-with-tick-marks-inactive-container-color: #003366;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-slider-ripple-color: #003366;
  --mat-slider-hover-state-layer-color: rgba(0, 51, 102, 0.05);
  --mat-slider-focus-state-layer-color: rgba(0, 51, 102, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-base-elevation-level: 8;
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: rgba(0, 0, 0, 0.12);
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 4px;
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #64748b;
  --mdc-checkbox-selected-hover-icon-color: #64748b;
  --mdc-checkbox-selected-icon-color: #64748b;
  --mdc-checkbox-selected-pressed-icon-color: #64748b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #64748b;
  --mdc-checkbox-selected-hover-state-layer-color: #64748b;
  --mdc-checkbox-selected-pressed-state-layer-color: #64748b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-checkbox-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
  --mdc-text-button-label-text-color: black;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
  --mdc-icon-button-icon-size: 24px;
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
  --mdc-fab-container-shape: 50%;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-color: white;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-fab-small-container-color: white;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #64748b;
  --mat-table-row-item-outline-width: 1px;
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mdc-circular-progress-active-indicator-color: #003366;
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
  --mat-badge-background-color: #003366;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-bottom-sheet-container-shape: 4px;
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #003366;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(0, 51, 102, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(0, 51, 102, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(0, 51, 102, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #003366;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(0, 51, 102, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-text-color: black;
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-divider-width: 1px;
  --mat-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
  --mat-icon-color: inherit;
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #003366;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #003366;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #003366;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #c00000;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #c00000;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #757575;
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: rgba(0, 0, 0, 0.87);
}
.svc-003366 .mat-accent {
  --mat-option-selected-state-label-text-color: #64748b;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.svc-003366 .mat-warn {
  --mat-option-selected-state-label-text-color: #c00000;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.svc-003366 .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #003366;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #003366;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.svc-003366 .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #64748b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.svc-003366 .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #c00000;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #c00000;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.svc-003366 .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #003366;
  --mdc-linear-progress-track-color: rgba(0, 51, 102, 0.25);
}
.svc-003366 .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #64748b;
  --mdc-linear-progress-track-color: rgba(100, 116, 139, 0.25);
}
.svc-003366 .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #c00000;
  --mdc-linear-progress-track-color: rgba(192, 0, 0, 0.25);
}
.svc-003366 .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #64748b;
  --mdc-filled-text-field-focus-active-indicator-color: #64748b;
  --mdc-filled-text-field-focus-label-text-color: rgba(100, 116, 139, 0.87);
  --mdc-outlined-text-field-caret-color: #64748b;
  --mdc-outlined-text-field-focus-outline-color: #64748b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(100, 116, 139, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(100, 116, 139, 0.87);
}
.svc-003366 .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #c00000;
  --mdc-filled-text-field-focus-active-indicator-color: #c00000;
  --mdc-filled-text-field-focus-label-text-color: rgba(192, 0, 0, 0.87);
  --mdc-outlined-text-field-caret-color: #c00000;
  --mdc-outlined-text-field-focus-outline-color: #c00000;
  --mdc-outlined-text-field-focus-label-text-color: rgba(192, 0, 0, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(192, 0, 0, 0.87);
}
.svc-003366 .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(100, 116, 139, 0.87);
  --mat-select-invalid-arrow-color: rgba(192, 0, 0, 0.87);
}
.svc-003366 .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(192, 0, 0, 0.87);
  --mat-select-invalid-arrow-color: rgba(192, 0, 0, 0.87);
}
.svc-003366 .mat-mdc-standard-chip {
  --mdc-chip-container-shape-radius: 16px;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}
.svc-003366 .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.svc-003366 .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .svc-003366 .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #003366;
  --mdc-chip-elevated-selected-container-color: #003366;
  --mdc-chip-elevated-disabled-container-color: #003366;
  --mdc-chip-flat-disabled-selected-container-color: #003366;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.svc-003366 .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .svc-003366 .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #64748b;
  --mdc-chip-elevated-selected-container-color: #64748b;
  --mdc-chip-elevated-disabled-container-color: #64748b;
  --mdc-chip-flat-disabled-selected-container-color: #64748b;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.svc-003366 .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .svc-003366 .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: #fef2f2;
  --mdc-chip-elevated-container-color: #c00000;
  --mdc-chip-elevated-selected-container-color: #c00000;
  --mdc-chip-elevated-disabled-container-color: #c00000;
  --mdc-chip-flat-disabled-selected-container-color: #c00000;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #fef2f2;
  --mdc-chip-selected-label-text-color: #fef2f2;
  --mdc-chip-with-icon-icon-color: #fef2f2;
  --mdc-chip-with-icon-disabled-icon-color: #fef2f2;
  --mdc-chip-with-icon-selected-icon-color: #fef2f2;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fef2f2;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fef2f2;
  --mat-chip-selected-disabled-trailing-icon-color: #fef2f2;
  --mat-chip-selected-trailing-icon-color: #fef2f2;
}
.svc-003366 .mat-mdc-slide-toggle {
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-selected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}
.svc-003366 .mat-mdc-slide-toggle {
  --mat-switch-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-003366 .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #475569;
  --mdc-switch-selected-handle-color: #475569;
  --mdc-switch-selected-hover-state-layer-color: #475569;
  --mdc-switch-selected-pressed-state-layer-color: #475569;
  --mdc-switch-selected-focus-handle-color: #0f172a;
  --mdc-switch-selected-hover-handle-color: #0f172a;
  --mdc-switch-selected-pressed-handle-color: #0f172a;
  --mdc-switch-selected-focus-track-color: #cbd5e1;
  --mdc-switch-selected-hover-track-color: #cbd5e1;
  --mdc-switch-selected-pressed-track-color: #cbd5e1;
  --mdc-switch-selected-track-color: #cbd5e1;
}
.svc-003366 .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #dc2626;
  --mdc-switch-selected-handle-color: #dc2626;
  --mdc-switch-selected-hover-state-layer-color: #dc2626;
  --mdc-switch-selected-pressed-state-layer-color: #dc2626;
  --mdc-switch-selected-focus-handle-color: #7f1d1d;
  --mdc-switch-selected-hover-handle-color: #7f1d1d;
  --mdc-switch-selected-pressed-handle-color: #7f1d1d;
  --mdc-switch-selected-focus-track-color: #fca5a5;
  --mdc-switch-selected-hover-track-color: #fca5a5;
  --mdc-switch-selected-pressed-track-color: #fca5a5;
  --mdc-switch-selected-track-color: #fca5a5;
}
.svc-003366 .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #003366;
  --mdc-radio-selected-hover-icon-color: #003366;
  --mdc-radio-selected-icon-color: #003366;
  --mdc-radio-selected-pressed-icon-color: #003366;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #003366;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-003366 .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #64748b;
  --mdc-radio-selected-hover-icon-color: #64748b;
  --mdc-radio-selected-icon-color: #64748b;
  --mdc-radio-selected-pressed-icon-color: #64748b;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #64748b;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-003366 .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #c00000;
  --mdc-radio-selected-hover-icon-color: #c00000;
  --mdc-radio-selected-icon-color: #c00000;
  --mdc-radio-selected-pressed-icon-color: #c00000;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #c00000;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-003366 .mat-accent {
  --mdc-slider-handle-color: #64748b;
  --mdc-slider-focus-handle-color: #64748b;
  --mdc-slider-hover-handle-color: #64748b;
  --mdc-slider-active-track-color: #64748b;
  --mdc-slider-inactive-track-color: #64748b;
  --mdc-slider-with-tick-marks-inactive-container-color: #64748b;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mat-slider-ripple-color: #64748b;
  --mat-slider-hover-state-layer-color: rgba(100, 116, 139, 0.05);
  --mat-slider-focus-state-layer-color: rgba(100, 116, 139, 0.2);
}
.svc-003366 .mat-warn {
  --mdc-slider-handle-color: #c00000;
  --mdc-slider-focus-handle-color: #c00000;
  --mdc-slider-hover-handle-color: #c00000;
  --mdc-slider-active-track-color: #c00000;
  --mdc-slider-inactive-track-color: #c00000;
  --mdc-slider-with-tick-marks-inactive-container-color: #c00000;
  --mdc-slider-with-tick-marks-active-container-color: #fef2f2;
  --mat-slider-ripple-color: #c00000;
  --mat-slider-hover-state-layer-color: rgba(192, 0, 0, 0.05);
  --mat-slider-focus-state-layer-color: rgba(192, 0, 0, 0.2);
}
.svc-003366 .mdc-list-item__start,
.svc-003366 .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #003366;
  --mdc-radio-selected-hover-icon-color: #003366;
  --mdc-radio-selected-icon-color: #003366;
  --mdc-radio-selected-pressed-icon-color: #003366;
}
.svc-003366 .mat-accent .mdc-list-item__start,
.svc-003366 .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #64748b;
  --mdc-radio-selected-hover-icon-color: #64748b;
  --mdc-radio-selected-icon-color: #64748b;
  --mdc-radio-selected-pressed-icon-color: #64748b;
}
.svc-003366 .mat-warn .mdc-list-item__start,
.svc-003366 .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #c00000;
  --mdc-radio-selected-hover-icon-color: #c00000;
  --mdc-radio-selected-icon-color: #c00000;
  --mdc-radio-selected-pressed-icon-color: #c00000;
}
.svc-003366 .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #003366;
  --mdc-checkbox-selected-hover-icon-color: #003366;
  --mdc-checkbox-selected-icon-color: #003366;
  --mdc-checkbox-selected-pressed-icon-color: #003366;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #003366;
  --mdc-checkbox-selected-hover-state-layer-color: #003366;
  --mdc-checkbox-selected-pressed-state-layer-color: #003366;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-003366 .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #64748b;
  --mdc-checkbox-selected-hover-icon-color: #64748b;
  --mdc-checkbox-selected-icon-color: #64748b;
  --mdc-checkbox-selected-pressed-icon-color: #64748b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #64748b;
  --mdc-checkbox-selected-hover-state-layer-color: #64748b;
  --mdc-checkbox-selected-pressed-state-layer-color: #64748b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-003366 .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fef2f2;
  --mdc-checkbox-selected-focus-icon-color: #c00000;
  --mdc-checkbox-selected-hover-icon-color: #c00000;
  --mdc-checkbox-selected-icon-color: #c00000;
  --mdc-checkbox-selected-pressed-icon-color: #c00000;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #c00000;
  --mdc-checkbox-selected-hover-state-layer-color: #c00000;
  --mdc-checkbox-selected-pressed-state-layer-color: #c00000;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-003366 .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.svc-003366 .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__start,
.svc-003366 .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.svc-003366 .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__start {
  color: #003366;
}
.svc-003366 .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.svc-003366 .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.svc-003366 .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.svc-003366 .mat-mdc-tab-group, .svc-003366 .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #003366;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #003366;
  --mat-tab-header-active-ripple-color: #003366;
  --mat-tab-header-inactive-ripple-color: #003366;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #003366;
  --mat-tab-header-active-hover-label-text-color: #003366;
  --mat-tab-header-active-focus-indicator-color: #003366;
  --mat-tab-header-active-hover-indicator-color: #003366;
}
.svc-003366 .mat-mdc-tab-group.mat-accent, .svc-003366 .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #64748b;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #64748b;
  --mat-tab-header-active-ripple-color: #64748b;
  --mat-tab-header-inactive-ripple-color: #64748b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #64748b;
  --mat-tab-header-active-hover-label-text-color: #64748b;
  --mat-tab-header-active-focus-indicator-color: #64748b;
  --mat-tab-header-active-hover-indicator-color: #64748b;
}
.svc-003366 .mat-mdc-tab-group.mat-warn, .svc-003366 .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #c00000;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #c00000;
  --mat-tab-header-active-ripple-color: #c00000;
  --mat-tab-header-inactive-ripple-color: #c00000;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #c00000;
  --mat-tab-header-active-hover-label-text-color: #c00000;
  --mat-tab-header-active-focus-indicator-color: #c00000;
  --mat-tab-header-active-hover-indicator-color: #c00000;
}
.svc-003366 .mat-mdc-tab-group.mat-background-primary, .svc-003366 .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #003366;
  --mat-tab-header-with-background-foreground-color: white;
}
.svc-003366 .mat-mdc-tab-group.mat-background-accent, .svc-003366 .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #64748b;
  --mat-tab-header-with-background-foreground-color: white;
}
.svc-003366 .mat-mdc-tab-group.mat-background-warn, .svc-003366 .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #c00000;
  --mat-tab-header-with-background-foreground-color: #fef2f2;
}
.svc-003366 .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #003366;
  --mdc-checkbox-selected-hover-icon-color: #003366;
  --mdc-checkbox-selected-icon-color: #003366;
  --mdc-checkbox-selected-pressed-icon-color: #003366;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #003366;
  --mdc-checkbox-selected-hover-state-layer-color: #003366;
  --mdc-checkbox-selected-pressed-state-layer-color: #003366;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-003366 .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fef2f2;
  --mdc-checkbox-selected-focus-icon-color: #c00000;
  --mdc-checkbox-selected-hover-icon-color: #c00000;
  --mdc-checkbox-selected-icon-color: #c00000;
  --mdc-checkbox-selected-pressed-icon-color: #c00000;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #c00000;
  --mdc-checkbox-selected-hover-state-layer-color: #c00000;
  --mdc-checkbox-selected-pressed-state-layer-color: #c00000;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-003366 .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #003366;
  --mat-text-button-state-layer-color: #003366;
  --mat-text-button-ripple-color: rgba(0, 51, 102, 0.1);
}
.svc-003366 .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #64748b;
  --mat-text-button-state-layer-color: #64748b;
  --mat-text-button-ripple-color: rgba(100, 116, 139, 0.1);
}
.svc-003366 .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #c00000;
  --mat-text-button-state-layer-color: #c00000;
  --mat-text-button-ripple-color: rgba(192, 0, 0, 0.1);
}
.svc-003366 .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #003366;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-003366 .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #64748b;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-003366 .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #c00000;
  --mdc-filled-button-label-text-color: #fef2f2;
  --mat-filled-button-state-layer-color: #fef2f2;
  --mat-filled-button-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-003366 .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #003366;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-003366 .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #64748b;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-003366 .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #c00000;
  --mdc-protected-button-label-text-color: #fef2f2;
  --mat-protected-button-state-layer-color: #fef2f2;
  --mat-protected-button-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-003366 .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #003366;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #003366;
  --mat-outlined-button-ripple-color: rgba(0, 51, 102, 0.1);
}
.svc-003366 .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #64748b;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #64748b;
  --mat-outlined-button-ripple-color: rgba(100, 116, 139, 0.1);
}
.svc-003366 .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #c00000;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #c00000;
  --mat-outlined-button-ripple-color: rgba(192, 0, 0, 0.1);
}
.svc-003366 .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #003366;
  --mat-icon-button-state-layer-color: #003366;
  --mat-icon-button-ripple-color: rgba(0, 51, 102, 0.1);
}
.svc-003366 .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #64748b;
  --mat-icon-button-state-layer-color: #64748b;
  --mat-icon-button-ripple-color: rgba(100, 116, 139, 0.1);
}
.svc-003366 .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #c00000;
  --mat-icon-button-state-layer-color: #c00000;
  --mat-icon-button-ripple-color: rgba(192, 0, 0, 0.1);
}
.svc-003366 .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #003366;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-003366 .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #64748b;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-003366 .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #c00000;
  --mat-fab-foreground-color: #fef2f2;
  --mat-fab-state-layer-color: #fef2f2;
  --mat-fab-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-003366 .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #003366;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-003366 .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #64748b;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-003366 .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #c00000;
  --mat-fab-small-foreground-color: #fef2f2;
  --mat-fab-small-state-layer-color: #fef2f2;
  --mat-fab-small-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-003366 .mat-accent {
  --mdc-circular-progress-active-indicator-color: #64748b;
}
.svc-003366 .mat-warn {
  --mdc-circular-progress-active-indicator-color: #c00000;
}
.svc-003366 .mat-badge-accent {
  --mat-badge-background-color: #64748b;
  --mat-badge-text-color: white;
}
.svc-003366 .mat-badge-warn {
  --mat-badge-background-color: #c00000;
  --mat-badge-text-color: #fef2f2;
}
.svc-003366 .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #64748b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(100, 116, 139, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(100, 116, 139, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(100, 116, 139, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(100, 116, 139, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.svc-003366 .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: #fef2f2;
  --mat-datepicker-calendar-date-selected-state-background-color: #c00000;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(192, 0, 0, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #fef2f2;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(192, 0, 0, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(192, 0, 0, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(192, 0, 0, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.svc-003366 .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #64748b;
}
.svc-003366 .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #c00000;
}
.svc-003366 .mat-icon.mat-primary {
  --mat-icon-color: #003366;
}
.svc-003366 .mat-icon.mat-accent {
  --mat-icon-color: #64748b;
}
.svc-003366 .mat-icon.mat-warn {
  --mat-icon-color: #c00000;
}
.svc-003366 .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #64748b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #64748b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #64748b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.svc-003366 .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: #fef2f2;
  --mat-stepper-header-selected-state-icon-background-color: #c00000;
  --mat-stepper-header-selected-state-icon-foreground-color: #fef2f2;
  --mat-stepper-header-done-state-icon-background-color: #c00000;
  --mat-stepper-header-done-state-icon-foreground-color: #fef2f2;
  --mat-stepper-header-edit-state-icon-background-color: #c00000;
  --mat-stepper-header-edit-state-icon-foreground-color: #fef2f2;
}
.svc-003366 .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #003366;
  --mat-toolbar-container-text-color: white;
}
.svc-003366 .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #64748b;
  --mat-toolbar-container-text-color: white;
}
.svc-003366 .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #c00000;
  --mat-toolbar-container-text-color: #fef2f2;
}

.svc-003399 {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-option-selected-state-label-text-color: #003399;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-full-pseudo-checkbox-selected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #64748b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mdc-filled-text-field-caret-color: #003399;
  --mdc-filled-text-field-focus-active-indicator-color: #003399;
  --mdc-filled-text-field-focus-label-text-color: rgba(0, 51, 153, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #c00000;
  --mdc-filled-text-field-error-focus-label-text-color: #c00000;
  --mdc-filled-text-field-error-label-text-color: #c00000;
  --mdc-filled-text-field-error-caret-color: #c00000;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #c00000;
  --mdc-filled-text-field-error-focus-active-indicator-color: #c00000;
  --mdc-filled-text-field-error-hover-active-indicator-color: #c00000;
  --mdc-outlined-text-field-caret-color: #003399;
  --mdc-outlined-text-field-focus-outline-color: #003399;
  --mdc-outlined-text-field-focus-label-text-color: rgba(0, 51, 153, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #c00000;
  --mdc-outlined-text-field-error-focus-label-text-color: #c00000;
  --mdc-outlined-text-field-error-label-text-color: #c00000;
  --mdc-outlined-text-field-error-hover-label-text-color: #c00000;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #c00000;
  --mdc-outlined-text-field-error-hover-outline-color: #c00000;
  --mdc-outlined-text-field-error-outline-color: #c00000;
  --mat-form-field-focus-select-arrow-color: rgba(0, 51, 153, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #c00000;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(0, 51, 153, 0.87);
  --mat-select-invalid-arrow-color: rgba(192, 0, 0, 0.87);
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: white;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-selected-focus-state-layer-color: #00297a;
  --mdc-switch-selected-handle-color: #00297a;
  --mdc-switch-selected-hover-state-layer-color: #00297a;
  --mdc-switch-selected-pressed-state-layer-color: #00297a;
  --mdc-switch-selected-focus-handle-color: #000a1f;
  --mdc-switch-selected-hover-handle-color: #000a1f;
  --mdc-switch-selected-pressed-handle-color: #000a1f;
  --mdc-switch-selected-focus-track-color: #6685c2;
  --mdc-switch-selected-hover-track-color: #6685c2;
  --mdc-switch-selected-pressed-track-color: #6685c2;
  --mdc-switch-selected-track-color: #6685c2;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: #fff;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-handle-color: #003399;
  --mdc-slider-focus-handle-color: #003399;
  --mdc-slider-hover-handle-color: #003399;
  --mdc-slider-active-track-color: #003399;
  --mdc-slider-inactive-track-color: #003399;
  --mdc-slider-with-tick-marks-inactive-container-color: #003399;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-slider-ripple-color: #003399;
  --mat-slider-hover-state-layer-color: rgba(0, 51, 153, 0.05);
  --mat-slider-focus-state-layer-color: rgba(0, 51, 153, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-base-elevation-level: 8;
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: rgba(0, 0, 0, 0.12);
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 4px;
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #64748b;
  --mdc-checkbox-selected-hover-icon-color: #64748b;
  --mdc-checkbox-selected-icon-color: #64748b;
  --mdc-checkbox-selected-pressed-icon-color: #64748b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #64748b;
  --mdc-checkbox-selected-hover-state-layer-color: #64748b;
  --mdc-checkbox-selected-pressed-state-layer-color: #64748b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-checkbox-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
  --mdc-text-button-label-text-color: black;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
  --mdc-icon-button-icon-size: 24px;
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
  --mdc-fab-container-shape: 50%;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-color: white;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-fab-small-container-color: white;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #64748b;
  --mat-table-row-item-outline-width: 1px;
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mdc-circular-progress-active-indicator-color: #003399;
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
  --mat-badge-background-color: #003399;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-bottom-sheet-container-shape: 4px;
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #003399;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(0, 51, 153, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(0, 51, 153, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(0, 51, 153, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #003399;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(0, 51, 153, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-text-color: black;
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-divider-width: 1px;
  --mat-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
  --mat-icon-color: inherit;
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #003399;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #003399;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #003399;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #c00000;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #c00000;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #757575;
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: rgba(0, 0, 0, 0.87);
}
.svc-003399 .mat-accent {
  --mat-option-selected-state-label-text-color: #64748b;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.svc-003399 .mat-warn {
  --mat-option-selected-state-label-text-color: #c00000;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.svc-003399 .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #003399;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #003399;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.svc-003399 .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #64748b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.svc-003399 .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #c00000;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #c00000;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.svc-003399 .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #003399;
  --mdc-linear-progress-track-color: rgba(0, 51, 153, 0.25);
}
.svc-003399 .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #64748b;
  --mdc-linear-progress-track-color: rgba(100, 116, 139, 0.25);
}
.svc-003399 .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #c00000;
  --mdc-linear-progress-track-color: rgba(192, 0, 0, 0.25);
}
.svc-003399 .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #64748b;
  --mdc-filled-text-field-focus-active-indicator-color: #64748b;
  --mdc-filled-text-field-focus-label-text-color: rgba(100, 116, 139, 0.87);
  --mdc-outlined-text-field-caret-color: #64748b;
  --mdc-outlined-text-field-focus-outline-color: #64748b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(100, 116, 139, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(100, 116, 139, 0.87);
}
.svc-003399 .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #c00000;
  --mdc-filled-text-field-focus-active-indicator-color: #c00000;
  --mdc-filled-text-field-focus-label-text-color: rgba(192, 0, 0, 0.87);
  --mdc-outlined-text-field-caret-color: #c00000;
  --mdc-outlined-text-field-focus-outline-color: #c00000;
  --mdc-outlined-text-field-focus-label-text-color: rgba(192, 0, 0, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(192, 0, 0, 0.87);
}
.svc-003399 .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(100, 116, 139, 0.87);
  --mat-select-invalid-arrow-color: rgba(192, 0, 0, 0.87);
}
.svc-003399 .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(192, 0, 0, 0.87);
  --mat-select-invalid-arrow-color: rgba(192, 0, 0, 0.87);
}
.svc-003399 .mat-mdc-standard-chip {
  --mdc-chip-container-shape-radius: 16px;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}
.svc-003399 .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.svc-003399 .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .svc-003399 .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #003399;
  --mdc-chip-elevated-selected-container-color: #003399;
  --mdc-chip-elevated-disabled-container-color: #003399;
  --mdc-chip-flat-disabled-selected-container-color: #003399;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.svc-003399 .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .svc-003399 .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #64748b;
  --mdc-chip-elevated-selected-container-color: #64748b;
  --mdc-chip-elevated-disabled-container-color: #64748b;
  --mdc-chip-flat-disabled-selected-container-color: #64748b;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.svc-003399 .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .svc-003399 .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: #fef2f2;
  --mdc-chip-elevated-container-color: #c00000;
  --mdc-chip-elevated-selected-container-color: #c00000;
  --mdc-chip-elevated-disabled-container-color: #c00000;
  --mdc-chip-flat-disabled-selected-container-color: #c00000;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #fef2f2;
  --mdc-chip-selected-label-text-color: #fef2f2;
  --mdc-chip-with-icon-icon-color: #fef2f2;
  --mdc-chip-with-icon-disabled-icon-color: #fef2f2;
  --mdc-chip-with-icon-selected-icon-color: #fef2f2;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fef2f2;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fef2f2;
  --mat-chip-selected-disabled-trailing-icon-color: #fef2f2;
  --mat-chip-selected-trailing-icon-color: #fef2f2;
}
.svc-003399 .mat-mdc-slide-toggle {
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-selected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}
.svc-003399 .mat-mdc-slide-toggle {
  --mat-switch-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-003399 .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #475569;
  --mdc-switch-selected-handle-color: #475569;
  --mdc-switch-selected-hover-state-layer-color: #475569;
  --mdc-switch-selected-pressed-state-layer-color: #475569;
  --mdc-switch-selected-focus-handle-color: #0f172a;
  --mdc-switch-selected-hover-handle-color: #0f172a;
  --mdc-switch-selected-pressed-handle-color: #0f172a;
  --mdc-switch-selected-focus-track-color: #cbd5e1;
  --mdc-switch-selected-hover-track-color: #cbd5e1;
  --mdc-switch-selected-pressed-track-color: #cbd5e1;
  --mdc-switch-selected-track-color: #cbd5e1;
}
.svc-003399 .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #dc2626;
  --mdc-switch-selected-handle-color: #dc2626;
  --mdc-switch-selected-hover-state-layer-color: #dc2626;
  --mdc-switch-selected-pressed-state-layer-color: #dc2626;
  --mdc-switch-selected-focus-handle-color: #7f1d1d;
  --mdc-switch-selected-hover-handle-color: #7f1d1d;
  --mdc-switch-selected-pressed-handle-color: #7f1d1d;
  --mdc-switch-selected-focus-track-color: #fca5a5;
  --mdc-switch-selected-hover-track-color: #fca5a5;
  --mdc-switch-selected-pressed-track-color: #fca5a5;
  --mdc-switch-selected-track-color: #fca5a5;
}
.svc-003399 .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #003399;
  --mdc-radio-selected-hover-icon-color: #003399;
  --mdc-radio-selected-icon-color: #003399;
  --mdc-radio-selected-pressed-icon-color: #003399;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #003399;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-003399 .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #64748b;
  --mdc-radio-selected-hover-icon-color: #64748b;
  --mdc-radio-selected-icon-color: #64748b;
  --mdc-radio-selected-pressed-icon-color: #64748b;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #64748b;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-003399 .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #c00000;
  --mdc-radio-selected-hover-icon-color: #c00000;
  --mdc-radio-selected-icon-color: #c00000;
  --mdc-radio-selected-pressed-icon-color: #c00000;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #c00000;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-003399 .mat-accent {
  --mdc-slider-handle-color: #64748b;
  --mdc-slider-focus-handle-color: #64748b;
  --mdc-slider-hover-handle-color: #64748b;
  --mdc-slider-active-track-color: #64748b;
  --mdc-slider-inactive-track-color: #64748b;
  --mdc-slider-with-tick-marks-inactive-container-color: #64748b;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mat-slider-ripple-color: #64748b;
  --mat-slider-hover-state-layer-color: rgba(100, 116, 139, 0.05);
  --mat-slider-focus-state-layer-color: rgba(100, 116, 139, 0.2);
}
.svc-003399 .mat-warn {
  --mdc-slider-handle-color: #c00000;
  --mdc-slider-focus-handle-color: #c00000;
  --mdc-slider-hover-handle-color: #c00000;
  --mdc-slider-active-track-color: #c00000;
  --mdc-slider-inactive-track-color: #c00000;
  --mdc-slider-with-tick-marks-inactive-container-color: #c00000;
  --mdc-slider-with-tick-marks-active-container-color: #fef2f2;
  --mat-slider-ripple-color: #c00000;
  --mat-slider-hover-state-layer-color: rgba(192, 0, 0, 0.05);
  --mat-slider-focus-state-layer-color: rgba(192, 0, 0, 0.2);
}
.svc-003399 .mdc-list-item__start,
.svc-003399 .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #003399;
  --mdc-radio-selected-hover-icon-color: #003399;
  --mdc-radio-selected-icon-color: #003399;
  --mdc-radio-selected-pressed-icon-color: #003399;
}
.svc-003399 .mat-accent .mdc-list-item__start,
.svc-003399 .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #64748b;
  --mdc-radio-selected-hover-icon-color: #64748b;
  --mdc-radio-selected-icon-color: #64748b;
  --mdc-radio-selected-pressed-icon-color: #64748b;
}
.svc-003399 .mat-warn .mdc-list-item__start,
.svc-003399 .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #c00000;
  --mdc-radio-selected-hover-icon-color: #c00000;
  --mdc-radio-selected-icon-color: #c00000;
  --mdc-radio-selected-pressed-icon-color: #c00000;
}
.svc-003399 .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #003399;
  --mdc-checkbox-selected-hover-icon-color: #003399;
  --mdc-checkbox-selected-icon-color: #003399;
  --mdc-checkbox-selected-pressed-icon-color: #003399;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #003399;
  --mdc-checkbox-selected-hover-state-layer-color: #003399;
  --mdc-checkbox-selected-pressed-state-layer-color: #003399;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-003399 .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #64748b;
  --mdc-checkbox-selected-hover-icon-color: #64748b;
  --mdc-checkbox-selected-icon-color: #64748b;
  --mdc-checkbox-selected-pressed-icon-color: #64748b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #64748b;
  --mdc-checkbox-selected-hover-state-layer-color: #64748b;
  --mdc-checkbox-selected-pressed-state-layer-color: #64748b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-003399 .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fef2f2;
  --mdc-checkbox-selected-focus-icon-color: #c00000;
  --mdc-checkbox-selected-hover-icon-color: #c00000;
  --mdc-checkbox-selected-icon-color: #c00000;
  --mdc-checkbox-selected-pressed-icon-color: #c00000;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #c00000;
  --mdc-checkbox-selected-hover-state-layer-color: #c00000;
  --mdc-checkbox-selected-pressed-state-layer-color: #c00000;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-003399 .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.svc-003399 .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__start,
.svc-003399 .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.svc-003399 .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__start {
  color: #003399;
}
.svc-003399 .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.svc-003399 .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.svc-003399 .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.svc-003399 .mat-mdc-tab-group, .svc-003399 .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #003399;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #003399;
  --mat-tab-header-active-ripple-color: #003399;
  --mat-tab-header-inactive-ripple-color: #003399;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #003399;
  --mat-tab-header-active-hover-label-text-color: #003399;
  --mat-tab-header-active-focus-indicator-color: #003399;
  --mat-tab-header-active-hover-indicator-color: #003399;
}
.svc-003399 .mat-mdc-tab-group.mat-accent, .svc-003399 .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #64748b;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #64748b;
  --mat-tab-header-active-ripple-color: #64748b;
  --mat-tab-header-inactive-ripple-color: #64748b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #64748b;
  --mat-tab-header-active-hover-label-text-color: #64748b;
  --mat-tab-header-active-focus-indicator-color: #64748b;
  --mat-tab-header-active-hover-indicator-color: #64748b;
}
.svc-003399 .mat-mdc-tab-group.mat-warn, .svc-003399 .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #c00000;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #c00000;
  --mat-tab-header-active-ripple-color: #c00000;
  --mat-tab-header-inactive-ripple-color: #c00000;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #c00000;
  --mat-tab-header-active-hover-label-text-color: #c00000;
  --mat-tab-header-active-focus-indicator-color: #c00000;
  --mat-tab-header-active-hover-indicator-color: #c00000;
}
.svc-003399 .mat-mdc-tab-group.mat-background-primary, .svc-003399 .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #003399;
  --mat-tab-header-with-background-foreground-color: white;
}
.svc-003399 .mat-mdc-tab-group.mat-background-accent, .svc-003399 .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #64748b;
  --mat-tab-header-with-background-foreground-color: white;
}
.svc-003399 .mat-mdc-tab-group.mat-background-warn, .svc-003399 .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #c00000;
  --mat-tab-header-with-background-foreground-color: #fef2f2;
}
.svc-003399 .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #003399;
  --mdc-checkbox-selected-hover-icon-color: #003399;
  --mdc-checkbox-selected-icon-color: #003399;
  --mdc-checkbox-selected-pressed-icon-color: #003399;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #003399;
  --mdc-checkbox-selected-hover-state-layer-color: #003399;
  --mdc-checkbox-selected-pressed-state-layer-color: #003399;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-003399 .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fef2f2;
  --mdc-checkbox-selected-focus-icon-color: #c00000;
  --mdc-checkbox-selected-hover-icon-color: #c00000;
  --mdc-checkbox-selected-icon-color: #c00000;
  --mdc-checkbox-selected-pressed-icon-color: #c00000;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #c00000;
  --mdc-checkbox-selected-hover-state-layer-color: #c00000;
  --mdc-checkbox-selected-pressed-state-layer-color: #c00000;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-003399 .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #003399;
  --mat-text-button-state-layer-color: #003399;
  --mat-text-button-ripple-color: rgba(0, 51, 153, 0.1);
}
.svc-003399 .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #64748b;
  --mat-text-button-state-layer-color: #64748b;
  --mat-text-button-ripple-color: rgba(100, 116, 139, 0.1);
}
.svc-003399 .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #c00000;
  --mat-text-button-state-layer-color: #c00000;
  --mat-text-button-ripple-color: rgba(192, 0, 0, 0.1);
}
.svc-003399 .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #003399;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-003399 .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #64748b;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-003399 .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #c00000;
  --mdc-filled-button-label-text-color: #fef2f2;
  --mat-filled-button-state-layer-color: #fef2f2;
  --mat-filled-button-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-003399 .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #003399;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-003399 .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #64748b;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-003399 .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #c00000;
  --mdc-protected-button-label-text-color: #fef2f2;
  --mat-protected-button-state-layer-color: #fef2f2;
  --mat-protected-button-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-003399 .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #003399;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #003399;
  --mat-outlined-button-ripple-color: rgba(0, 51, 153, 0.1);
}
.svc-003399 .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #64748b;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #64748b;
  --mat-outlined-button-ripple-color: rgba(100, 116, 139, 0.1);
}
.svc-003399 .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #c00000;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #c00000;
  --mat-outlined-button-ripple-color: rgba(192, 0, 0, 0.1);
}
.svc-003399 .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #003399;
  --mat-icon-button-state-layer-color: #003399;
  --mat-icon-button-ripple-color: rgba(0, 51, 153, 0.1);
}
.svc-003399 .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #64748b;
  --mat-icon-button-state-layer-color: #64748b;
  --mat-icon-button-ripple-color: rgba(100, 116, 139, 0.1);
}
.svc-003399 .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #c00000;
  --mat-icon-button-state-layer-color: #c00000;
  --mat-icon-button-ripple-color: rgba(192, 0, 0, 0.1);
}
.svc-003399 .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #003399;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-003399 .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #64748b;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-003399 .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #c00000;
  --mat-fab-foreground-color: #fef2f2;
  --mat-fab-state-layer-color: #fef2f2;
  --mat-fab-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-003399 .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #003399;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-003399 .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #64748b;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-003399 .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #c00000;
  --mat-fab-small-foreground-color: #fef2f2;
  --mat-fab-small-state-layer-color: #fef2f2;
  --mat-fab-small-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-003399 .mat-accent {
  --mdc-circular-progress-active-indicator-color: #64748b;
}
.svc-003399 .mat-warn {
  --mdc-circular-progress-active-indicator-color: #c00000;
}
.svc-003399 .mat-badge-accent {
  --mat-badge-background-color: #64748b;
  --mat-badge-text-color: white;
}
.svc-003399 .mat-badge-warn {
  --mat-badge-background-color: #c00000;
  --mat-badge-text-color: #fef2f2;
}
.svc-003399 .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #64748b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(100, 116, 139, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(100, 116, 139, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(100, 116, 139, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(100, 116, 139, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.svc-003399 .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: #fef2f2;
  --mat-datepicker-calendar-date-selected-state-background-color: #c00000;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(192, 0, 0, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #fef2f2;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(192, 0, 0, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(192, 0, 0, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(192, 0, 0, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.svc-003399 .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #64748b;
}
.svc-003399 .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #c00000;
}
.svc-003399 .mat-icon.mat-primary {
  --mat-icon-color: #003399;
}
.svc-003399 .mat-icon.mat-accent {
  --mat-icon-color: #64748b;
}
.svc-003399 .mat-icon.mat-warn {
  --mat-icon-color: #c00000;
}
.svc-003399 .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #64748b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #64748b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #64748b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.svc-003399 .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: #fef2f2;
  --mat-stepper-header-selected-state-icon-background-color: #c00000;
  --mat-stepper-header-selected-state-icon-foreground-color: #fef2f2;
  --mat-stepper-header-done-state-icon-background-color: #c00000;
  --mat-stepper-header-done-state-icon-foreground-color: #fef2f2;
  --mat-stepper-header-edit-state-icon-background-color: #c00000;
  --mat-stepper-header-edit-state-icon-foreground-color: #fef2f2;
}
.svc-003399 .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #003399;
  --mat-toolbar-container-text-color: white;
}
.svc-003399 .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #64748b;
  --mat-toolbar-container-text-color: white;
}
.svc-003399 .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #c00000;
  --mat-toolbar-container-text-color: #fef2f2;
}

.svc-005F86 {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-option-selected-state-label-text-color: #005f86;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-full-pseudo-checkbox-selected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #64748b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mdc-filled-text-field-caret-color: #005f86;
  --mdc-filled-text-field-focus-active-indicator-color: #005f86;
  --mdc-filled-text-field-focus-label-text-color: rgba(0, 95, 134, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #c00000;
  --mdc-filled-text-field-error-focus-label-text-color: #c00000;
  --mdc-filled-text-field-error-label-text-color: #c00000;
  --mdc-filled-text-field-error-caret-color: #c00000;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #c00000;
  --mdc-filled-text-field-error-focus-active-indicator-color: #c00000;
  --mdc-filled-text-field-error-hover-active-indicator-color: #c00000;
  --mdc-outlined-text-field-caret-color: #005f86;
  --mdc-outlined-text-field-focus-outline-color: #005f86;
  --mdc-outlined-text-field-focus-label-text-color: rgba(0, 95, 134, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #c00000;
  --mdc-outlined-text-field-error-focus-label-text-color: #c00000;
  --mdc-outlined-text-field-error-label-text-color: #c00000;
  --mdc-outlined-text-field-error-hover-label-text-color: #c00000;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #c00000;
  --mdc-outlined-text-field-error-hover-outline-color: #c00000;
  --mdc-outlined-text-field-error-outline-color: #c00000;
  --mat-form-field-focus-select-arrow-color: rgba(0, 95, 134, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #c00000;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(0, 95, 134, 0.87);
  --mat-select-invalid-arrow-color: rgba(192, 0, 0, 0.87);
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: white;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-selected-focus-state-layer-color: #004c6b;
  --mdc-switch-selected-handle-color: #004c6b;
  --mdc-switch-selected-hover-state-layer-color: #004c6b;
  --mdc-switch-selected-pressed-state-layer-color: #004c6b;
  --mdc-switch-selected-focus-handle-color: #00131b;
  --mdc-switch-selected-hover-handle-color: #00131b;
  --mdc-switch-selected-pressed-handle-color: #00131b;
  --mdc-switch-selected-focus-track-color: #669fb6;
  --mdc-switch-selected-hover-track-color: #669fb6;
  --mdc-switch-selected-pressed-track-color: #669fb6;
  --mdc-switch-selected-track-color: #669fb6;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: #fff;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-handle-color: #005f86;
  --mdc-slider-focus-handle-color: #005f86;
  --mdc-slider-hover-handle-color: #005f86;
  --mdc-slider-active-track-color: #005f86;
  --mdc-slider-inactive-track-color: #005f86;
  --mdc-slider-with-tick-marks-inactive-container-color: #005f86;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-slider-ripple-color: #005f86;
  --mat-slider-hover-state-layer-color: rgba(0, 95, 134, 0.05);
  --mat-slider-focus-state-layer-color: rgba(0, 95, 134, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-base-elevation-level: 8;
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: rgba(0, 0, 0, 0.12);
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 4px;
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #64748b;
  --mdc-checkbox-selected-hover-icon-color: #64748b;
  --mdc-checkbox-selected-icon-color: #64748b;
  --mdc-checkbox-selected-pressed-icon-color: #64748b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #64748b;
  --mdc-checkbox-selected-hover-state-layer-color: #64748b;
  --mdc-checkbox-selected-pressed-state-layer-color: #64748b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-checkbox-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
  --mdc-text-button-label-text-color: black;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
  --mdc-icon-button-icon-size: 24px;
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
  --mdc-fab-container-shape: 50%;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-color: white;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-fab-small-container-color: white;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #64748b;
  --mat-table-row-item-outline-width: 1px;
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mdc-circular-progress-active-indicator-color: #005f86;
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
  --mat-badge-background-color: #005f86;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-bottom-sheet-container-shape: 4px;
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #005f86;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(0, 95, 134, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(0, 95, 134, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(0, 95, 134, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #005f86;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(0, 95, 134, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-text-color: black;
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-divider-width: 1px;
  --mat-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
  --mat-icon-color: inherit;
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #005f86;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #005f86;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #005f86;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #c00000;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #c00000;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #757575;
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: rgba(0, 0, 0, 0.87);
}
.svc-005F86 .mat-accent {
  --mat-option-selected-state-label-text-color: #64748b;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.svc-005F86 .mat-warn {
  --mat-option-selected-state-label-text-color: #c00000;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.svc-005F86 .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #005f86;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #005f86;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.svc-005F86 .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #64748b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.svc-005F86 .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #c00000;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #c00000;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.svc-005F86 .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #005f86;
  --mdc-linear-progress-track-color: rgba(0, 95, 134, 0.25);
}
.svc-005F86 .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #64748b;
  --mdc-linear-progress-track-color: rgba(100, 116, 139, 0.25);
}
.svc-005F86 .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #c00000;
  --mdc-linear-progress-track-color: rgba(192, 0, 0, 0.25);
}
.svc-005F86 .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #64748b;
  --mdc-filled-text-field-focus-active-indicator-color: #64748b;
  --mdc-filled-text-field-focus-label-text-color: rgba(100, 116, 139, 0.87);
  --mdc-outlined-text-field-caret-color: #64748b;
  --mdc-outlined-text-field-focus-outline-color: #64748b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(100, 116, 139, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(100, 116, 139, 0.87);
}
.svc-005F86 .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #c00000;
  --mdc-filled-text-field-focus-active-indicator-color: #c00000;
  --mdc-filled-text-field-focus-label-text-color: rgba(192, 0, 0, 0.87);
  --mdc-outlined-text-field-caret-color: #c00000;
  --mdc-outlined-text-field-focus-outline-color: #c00000;
  --mdc-outlined-text-field-focus-label-text-color: rgba(192, 0, 0, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(192, 0, 0, 0.87);
}
.svc-005F86 .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(100, 116, 139, 0.87);
  --mat-select-invalid-arrow-color: rgba(192, 0, 0, 0.87);
}
.svc-005F86 .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(192, 0, 0, 0.87);
  --mat-select-invalid-arrow-color: rgba(192, 0, 0, 0.87);
}
.svc-005F86 .mat-mdc-standard-chip {
  --mdc-chip-container-shape-radius: 16px;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}
.svc-005F86 .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.svc-005F86 .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .svc-005F86 .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #005f86;
  --mdc-chip-elevated-selected-container-color: #005f86;
  --mdc-chip-elevated-disabled-container-color: #005f86;
  --mdc-chip-flat-disabled-selected-container-color: #005f86;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.svc-005F86 .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .svc-005F86 .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #64748b;
  --mdc-chip-elevated-selected-container-color: #64748b;
  --mdc-chip-elevated-disabled-container-color: #64748b;
  --mdc-chip-flat-disabled-selected-container-color: #64748b;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.svc-005F86 .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .svc-005F86 .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: #fef2f2;
  --mdc-chip-elevated-container-color: #c00000;
  --mdc-chip-elevated-selected-container-color: #c00000;
  --mdc-chip-elevated-disabled-container-color: #c00000;
  --mdc-chip-flat-disabled-selected-container-color: #c00000;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #fef2f2;
  --mdc-chip-selected-label-text-color: #fef2f2;
  --mdc-chip-with-icon-icon-color: #fef2f2;
  --mdc-chip-with-icon-disabled-icon-color: #fef2f2;
  --mdc-chip-with-icon-selected-icon-color: #fef2f2;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fef2f2;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fef2f2;
  --mat-chip-selected-disabled-trailing-icon-color: #fef2f2;
  --mat-chip-selected-trailing-icon-color: #fef2f2;
}
.svc-005F86 .mat-mdc-slide-toggle {
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-selected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}
.svc-005F86 .mat-mdc-slide-toggle {
  --mat-switch-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-005F86 .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #475569;
  --mdc-switch-selected-handle-color: #475569;
  --mdc-switch-selected-hover-state-layer-color: #475569;
  --mdc-switch-selected-pressed-state-layer-color: #475569;
  --mdc-switch-selected-focus-handle-color: #0f172a;
  --mdc-switch-selected-hover-handle-color: #0f172a;
  --mdc-switch-selected-pressed-handle-color: #0f172a;
  --mdc-switch-selected-focus-track-color: #cbd5e1;
  --mdc-switch-selected-hover-track-color: #cbd5e1;
  --mdc-switch-selected-pressed-track-color: #cbd5e1;
  --mdc-switch-selected-track-color: #cbd5e1;
}
.svc-005F86 .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #dc2626;
  --mdc-switch-selected-handle-color: #dc2626;
  --mdc-switch-selected-hover-state-layer-color: #dc2626;
  --mdc-switch-selected-pressed-state-layer-color: #dc2626;
  --mdc-switch-selected-focus-handle-color: #7f1d1d;
  --mdc-switch-selected-hover-handle-color: #7f1d1d;
  --mdc-switch-selected-pressed-handle-color: #7f1d1d;
  --mdc-switch-selected-focus-track-color: #fca5a5;
  --mdc-switch-selected-hover-track-color: #fca5a5;
  --mdc-switch-selected-pressed-track-color: #fca5a5;
  --mdc-switch-selected-track-color: #fca5a5;
}
.svc-005F86 .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #005f86;
  --mdc-radio-selected-hover-icon-color: #005f86;
  --mdc-radio-selected-icon-color: #005f86;
  --mdc-radio-selected-pressed-icon-color: #005f86;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #005f86;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-005F86 .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #64748b;
  --mdc-radio-selected-hover-icon-color: #64748b;
  --mdc-radio-selected-icon-color: #64748b;
  --mdc-radio-selected-pressed-icon-color: #64748b;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #64748b;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-005F86 .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #c00000;
  --mdc-radio-selected-hover-icon-color: #c00000;
  --mdc-radio-selected-icon-color: #c00000;
  --mdc-radio-selected-pressed-icon-color: #c00000;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #c00000;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-005F86 .mat-accent {
  --mdc-slider-handle-color: #64748b;
  --mdc-slider-focus-handle-color: #64748b;
  --mdc-slider-hover-handle-color: #64748b;
  --mdc-slider-active-track-color: #64748b;
  --mdc-slider-inactive-track-color: #64748b;
  --mdc-slider-with-tick-marks-inactive-container-color: #64748b;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mat-slider-ripple-color: #64748b;
  --mat-slider-hover-state-layer-color: rgba(100, 116, 139, 0.05);
  --mat-slider-focus-state-layer-color: rgba(100, 116, 139, 0.2);
}
.svc-005F86 .mat-warn {
  --mdc-slider-handle-color: #c00000;
  --mdc-slider-focus-handle-color: #c00000;
  --mdc-slider-hover-handle-color: #c00000;
  --mdc-slider-active-track-color: #c00000;
  --mdc-slider-inactive-track-color: #c00000;
  --mdc-slider-with-tick-marks-inactive-container-color: #c00000;
  --mdc-slider-with-tick-marks-active-container-color: #fef2f2;
  --mat-slider-ripple-color: #c00000;
  --mat-slider-hover-state-layer-color: rgba(192, 0, 0, 0.05);
  --mat-slider-focus-state-layer-color: rgba(192, 0, 0, 0.2);
}
.svc-005F86 .mdc-list-item__start,
.svc-005F86 .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #005f86;
  --mdc-radio-selected-hover-icon-color: #005f86;
  --mdc-radio-selected-icon-color: #005f86;
  --mdc-radio-selected-pressed-icon-color: #005f86;
}
.svc-005F86 .mat-accent .mdc-list-item__start,
.svc-005F86 .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #64748b;
  --mdc-radio-selected-hover-icon-color: #64748b;
  --mdc-radio-selected-icon-color: #64748b;
  --mdc-radio-selected-pressed-icon-color: #64748b;
}
.svc-005F86 .mat-warn .mdc-list-item__start,
.svc-005F86 .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #c00000;
  --mdc-radio-selected-hover-icon-color: #c00000;
  --mdc-radio-selected-icon-color: #c00000;
  --mdc-radio-selected-pressed-icon-color: #c00000;
}
.svc-005F86 .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #005f86;
  --mdc-checkbox-selected-hover-icon-color: #005f86;
  --mdc-checkbox-selected-icon-color: #005f86;
  --mdc-checkbox-selected-pressed-icon-color: #005f86;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #005f86;
  --mdc-checkbox-selected-hover-state-layer-color: #005f86;
  --mdc-checkbox-selected-pressed-state-layer-color: #005f86;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-005F86 .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #64748b;
  --mdc-checkbox-selected-hover-icon-color: #64748b;
  --mdc-checkbox-selected-icon-color: #64748b;
  --mdc-checkbox-selected-pressed-icon-color: #64748b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #64748b;
  --mdc-checkbox-selected-hover-state-layer-color: #64748b;
  --mdc-checkbox-selected-pressed-state-layer-color: #64748b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-005F86 .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fef2f2;
  --mdc-checkbox-selected-focus-icon-color: #c00000;
  --mdc-checkbox-selected-hover-icon-color: #c00000;
  --mdc-checkbox-selected-icon-color: #c00000;
  --mdc-checkbox-selected-pressed-icon-color: #c00000;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #c00000;
  --mdc-checkbox-selected-hover-state-layer-color: #c00000;
  --mdc-checkbox-selected-pressed-state-layer-color: #c00000;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-005F86 .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.svc-005F86 .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__start,
.svc-005F86 .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.svc-005F86 .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__start {
  color: #005f86;
}
.svc-005F86 .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.svc-005F86 .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.svc-005F86 .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.svc-005F86 .mat-mdc-tab-group, .svc-005F86 .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #005f86;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #005f86;
  --mat-tab-header-active-ripple-color: #005f86;
  --mat-tab-header-inactive-ripple-color: #005f86;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #005f86;
  --mat-tab-header-active-hover-label-text-color: #005f86;
  --mat-tab-header-active-focus-indicator-color: #005f86;
  --mat-tab-header-active-hover-indicator-color: #005f86;
}
.svc-005F86 .mat-mdc-tab-group.mat-accent, .svc-005F86 .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #64748b;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #64748b;
  --mat-tab-header-active-ripple-color: #64748b;
  --mat-tab-header-inactive-ripple-color: #64748b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #64748b;
  --mat-tab-header-active-hover-label-text-color: #64748b;
  --mat-tab-header-active-focus-indicator-color: #64748b;
  --mat-tab-header-active-hover-indicator-color: #64748b;
}
.svc-005F86 .mat-mdc-tab-group.mat-warn, .svc-005F86 .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #c00000;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #c00000;
  --mat-tab-header-active-ripple-color: #c00000;
  --mat-tab-header-inactive-ripple-color: #c00000;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #c00000;
  --mat-tab-header-active-hover-label-text-color: #c00000;
  --mat-tab-header-active-focus-indicator-color: #c00000;
  --mat-tab-header-active-hover-indicator-color: #c00000;
}
.svc-005F86 .mat-mdc-tab-group.mat-background-primary, .svc-005F86 .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #005f86;
  --mat-tab-header-with-background-foreground-color: white;
}
.svc-005F86 .mat-mdc-tab-group.mat-background-accent, .svc-005F86 .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #64748b;
  --mat-tab-header-with-background-foreground-color: white;
}
.svc-005F86 .mat-mdc-tab-group.mat-background-warn, .svc-005F86 .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #c00000;
  --mat-tab-header-with-background-foreground-color: #fef2f2;
}
.svc-005F86 .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #005f86;
  --mdc-checkbox-selected-hover-icon-color: #005f86;
  --mdc-checkbox-selected-icon-color: #005f86;
  --mdc-checkbox-selected-pressed-icon-color: #005f86;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #005f86;
  --mdc-checkbox-selected-hover-state-layer-color: #005f86;
  --mdc-checkbox-selected-pressed-state-layer-color: #005f86;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-005F86 .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fef2f2;
  --mdc-checkbox-selected-focus-icon-color: #c00000;
  --mdc-checkbox-selected-hover-icon-color: #c00000;
  --mdc-checkbox-selected-icon-color: #c00000;
  --mdc-checkbox-selected-pressed-icon-color: #c00000;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #c00000;
  --mdc-checkbox-selected-hover-state-layer-color: #c00000;
  --mdc-checkbox-selected-pressed-state-layer-color: #c00000;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-005F86 .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #005f86;
  --mat-text-button-state-layer-color: #005f86;
  --mat-text-button-ripple-color: rgba(0, 95, 134, 0.1);
}
.svc-005F86 .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #64748b;
  --mat-text-button-state-layer-color: #64748b;
  --mat-text-button-ripple-color: rgba(100, 116, 139, 0.1);
}
.svc-005F86 .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #c00000;
  --mat-text-button-state-layer-color: #c00000;
  --mat-text-button-ripple-color: rgba(192, 0, 0, 0.1);
}
.svc-005F86 .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #005f86;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-005F86 .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #64748b;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-005F86 .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #c00000;
  --mdc-filled-button-label-text-color: #fef2f2;
  --mat-filled-button-state-layer-color: #fef2f2;
  --mat-filled-button-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-005F86 .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #005f86;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-005F86 .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #64748b;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-005F86 .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #c00000;
  --mdc-protected-button-label-text-color: #fef2f2;
  --mat-protected-button-state-layer-color: #fef2f2;
  --mat-protected-button-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-005F86 .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #005f86;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #005f86;
  --mat-outlined-button-ripple-color: rgba(0, 95, 134, 0.1);
}
.svc-005F86 .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #64748b;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #64748b;
  --mat-outlined-button-ripple-color: rgba(100, 116, 139, 0.1);
}
.svc-005F86 .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #c00000;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #c00000;
  --mat-outlined-button-ripple-color: rgba(192, 0, 0, 0.1);
}
.svc-005F86 .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #005f86;
  --mat-icon-button-state-layer-color: #005f86;
  --mat-icon-button-ripple-color: rgba(0, 95, 134, 0.1);
}
.svc-005F86 .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #64748b;
  --mat-icon-button-state-layer-color: #64748b;
  --mat-icon-button-ripple-color: rgba(100, 116, 139, 0.1);
}
.svc-005F86 .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #c00000;
  --mat-icon-button-state-layer-color: #c00000;
  --mat-icon-button-ripple-color: rgba(192, 0, 0, 0.1);
}
.svc-005F86 .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #005f86;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-005F86 .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #64748b;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-005F86 .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #c00000;
  --mat-fab-foreground-color: #fef2f2;
  --mat-fab-state-layer-color: #fef2f2;
  --mat-fab-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-005F86 .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #005f86;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-005F86 .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #64748b;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-005F86 .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #c00000;
  --mat-fab-small-foreground-color: #fef2f2;
  --mat-fab-small-state-layer-color: #fef2f2;
  --mat-fab-small-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-005F86 .mat-accent {
  --mdc-circular-progress-active-indicator-color: #64748b;
}
.svc-005F86 .mat-warn {
  --mdc-circular-progress-active-indicator-color: #c00000;
}
.svc-005F86 .mat-badge-accent {
  --mat-badge-background-color: #64748b;
  --mat-badge-text-color: white;
}
.svc-005F86 .mat-badge-warn {
  --mat-badge-background-color: #c00000;
  --mat-badge-text-color: #fef2f2;
}
.svc-005F86 .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #64748b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(100, 116, 139, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(100, 116, 139, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(100, 116, 139, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(100, 116, 139, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.svc-005F86 .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: #fef2f2;
  --mat-datepicker-calendar-date-selected-state-background-color: #c00000;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(192, 0, 0, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #fef2f2;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(192, 0, 0, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(192, 0, 0, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(192, 0, 0, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.svc-005F86 .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #64748b;
}
.svc-005F86 .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #c00000;
}
.svc-005F86 .mat-icon.mat-primary {
  --mat-icon-color: #005f86;
}
.svc-005F86 .mat-icon.mat-accent {
  --mat-icon-color: #64748b;
}
.svc-005F86 .mat-icon.mat-warn {
  --mat-icon-color: #c00000;
}
.svc-005F86 .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #64748b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #64748b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #64748b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.svc-005F86 .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: #fef2f2;
  --mat-stepper-header-selected-state-icon-background-color: #c00000;
  --mat-stepper-header-selected-state-icon-foreground-color: #fef2f2;
  --mat-stepper-header-done-state-icon-background-color: #c00000;
  --mat-stepper-header-done-state-icon-foreground-color: #fef2f2;
  --mat-stepper-header-edit-state-icon-background-color: #c00000;
  --mat-stepper-header-edit-state-icon-foreground-color: #fef2f2;
}
.svc-005F86 .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #005f86;
  --mat-toolbar-container-text-color: white;
}
.svc-005F86 .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #64748b;
  --mat-toolbar-container-text-color: white;
}
.svc-005F86 .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #c00000;
  --mat-toolbar-container-text-color: #fef2f2;
}

.svc-0F58D6 {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-option-selected-state-label-text-color: #0f58d6;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-full-pseudo-checkbox-selected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #64748b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mdc-filled-text-field-caret-color: #0f58d6;
  --mdc-filled-text-field-focus-active-indicator-color: #0f58d6;
  --mdc-filled-text-field-focus-label-text-color: rgba(15, 88, 214, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #c00000;
  --mdc-filled-text-field-error-focus-label-text-color: #c00000;
  --mdc-filled-text-field-error-label-text-color: #c00000;
  --mdc-filled-text-field-error-caret-color: #c00000;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #c00000;
  --mdc-filled-text-field-error-focus-active-indicator-color: #c00000;
  --mdc-filled-text-field-error-hover-active-indicator-color: #c00000;
  --mdc-outlined-text-field-caret-color: #0f58d6;
  --mdc-outlined-text-field-focus-outline-color: #0f58d6;
  --mdc-outlined-text-field-focus-label-text-color: rgba(15, 88, 214, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #c00000;
  --mdc-outlined-text-field-error-focus-label-text-color: #c00000;
  --mdc-outlined-text-field-error-label-text-color: #c00000;
  --mdc-outlined-text-field-error-hover-label-text-color: #c00000;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #c00000;
  --mdc-outlined-text-field-error-hover-outline-color: #c00000;
  --mdc-outlined-text-field-error-outline-color: #c00000;
  --mat-form-field-focus-select-arrow-color: rgba(15, 88, 214, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #c00000;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(15, 88, 214, 0.87);
  --mat-select-invalid-arrow-color: rgba(192, 0, 0, 0.87);
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: white;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-selected-focus-state-layer-color: #0c46ab;
  --mdc-switch-selected-handle-color: #0c46ab;
  --mdc-switch-selected-hover-state-layer-color: #0c46ab;
  --mdc-switch-selected-pressed-state-layer-color: #0c46ab;
  --mdc-switch-selected-focus-handle-color: #03122b;
  --mdc-switch-selected-hover-handle-color: #03122b;
  --mdc-switch-selected-pressed-handle-color: #03122b;
  --mdc-switch-selected-focus-track-color: #6f9be6;
  --mdc-switch-selected-hover-track-color: #6f9be6;
  --mdc-switch-selected-pressed-track-color: #6f9be6;
  --mdc-switch-selected-track-color: #6f9be6;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: #fff;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-handle-color: #0f58d6;
  --mdc-slider-focus-handle-color: #0f58d6;
  --mdc-slider-hover-handle-color: #0f58d6;
  --mdc-slider-active-track-color: #0f58d6;
  --mdc-slider-inactive-track-color: #0f58d6;
  --mdc-slider-with-tick-marks-inactive-container-color: #0f58d6;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-slider-ripple-color: #0f58d6;
  --mat-slider-hover-state-layer-color: rgba(15, 88, 214, 0.05);
  --mat-slider-focus-state-layer-color: rgba(15, 88, 214, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-base-elevation-level: 8;
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: rgba(0, 0, 0, 0.12);
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 4px;
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #64748b;
  --mdc-checkbox-selected-hover-icon-color: #64748b;
  --mdc-checkbox-selected-icon-color: #64748b;
  --mdc-checkbox-selected-pressed-icon-color: #64748b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #64748b;
  --mdc-checkbox-selected-hover-state-layer-color: #64748b;
  --mdc-checkbox-selected-pressed-state-layer-color: #64748b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-checkbox-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
  --mdc-text-button-label-text-color: black;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
  --mdc-icon-button-icon-size: 24px;
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
  --mdc-fab-container-shape: 50%;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-color: white;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-fab-small-container-color: white;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #64748b;
  --mat-table-row-item-outline-width: 1px;
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mdc-circular-progress-active-indicator-color: #0f58d6;
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
  --mat-badge-background-color: #0f58d6;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-bottom-sheet-container-shape: 4px;
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #0f58d6;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(15, 88, 214, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(15, 88, 214, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(15, 88, 214, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #0f58d6;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(15, 88, 214, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-text-color: black;
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-divider-width: 1px;
  --mat-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
  --mat-icon-color: inherit;
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #0f58d6;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #0f58d6;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #0f58d6;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #c00000;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #c00000;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #757575;
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: rgba(0, 0, 0, 0.87);
}
.svc-0F58D6 .mat-accent {
  --mat-option-selected-state-label-text-color: #64748b;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.svc-0F58D6 .mat-warn {
  --mat-option-selected-state-label-text-color: #c00000;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.svc-0F58D6 .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #0f58d6;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #0f58d6;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.svc-0F58D6 .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #64748b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.svc-0F58D6 .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #c00000;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #c00000;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.svc-0F58D6 .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #0f58d6;
  --mdc-linear-progress-track-color: rgba(15, 88, 214, 0.25);
}
.svc-0F58D6 .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #64748b;
  --mdc-linear-progress-track-color: rgba(100, 116, 139, 0.25);
}
.svc-0F58D6 .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #c00000;
  --mdc-linear-progress-track-color: rgba(192, 0, 0, 0.25);
}
.svc-0F58D6 .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #64748b;
  --mdc-filled-text-field-focus-active-indicator-color: #64748b;
  --mdc-filled-text-field-focus-label-text-color: rgba(100, 116, 139, 0.87);
  --mdc-outlined-text-field-caret-color: #64748b;
  --mdc-outlined-text-field-focus-outline-color: #64748b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(100, 116, 139, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(100, 116, 139, 0.87);
}
.svc-0F58D6 .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #c00000;
  --mdc-filled-text-field-focus-active-indicator-color: #c00000;
  --mdc-filled-text-field-focus-label-text-color: rgba(192, 0, 0, 0.87);
  --mdc-outlined-text-field-caret-color: #c00000;
  --mdc-outlined-text-field-focus-outline-color: #c00000;
  --mdc-outlined-text-field-focus-label-text-color: rgba(192, 0, 0, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(192, 0, 0, 0.87);
}
.svc-0F58D6 .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(100, 116, 139, 0.87);
  --mat-select-invalid-arrow-color: rgba(192, 0, 0, 0.87);
}
.svc-0F58D6 .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(192, 0, 0, 0.87);
  --mat-select-invalid-arrow-color: rgba(192, 0, 0, 0.87);
}
.svc-0F58D6 .mat-mdc-standard-chip {
  --mdc-chip-container-shape-radius: 16px;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}
.svc-0F58D6 .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.svc-0F58D6 .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .svc-0F58D6 .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #0f58d6;
  --mdc-chip-elevated-selected-container-color: #0f58d6;
  --mdc-chip-elevated-disabled-container-color: #0f58d6;
  --mdc-chip-flat-disabled-selected-container-color: #0f58d6;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.svc-0F58D6 .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .svc-0F58D6 .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #64748b;
  --mdc-chip-elevated-selected-container-color: #64748b;
  --mdc-chip-elevated-disabled-container-color: #64748b;
  --mdc-chip-flat-disabled-selected-container-color: #64748b;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.svc-0F58D6 .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .svc-0F58D6 .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: #fef2f2;
  --mdc-chip-elevated-container-color: #c00000;
  --mdc-chip-elevated-selected-container-color: #c00000;
  --mdc-chip-elevated-disabled-container-color: #c00000;
  --mdc-chip-flat-disabled-selected-container-color: #c00000;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #fef2f2;
  --mdc-chip-selected-label-text-color: #fef2f2;
  --mdc-chip-with-icon-icon-color: #fef2f2;
  --mdc-chip-with-icon-disabled-icon-color: #fef2f2;
  --mdc-chip-with-icon-selected-icon-color: #fef2f2;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fef2f2;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fef2f2;
  --mat-chip-selected-disabled-trailing-icon-color: #fef2f2;
  --mat-chip-selected-trailing-icon-color: #fef2f2;
}
.svc-0F58D6 .mat-mdc-slide-toggle {
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-selected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}
.svc-0F58D6 .mat-mdc-slide-toggle {
  --mat-switch-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-0F58D6 .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #475569;
  --mdc-switch-selected-handle-color: #475569;
  --mdc-switch-selected-hover-state-layer-color: #475569;
  --mdc-switch-selected-pressed-state-layer-color: #475569;
  --mdc-switch-selected-focus-handle-color: #0f172a;
  --mdc-switch-selected-hover-handle-color: #0f172a;
  --mdc-switch-selected-pressed-handle-color: #0f172a;
  --mdc-switch-selected-focus-track-color: #cbd5e1;
  --mdc-switch-selected-hover-track-color: #cbd5e1;
  --mdc-switch-selected-pressed-track-color: #cbd5e1;
  --mdc-switch-selected-track-color: #cbd5e1;
}
.svc-0F58D6 .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #dc2626;
  --mdc-switch-selected-handle-color: #dc2626;
  --mdc-switch-selected-hover-state-layer-color: #dc2626;
  --mdc-switch-selected-pressed-state-layer-color: #dc2626;
  --mdc-switch-selected-focus-handle-color: #7f1d1d;
  --mdc-switch-selected-hover-handle-color: #7f1d1d;
  --mdc-switch-selected-pressed-handle-color: #7f1d1d;
  --mdc-switch-selected-focus-track-color: #fca5a5;
  --mdc-switch-selected-hover-track-color: #fca5a5;
  --mdc-switch-selected-pressed-track-color: #fca5a5;
  --mdc-switch-selected-track-color: #fca5a5;
}
.svc-0F58D6 .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #0f58d6;
  --mdc-radio-selected-hover-icon-color: #0f58d6;
  --mdc-radio-selected-icon-color: #0f58d6;
  --mdc-radio-selected-pressed-icon-color: #0f58d6;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #0f58d6;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-0F58D6 .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #64748b;
  --mdc-radio-selected-hover-icon-color: #64748b;
  --mdc-radio-selected-icon-color: #64748b;
  --mdc-radio-selected-pressed-icon-color: #64748b;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #64748b;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-0F58D6 .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #c00000;
  --mdc-radio-selected-hover-icon-color: #c00000;
  --mdc-radio-selected-icon-color: #c00000;
  --mdc-radio-selected-pressed-icon-color: #c00000;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #c00000;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-0F58D6 .mat-accent {
  --mdc-slider-handle-color: #64748b;
  --mdc-slider-focus-handle-color: #64748b;
  --mdc-slider-hover-handle-color: #64748b;
  --mdc-slider-active-track-color: #64748b;
  --mdc-slider-inactive-track-color: #64748b;
  --mdc-slider-with-tick-marks-inactive-container-color: #64748b;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mat-slider-ripple-color: #64748b;
  --mat-slider-hover-state-layer-color: rgba(100, 116, 139, 0.05);
  --mat-slider-focus-state-layer-color: rgba(100, 116, 139, 0.2);
}
.svc-0F58D6 .mat-warn {
  --mdc-slider-handle-color: #c00000;
  --mdc-slider-focus-handle-color: #c00000;
  --mdc-slider-hover-handle-color: #c00000;
  --mdc-slider-active-track-color: #c00000;
  --mdc-slider-inactive-track-color: #c00000;
  --mdc-slider-with-tick-marks-inactive-container-color: #c00000;
  --mdc-slider-with-tick-marks-active-container-color: #fef2f2;
  --mat-slider-ripple-color: #c00000;
  --mat-slider-hover-state-layer-color: rgba(192, 0, 0, 0.05);
  --mat-slider-focus-state-layer-color: rgba(192, 0, 0, 0.2);
}
.svc-0F58D6 .mdc-list-item__start,
.svc-0F58D6 .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #0f58d6;
  --mdc-radio-selected-hover-icon-color: #0f58d6;
  --mdc-radio-selected-icon-color: #0f58d6;
  --mdc-radio-selected-pressed-icon-color: #0f58d6;
}
.svc-0F58D6 .mat-accent .mdc-list-item__start,
.svc-0F58D6 .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #64748b;
  --mdc-radio-selected-hover-icon-color: #64748b;
  --mdc-radio-selected-icon-color: #64748b;
  --mdc-radio-selected-pressed-icon-color: #64748b;
}
.svc-0F58D6 .mat-warn .mdc-list-item__start,
.svc-0F58D6 .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #c00000;
  --mdc-radio-selected-hover-icon-color: #c00000;
  --mdc-radio-selected-icon-color: #c00000;
  --mdc-radio-selected-pressed-icon-color: #c00000;
}
.svc-0F58D6 .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #0f58d6;
  --mdc-checkbox-selected-hover-icon-color: #0f58d6;
  --mdc-checkbox-selected-icon-color: #0f58d6;
  --mdc-checkbox-selected-pressed-icon-color: #0f58d6;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #0f58d6;
  --mdc-checkbox-selected-hover-state-layer-color: #0f58d6;
  --mdc-checkbox-selected-pressed-state-layer-color: #0f58d6;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-0F58D6 .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #64748b;
  --mdc-checkbox-selected-hover-icon-color: #64748b;
  --mdc-checkbox-selected-icon-color: #64748b;
  --mdc-checkbox-selected-pressed-icon-color: #64748b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #64748b;
  --mdc-checkbox-selected-hover-state-layer-color: #64748b;
  --mdc-checkbox-selected-pressed-state-layer-color: #64748b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-0F58D6 .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fef2f2;
  --mdc-checkbox-selected-focus-icon-color: #c00000;
  --mdc-checkbox-selected-hover-icon-color: #c00000;
  --mdc-checkbox-selected-icon-color: #c00000;
  --mdc-checkbox-selected-pressed-icon-color: #c00000;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #c00000;
  --mdc-checkbox-selected-hover-state-layer-color: #c00000;
  --mdc-checkbox-selected-pressed-state-layer-color: #c00000;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-0F58D6 .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.svc-0F58D6 .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__start,
.svc-0F58D6 .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.svc-0F58D6 .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__start {
  color: #0f58d6;
}
.svc-0F58D6 .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.svc-0F58D6 .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.svc-0F58D6 .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.svc-0F58D6 .mat-mdc-tab-group, .svc-0F58D6 .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #0f58d6;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #0f58d6;
  --mat-tab-header-active-ripple-color: #0f58d6;
  --mat-tab-header-inactive-ripple-color: #0f58d6;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #0f58d6;
  --mat-tab-header-active-hover-label-text-color: #0f58d6;
  --mat-tab-header-active-focus-indicator-color: #0f58d6;
  --mat-tab-header-active-hover-indicator-color: #0f58d6;
}
.svc-0F58D6 .mat-mdc-tab-group.mat-accent, .svc-0F58D6 .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #64748b;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #64748b;
  --mat-tab-header-active-ripple-color: #64748b;
  --mat-tab-header-inactive-ripple-color: #64748b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #64748b;
  --mat-tab-header-active-hover-label-text-color: #64748b;
  --mat-tab-header-active-focus-indicator-color: #64748b;
  --mat-tab-header-active-hover-indicator-color: #64748b;
}
.svc-0F58D6 .mat-mdc-tab-group.mat-warn, .svc-0F58D6 .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #c00000;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #c00000;
  --mat-tab-header-active-ripple-color: #c00000;
  --mat-tab-header-inactive-ripple-color: #c00000;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #c00000;
  --mat-tab-header-active-hover-label-text-color: #c00000;
  --mat-tab-header-active-focus-indicator-color: #c00000;
  --mat-tab-header-active-hover-indicator-color: #c00000;
}
.svc-0F58D6 .mat-mdc-tab-group.mat-background-primary, .svc-0F58D6 .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #0f58d6;
  --mat-tab-header-with-background-foreground-color: white;
}
.svc-0F58D6 .mat-mdc-tab-group.mat-background-accent, .svc-0F58D6 .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #64748b;
  --mat-tab-header-with-background-foreground-color: white;
}
.svc-0F58D6 .mat-mdc-tab-group.mat-background-warn, .svc-0F58D6 .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #c00000;
  --mat-tab-header-with-background-foreground-color: #fef2f2;
}
.svc-0F58D6 .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #0f58d6;
  --mdc-checkbox-selected-hover-icon-color: #0f58d6;
  --mdc-checkbox-selected-icon-color: #0f58d6;
  --mdc-checkbox-selected-pressed-icon-color: #0f58d6;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #0f58d6;
  --mdc-checkbox-selected-hover-state-layer-color: #0f58d6;
  --mdc-checkbox-selected-pressed-state-layer-color: #0f58d6;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-0F58D6 .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fef2f2;
  --mdc-checkbox-selected-focus-icon-color: #c00000;
  --mdc-checkbox-selected-hover-icon-color: #c00000;
  --mdc-checkbox-selected-icon-color: #c00000;
  --mdc-checkbox-selected-pressed-icon-color: #c00000;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #c00000;
  --mdc-checkbox-selected-hover-state-layer-color: #c00000;
  --mdc-checkbox-selected-pressed-state-layer-color: #c00000;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-0F58D6 .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #0f58d6;
  --mat-text-button-state-layer-color: #0f58d6;
  --mat-text-button-ripple-color: rgba(15, 88, 214, 0.1);
}
.svc-0F58D6 .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #64748b;
  --mat-text-button-state-layer-color: #64748b;
  --mat-text-button-ripple-color: rgba(100, 116, 139, 0.1);
}
.svc-0F58D6 .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #c00000;
  --mat-text-button-state-layer-color: #c00000;
  --mat-text-button-ripple-color: rgba(192, 0, 0, 0.1);
}
.svc-0F58D6 .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #0f58d6;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-0F58D6 .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #64748b;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-0F58D6 .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #c00000;
  --mdc-filled-button-label-text-color: #fef2f2;
  --mat-filled-button-state-layer-color: #fef2f2;
  --mat-filled-button-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-0F58D6 .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #0f58d6;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-0F58D6 .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #64748b;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-0F58D6 .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #c00000;
  --mdc-protected-button-label-text-color: #fef2f2;
  --mat-protected-button-state-layer-color: #fef2f2;
  --mat-protected-button-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-0F58D6 .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #0f58d6;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #0f58d6;
  --mat-outlined-button-ripple-color: rgba(15, 88, 214, 0.1);
}
.svc-0F58D6 .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #64748b;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #64748b;
  --mat-outlined-button-ripple-color: rgba(100, 116, 139, 0.1);
}
.svc-0F58D6 .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #c00000;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #c00000;
  --mat-outlined-button-ripple-color: rgba(192, 0, 0, 0.1);
}
.svc-0F58D6 .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #0f58d6;
  --mat-icon-button-state-layer-color: #0f58d6;
  --mat-icon-button-ripple-color: rgba(15, 88, 214, 0.1);
}
.svc-0F58D6 .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #64748b;
  --mat-icon-button-state-layer-color: #64748b;
  --mat-icon-button-ripple-color: rgba(100, 116, 139, 0.1);
}
.svc-0F58D6 .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #c00000;
  --mat-icon-button-state-layer-color: #c00000;
  --mat-icon-button-ripple-color: rgba(192, 0, 0, 0.1);
}
.svc-0F58D6 .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #0f58d6;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-0F58D6 .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #64748b;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-0F58D6 .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #c00000;
  --mat-fab-foreground-color: #fef2f2;
  --mat-fab-state-layer-color: #fef2f2;
  --mat-fab-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-0F58D6 .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #0f58d6;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-0F58D6 .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #64748b;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-0F58D6 .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #c00000;
  --mat-fab-small-foreground-color: #fef2f2;
  --mat-fab-small-state-layer-color: #fef2f2;
  --mat-fab-small-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-0F58D6 .mat-accent {
  --mdc-circular-progress-active-indicator-color: #64748b;
}
.svc-0F58D6 .mat-warn {
  --mdc-circular-progress-active-indicator-color: #c00000;
}
.svc-0F58D6 .mat-badge-accent {
  --mat-badge-background-color: #64748b;
  --mat-badge-text-color: white;
}
.svc-0F58D6 .mat-badge-warn {
  --mat-badge-background-color: #c00000;
  --mat-badge-text-color: #fef2f2;
}
.svc-0F58D6 .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #64748b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(100, 116, 139, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(100, 116, 139, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(100, 116, 139, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(100, 116, 139, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.svc-0F58D6 .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: #fef2f2;
  --mat-datepicker-calendar-date-selected-state-background-color: #c00000;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(192, 0, 0, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #fef2f2;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(192, 0, 0, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(192, 0, 0, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(192, 0, 0, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.svc-0F58D6 .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #64748b;
}
.svc-0F58D6 .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #c00000;
}
.svc-0F58D6 .mat-icon.mat-primary {
  --mat-icon-color: #0f58d6;
}
.svc-0F58D6 .mat-icon.mat-accent {
  --mat-icon-color: #64748b;
}
.svc-0F58D6 .mat-icon.mat-warn {
  --mat-icon-color: #c00000;
}
.svc-0F58D6 .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #64748b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #64748b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #64748b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.svc-0F58D6 .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: #fef2f2;
  --mat-stepper-header-selected-state-icon-background-color: #c00000;
  --mat-stepper-header-selected-state-icon-foreground-color: #fef2f2;
  --mat-stepper-header-done-state-icon-background-color: #c00000;
  --mat-stepper-header-done-state-icon-foreground-color: #fef2f2;
  --mat-stepper-header-edit-state-icon-background-color: #c00000;
  --mat-stepper-header-edit-state-icon-foreground-color: #fef2f2;
}
.svc-0F58D6 .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #0f58d6;
  --mat-toolbar-container-text-color: white;
}
.svc-0F58D6 .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #64748b;
  --mat-toolbar-container-text-color: white;
}
.svc-0F58D6 .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #c00000;
  --mat-toolbar-container-text-color: #fef2f2;
}

.svc-161718 {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-option-selected-state-label-text-color: #161718;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-full-pseudo-checkbox-selected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #64748b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mdc-filled-text-field-caret-color: #161718;
  --mdc-filled-text-field-focus-active-indicator-color: #161718;
  --mdc-filled-text-field-focus-label-text-color: rgba(22, 23, 24, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #c00000;
  --mdc-filled-text-field-error-focus-label-text-color: #c00000;
  --mdc-filled-text-field-error-label-text-color: #c00000;
  --mdc-filled-text-field-error-caret-color: #c00000;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #c00000;
  --mdc-filled-text-field-error-focus-active-indicator-color: #c00000;
  --mdc-filled-text-field-error-hover-active-indicator-color: #c00000;
  --mdc-outlined-text-field-caret-color: #161718;
  --mdc-outlined-text-field-focus-outline-color: #161718;
  --mdc-outlined-text-field-focus-label-text-color: rgba(22, 23, 24, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #c00000;
  --mdc-outlined-text-field-error-focus-label-text-color: #c00000;
  --mdc-outlined-text-field-error-label-text-color: #c00000;
  --mdc-outlined-text-field-error-hover-label-text-color: #c00000;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #c00000;
  --mdc-outlined-text-field-error-hover-outline-color: #c00000;
  --mdc-outlined-text-field-error-outline-color: #c00000;
  --mat-form-field-focus-select-arrow-color: rgba(22, 23, 24, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #c00000;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(22, 23, 24, 0.87);
  --mat-select-invalid-arrow-color: rgba(192, 0, 0, 0.87);
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: white;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-selected-focus-state-layer-color: #121213;
  --mdc-switch-selected-handle-color: #121213;
  --mdc-switch-selected-hover-state-layer-color: #121213;
  --mdc-switch-selected-pressed-state-layer-color: #121213;
  --mdc-switch-selected-focus-handle-color: #040505;
  --mdc-switch-selected-hover-handle-color: #040505;
  --mdc-switch-selected-pressed-handle-color: #040505;
  --mdc-switch-selected-focus-track-color: #737474;
  --mdc-switch-selected-hover-track-color: #737474;
  --mdc-switch-selected-pressed-track-color: #737474;
  --mdc-switch-selected-track-color: #737474;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: #fff;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-handle-color: #161718;
  --mdc-slider-focus-handle-color: #161718;
  --mdc-slider-hover-handle-color: #161718;
  --mdc-slider-active-track-color: #161718;
  --mdc-slider-inactive-track-color: #161718;
  --mdc-slider-with-tick-marks-inactive-container-color: #161718;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-slider-ripple-color: #161718;
  --mat-slider-hover-state-layer-color: rgba(22, 23, 24, 0.05);
  --mat-slider-focus-state-layer-color: rgba(22, 23, 24, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-base-elevation-level: 8;
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: rgba(0, 0, 0, 0.12);
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 4px;
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #64748b;
  --mdc-checkbox-selected-hover-icon-color: #64748b;
  --mdc-checkbox-selected-icon-color: #64748b;
  --mdc-checkbox-selected-pressed-icon-color: #64748b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #64748b;
  --mdc-checkbox-selected-hover-state-layer-color: #64748b;
  --mdc-checkbox-selected-pressed-state-layer-color: #64748b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-checkbox-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
  --mdc-text-button-label-text-color: black;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
  --mdc-icon-button-icon-size: 24px;
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
  --mdc-fab-container-shape: 50%;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-color: white;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-fab-small-container-color: white;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #64748b;
  --mat-table-row-item-outline-width: 1px;
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mdc-circular-progress-active-indicator-color: #161718;
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
  --mat-badge-background-color: #161718;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-bottom-sheet-container-shape: 4px;
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #161718;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(22, 23, 24, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(22, 23, 24, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(22, 23, 24, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #161718;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(22, 23, 24, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-text-color: black;
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-divider-width: 1px;
  --mat-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
  --mat-icon-color: inherit;
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #161718;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #161718;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #161718;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #c00000;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #c00000;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #757575;
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: rgba(0, 0, 0, 0.87);
}
.svc-161718 .mat-accent {
  --mat-option-selected-state-label-text-color: #64748b;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.svc-161718 .mat-warn {
  --mat-option-selected-state-label-text-color: #c00000;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.svc-161718 .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #161718;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #161718;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.svc-161718 .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #64748b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.svc-161718 .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #c00000;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #c00000;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.svc-161718 .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #161718;
  --mdc-linear-progress-track-color: rgba(22, 23, 24, 0.25);
}
.svc-161718 .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #64748b;
  --mdc-linear-progress-track-color: rgba(100, 116, 139, 0.25);
}
.svc-161718 .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #c00000;
  --mdc-linear-progress-track-color: rgba(192, 0, 0, 0.25);
}
.svc-161718 .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #64748b;
  --mdc-filled-text-field-focus-active-indicator-color: #64748b;
  --mdc-filled-text-field-focus-label-text-color: rgba(100, 116, 139, 0.87);
  --mdc-outlined-text-field-caret-color: #64748b;
  --mdc-outlined-text-field-focus-outline-color: #64748b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(100, 116, 139, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(100, 116, 139, 0.87);
}
.svc-161718 .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #c00000;
  --mdc-filled-text-field-focus-active-indicator-color: #c00000;
  --mdc-filled-text-field-focus-label-text-color: rgba(192, 0, 0, 0.87);
  --mdc-outlined-text-field-caret-color: #c00000;
  --mdc-outlined-text-field-focus-outline-color: #c00000;
  --mdc-outlined-text-field-focus-label-text-color: rgba(192, 0, 0, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(192, 0, 0, 0.87);
}
.svc-161718 .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(100, 116, 139, 0.87);
  --mat-select-invalid-arrow-color: rgba(192, 0, 0, 0.87);
}
.svc-161718 .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(192, 0, 0, 0.87);
  --mat-select-invalid-arrow-color: rgba(192, 0, 0, 0.87);
}
.svc-161718 .mat-mdc-standard-chip {
  --mdc-chip-container-shape-radius: 16px;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}
.svc-161718 .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.svc-161718 .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .svc-161718 .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #161718;
  --mdc-chip-elevated-selected-container-color: #161718;
  --mdc-chip-elevated-disabled-container-color: #161718;
  --mdc-chip-flat-disabled-selected-container-color: #161718;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.svc-161718 .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .svc-161718 .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #64748b;
  --mdc-chip-elevated-selected-container-color: #64748b;
  --mdc-chip-elevated-disabled-container-color: #64748b;
  --mdc-chip-flat-disabled-selected-container-color: #64748b;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.svc-161718 .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .svc-161718 .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: #fef2f2;
  --mdc-chip-elevated-container-color: #c00000;
  --mdc-chip-elevated-selected-container-color: #c00000;
  --mdc-chip-elevated-disabled-container-color: #c00000;
  --mdc-chip-flat-disabled-selected-container-color: #c00000;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #fef2f2;
  --mdc-chip-selected-label-text-color: #fef2f2;
  --mdc-chip-with-icon-icon-color: #fef2f2;
  --mdc-chip-with-icon-disabled-icon-color: #fef2f2;
  --mdc-chip-with-icon-selected-icon-color: #fef2f2;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fef2f2;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fef2f2;
  --mat-chip-selected-disabled-trailing-icon-color: #fef2f2;
  --mat-chip-selected-trailing-icon-color: #fef2f2;
}
.svc-161718 .mat-mdc-slide-toggle {
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-selected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}
.svc-161718 .mat-mdc-slide-toggle {
  --mat-switch-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-161718 .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #475569;
  --mdc-switch-selected-handle-color: #475569;
  --mdc-switch-selected-hover-state-layer-color: #475569;
  --mdc-switch-selected-pressed-state-layer-color: #475569;
  --mdc-switch-selected-focus-handle-color: #0f172a;
  --mdc-switch-selected-hover-handle-color: #0f172a;
  --mdc-switch-selected-pressed-handle-color: #0f172a;
  --mdc-switch-selected-focus-track-color: #cbd5e1;
  --mdc-switch-selected-hover-track-color: #cbd5e1;
  --mdc-switch-selected-pressed-track-color: #cbd5e1;
  --mdc-switch-selected-track-color: #cbd5e1;
}
.svc-161718 .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #dc2626;
  --mdc-switch-selected-handle-color: #dc2626;
  --mdc-switch-selected-hover-state-layer-color: #dc2626;
  --mdc-switch-selected-pressed-state-layer-color: #dc2626;
  --mdc-switch-selected-focus-handle-color: #7f1d1d;
  --mdc-switch-selected-hover-handle-color: #7f1d1d;
  --mdc-switch-selected-pressed-handle-color: #7f1d1d;
  --mdc-switch-selected-focus-track-color: #fca5a5;
  --mdc-switch-selected-hover-track-color: #fca5a5;
  --mdc-switch-selected-pressed-track-color: #fca5a5;
  --mdc-switch-selected-track-color: #fca5a5;
}
.svc-161718 .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #161718;
  --mdc-radio-selected-hover-icon-color: #161718;
  --mdc-radio-selected-icon-color: #161718;
  --mdc-radio-selected-pressed-icon-color: #161718;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #161718;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-161718 .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #64748b;
  --mdc-radio-selected-hover-icon-color: #64748b;
  --mdc-radio-selected-icon-color: #64748b;
  --mdc-radio-selected-pressed-icon-color: #64748b;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #64748b;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-161718 .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #c00000;
  --mdc-radio-selected-hover-icon-color: #c00000;
  --mdc-radio-selected-icon-color: #c00000;
  --mdc-radio-selected-pressed-icon-color: #c00000;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #c00000;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-161718 .mat-accent {
  --mdc-slider-handle-color: #64748b;
  --mdc-slider-focus-handle-color: #64748b;
  --mdc-slider-hover-handle-color: #64748b;
  --mdc-slider-active-track-color: #64748b;
  --mdc-slider-inactive-track-color: #64748b;
  --mdc-slider-with-tick-marks-inactive-container-color: #64748b;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mat-slider-ripple-color: #64748b;
  --mat-slider-hover-state-layer-color: rgba(100, 116, 139, 0.05);
  --mat-slider-focus-state-layer-color: rgba(100, 116, 139, 0.2);
}
.svc-161718 .mat-warn {
  --mdc-slider-handle-color: #c00000;
  --mdc-slider-focus-handle-color: #c00000;
  --mdc-slider-hover-handle-color: #c00000;
  --mdc-slider-active-track-color: #c00000;
  --mdc-slider-inactive-track-color: #c00000;
  --mdc-slider-with-tick-marks-inactive-container-color: #c00000;
  --mdc-slider-with-tick-marks-active-container-color: #fef2f2;
  --mat-slider-ripple-color: #c00000;
  --mat-slider-hover-state-layer-color: rgba(192, 0, 0, 0.05);
  --mat-slider-focus-state-layer-color: rgba(192, 0, 0, 0.2);
}
.svc-161718 .mdc-list-item__start,
.svc-161718 .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #161718;
  --mdc-radio-selected-hover-icon-color: #161718;
  --mdc-radio-selected-icon-color: #161718;
  --mdc-radio-selected-pressed-icon-color: #161718;
}
.svc-161718 .mat-accent .mdc-list-item__start,
.svc-161718 .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #64748b;
  --mdc-radio-selected-hover-icon-color: #64748b;
  --mdc-radio-selected-icon-color: #64748b;
  --mdc-radio-selected-pressed-icon-color: #64748b;
}
.svc-161718 .mat-warn .mdc-list-item__start,
.svc-161718 .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #c00000;
  --mdc-radio-selected-hover-icon-color: #c00000;
  --mdc-radio-selected-icon-color: #c00000;
  --mdc-radio-selected-pressed-icon-color: #c00000;
}
.svc-161718 .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #161718;
  --mdc-checkbox-selected-hover-icon-color: #161718;
  --mdc-checkbox-selected-icon-color: #161718;
  --mdc-checkbox-selected-pressed-icon-color: #161718;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #161718;
  --mdc-checkbox-selected-hover-state-layer-color: #161718;
  --mdc-checkbox-selected-pressed-state-layer-color: #161718;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-161718 .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #64748b;
  --mdc-checkbox-selected-hover-icon-color: #64748b;
  --mdc-checkbox-selected-icon-color: #64748b;
  --mdc-checkbox-selected-pressed-icon-color: #64748b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #64748b;
  --mdc-checkbox-selected-hover-state-layer-color: #64748b;
  --mdc-checkbox-selected-pressed-state-layer-color: #64748b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-161718 .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fef2f2;
  --mdc-checkbox-selected-focus-icon-color: #c00000;
  --mdc-checkbox-selected-hover-icon-color: #c00000;
  --mdc-checkbox-selected-icon-color: #c00000;
  --mdc-checkbox-selected-pressed-icon-color: #c00000;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #c00000;
  --mdc-checkbox-selected-hover-state-layer-color: #c00000;
  --mdc-checkbox-selected-pressed-state-layer-color: #c00000;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-161718 .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.svc-161718 .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__start,
.svc-161718 .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.svc-161718 .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__start {
  color: #161718;
}
.svc-161718 .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.svc-161718 .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.svc-161718 .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.svc-161718 .mat-mdc-tab-group, .svc-161718 .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #161718;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #161718;
  --mat-tab-header-active-ripple-color: #161718;
  --mat-tab-header-inactive-ripple-color: #161718;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #161718;
  --mat-tab-header-active-hover-label-text-color: #161718;
  --mat-tab-header-active-focus-indicator-color: #161718;
  --mat-tab-header-active-hover-indicator-color: #161718;
}
.svc-161718 .mat-mdc-tab-group.mat-accent, .svc-161718 .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #64748b;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #64748b;
  --mat-tab-header-active-ripple-color: #64748b;
  --mat-tab-header-inactive-ripple-color: #64748b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #64748b;
  --mat-tab-header-active-hover-label-text-color: #64748b;
  --mat-tab-header-active-focus-indicator-color: #64748b;
  --mat-tab-header-active-hover-indicator-color: #64748b;
}
.svc-161718 .mat-mdc-tab-group.mat-warn, .svc-161718 .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #c00000;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #c00000;
  --mat-tab-header-active-ripple-color: #c00000;
  --mat-tab-header-inactive-ripple-color: #c00000;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #c00000;
  --mat-tab-header-active-hover-label-text-color: #c00000;
  --mat-tab-header-active-focus-indicator-color: #c00000;
  --mat-tab-header-active-hover-indicator-color: #c00000;
}
.svc-161718 .mat-mdc-tab-group.mat-background-primary, .svc-161718 .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #161718;
  --mat-tab-header-with-background-foreground-color: white;
}
.svc-161718 .mat-mdc-tab-group.mat-background-accent, .svc-161718 .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #64748b;
  --mat-tab-header-with-background-foreground-color: white;
}
.svc-161718 .mat-mdc-tab-group.mat-background-warn, .svc-161718 .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #c00000;
  --mat-tab-header-with-background-foreground-color: #fef2f2;
}
.svc-161718 .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #161718;
  --mdc-checkbox-selected-hover-icon-color: #161718;
  --mdc-checkbox-selected-icon-color: #161718;
  --mdc-checkbox-selected-pressed-icon-color: #161718;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #161718;
  --mdc-checkbox-selected-hover-state-layer-color: #161718;
  --mdc-checkbox-selected-pressed-state-layer-color: #161718;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-161718 .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fef2f2;
  --mdc-checkbox-selected-focus-icon-color: #c00000;
  --mdc-checkbox-selected-hover-icon-color: #c00000;
  --mdc-checkbox-selected-icon-color: #c00000;
  --mdc-checkbox-selected-pressed-icon-color: #c00000;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #c00000;
  --mdc-checkbox-selected-hover-state-layer-color: #c00000;
  --mdc-checkbox-selected-pressed-state-layer-color: #c00000;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-161718 .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #161718;
  --mat-text-button-state-layer-color: #161718;
  --mat-text-button-ripple-color: rgba(22, 23, 24, 0.1);
}
.svc-161718 .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #64748b;
  --mat-text-button-state-layer-color: #64748b;
  --mat-text-button-ripple-color: rgba(100, 116, 139, 0.1);
}
.svc-161718 .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #c00000;
  --mat-text-button-state-layer-color: #c00000;
  --mat-text-button-ripple-color: rgba(192, 0, 0, 0.1);
}
.svc-161718 .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #161718;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-161718 .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #64748b;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-161718 .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #c00000;
  --mdc-filled-button-label-text-color: #fef2f2;
  --mat-filled-button-state-layer-color: #fef2f2;
  --mat-filled-button-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-161718 .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #161718;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-161718 .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #64748b;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-161718 .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #c00000;
  --mdc-protected-button-label-text-color: #fef2f2;
  --mat-protected-button-state-layer-color: #fef2f2;
  --mat-protected-button-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-161718 .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #161718;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #161718;
  --mat-outlined-button-ripple-color: rgba(22, 23, 24, 0.1);
}
.svc-161718 .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #64748b;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #64748b;
  --mat-outlined-button-ripple-color: rgba(100, 116, 139, 0.1);
}
.svc-161718 .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #c00000;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #c00000;
  --mat-outlined-button-ripple-color: rgba(192, 0, 0, 0.1);
}
.svc-161718 .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #161718;
  --mat-icon-button-state-layer-color: #161718;
  --mat-icon-button-ripple-color: rgba(22, 23, 24, 0.1);
}
.svc-161718 .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #64748b;
  --mat-icon-button-state-layer-color: #64748b;
  --mat-icon-button-ripple-color: rgba(100, 116, 139, 0.1);
}
.svc-161718 .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #c00000;
  --mat-icon-button-state-layer-color: #c00000;
  --mat-icon-button-ripple-color: rgba(192, 0, 0, 0.1);
}
.svc-161718 .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #161718;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-161718 .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #64748b;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-161718 .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #c00000;
  --mat-fab-foreground-color: #fef2f2;
  --mat-fab-state-layer-color: #fef2f2;
  --mat-fab-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-161718 .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #161718;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-161718 .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #64748b;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-161718 .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #c00000;
  --mat-fab-small-foreground-color: #fef2f2;
  --mat-fab-small-state-layer-color: #fef2f2;
  --mat-fab-small-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-161718 .mat-accent {
  --mdc-circular-progress-active-indicator-color: #64748b;
}
.svc-161718 .mat-warn {
  --mdc-circular-progress-active-indicator-color: #c00000;
}
.svc-161718 .mat-badge-accent {
  --mat-badge-background-color: #64748b;
  --mat-badge-text-color: white;
}
.svc-161718 .mat-badge-warn {
  --mat-badge-background-color: #c00000;
  --mat-badge-text-color: #fef2f2;
}
.svc-161718 .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #64748b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(100, 116, 139, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(100, 116, 139, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(100, 116, 139, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(100, 116, 139, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.svc-161718 .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: #fef2f2;
  --mat-datepicker-calendar-date-selected-state-background-color: #c00000;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(192, 0, 0, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #fef2f2;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(192, 0, 0, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(192, 0, 0, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(192, 0, 0, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.svc-161718 .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #64748b;
}
.svc-161718 .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #c00000;
}
.svc-161718 .mat-icon.mat-primary {
  --mat-icon-color: #161718;
}
.svc-161718 .mat-icon.mat-accent {
  --mat-icon-color: #64748b;
}
.svc-161718 .mat-icon.mat-warn {
  --mat-icon-color: #c00000;
}
.svc-161718 .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #64748b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #64748b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #64748b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.svc-161718 .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: #fef2f2;
  --mat-stepper-header-selected-state-icon-background-color: #c00000;
  --mat-stepper-header-selected-state-icon-foreground-color: #fef2f2;
  --mat-stepper-header-done-state-icon-background-color: #c00000;
  --mat-stepper-header-done-state-icon-foreground-color: #fef2f2;
  --mat-stepper-header-edit-state-icon-background-color: #c00000;
  --mat-stepper-header-edit-state-icon-foreground-color: #fef2f2;
}
.svc-161718 .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #161718;
  --mat-toolbar-container-text-color: white;
}
.svc-161718 .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #64748b;
  --mat-toolbar-container-text-color: white;
}
.svc-161718 .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #c00000;
  --mat-toolbar-container-text-color: #fef2f2;
}

.svc-3A8DD2 {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-option-selected-state-label-text-color: #3a8dd2;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-full-pseudo-checkbox-selected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #64748b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mdc-filled-text-field-caret-color: #3a8dd2;
  --mdc-filled-text-field-focus-active-indicator-color: #3a8dd2;
  --mdc-filled-text-field-focus-label-text-color: rgba(58, 141, 210, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #c00000;
  --mdc-filled-text-field-error-focus-label-text-color: #c00000;
  --mdc-filled-text-field-error-label-text-color: #c00000;
  --mdc-filled-text-field-error-caret-color: #c00000;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #c00000;
  --mdc-filled-text-field-error-focus-active-indicator-color: #c00000;
  --mdc-filled-text-field-error-hover-active-indicator-color: #c00000;
  --mdc-outlined-text-field-caret-color: #3a8dd2;
  --mdc-outlined-text-field-focus-outline-color: #3a8dd2;
  --mdc-outlined-text-field-focus-label-text-color: rgba(58, 141, 210, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #c00000;
  --mdc-outlined-text-field-error-focus-label-text-color: #c00000;
  --mdc-outlined-text-field-error-label-text-color: #c00000;
  --mdc-outlined-text-field-error-hover-label-text-color: #c00000;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #c00000;
  --mdc-outlined-text-field-error-hover-outline-color: #c00000;
  --mdc-outlined-text-field-error-outline-color: #c00000;
  --mat-form-field-focus-select-arrow-color: rgba(58, 141, 210, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #c00000;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(58, 141, 210, 0.87);
  --mat-select-invalid-arrow-color: rgba(192, 0, 0, 0.87);
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: white;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-selected-focus-state-layer-color: #2e71a8;
  --mdc-switch-selected-handle-color: #2e71a8;
  --mdc-switch-selected-hover-state-layer-color: #2e71a8;
  --mdc-switch-selected-pressed-state-layer-color: #2e71a8;
  --mdc-switch-selected-focus-handle-color: #0c1c2a;
  --mdc-switch-selected-hover-handle-color: #0c1c2a;
  --mdc-switch-selected-pressed-handle-color: #0c1c2a;
  --mdc-switch-selected-focus-track-color: #89bbe4;
  --mdc-switch-selected-hover-track-color: #89bbe4;
  --mdc-switch-selected-pressed-track-color: #89bbe4;
  --mdc-switch-selected-track-color: #89bbe4;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: #fff;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-handle-color: #3a8dd2;
  --mdc-slider-focus-handle-color: #3a8dd2;
  --mdc-slider-hover-handle-color: #3a8dd2;
  --mdc-slider-active-track-color: #3a8dd2;
  --mdc-slider-inactive-track-color: #3a8dd2;
  --mdc-slider-with-tick-marks-inactive-container-color: #3a8dd2;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-slider-ripple-color: #3a8dd2;
  --mat-slider-hover-state-layer-color: rgba(58, 141, 210, 0.05);
  --mat-slider-focus-state-layer-color: rgba(58, 141, 210, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-base-elevation-level: 8;
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: rgba(0, 0, 0, 0.12);
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 4px;
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #64748b;
  --mdc-checkbox-selected-hover-icon-color: #64748b;
  --mdc-checkbox-selected-icon-color: #64748b;
  --mdc-checkbox-selected-pressed-icon-color: #64748b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #64748b;
  --mdc-checkbox-selected-hover-state-layer-color: #64748b;
  --mdc-checkbox-selected-pressed-state-layer-color: #64748b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-checkbox-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
  --mdc-text-button-label-text-color: black;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
  --mdc-icon-button-icon-size: 24px;
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
  --mdc-fab-container-shape: 50%;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-color: white;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-fab-small-container-color: white;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #64748b;
  --mat-table-row-item-outline-width: 1px;
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mdc-circular-progress-active-indicator-color: #3a8dd2;
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
  --mat-badge-background-color: #3a8dd2;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-bottom-sheet-container-shape: 4px;
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #3a8dd2;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(58, 141, 210, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(58, 141, 210, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(58, 141, 210, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #3a8dd2;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(58, 141, 210, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-text-color: black;
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-divider-width: 1px;
  --mat-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
  --mat-icon-color: inherit;
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #3a8dd2;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #3a8dd2;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #3a8dd2;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #c00000;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #c00000;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #757575;
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: rgba(0, 0, 0, 0.87);
}
.svc-3A8DD2 .mat-accent {
  --mat-option-selected-state-label-text-color: #64748b;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.svc-3A8DD2 .mat-warn {
  --mat-option-selected-state-label-text-color: #c00000;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.svc-3A8DD2 .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #3a8dd2;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #3a8dd2;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.svc-3A8DD2 .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #64748b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.svc-3A8DD2 .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #c00000;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #c00000;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.svc-3A8DD2 .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #3a8dd2;
  --mdc-linear-progress-track-color: rgba(58, 141, 210, 0.25);
}
.svc-3A8DD2 .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #64748b;
  --mdc-linear-progress-track-color: rgba(100, 116, 139, 0.25);
}
.svc-3A8DD2 .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #c00000;
  --mdc-linear-progress-track-color: rgba(192, 0, 0, 0.25);
}
.svc-3A8DD2 .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #64748b;
  --mdc-filled-text-field-focus-active-indicator-color: #64748b;
  --mdc-filled-text-field-focus-label-text-color: rgba(100, 116, 139, 0.87);
  --mdc-outlined-text-field-caret-color: #64748b;
  --mdc-outlined-text-field-focus-outline-color: #64748b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(100, 116, 139, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(100, 116, 139, 0.87);
}
.svc-3A8DD2 .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #c00000;
  --mdc-filled-text-field-focus-active-indicator-color: #c00000;
  --mdc-filled-text-field-focus-label-text-color: rgba(192, 0, 0, 0.87);
  --mdc-outlined-text-field-caret-color: #c00000;
  --mdc-outlined-text-field-focus-outline-color: #c00000;
  --mdc-outlined-text-field-focus-label-text-color: rgba(192, 0, 0, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(192, 0, 0, 0.87);
}
.svc-3A8DD2 .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(100, 116, 139, 0.87);
  --mat-select-invalid-arrow-color: rgba(192, 0, 0, 0.87);
}
.svc-3A8DD2 .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(192, 0, 0, 0.87);
  --mat-select-invalid-arrow-color: rgba(192, 0, 0, 0.87);
}
.svc-3A8DD2 .mat-mdc-standard-chip {
  --mdc-chip-container-shape-radius: 16px;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}
.svc-3A8DD2 .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.svc-3A8DD2 .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .svc-3A8DD2 .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #3a8dd2;
  --mdc-chip-elevated-selected-container-color: #3a8dd2;
  --mdc-chip-elevated-disabled-container-color: #3a8dd2;
  --mdc-chip-flat-disabled-selected-container-color: #3a8dd2;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.svc-3A8DD2 .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .svc-3A8DD2 .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #64748b;
  --mdc-chip-elevated-selected-container-color: #64748b;
  --mdc-chip-elevated-disabled-container-color: #64748b;
  --mdc-chip-flat-disabled-selected-container-color: #64748b;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.svc-3A8DD2 .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .svc-3A8DD2 .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: #fef2f2;
  --mdc-chip-elevated-container-color: #c00000;
  --mdc-chip-elevated-selected-container-color: #c00000;
  --mdc-chip-elevated-disabled-container-color: #c00000;
  --mdc-chip-flat-disabled-selected-container-color: #c00000;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #fef2f2;
  --mdc-chip-selected-label-text-color: #fef2f2;
  --mdc-chip-with-icon-icon-color: #fef2f2;
  --mdc-chip-with-icon-disabled-icon-color: #fef2f2;
  --mdc-chip-with-icon-selected-icon-color: #fef2f2;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fef2f2;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fef2f2;
  --mat-chip-selected-disabled-trailing-icon-color: #fef2f2;
  --mat-chip-selected-trailing-icon-color: #fef2f2;
}
.svc-3A8DD2 .mat-mdc-slide-toggle {
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-selected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}
.svc-3A8DD2 .mat-mdc-slide-toggle {
  --mat-switch-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-3A8DD2 .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #475569;
  --mdc-switch-selected-handle-color: #475569;
  --mdc-switch-selected-hover-state-layer-color: #475569;
  --mdc-switch-selected-pressed-state-layer-color: #475569;
  --mdc-switch-selected-focus-handle-color: #0f172a;
  --mdc-switch-selected-hover-handle-color: #0f172a;
  --mdc-switch-selected-pressed-handle-color: #0f172a;
  --mdc-switch-selected-focus-track-color: #cbd5e1;
  --mdc-switch-selected-hover-track-color: #cbd5e1;
  --mdc-switch-selected-pressed-track-color: #cbd5e1;
  --mdc-switch-selected-track-color: #cbd5e1;
}
.svc-3A8DD2 .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #dc2626;
  --mdc-switch-selected-handle-color: #dc2626;
  --mdc-switch-selected-hover-state-layer-color: #dc2626;
  --mdc-switch-selected-pressed-state-layer-color: #dc2626;
  --mdc-switch-selected-focus-handle-color: #7f1d1d;
  --mdc-switch-selected-hover-handle-color: #7f1d1d;
  --mdc-switch-selected-pressed-handle-color: #7f1d1d;
  --mdc-switch-selected-focus-track-color: #fca5a5;
  --mdc-switch-selected-hover-track-color: #fca5a5;
  --mdc-switch-selected-pressed-track-color: #fca5a5;
  --mdc-switch-selected-track-color: #fca5a5;
}
.svc-3A8DD2 .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #3a8dd2;
  --mdc-radio-selected-hover-icon-color: #3a8dd2;
  --mdc-radio-selected-icon-color: #3a8dd2;
  --mdc-radio-selected-pressed-icon-color: #3a8dd2;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #3a8dd2;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-3A8DD2 .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #64748b;
  --mdc-radio-selected-hover-icon-color: #64748b;
  --mdc-radio-selected-icon-color: #64748b;
  --mdc-radio-selected-pressed-icon-color: #64748b;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #64748b;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-3A8DD2 .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #c00000;
  --mdc-radio-selected-hover-icon-color: #c00000;
  --mdc-radio-selected-icon-color: #c00000;
  --mdc-radio-selected-pressed-icon-color: #c00000;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #c00000;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-3A8DD2 .mat-accent {
  --mdc-slider-handle-color: #64748b;
  --mdc-slider-focus-handle-color: #64748b;
  --mdc-slider-hover-handle-color: #64748b;
  --mdc-slider-active-track-color: #64748b;
  --mdc-slider-inactive-track-color: #64748b;
  --mdc-slider-with-tick-marks-inactive-container-color: #64748b;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mat-slider-ripple-color: #64748b;
  --mat-slider-hover-state-layer-color: rgba(100, 116, 139, 0.05);
  --mat-slider-focus-state-layer-color: rgba(100, 116, 139, 0.2);
}
.svc-3A8DD2 .mat-warn {
  --mdc-slider-handle-color: #c00000;
  --mdc-slider-focus-handle-color: #c00000;
  --mdc-slider-hover-handle-color: #c00000;
  --mdc-slider-active-track-color: #c00000;
  --mdc-slider-inactive-track-color: #c00000;
  --mdc-slider-with-tick-marks-inactive-container-color: #c00000;
  --mdc-slider-with-tick-marks-active-container-color: #fef2f2;
  --mat-slider-ripple-color: #c00000;
  --mat-slider-hover-state-layer-color: rgba(192, 0, 0, 0.05);
  --mat-slider-focus-state-layer-color: rgba(192, 0, 0, 0.2);
}
.svc-3A8DD2 .mdc-list-item__start,
.svc-3A8DD2 .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #3a8dd2;
  --mdc-radio-selected-hover-icon-color: #3a8dd2;
  --mdc-radio-selected-icon-color: #3a8dd2;
  --mdc-radio-selected-pressed-icon-color: #3a8dd2;
}
.svc-3A8DD2 .mat-accent .mdc-list-item__start,
.svc-3A8DD2 .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #64748b;
  --mdc-radio-selected-hover-icon-color: #64748b;
  --mdc-radio-selected-icon-color: #64748b;
  --mdc-radio-selected-pressed-icon-color: #64748b;
}
.svc-3A8DD2 .mat-warn .mdc-list-item__start,
.svc-3A8DD2 .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #c00000;
  --mdc-radio-selected-hover-icon-color: #c00000;
  --mdc-radio-selected-icon-color: #c00000;
  --mdc-radio-selected-pressed-icon-color: #c00000;
}
.svc-3A8DD2 .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #3a8dd2;
  --mdc-checkbox-selected-hover-icon-color: #3a8dd2;
  --mdc-checkbox-selected-icon-color: #3a8dd2;
  --mdc-checkbox-selected-pressed-icon-color: #3a8dd2;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #3a8dd2;
  --mdc-checkbox-selected-hover-state-layer-color: #3a8dd2;
  --mdc-checkbox-selected-pressed-state-layer-color: #3a8dd2;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-3A8DD2 .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #64748b;
  --mdc-checkbox-selected-hover-icon-color: #64748b;
  --mdc-checkbox-selected-icon-color: #64748b;
  --mdc-checkbox-selected-pressed-icon-color: #64748b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #64748b;
  --mdc-checkbox-selected-hover-state-layer-color: #64748b;
  --mdc-checkbox-selected-pressed-state-layer-color: #64748b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-3A8DD2 .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fef2f2;
  --mdc-checkbox-selected-focus-icon-color: #c00000;
  --mdc-checkbox-selected-hover-icon-color: #c00000;
  --mdc-checkbox-selected-icon-color: #c00000;
  --mdc-checkbox-selected-pressed-icon-color: #c00000;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #c00000;
  --mdc-checkbox-selected-hover-state-layer-color: #c00000;
  --mdc-checkbox-selected-pressed-state-layer-color: #c00000;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-3A8DD2 .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.svc-3A8DD2 .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__start,
.svc-3A8DD2 .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.svc-3A8DD2 .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__start {
  color: #3a8dd2;
}
.svc-3A8DD2 .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.svc-3A8DD2 .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.svc-3A8DD2 .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.svc-3A8DD2 .mat-mdc-tab-group, .svc-3A8DD2 .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #3a8dd2;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #3a8dd2;
  --mat-tab-header-active-ripple-color: #3a8dd2;
  --mat-tab-header-inactive-ripple-color: #3a8dd2;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #3a8dd2;
  --mat-tab-header-active-hover-label-text-color: #3a8dd2;
  --mat-tab-header-active-focus-indicator-color: #3a8dd2;
  --mat-tab-header-active-hover-indicator-color: #3a8dd2;
}
.svc-3A8DD2 .mat-mdc-tab-group.mat-accent, .svc-3A8DD2 .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #64748b;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #64748b;
  --mat-tab-header-active-ripple-color: #64748b;
  --mat-tab-header-inactive-ripple-color: #64748b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #64748b;
  --mat-tab-header-active-hover-label-text-color: #64748b;
  --mat-tab-header-active-focus-indicator-color: #64748b;
  --mat-tab-header-active-hover-indicator-color: #64748b;
}
.svc-3A8DD2 .mat-mdc-tab-group.mat-warn, .svc-3A8DD2 .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #c00000;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #c00000;
  --mat-tab-header-active-ripple-color: #c00000;
  --mat-tab-header-inactive-ripple-color: #c00000;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #c00000;
  --mat-tab-header-active-hover-label-text-color: #c00000;
  --mat-tab-header-active-focus-indicator-color: #c00000;
  --mat-tab-header-active-hover-indicator-color: #c00000;
}
.svc-3A8DD2 .mat-mdc-tab-group.mat-background-primary, .svc-3A8DD2 .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #3a8dd2;
  --mat-tab-header-with-background-foreground-color: white;
}
.svc-3A8DD2 .mat-mdc-tab-group.mat-background-accent, .svc-3A8DD2 .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #64748b;
  --mat-tab-header-with-background-foreground-color: white;
}
.svc-3A8DD2 .mat-mdc-tab-group.mat-background-warn, .svc-3A8DD2 .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #c00000;
  --mat-tab-header-with-background-foreground-color: #fef2f2;
}
.svc-3A8DD2 .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #3a8dd2;
  --mdc-checkbox-selected-hover-icon-color: #3a8dd2;
  --mdc-checkbox-selected-icon-color: #3a8dd2;
  --mdc-checkbox-selected-pressed-icon-color: #3a8dd2;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #3a8dd2;
  --mdc-checkbox-selected-hover-state-layer-color: #3a8dd2;
  --mdc-checkbox-selected-pressed-state-layer-color: #3a8dd2;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-3A8DD2 .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fef2f2;
  --mdc-checkbox-selected-focus-icon-color: #c00000;
  --mdc-checkbox-selected-hover-icon-color: #c00000;
  --mdc-checkbox-selected-icon-color: #c00000;
  --mdc-checkbox-selected-pressed-icon-color: #c00000;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #c00000;
  --mdc-checkbox-selected-hover-state-layer-color: #c00000;
  --mdc-checkbox-selected-pressed-state-layer-color: #c00000;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-3A8DD2 .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #3a8dd2;
  --mat-text-button-state-layer-color: #3a8dd2;
  --mat-text-button-ripple-color: rgba(58, 141, 210, 0.1);
}
.svc-3A8DD2 .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #64748b;
  --mat-text-button-state-layer-color: #64748b;
  --mat-text-button-ripple-color: rgba(100, 116, 139, 0.1);
}
.svc-3A8DD2 .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #c00000;
  --mat-text-button-state-layer-color: #c00000;
  --mat-text-button-ripple-color: rgba(192, 0, 0, 0.1);
}
.svc-3A8DD2 .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #3a8dd2;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-3A8DD2 .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #64748b;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-3A8DD2 .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #c00000;
  --mdc-filled-button-label-text-color: #fef2f2;
  --mat-filled-button-state-layer-color: #fef2f2;
  --mat-filled-button-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-3A8DD2 .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #3a8dd2;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-3A8DD2 .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #64748b;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-3A8DD2 .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #c00000;
  --mdc-protected-button-label-text-color: #fef2f2;
  --mat-protected-button-state-layer-color: #fef2f2;
  --mat-protected-button-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-3A8DD2 .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #3a8dd2;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #3a8dd2;
  --mat-outlined-button-ripple-color: rgba(58, 141, 210, 0.1);
}
.svc-3A8DD2 .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #64748b;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #64748b;
  --mat-outlined-button-ripple-color: rgba(100, 116, 139, 0.1);
}
.svc-3A8DD2 .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #c00000;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #c00000;
  --mat-outlined-button-ripple-color: rgba(192, 0, 0, 0.1);
}
.svc-3A8DD2 .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #3a8dd2;
  --mat-icon-button-state-layer-color: #3a8dd2;
  --mat-icon-button-ripple-color: rgba(58, 141, 210, 0.1);
}
.svc-3A8DD2 .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #64748b;
  --mat-icon-button-state-layer-color: #64748b;
  --mat-icon-button-ripple-color: rgba(100, 116, 139, 0.1);
}
.svc-3A8DD2 .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #c00000;
  --mat-icon-button-state-layer-color: #c00000;
  --mat-icon-button-ripple-color: rgba(192, 0, 0, 0.1);
}
.svc-3A8DD2 .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #3a8dd2;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-3A8DD2 .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #64748b;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-3A8DD2 .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #c00000;
  --mat-fab-foreground-color: #fef2f2;
  --mat-fab-state-layer-color: #fef2f2;
  --mat-fab-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-3A8DD2 .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #3a8dd2;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-3A8DD2 .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #64748b;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-3A8DD2 .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #c00000;
  --mat-fab-small-foreground-color: #fef2f2;
  --mat-fab-small-state-layer-color: #fef2f2;
  --mat-fab-small-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-3A8DD2 .mat-accent {
  --mdc-circular-progress-active-indicator-color: #64748b;
}
.svc-3A8DD2 .mat-warn {
  --mdc-circular-progress-active-indicator-color: #c00000;
}
.svc-3A8DD2 .mat-badge-accent {
  --mat-badge-background-color: #64748b;
  --mat-badge-text-color: white;
}
.svc-3A8DD2 .mat-badge-warn {
  --mat-badge-background-color: #c00000;
  --mat-badge-text-color: #fef2f2;
}
.svc-3A8DD2 .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #64748b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(100, 116, 139, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(100, 116, 139, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(100, 116, 139, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(100, 116, 139, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.svc-3A8DD2 .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: #fef2f2;
  --mat-datepicker-calendar-date-selected-state-background-color: #c00000;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(192, 0, 0, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #fef2f2;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(192, 0, 0, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(192, 0, 0, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(192, 0, 0, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.svc-3A8DD2 .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #64748b;
}
.svc-3A8DD2 .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #c00000;
}
.svc-3A8DD2 .mat-icon.mat-primary {
  --mat-icon-color: #3a8dd2;
}
.svc-3A8DD2 .mat-icon.mat-accent {
  --mat-icon-color: #64748b;
}
.svc-3A8DD2 .mat-icon.mat-warn {
  --mat-icon-color: #c00000;
}
.svc-3A8DD2 .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #64748b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #64748b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #64748b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.svc-3A8DD2 .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: #fef2f2;
  --mat-stepper-header-selected-state-icon-background-color: #c00000;
  --mat-stepper-header-selected-state-icon-foreground-color: #fef2f2;
  --mat-stepper-header-done-state-icon-background-color: #c00000;
  --mat-stepper-header-done-state-icon-foreground-color: #fef2f2;
  --mat-stepper-header-edit-state-icon-background-color: #c00000;
  --mat-stepper-header-edit-state-icon-foreground-color: #fef2f2;
}
.svc-3A8DD2 .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #3a8dd2;
  --mat-toolbar-container-text-color: white;
}
.svc-3A8DD2 .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #64748b;
  --mat-toolbar-container-text-color: white;
}
.svc-3A8DD2 .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #c00000;
  --mat-toolbar-container-text-color: #fef2f2;
}

.svc-512072 {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-option-selected-state-label-text-color: #512072;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-full-pseudo-checkbox-selected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #64748b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mdc-filled-text-field-caret-color: #512072;
  --mdc-filled-text-field-focus-active-indicator-color: #512072;
  --mdc-filled-text-field-focus-label-text-color: rgba(81, 32, 114, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #c00000;
  --mdc-filled-text-field-error-focus-label-text-color: #c00000;
  --mdc-filled-text-field-error-label-text-color: #c00000;
  --mdc-filled-text-field-error-caret-color: #c00000;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #c00000;
  --mdc-filled-text-field-error-focus-active-indicator-color: #c00000;
  --mdc-filled-text-field-error-hover-active-indicator-color: #c00000;
  --mdc-outlined-text-field-caret-color: #512072;
  --mdc-outlined-text-field-focus-outline-color: #512072;
  --mdc-outlined-text-field-focus-label-text-color: rgba(81, 32, 114, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #c00000;
  --mdc-outlined-text-field-error-focus-label-text-color: #c00000;
  --mdc-outlined-text-field-error-label-text-color: #c00000;
  --mdc-outlined-text-field-error-hover-label-text-color: #c00000;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #c00000;
  --mdc-outlined-text-field-error-hover-outline-color: #c00000;
  --mdc-outlined-text-field-error-outline-color: #c00000;
  --mat-form-field-focus-select-arrow-color: rgba(81, 32, 114, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #c00000;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(81, 32, 114, 0.87);
  --mat-select-invalid-arrow-color: rgba(192, 0, 0, 0.87);
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: white;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-selected-focus-state-layer-color: #411a5b;
  --mdc-switch-selected-handle-color: #411a5b;
  --mdc-switch-selected-hover-state-layer-color: #411a5b;
  --mdc-switch-selected-pressed-state-layer-color: #411a5b;
  --mdc-switch-selected-focus-handle-color: #100617;
  --mdc-switch-selected-hover-handle-color: #100617;
  --mdc-switch-selected-pressed-handle-color: #100617;
  --mdc-switch-selected-focus-track-color: #9779aa;
  --mdc-switch-selected-hover-track-color: #9779aa;
  --mdc-switch-selected-pressed-track-color: #9779aa;
  --mdc-switch-selected-track-color: #9779aa;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: #fff;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-handle-color: #512072;
  --mdc-slider-focus-handle-color: #512072;
  --mdc-slider-hover-handle-color: #512072;
  --mdc-slider-active-track-color: #512072;
  --mdc-slider-inactive-track-color: #512072;
  --mdc-slider-with-tick-marks-inactive-container-color: #512072;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-slider-ripple-color: #512072;
  --mat-slider-hover-state-layer-color: rgba(81, 32, 114, 0.05);
  --mat-slider-focus-state-layer-color: rgba(81, 32, 114, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-base-elevation-level: 8;
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: rgba(0, 0, 0, 0.12);
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 4px;
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #64748b;
  --mdc-checkbox-selected-hover-icon-color: #64748b;
  --mdc-checkbox-selected-icon-color: #64748b;
  --mdc-checkbox-selected-pressed-icon-color: #64748b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #64748b;
  --mdc-checkbox-selected-hover-state-layer-color: #64748b;
  --mdc-checkbox-selected-pressed-state-layer-color: #64748b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-checkbox-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
  --mdc-text-button-label-text-color: black;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
  --mdc-icon-button-icon-size: 24px;
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
  --mdc-fab-container-shape: 50%;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-color: white;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-fab-small-container-color: white;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #64748b;
  --mat-table-row-item-outline-width: 1px;
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mdc-circular-progress-active-indicator-color: #512072;
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
  --mat-badge-background-color: #512072;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-bottom-sheet-container-shape: 4px;
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #512072;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(81, 32, 114, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(81, 32, 114, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(81, 32, 114, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #512072;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(81, 32, 114, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-text-color: black;
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-divider-width: 1px;
  --mat-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
  --mat-icon-color: inherit;
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #512072;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #512072;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #512072;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #c00000;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #c00000;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #757575;
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: rgba(0, 0, 0, 0.87);
}
.svc-512072 .mat-accent {
  --mat-option-selected-state-label-text-color: #64748b;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.svc-512072 .mat-warn {
  --mat-option-selected-state-label-text-color: #c00000;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.svc-512072 .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #512072;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #512072;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.svc-512072 .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #64748b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.svc-512072 .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #c00000;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #c00000;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.svc-512072 .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #512072;
  --mdc-linear-progress-track-color: rgba(81, 32, 114, 0.25);
}
.svc-512072 .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #64748b;
  --mdc-linear-progress-track-color: rgba(100, 116, 139, 0.25);
}
.svc-512072 .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #c00000;
  --mdc-linear-progress-track-color: rgba(192, 0, 0, 0.25);
}
.svc-512072 .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #64748b;
  --mdc-filled-text-field-focus-active-indicator-color: #64748b;
  --mdc-filled-text-field-focus-label-text-color: rgba(100, 116, 139, 0.87);
  --mdc-outlined-text-field-caret-color: #64748b;
  --mdc-outlined-text-field-focus-outline-color: #64748b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(100, 116, 139, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(100, 116, 139, 0.87);
}
.svc-512072 .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #c00000;
  --mdc-filled-text-field-focus-active-indicator-color: #c00000;
  --mdc-filled-text-field-focus-label-text-color: rgba(192, 0, 0, 0.87);
  --mdc-outlined-text-field-caret-color: #c00000;
  --mdc-outlined-text-field-focus-outline-color: #c00000;
  --mdc-outlined-text-field-focus-label-text-color: rgba(192, 0, 0, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(192, 0, 0, 0.87);
}
.svc-512072 .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(100, 116, 139, 0.87);
  --mat-select-invalid-arrow-color: rgba(192, 0, 0, 0.87);
}
.svc-512072 .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(192, 0, 0, 0.87);
  --mat-select-invalid-arrow-color: rgba(192, 0, 0, 0.87);
}
.svc-512072 .mat-mdc-standard-chip {
  --mdc-chip-container-shape-radius: 16px;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}
.svc-512072 .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.svc-512072 .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .svc-512072 .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #512072;
  --mdc-chip-elevated-selected-container-color: #512072;
  --mdc-chip-elevated-disabled-container-color: #512072;
  --mdc-chip-flat-disabled-selected-container-color: #512072;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.svc-512072 .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .svc-512072 .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #64748b;
  --mdc-chip-elevated-selected-container-color: #64748b;
  --mdc-chip-elevated-disabled-container-color: #64748b;
  --mdc-chip-flat-disabled-selected-container-color: #64748b;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.svc-512072 .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .svc-512072 .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: #fef2f2;
  --mdc-chip-elevated-container-color: #c00000;
  --mdc-chip-elevated-selected-container-color: #c00000;
  --mdc-chip-elevated-disabled-container-color: #c00000;
  --mdc-chip-flat-disabled-selected-container-color: #c00000;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #fef2f2;
  --mdc-chip-selected-label-text-color: #fef2f2;
  --mdc-chip-with-icon-icon-color: #fef2f2;
  --mdc-chip-with-icon-disabled-icon-color: #fef2f2;
  --mdc-chip-with-icon-selected-icon-color: #fef2f2;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fef2f2;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fef2f2;
  --mat-chip-selected-disabled-trailing-icon-color: #fef2f2;
  --mat-chip-selected-trailing-icon-color: #fef2f2;
}
.svc-512072 .mat-mdc-slide-toggle {
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-selected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}
.svc-512072 .mat-mdc-slide-toggle {
  --mat-switch-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-512072 .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #475569;
  --mdc-switch-selected-handle-color: #475569;
  --mdc-switch-selected-hover-state-layer-color: #475569;
  --mdc-switch-selected-pressed-state-layer-color: #475569;
  --mdc-switch-selected-focus-handle-color: #0f172a;
  --mdc-switch-selected-hover-handle-color: #0f172a;
  --mdc-switch-selected-pressed-handle-color: #0f172a;
  --mdc-switch-selected-focus-track-color: #cbd5e1;
  --mdc-switch-selected-hover-track-color: #cbd5e1;
  --mdc-switch-selected-pressed-track-color: #cbd5e1;
  --mdc-switch-selected-track-color: #cbd5e1;
}
.svc-512072 .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #dc2626;
  --mdc-switch-selected-handle-color: #dc2626;
  --mdc-switch-selected-hover-state-layer-color: #dc2626;
  --mdc-switch-selected-pressed-state-layer-color: #dc2626;
  --mdc-switch-selected-focus-handle-color: #7f1d1d;
  --mdc-switch-selected-hover-handle-color: #7f1d1d;
  --mdc-switch-selected-pressed-handle-color: #7f1d1d;
  --mdc-switch-selected-focus-track-color: #fca5a5;
  --mdc-switch-selected-hover-track-color: #fca5a5;
  --mdc-switch-selected-pressed-track-color: #fca5a5;
  --mdc-switch-selected-track-color: #fca5a5;
}
.svc-512072 .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #512072;
  --mdc-radio-selected-hover-icon-color: #512072;
  --mdc-radio-selected-icon-color: #512072;
  --mdc-radio-selected-pressed-icon-color: #512072;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #512072;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-512072 .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #64748b;
  --mdc-radio-selected-hover-icon-color: #64748b;
  --mdc-radio-selected-icon-color: #64748b;
  --mdc-radio-selected-pressed-icon-color: #64748b;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #64748b;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-512072 .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #c00000;
  --mdc-radio-selected-hover-icon-color: #c00000;
  --mdc-radio-selected-icon-color: #c00000;
  --mdc-radio-selected-pressed-icon-color: #c00000;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #c00000;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-512072 .mat-accent {
  --mdc-slider-handle-color: #64748b;
  --mdc-slider-focus-handle-color: #64748b;
  --mdc-slider-hover-handle-color: #64748b;
  --mdc-slider-active-track-color: #64748b;
  --mdc-slider-inactive-track-color: #64748b;
  --mdc-slider-with-tick-marks-inactive-container-color: #64748b;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mat-slider-ripple-color: #64748b;
  --mat-slider-hover-state-layer-color: rgba(100, 116, 139, 0.05);
  --mat-slider-focus-state-layer-color: rgba(100, 116, 139, 0.2);
}
.svc-512072 .mat-warn {
  --mdc-slider-handle-color: #c00000;
  --mdc-slider-focus-handle-color: #c00000;
  --mdc-slider-hover-handle-color: #c00000;
  --mdc-slider-active-track-color: #c00000;
  --mdc-slider-inactive-track-color: #c00000;
  --mdc-slider-with-tick-marks-inactive-container-color: #c00000;
  --mdc-slider-with-tick-marks-active-container-color: #fef2f2;
  --mat-slider-ripple-color: #c00000;
  --mat-slider-hover-state-layer-color: rgba(192, 0, 0, 0.05);
  --mat-slider-focus-state-layer-color: rgba(192, 0, 0, 0.2);
}
.svc-512072 .mdc-list-item__start,
.svc-512072 .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #512072;
  --mdc-radio-selected-hover-icon-color: #512072;
  --mdc-radio-selected-icon-color: #512072;
  --mdc-radio-selected-pressed-icon-color: #512072;
}
.svc-512072 .mat-accent .mdc-list-item__start,
.svc-512072 .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #64748b;
  --mdc-radio-selected-hover-icon-color: #64748b;
  --mdc-radio-selected-icon-color: #64748b;
  --mdc-radio-selected-pressed-icon-color: #64748b;
}
.svc-512072 .mat-warn .mdc-list-item__start,
.svc-512072 .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #c00000;
  --mdc-radio-selected-hover-icon-color: #c00000;
  --mdc-radio-selected-icon-color: #c00000;
  --mdc-radio-selected-pressed-icon-color: #c00000;
}
.svc-512072 .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #512072;
  --mdc-checkbox-selected-hover-icon-color: #512072;
  --mdc-checkbox-selected-icon-color: #512072;
  --mdc-checkbox-selected-pressed-icon-color: #512072;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #512072;
  --mdc-checkbox-selected-hover-state-layer-color: #512072;
  --mdc-checkbox-selected-pressed-state-layer-color: #512072;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-512072 .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #64748b;
  --mdc-checkbox-selected-hover-icon-color: #64748b;
  --mdc-checkbox-selected-icon-color: #64748b;
  --mdc-checkbox-selected-pressed-icon-color: #64748b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #64748b;
  --mdc-checkbox-selected-hover-state-layer-color: #64748b;
  --mdc-checkbox-selected-pressed-state-layer-color: #64748b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-512072 .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fef2f2;
  --mdc-checkbox-selected-focus-icon-color: #c00000;
  --mdc-checkbox-selected-hover-icon-color: #c00000;
  --mdc-checkbox-selected-icon-color: #c00000;
  --mdc-checkbox-selected-pressed-icon-color: #c00000;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #c00000;
  --mdc-checkbox-selected-hover-state-layer-color: #c00000;
  --mdc-checkbox-selected-pressed-state-layer-color: #c00000;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-512072 .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.svc-512072 .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__start,
.svc-512072 .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.svc-512072 .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__start {
  color: #512072;
}
.svc-512072 .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.svc-512072 .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.svc-512072 .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.svc-512072 .mat-mdc-tab-group, .svc-512072 .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #512072;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #512072;
  --mat-tab-header-active-ripple-color: #512072;
  --mat-tab-header-inactive-ripple-color: #512072;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #512072;
  --mat-tab-header-active-hover-label-text-color: #512072;
  --mat-tab-header-active-focus-indicator-color: #512072;
  --mat-tab-header-active-hover-indicator-color: #512072;
}
.svc-512072 .mat-mdc-tab-group.mat-accent, .svc-512072 .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #64748b;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #64748b;
  --mat-tab-header-active-ripple-color: #64748b;
  --mat-tab-header-inactive-ripple-color: #64748b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #64748b;
  --mat-tab-header-active-hover-label-text-color: #64748b;
  --mat-tab-header-active-focus-indicator-color: #64748b;
  --mat-tab-header-active-hover-indicator-color: #64748b;
}
.svc-512072 .mat-mdc-tab-group.mat-warn, .svc-512072 .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #c00000;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #c00000;
  --mat-tab-header-active-ripple-color: #c00000;
  --mat-tab-header-inactive-ripple-color: #c00000;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #c00000;
  --mat-tab-header-active-hover-label-text-color: #c00000;
  --mat-tab-header-active-focus-indicator-color: #c00000;
  --mat-tab-header-active-hover-indicator-color: #c00000;
}
.svc-512072 .mat-mdc-tab-group.mat-background-primary, .svc-512072 .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #512072;
  --mat-tab-header-with-background-foreground-color: white;
}
.svc-512072 .mat-mdc-tab-group.mat-background-accent, .svc-512072 .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #64748b;
  --mat-tab-header-with-background-foreground-color: white;
}
.svc-512072 .mat-mdc-tab-group.mat-background-warn, .svc-512072 .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #c00000;
  --mat-tab-header-with-background-foreground-color: #fef2f2;
}
.svc-512072 .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #512072;
  --mdc-checkbox-selected-hover-icon-color: #512072;
  --mdc-checkbox-selected-icon-color: #512072;
  --mdc-checkbox-selected-pressed-icon-color: #512072;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #512072;
  --mdc-checkbox-selected-hover-state-layer-color: #512072;
  --mdc-checkbox-selected-pressed-state-layer-color: #512072;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-512072 .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fef2f2;
  --mdc-checkbox-selected-focus-icon-color: #c00000;
  --mdc-checkbox-selected-hover-icon-color: #c00000;
  --mdc-checkbox-selected-icon-color: #c00000;
  --mdc-checkbox-selected-pressed-icon-color: #c00000;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #c00000;
  --mdc-checkbox-selected-hover-state-layer-color: #c00000;
  --mdc-checkbox-selected-pressed-state-layer-color: #c00000;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-512072 .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #512072;
  --mat-text-button-state-layer-color: #512072;
  --mat-text-button-ripple-color: rgba(81, 32, 114, 0.1);
}
.svc-512072 .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #64748b;
  --mat-text-button-state-layer-color: #64748b;
  --mat-text-button-ripple-color: rgba(100, 116, 139, 0.1);
}
.svc-512072 .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #c00000;
  --mat-text-button-state-layer-color: #c00000;
  --mat-text-button-ripple-color: rgba(192, 0, 0, 0.1);
}
.svc-512072 .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #512072;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-512072 .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #64748b;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-512072 .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #c00000;
  --mdc-filled-button-label-text-color: #fef2f2;
  --mat-filled-button-state-layer-color: #fef2f2;
  --mat-filled-button-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-512072 .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #512072;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-512072 .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #64748b;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-512072 .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #c00000;
  --mdc-protected-button-label-text-color: #fef2f2;
  --mat-protected-button-state-layer-color: #fef2f2;
  --mat-protected-button-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-512072 .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #512072;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #512072;
  --mat-outlined-button-ripple-color: rgba(81, 32, 114, 0.1);
}
.svc-512072 .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #64748b;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #64748b;
  --mat-outlined-button-ripple-color: rgba(100, 116, 139, 0.1);
}
.svc-512072 .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #c00000;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #c00000;
  --mat-outlined-button-ripple-color: rgba(192, 0, 0, 0.1);
}
.svc-512072 .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #512072;
  --mat-icon-button-state-layer-color: #512072;
  --mat-icon-button-ripple-color: rgba(81, 32, 114, 0.1);
}
.svc-512072 .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #64748b;
  --mat-icon-button-state-layer-color: #64748b;
  --mat-icon-button-ripple-color: rgba(100, 116, 139, 0.1);
}
.svc-512072 .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #c00000;
  --mat-icon-button-state-layer-color: #c00000;
  --mat-icon-button-ripple-color: rgba(192, 0, 0, 0.1);
}
.svc-512072 .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #512072;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-512072 .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #64748b;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-512072 .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #c00000;
  --mat-fab-foreground-color: #fef2f2;
  --mat-fab-state-layer-color: #fef2f2;
  --mat-fab-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-512072 .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #512072;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-512072 .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #64748b;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-512072 .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #c00000;
  --mat-fab-small-foreground-color: #fef2f2;
  --mat-fab-small-state-layer-color: #fef2f2;
  --mat-fab-small-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-512072 .mat-accent {
  --mdc-circular-progress-active-indicator-color: #64748b;
}
.svc-512072 .mat-warn {
  --mdc-circular-progress-active-indicator-color: #c00000;
}
.svc-512072 .mat-badge-accent {
  --mat-badge-background-color: #64748b;
  --mat-badge-text-color: white;
}
.svc-512072 .mat-badge-warn {
  --mat-badge-background-color: #c00000;
  --mat-badge-text-color: #fef2f2;
}
.svc-512072 .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #64748b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(100, 116, 139, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(100, 116, 139, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(100, 116, 139, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(100, 116, 139, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.svc-512072 .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: #fef2f2;
  --mat-datepicker-calendar-date-selected-state-background-color: #c00000;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(192, 0, 0, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #fef2f2;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(192, 0, 0, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(192, 0, 0, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(192, 0, 0, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.svc-512072 .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #64748b;
}
.svc-512072 .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #c00000;
}
.svc-512072 .mat-icon.mat-primary {
  --mat-icon-color: #512072;
}
.svc-512072 .mat-icon.mat-accent {
  --mat-icon-color: #64748b;
}
.svc-512072 .mat-icon.mat-warn {
  --mat-icon-color: #c00000;
}
.svc-512072 .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #64748b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #64748b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #64748b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.svc-512072 .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: #fef2f2;
  --mat-stepper-header-selected-state-icon-background-color: #c00000;
  --mat-stepper-header-selected-state-icon-foreground-color: #fef2f2;
  --mat-stepper-header-done-state-icon-background-color: #c00000;
  --mat-stepper-header-done-state-icon-foreground-color: #fef2f2;
  --mat-stepper-header-edit-state-icon-background-color: #c00000;
  --mat-stepper-header-edit-state-icon-foreground-color: #fef2f2;
}
.svc-512072 .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #512072;
  --mat-toolbar-container-text-color: white;
}
.svc-512072 .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #64748b;
  --mat-toolbar-container-text-color: white;
}
.svc-512072 .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #c00000;
  --mat-toolbar-container-text-color: #fef2f2;
}

.svc-7B7521 {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-option-selected-state-label-text-color: #512072;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-full-pseudo-checkbox-selected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #64748b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mdc-filled-text-field-caret-color: #512072;
  --mdc-filled-text-field-focus-active-indicator-color: #512072;
  --mdc-filled-text-field-focus-label-text-color: rgba(81, 32, 114, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #c00000;
  --mdc-filled-text-field-error-focus-label-text-color: #c00000;
  --mdc-filled-text-field-error-label-text-color: #c00000;
  --mdc-filled-text-field-error-caret-color: #c00000;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #c00000;
  --mdc-filled-text-field-error-focus-active-indicator-color: #c00000;
  --mdc-filled-text-field-error-hover-active-indicator-color: #c00000;
  --mdc-outlined-text-field-caret-color: #512072;
  --mdc-outlined-text-field-focus-outline-color: #512072;
  --mdc-outlined-text-field-focus-label-text-color: rgba(81, 32, 114, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #c00000;
  --mdc-outlined-text-field-error-focus-label-text-color: #c00000;
  --mdc-outlined-text-field-error-label-text-color: #c00000;
  --mdc-outlined-text-field-error-hover-label-text-color: #c00000;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #c00000;
  --mdc-outlined-text-field-error-hover-outline-color: #c00000;
  --mdc-outlined-text-field-error-outline-color: #c00000;
  --mat-form-field-focus-select-arrow-color: rgba(81, 32, 114, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #c00000;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(81, 32, 114, 0.87);
  --mat-select-invalid-arrow-color: rgba(192, 0, 0, 0.87);
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: white;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-selected-focus-state-layer-color: #411a5b;
  --mdc-switch-selected-handle-color: #411a5b;
  --mdc-switch-selected-hover-state-layer-color: #411a5b;
  --mdc-switch-selected-pressed-state-layer-color: #411a5b;
  --mdc-switch-selected-focus-handle-color: #100617;
  --mdc-switch-selected-hover-handle-color: #100617;
  --mdc-switch-selected-pressed-handle-color: #100617;
  --mdc-switch-selected-focus-track-color: #9779aa;
  --mdc-switch-selected-hover-track-color: #9779aa;
  --mdc-switch-selected-pressed-track-color: #9779aa;
  --mdc-switch-selected-track-color: #9779aa;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: #fff;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-handle-color: #512072;
  --mdc-slider-focus-handle-color: #512072;
  --mdc-slider-hover-handle-color: #512072;
  --mdc-slider-active-track-color: #512072;
  --mdc-slider-inactive-track-color: #512072;
  --mdc-slider-with-tick-marks-inactive-container-color: #512072;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-slider-ripple-color: #512072;
  --mat-slider-hover-state-layer-color: rgba(81, 32, 114, 0.05);
  --mat-slider-focus-state-layer-color: rgba(81, 32, 114, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-base-elevation-level: 8;
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: rgba(0, 0, 0, 0.12);
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 4px;
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #64748b;
  --mdc-checkbox-selected-hover-icon-color: #64748b;
  --mdc-checkbox-selected-icon-color: #64748b;
  --mdc-checkbox-selected-pressed-icon-color: #64748b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #64748b;
  --mdc-checkbox-selected-hover-state-layer-color: #64748b;
  --mdc-checkbox-selected-pressed-state-layer-color: #64748b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-checkbox-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
  --mdc-text-button-label-text-color: black;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
  --mdc-icon-button-icon-size: 24px;
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
  --mdc-fab-container-shape: 50%;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-color: white;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-fab-small-container-color: white;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #64748b;
  --mat-table-row-item-outline-width: 1px;
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mdc-circular-progress-active-indicator-color: #512072;
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
  --mat-badge-background-color: #512072;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-bottom-sheet-container-shape: 4px;
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #512072;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(81, 32, 114, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(81, 32, 114, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(81, 32, 114, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #512072;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(81, 32, 114, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-text-color: black;
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-divider-width: 1px;
  --mat-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
  --mat-icon-color: inherit;
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #512072;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #512072;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #512072;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #c00000;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #c00000;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #757575;
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: rgba(0, 0, 0, 0.87);
}
.svc-7B7521 .mat-accent {
  --mat-option-selected-state-label-text-color: #64748b;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.svc-7B7521 .mat-warn {
  --mat-option-selected-state-label-text-color: #c00000;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.svc-7B7521 .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #512072;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #512072;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.svc-7B7521 .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #64748b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.svc-7B7521 .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #c00000;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #c00000;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.svc-7B7521 .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #512072;
  --mdc-linear-progress-track-color: rgba(81, 32, 114, 0.25);
}
.svc-7B7521 .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #64748b;
  --mdc-linear-progress-track-color: rgba(100, 116, 139, 0.25);
}
.svc-7B7521 .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #c00000;
  --mdc-linear-progress-track-color: rgba(192, 0, 0, 0.25);
}
.svc-7B7521 .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #64748b;
  --mdc-filled-text-field-focus-active-indicator-color: #64748b;
  --mdc-filled-text-field-focus-label-text-color: rgba(100, 116, 139, 0.87);
  --mdc-outlined-text-field-caret-color: #64748b;
  --mdc-outlined-text-field-focus-outline-color: #64748b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(100, 116, 139, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(100, 116, 139, 0.87);
}
.svc-7B7521 .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #c00000;
  --mdc-filled-text-field-focus-active-indicator-color: #c00000;
  --mdc-filled-text-field-focus-label-text-color: rgba(192, 0, 0, 0.87);
  --mdc-outlined-text-field-caret-color: #c00000;
  --mdc-outlined-text-field-focus-outline-color: #c00000;
  --mdc-outlined-text-field-focus-label-text-color: rgba(192, 0, 0, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(192, 0, 0, 0.87);
}
.svc-7B7521 .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(100, 116, 139, 0.87);
  --mat-select-invalid-arrow-color: rgba(192, 0, 0, 0.87);
}
.svc-7B7521 .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(192, 0, 0, 0.87);
  --mat-select-invalid-arrow-color: rgba(192, 0, 0, 0.87);
}
.svc-7B7521 .mat-mdc-standard-chip {
  --mdc-chip-container-shape-radius: 16px;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}
.svc-7B7521 .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.svc-7B7521 .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .svc-7B7521 .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #512072;
  --mdc-chip-elevated-selected-container-color: #512072;
  --mdc-chip-elevated-disabled-container-color: #512072;
  --mdc-chip-flat-disabled-selected-container-color: #512072;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.svc-7B7521 .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .svc-7B7521 .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #64748b;
  --mdc-chip-elevated-selected-container-color: #64748b;
  --mdc-chip-elevated-disabled-container-color: #64748b;
  --mdc-chip-flat-disabled-selected-container-color: #64748b;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.svc-7B7521 .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .svc-7B7521 .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: #fef2f2;
  --mdc-chip-elevated-container-color: #c00000;
  --mdc-chip-elevated-selected-container-color: #c00000;
  --mdc-chip-elevated-disabled-container-color: #c00000;
  --mdc-chip-flat-disabled-selected-container-color: #c00000;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #fef2f2;
  --mdc-chip-selected-label-text-color: #fef2f2;
  --mdc-chip-with-icon-icon-color: #fef2f2;
  --mdc-chip-with-icon-disabled-icon-color: #fef2f2;
  --mdc-chip-with-icon-selected-icon-color: #fef2f2;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fef2f2;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fef2f2;
  --mat-chip-selected-disabled-trailing-icon-color: #fef2f2;
  --mat-chip-selected-trailing-icon-color: #fef2f2;
}
.svc-7B7521 .mat-mdc-slide-toggle {
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-selected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}
.svc-7B7521 .mat-mdc-slide-toggle {
  --mat-switch-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-7B7521 .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #475569;
  --mdc-switch-selected-handle-color: #475569;
  --mdc-switch-selected-hover-state-layer-color: #475569;
  --mdc-switch-selected-pressed-state-layer-color: #475569;
  --mdc-switch-selected-focus-handle-color: #0f172a;
  --mdc-switch-selected-hover-handle-color: #0f172a;
  --mdc-switch-selected-pressed-handle-color: #0f172a;
  --mdc-switch-selected-focus-track-color: #cbd5e1;
  --mdc-switch-selected-hover-track-color: #cbd5e1;
  --mdc-switch-selected-pressed-track-color: #cbd5e1;
  --mdc-switch-selected-track-color: #cbd5e1;
}
.svc-7B7521 .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #dc2626;
  --mdc-switch-selected-handle-color: #dc2626;
  --mdc-switch-selected-hover-state-layer-color: #dc2626;
  --mdc-switch-selected-pressed-state-layer-color: #dc2626;
  --mdc-switch-selected-focus-handle-color: #7f1d1d;
  --mdc-switch-selected-hover-handle-color: #7f1d1d;
  --mdc-switch-selected-pressed-handle-color: #7f1d1d;
  --mdc-switch-selected-focus-track-color: #fca5a5;
  --mdc-switch-selected-hover-track-color: #fca5a5;
  --mdc-switch-selected-pressed-track-color: #fca5a5;
  --mdc-switch-selected-track-color: #fca5a5;
}
.svc-7B7521 .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #512072;
  --mdc-radio-selected-hover-icon-color: #512072;
  --mdc-radio-selected-icon-color: #512072;
  --mdc-radio-selected-pressed-icon-color: #512072;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #512072;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-7B7521 .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #64748b;
  --mdc-radio-selected-hover-icon-color: #64748b;
  --mdc-radio-selected-icon-color: #64748b;
  --mdc-radio-selected-pressed-icon-color: #64748b;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #64748b;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-7B7521 .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #c00000;
  --mdc-radio-selected-hover-icon-color: #c00000;
  --mdc-radio-selected-icon-color: #c00000;
  --mdc-radio-selected-pressed-icon-color: #c00000;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #c00000;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-7B7521 .mat-accent {
  --mdc-slider-handle-color: #64748b;
  --mdc-slider-focus-handle-color: #64748b;
  --mdc-slider-hover-handle-color: #64748b;
  --mdc-slider-active-track-color: #64748b;
  --mdc-slider-inactive-track-color: #64748b;
  --mdc-slider-with-tick-marks-inactive-container-color: #64748b;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mat-slider-ripple-color: #64748b;
  --mat-slider-hover-state-layer-color: rgba(100, 116, 139, 0.05);
  --mat-slider-focus-state-layer-color: rgba(100, 116, 139, 0.2);
}
.svc-7B7521 .mat-warn {
  --mdc-slider-handle-color: #c00000;
  --mdc-slider-focus-handle-color: #c00000;
  --mdc-slider-hover-handle-color: #c00000;
  --mdc-slider-active-track-color: #c00000;
  --mdc-slider-inactive-track-color: #c00000;
  --mdc-slider-with-tick-marks-inactive-container-color: #c00000;
  --mdc-slider-with-tick-marks-active-container-color: #fef2f2;
  --mat-slider-ripple-color: #c00000;
  --mat-slider-hover-state-layer-color: rgba(192, 0, 0, 0.05);
  --mat-slider-focus-state-layer-color: rgba(192, 0, 0, 0.2);
}
.svc-7B7521 .mdc-list-item__start,
.svc-7B7521 .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #512072;
  --mdc-radio-selected-hover-icon-color: #512072;
  --mdc-radio-selected-icon-color: #512072;
  --mdc-radio-selected-pressed-icon-color: #512072;
}
.svc-7B7521 .mat-accent .mdc-list-item__start,
.svc-7B7521 .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #64748b;
  --mdc-radio-selected-hover-icon-color: #64748b;
  --mdc-radio-selected-icon-color: #64748b;
  --mdc-radio-selected-pressed-icon-color: #64748b;
}
.svc-7B7521 .mat-warn .mdc-list-item__start,
.svc-7B7521 .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #c00000;
  --mdc-radio-selected-hover-icon-color: #c00000;
  --mdc-radio-selected-icon-color: #c00000;
  --mdc-radio-selected-pressed-icon-color: #c00000;
}
.svc-7B7521 .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #512072;
  --mdc-checkbox-selected-hover-icon-color: #512072;
  --mdc-checkbox-selected-icon-color: #512072;
  --mdc-checkbox-selected-pressed-icon-color: #512072;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #512072;
  --mdc-checkbox-selected-hover-state-layer-color: #512072;
  --mdc-checkbox-selected-pressed-state-layer-color: #512072;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-7B7521 .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #64748b;
  --mdc-checkbox-selected-hover-icon-color: #64748b;
  --mdc-checkbox-selected-icon-color: #64748b;
  --mdc-checkbox-selected-pressed-icon-color: #64748b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #64748b;
  --mdc-checkbox-selected-hover-state-layer-color: #64748b;
  --mdc-checkbox-selected-pressed-state-layer-color: #64748b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-7B7521 .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fef2f2;
  --mdc-checkbox-selected-focus-icon-color: #c00000;
  --mdc-checkbox-selected-hover-icon-color: #c00000;
  --mdc-checkbox-selected-icon-color: #c00000;
  --mdc-checkbox-selected-pressed-icon-color: #c00000;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #c00000;
  --mdc-checkbox-selected-hover-state-layer-color: #c00000;
  --mdc-checkbox-selected-pressed-state-layer-color: #c00000;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-7B7521 .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.svc-7B7521 .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__start,
.svc-7B7521 .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.svc-7B7521 .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__start {
  color: #512072;
}
.svc-7B7521 .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.svc-7B7521 .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.svc-7B7521 .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.svc-7B7521 .mat-mdc-tab-group, .svc-7B7521 .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #512072;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #512072;
  --mat-tab-header-active-ripple-color: #512072;
  --mat-tab-header-inactive-ripple-color: #512072;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #512072;
  --mat-tab-header-active-hover-label-text-color: #512072;
  --mat-tab-header-active-focus-indicator-color: #512072;
  --mat-tab-header-active-hover-indicator-color: #512072;
}
.svc-7B7521 .mat-mdc-tab-group.mat-accent, .svc-7B7521 .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #64748b;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #64748b;
  --mat-tab-header-active-ripple-color: #64748b;
  --mat-tab-header-inactive-ripple-color: #64748b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #64748b;
  --mat-tab-header-active-hover-label-text-color: #64748b;
  --mat-tab-header-active-focus-indicator-color: #64748b;
  --mat-tab-header-active-hover-indicator-color: #64748b;
}
.svc-7B7521 .mat-mdc-tab-group.mat-warn, .svc-7B7521 .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #c00000;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #c00000;
  --mat-tab-header-active-ripple-color: #c00000;
  --mat-tab-header-inactive-ripple-color: #c00000;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #c00000;
  --mat-tab-header-active-hover-label-text-color: #c00000;
  --mat-tab-header-active-focus-indicator-color: #c00000;
  --mat-tab-header-active-hover-indicator-color: #c00000;
}
.svc-7B7521 .mat-mdc-tab-group.mat-background-primary, .svc-7B7521 .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #512072;
  --mat-tab-header-with-background-foreground-color: white;
}
.svc-7B7521 .mat-mdc-tab-group.mat-background-accent, .svc-7B7521 .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #64748b;
  --mat-tab-header-with-background-foreground-color: white;
}
.svc-7B7521 .mat-mdc-tab-group.mat-background-warn, .svc-7B7521 .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #c00000;
  --mat-tab-header-with-background-foreground-color: #fef2f2;
}
.svc-7B7521 .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #512072;
  --mdc-checkbox-selected-hover-icon-color: #512072;
  --mdc-checkbox-selected-icon-color: #512072;
  --mdc-checkbox-selected-pressed-icon-color: #512072;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #512072;
  --mdc-checkbox-selected-hover-state-layer-color: #512072;
  --mdc-checkbox-selected-pressed-state-layer-color: #512072;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-7B7521 .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fef2f2;
  --mdc-checkbox-selected-focus-icon-color: #c00000;
  --mdc-checkbox-selected-hover-icon-color: #c00000;
  --mdc-checkbox-selected-icon-color: #c00000;
  --mdc-checkbox-selected-pressed-icon-color: #c00000;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #c00000;
  --mdc-checkbox-selected-hover-state-layer-color: #c00000;
  --mdc-checkbox-selected-pressed-state-layer-color: #c00000;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-7B7521 .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #512072;
  --mat-text-button-state-layer-color: #512072;
  --mat-text-button-ripple-color: rgba(81, 32, 114, 0.1);
}
.svc-7B7521 .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #64748b;
  --mat-text-button-state-layer-color: #64748b;
  --mat-text-button-ripple-color: rgba(100, 116, 139, 0.1);
}
.svc-7B7521 .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #c00000;
  --mat-text-button-state-layer-color: #c00000;
  --mat-text-button-ripple-color: rgba(192, 0, 0, 0.1);
}
.svc-7B7521 .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #512072;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-7B7521 .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #64748b;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-7B7521 .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #c00000;
  --mdc-filled-button-label-text-color: #fef2f2;
  --mat-filled-button-state-layer-color: #fef2f2;
  --mat-filled-button-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-7B7521 .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #512072;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-7B7521 .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #64748b;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-7B7521 .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #c00000;
  --mdc-protected-button-label-text-color: #fef2f2;
  --mat-protected-button-state-layer-color: #fef2f2;
  --mat-protected-button-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-7B7521 .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #512072;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #512072;
  --mat-outlined-button-ripple-color: rgba(81, 32, 114, 0.1);
}
.svc-7B7521 .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #64748b;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #64748b;
  --mat-outlined-button-ripple-color: rgba(100, 116, 139, 0.1);
}
.svc-7B7521 .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #c00000;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #c00000;
  --mat-outlined-button-ripple-color: rgba(192, 0, 0, 0.1);
}
.svc-7B7521 .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #512072;
  --mat-icon-button-state-layer-color: #512072;
  --mat-icon-button-ripple-color: rgba(81, 32, 114, 0.1);
}
.svc-7B7521 .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #64748b;
  --mat-icon-button-state-layer-color: #64748b;
  --mat-icon-button-ripple-color: rgba(100, 116, 139, 0.1);
}
.svc-7B7521 .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #c00000;
  --mat-icon-button-state-layer-color: #c00000;
  --mat-icon-button-ripple-color: rgba(192, 0, 0, 0.1);
}
.svc-7B7521 .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #512072;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-7B7521 .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #64748b;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-7B7521 .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #c00000;
  --mat-fab-foreground-color: #fef2f2;
  --mat-fab-state-layer-color: #fef2f2;
  --mat-fab-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-7B7521 .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #512072;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-7B7521 .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #64748b;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-7B7521 .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #c00000;
  --mat-fab-small-foreground-color: #fef2f2;
  --mat-fab-small-state-layer-color: #fef2f2;
  --mat-fab-small-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-7B7521 .mat-accent {
  --mdc-circular-progress-active-indicator-color: #64748b;
}
.svc-7B7521 .mat-warn {
  --mdc-circular-progress-active-indicator-color: #c00000;
}
.svc-7B7521 .mat-badge-accent {
  --mat-badge-background-color: #64748b;
  --mat-badge-text-color: white;
}
.svc-7B7521 .mat-badge-warn {
  --mat-badge-background-color: #c00000;
  --mat-badge-text-color: #fef2f2;
}
.svc-7B7521 .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #64748b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(100, 116, 139, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(100, 116, 139, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(100, 116, 139, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(100, 116, 139, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.svc-7B7521 .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: #fef2f2;
  --mat-datepicker-calendar-date-selected-state-background-color: #c00000;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(192, 0, 0, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #fef2f2;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(192, 0, 0, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(192, 0, 0, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(192, 0, 0, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.svc-7B7521 .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #64748b;
}
.svc-7B7521 .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #c00000;
}
.svc-7B7521 .mat-icon.mat-primary {
  --mat-icon-color: #512072;
}
.svc-7B7521 .mat-icon.mat-accent {
  --mat-icon-color: #64748b;
}
.svc-7B7521 .mat-icon.mat-warn {
  --mat-icon-color: #c00000;
}
.svc-7B7521 .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #64748b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #64748b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #64748b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.svc-7B7521 .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: #fef2f2;
  --mat-stepper-header-selected-state-icon-background-color: #c00000;
  --mat-stepper-header-selected-state-icon-foreground-color: #fef2f2;
  --mat-stepper-header-done-state-icon-background-color: #c00000;
  --mat-stepper-header-done-state-icon-foreground-color: #fef2f2;
  --mat-stepper-header-edit-state-icon-background-color: #c00000;
  --mat-stepper-header-edit-state-icon-foreground-color: #fef2f2;
}
.svc-7B7521 .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #512072;
  --mat-toolbar-container-text-color: white;
}
.svc-7B7521 .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #64748b;
  --mat-toolbar-container-text-color: white;
}
.svc-7B7521 .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #c00000;
  --mat-toolbar-container-text-color: #fef2f2;
}

.svc-830051 {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-option-selected-state-label-text-color: #830051;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-full-pseudo-checkbox-selected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #64748b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mdc-filled-text-field-caret-color: #830051;
  --mdc-filled-text-field-focus-active-indicator-color: #830051;
  --mdc-filled-text-field-focus-label-text-color: rgba(131, 0, 81, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #c00000;
  --mdc-filled-text-field-error-focus-label-text-color: #c00000;
  --mdc-filled-text-field-error-label-text-color: #c00000;
  --mdc-filled-text-field-error-caret-color: #c00000;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #c00000;
  --mdc-filled-text-field-error-focus-active-indicator-color: #c00000;
  --mdc-filled-text-field-error-hover-active-indicator-color: #c00000;
  --mdc-outlined-text-field-caret-color: #830051;
  --mdc-outlined-text-field-focus-outline-color: #830051;
  --mdc-outlined-text-field-focus-label-text-color: rgba(131, 0, 81, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #c00000;
  --mdc-outlined-text-field-error-focus-label-text-color: #c00000;
  --mdc-outlined-text-field-error-label-text-color: #c00000;
  --mdc-outlined-text-field-error-hover-label-text-color: #c00000;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #c00000;
  --mdc-outlined-text-field-error-hover-outline-color: #c00000;
  --mdc-outlined-text-field-error-outline-color: #c00000;
  --mat-form-field-focus-select-arrow-color: rgba(131, 0, 81, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #c00000;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(131, 0, 81, 0.87);
  --mat-select-invalid-arrow-color: rgba(192, 0, 0, 0.87);
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: white;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-selected-focus-state-layer-color: #690041;
  --mdc-switch-selected-handle-color: #690041;
  --mdc-switch-selected-hover-state-layer-color: #690041;
  --mdc-switch-selected-pressed-state-layer-color: #690041;
  --mdc-switch-selected-focus-handle-color: #1a0010;
  --mdc-switch-selected-hover-handle-color: #1a0010;
  --mdc-switch-selected-pressed-handle-color: #1a0010;
  --mdc-switch-selected-focus-track-color: #b56697;
  --mdc-switch-selected-hover-track-color: #b56697;
  --mdc-switch-selected-pressed-track-color: #b56697;
  --mdc-switch-selected-track-color: #b56697;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: #fff;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-handle-color: #830051;
  --mdc-slider-focus-handle-color: #830051;
  --mdc-slider-hover-handle-color: #830051;
  --mdc-slider-active-track-color: #830051;
  --mdc-slider-inactive-track-color: #830051;
  --mdc-slider-with-tick-marks-inactive-container-color: #830051;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-slider-ripple-color: #830051;
  --mat-slider-hover-state-layer-color: rgba(131, 0, 81, 0.05);
  --mat-slider-focus-state-layer-color: rgba(131, 0, 81, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-base-elevation-level: 8;
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: rgba(0, 0, 0, 0.12);
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 4px;
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #64748b;
  --mdc-checkbox-selected-hover-icon-color: #64748b;
  --mdc-checkbox-selected-icon-color: #64748b;
  --mdc-checkbox-selected-pressed-icon-color: #64748b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #64748b;
  --mdc-checkbox-selected-hover-state-layer-color: #64748b;
  --mdc-checkbox-selected-pressed-state-layer-color: #64748b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-checkbox-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
  --mdc-text-button-label-text-color: black;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
  --mdc-icon-button-icon-size: 24px;
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
  --mdc-fab-container-shape: 50%;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-color: white;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-fab-small-container-color: white;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #64748b;
  --mat-table-row-item-outline-width: 1px;
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mdc-circular-progress-active-indicator-color: #830051;
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
  --mat-badge-background-color: #830051;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-bottom-sheet-container-shape: 4px;
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #830051;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(131, 0, 81, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(131, 0, 81, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(131, 0, 81, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #830051;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(131, 0, 81, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-text-color: black;
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-divider-width: 1px;
  --mat-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
  --mat-icon-color: inherit;
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #830051;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #830051;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #830051;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #c00000;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #c00000;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #757575;
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: rgba(0, 0, 0, 0.87);
}
.svc-830051 .mat-accent {
  --mat-option-selected-state-label-text-color: #64748b;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.svc-830051 .mat-warn {
  --mat-option-selected-state-label-text-color: #c00000;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.svc-830051 .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #830051;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #830051;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.svc-830051 .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #64748b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.svc-830051 .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #c00000;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #c00000;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.svc-830051 .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #830051;
  --mdc-linear-progress-track-color: rgba(131, 0, 81, 0.25);
}
.svc-830051 .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #64748b;
  --mdc-linear-progress-track-color: rgba(100, 116, 139, 0.25);
}
.svc-830051 .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #c00000;
  --mdc-linear-progress-track-color: rgba(192, 0, 0, 0.25);
}
.svc-830051 .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #64748b;
  --mdc-filled-text-field-focus-active-indicator-color: #64748b;
  --mdc-filled-text-field-focus-label-text-color: rgba(100, 116, 139, 0.87);
  --mdc-outlined-text-field-caret-color: #64748b;
  --mdc-outlined-text-field-focus-outline-color: #64748b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(100, 116, 139, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(100, 116, 139, 0.87);
}
.svc-830051 .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #c00000;
  --mdc-filled-text-field-focus-active-indicator-color: #c00000;
  --mdc-filled-text-field-focus-label-text-color: rgba(192, 0, 0, 0.87);
  --mdc-outlined-text-field-caret-color: #c00000;
  --mdc-outlined-text-field-focus-outline-color: #c00000;
  --mdc-outlined-text-field-focus-label-text-color: rgba(192, 0, 0, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(192, 0, 0, 0.87);
}
.svc-830051 .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(100, 116, 139, 0.87);
  --mat-select-invalid-arrow-color: rgba(192, 0, 0, 0.87);
}
.svc-830051 .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(192, 0, 0, 0.87);
  --mat-select-invalid-arrow-color: rgba(192, 0, 0, 0.87);
}
.svc-830051 .mat-mdc-standard-chip {
  --mdc-chip-container-shape-radius: 16px;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}
.svc-830051 .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.svc-830051 .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .svc-830051 .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #830051;
  --mdc-chip-elevated-selected-container-color: #830051;
  --mdc-chip-elevated-disabled-container-color: #830051;
  --mdc-chip-flat-disabled-selected-container-color: #830051;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.svc-830051 .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .svc-830051 .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #64748b;
  --mdc-chip-elevated-selected-container-color: #64748b;
  --mdc-chip-elevated-disabled-container-color: #64748b;
  --mdc-chip-flat-disabled-selected-container-color: #64748b;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.svc-830051 .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .svc-830051 .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: #fef2f2;
  --mdc-chip-elevated-container-color: #c00000;
  --mdc-chip-elevated-selected-container-color: #c00000;
  --mdc-chip-elevated-disabled-container-color: #c00000;
  --mdc-chip-flat-disabled-selected-container-color: #c00000;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #fef2f2;
  --mdc-chip-selected-label-text-color: #fef2f2;
  --mdc-chip-with-icon-icon-color: #fef2f2;
  --mdc-chip-with-icon-disabled-icon-color: #fef2f2;
  --mdc-chip-with-icon-selected-icon-color: #fef2f2;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fef2f2;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fef2f2;
  --mat-chip-selected-disabled-trailing-icon-color: #fef2f2;
  --mat-chip-selected-trailing-icon-color: #fef2f2;
}
.svc-830051 .mat-mdc-slide-toggle {
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-selected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}
.svc-830051 .mat-mdc-slide-toggle {
  --mat-switch-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-830051 .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #475569;
  --mdc-switch-selected-handle-color: #475569;
  --mdc-switch-selected-hover-state-layer-color: #475569;
  --mdc-switch-selected-pressed-state-layer-color: #475569;
  --mdc-switch-selected-focus-handle-color: #0f172a;
  --mdc-switch-selected-hover-handle-color: #0f172a;
  --mdc-switch-selected-pressed-handle-color: #0f172a;
  --mdc-switch-selected-focus-track-color: #cbd5e1;
  --mdc-switch-selected-hover-track-color: #cbd5e1;
  --mdc-switch-selected-pressed-track-color: #cbd5e1;
  --mdc-switch-selected-track-color: #cbd5e1;
}
.svc-830051 .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #dc2626;
  --mdc-switch-selected-handle-color: #dc2626;
  --mdc-switch-selected-hover-state-layer-color: #dc2626;
  --mdc-switch-selected-pressed-state-layer-color: #dc2626;
  --mdc-switch-selected-focus-handle-color: #7f1d1d;
  --mdc-switch-selected-hover-handle-color: #7f1d1d;
  --mdc-switch-selected-pressed-handle-color: #7f1d1d;
  --mdc-switch-selected-focus-track-color: #fca5a5;
  --mdc-switch-selected-hover-track-color: #fca5a5;
  --mdc-switch-selected-pressed-track-color: #fca5a5;
  --mdc-switch-selected-track-color: #fca5a5;
}
.svc-830051 .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #830051;
  --mdc-radio-selected-hover-icon-color: #830051;
  --mdc-radio-selected-icon-color: #830051;
  --mdc-radio-selected-pressed-icon-color: #830051;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #830051;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-830051 .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #64748b;
  --mdc-radio-selected-hover-icon-color: #64748b;
  --mdc-radio-selected-icon-color: #64748b;
  --mdc-radio-selected-pressed-icon-color: #64748b;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #64748b;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-830051 .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #c00000;
  --mdc-radio-selected-hover-icon-color: #c00000;
  --mdc-radio-selected-icon-color: #c00000;
  --mdc-radio-selected-pressed-icon-color: #c00000;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #c00000;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-830051 .mat-accent {
  --mdc-slider-handle-color: #64748b;
  --mdc-slider-focus-handle-color: #64748b;
  --mdc-slider-hover-handle-color: #64748b;
  --mdc-slider-active-track-color: #64748b;
  --mdc-slider-inactive-track-color: #64748b;
  --mdc-slider-with-tick-marks-inactive-container-color: #64748b;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mat-slider-ripple-color: #64748b;
  --mat-slider-hover-state-layer-color: rgba(100, 116, 139, 0.05);
  --mat-slider-focus-state-layer-color: rgba(100, 116, 139, 0.2);
}
.svc-830051 .mat-warn {
  --mdc-slider-handle-color: #c00000;
  --mdc-slider-focus-handle-color: #c00000;
  --mdc-slider-hover-handle-color: #c00000;
  --mdc-slider-active-track-color: #c00000;
  --mdc-slider-inactive-track-color: #c00000;
  --mdc-slider-with-tick-marks-inactive-container-color: #c00000;
  --mdc-slider-with-tick-marks-active-container-color: #fef2f2;
  --mat-slider-ripple-color: #c00000;
  --mat-slider-hover-state-layer-color: rgba(192, 0, 0, 0.05);
  --mat-slider-focus-state-layer-color: rgba(192, 0, 0, 0.2);
}
.svc-830051 .mdc-list-item__start,
.svc-830051 .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #830051;
  --mdc-radio-selected-hover-icon-color: #830051;
  --mdc-radio-selected-icon-color: #830051;
  --mdc-radio-selected-pressed-icon-color: #830051;
}
.svc-830051 .mat-accent .mdc-list-item__start,
.svc-830051 .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #64748b;
  --mdc-radio-selected-hover-icon-color: #64748b;
  --mdc-radio-selected-icon-color: #64748b;
  --mdc-radio-selected-pressed-icon-color: #64748b;
}
.svc-830051 .mat-warn .mdc-list-item__start,
.svc-830051 .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #c00000;
  --mdc-radio-selected-hover-icon-color: #c00000;
  --mdc-radio-selected-icon-color: #c00000;
  --mdc-radio-selected-pressed-icon-color: #c00000;
}
.svc-830051 .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #830051;
  --mdc-checkbox-selected-hover-icon-color: #830051;
  --mdc-checkbox-selected-icon-color: #830051;
  --mdc-checkbox-selected-pressed-icon-color: #830051;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #830051;
  --mdc-checkbox-selected-hover-state-layer-color: #830051;
  --mdc-checkbox-selected-pressed-state-layer-color: #830051;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-830051 .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #64748b;
  --mdc-checkbox-selected-hover-icon-color: #64748b;
  --mdc-checkbox-selected-icon-color: #64748b;
  --mdc-checkbox-selected-pressed-icon-color: #64748b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #64748b;
  --mdc-checkbox-selected-hover-state-layer-color: #64748b;
  --mdc-checkbox-selected-pressed-state-layer-color: #64748b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-830051 .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fef2f2;
  --mdc-checkbox-selected-focus-icon-color: #c00000;
  --mdc-checkbox-selected-hover-icon-color: #c00000;
  --mdc-checkbox-selected-icon-color: #c00000;
  --mdc-checkbox-selected-pressed-icon-color: #c00000;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #c00000;
  --mdc-checkbox-selected-hover-state-layer-color: #c00000;
  --mdc-checkbox-selected-pressed-state-layer-color: #c00000;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-830051 .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.svc-830051 .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__start,
.svc-830051 .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.svc-830051 .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__start {
  color: #830051;
}
.svc-830051 .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.svc-830051 .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.svc-830051 .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.svc-830051 .mat-mdc-tab-group, .svc-830051 .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #830051;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #830051;
  --mat-tab-header-active-ripple-color: #830051;
  --mat-tab-header-inactive-ripple-color: #830051;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #830051;
  --mat-tab-header-active-hover-label-text-color: #830051;
  --mat-tab-header-active-focus-indicator-color: #830051;
  --mat-tab-header-active-hover-indicator-color: #830051;
}
.svc-830051 .mat-mdc-tab-group.mat-accent, .svc-830051 .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #64748b;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #64748b;
  --mat-tab-header-active-ripple-color: #64748b;
  --mat-tab-header-inactive-ripple-color: #64748b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #64748b;
  --mat-tab-header-active-hover-label-text-color: #64748b;
  --mat-tab-header-active-focus-indicator-color: #64748b;
  --mat-tab-header-active-hover-indicator-color: #64748b;
}
.svc-830051 .mat-mdc-tab-group.mat-warn, .svc-830051 .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #c00000;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #c00000;
  --mat-tab-header-active-ripple-color: #c00000;
  --mat-tab-header-inactive-ripple-color: #c00000;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #c00000;
  --mat-tab-header-active-hover-label-text-color: #c00000;
  --mat-tab-header-active-focus-indicator-color: #c00000;
  --mat-tab-header-active-hover-indicator-color: #c00000;
}
.svc-830051 .mat-mdc-tab-group.mat-background-primary, .svc-830051 .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #830051;
  --mat-tab-header-with-background-foreground-color: white;
}
.svc-830051 .mat-mdc-tab-group.mat-background-accent, .svc-830051 .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #64748b;
  --mat-tab-header-with-background-foreground-color: white;
}
.svc-830051 .mat-mdc-tab-group.mat-background-warn, .svc-830051 .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #c00000;
  --mat-tab-header-with-background-foreground-color: #fef2f2;
}
.svc-830051 .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #830051;
  --mdc-checkbox-selected-hover-icon-color: #830051;
  --mdc-checkbox-selected-icon-color: #830051;
  --mdc-checkbox-selected-pressed-icon-color: #830051;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #830051;
  --mdc-checkbox-selected-hover-state-layer-color: #830051;
  --mdc-checkbox-selected-pressed-state-layer-color: #830051;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-830051 .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fef2f2;
  --mdc-checkbox-selected-focus-icon-color: #c00000;
  --mdc-checkbox-selected-hover-icon-color: #c00000;
  --mdc-checkbox-selected-icon-color: #c00000;
  --mdc-checkbox-selected-pressed-icon-color: #c00000;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #c00000;
  --mdc-checkbox-selected-hover-state-layer-color: #c00000;
  --mdc-checkbox-selected-pressed-state-layer-color: #c00000;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-830051 .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #830051;
  --mat-text-button-state-layer-color: #830051;
  --mat-text-button-ripple-color: rgba(131, 0, 81, 0.1);
}
.svc-830051 .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #64748b;
  --mat-text-button-state-layer-color: #64748b;
  --mat-text-button-ripple-color: rgba(100, 116, 139, 0.1);
}
.svc-830051 .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #c00000;
  --mat-text-button-state-layer-color: #c00000;
  --mat-text-button-ripple-color: rgba(192, 0, 0, 0.1);
}
.svc-830051 .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #830051;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-830051 .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #64748b;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-830051 .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #c00000;
  --mdc-filled-button-label-text-color: #fef2f2;
  --mat-filled-button-state-layer-color: #fef2f2;
  --mat-filled-button-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-830051 .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #830051;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-830051 .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #64748b;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-830051 .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #c00000;
  --mdc-protected-button-label-text-color: #fef2f2;
  --mat-protected-button-state-layer-color: #fef2f2;
  --mat-protected-button-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-830051 .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #830051;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #830051;
  --mat-outlined-button-ripple-color: rgba(131, 0, 81, 0.1);
}
.svc-830051 .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #64748b;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #64748b;
  --mat-outlined-button-ripple-color: rgba(100, 116, 139, 0.1);
}
.svc-830051 .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #c00000;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #c00000;
  --mat-outlined-button-ripple-color: rgba(192, 0, 0, 0.1);
}
.svc-830051 .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #830051;
  --mat-icon-button-state-layer-color: #830051;
  --mat-icon-button-ripple-color: rgba(131, 0, 81, 0.1);
}
.svc-830051 .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #64748b;
  --mat-icon-button-state-layer-color: #64748b;
  --mat-icon-button-ripple-color: rgba(100, 116, 139, 0.1);
}
.svc-830051 .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #c00000;
  --mat-icon-button-state-layer-color: #c00000;
  --mat-icon-button-ripple-color: rgba(192, 0, 0, 0.1);
}
.svc-830051 .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #830051;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-830051 .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #64748b;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-830051 .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #c00000;
  --mat-fab-foreground-color: #fef2f2;
  --mat-fab-state-layer-color: #fef2f2;
  --mat-fab-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-830051 .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #830051;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-830051 .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #64748b;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-830051 .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #c00000;
  --mat-fab-small-foreground-color: #fef2f2;
  --mat-fab-small-state-layer-color: #fef2f2;
  --mat-fab-small-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-830051 .mat-accent {
  --mdc-circular-progress-active-indicator-color: #64748b;
}
.svc-830051 .mat-warn {
  --mdc-circular-progress-active-indicator-color: #c00000;
}
.svc-830051 .mat-badge-accent {
  --mat-badge-background-color: #64748b;
  --mat-badge-text-color: white;
}
.svc-830051 .mat-badge-warn {
  --mat-badge-background-color: #c00000;
  --mat-badge-text-color: #fef2f2;
}
.svc-830051 .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #64748b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(100, 116, 139, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(100, 116, 139, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(100, 116, 139, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(100, 116, 139, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.svc-830051 .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: #fef2f2;
  --mat-datepicker-calendar-date-selected-state-background-color: #c00000;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(192, 0, 0, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #fef2f2;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(192, 0, 0, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(192, 0, 0, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(192, 0, 0, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.svc-830051 .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #64748b;
}
.svc-830051 .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #c00000;
}
.svc-830051 .mat-icon.mat-primary {
  --mat-icon-color: #830051;
}
.svc-830051 .mat-icon.mat-accent {
  --mat-icon-color: #64748b;
}
.svc-830051 .mat-icon.mat-warn {
  --mat-icon-color: #c00000;
}
.svc-830051 .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #64748b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #64748b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #64748b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.svc-830051 .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: #fef2f2;
  --mat-stepper-header-selected-state-icon-background-color: #c00000;
  --mat-stepper-header-selected-state-icon-foreground-color: #fef2f2;
  --mat-stepper-header-done-state-icon-background-color: #c00000;
  --mat-stepper-header-done-state-icon-foreground-color: #fef2f2;
  --mat-stepper-header-edit-state-icon-background-color: #c00000;
  --mat-stepper-header-edit-state-icon-foreground-color: #fef2f2;
}
.svc-830051 .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #830051;
  --mat-toolbar-container-text-color: white;
}
.svc-830051 .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #64748b;
  --mat-toolbar-container-text-color: white;
}
.svc-830051 .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #c00000;
  --mat-toolbar-container-text-color: #fef2f2;
}

.svc-E4002B {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-option-selected-state-label-text-color: #e4002b;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-full-pseudo-checkbox-selected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #64748b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mdc-filled-text-field-caret-color: #e4002b;
  --mdc-filled-text-field-focus-active-indicator-color: #e4002b;
  --mdc-filled-text-field-focus-label-text-color: rgba(228, 0, 43, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #c00000;
  --mdc-filled-text-field-error-focus-label-text-color: #c00000;
  --mdc-filled-text-field-error-label-text-color: #c00000;
  --mdc-filled-text-field-error-caret-color: #c00000;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #c00000;
  --mdc-filled-text-field-error-focus-active-indicator-color: #c00000;
  --mdc-filled-text-field-error-hover-active-indicator-color: #c00000;
  --mdc-outlined-text-field-caret-color: #e4002b;
  --mdc-outlined-text-field-focus-outline-color: #e4002b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(228, 0, 43, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #c00000;
  --mdc-outlined-text-field-error-focus-label-text-color: #c00000;
  --mdc-outlined-text-field-error-label-text-color: #c00000;
  --mdc-outlined-text-field-error-hover-label-text-color: #c00000;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #c00000;
  --mdc-outlined-text-field-error-hover-outline-color: #c00000;
  --mdc-outlined-text-field-error-outline-color: #c00000;
  --mat-form-field-focus-select-arrow-color: rgba(228, 0, 43, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #c00000;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(228, 0, 43, 0.87);
  --mat-select-invalid-arrow-color: rgba(192, 0, 0, 0.87);
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: white;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-selected-focus-state-layer-color: #b60022;
  --mdc-switch-selected-handle-color: #b60022;
  --mdc-switch-selected-hover-state-layer-color: #b60022;
  --mdc-switch-selected-pressed-state-layer-color: #b60022;
  --mdc-switch-selected-focus-handle-color: #2e0009;
  --mdc-switch-selected-hover-handle-color: #2e0009;
  --mdc-switch-selected-pressed-handle-color: #2e0009;
  --mdc-switch-selected-focus-track-color: #ef6680;
  --mdc-switch-selected-hover-track-color: #ef6680;
  --mdc-switch-selected-pressed-track-color: #ef6680;
  --mdc-switch-selected-track-color: #ef6680;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: #fff;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-handle-color: #e4002b;
  --mdc-slider-focus-handle-color: #e4002b;
  --mdc-slider-hover-handle-color: #e4002b;
  --mdc-slider-active-track-color: #e4002b;
  --mdc-slider-inactive-track-color: #e4002b;
  --mdc-slider-with-tick-marks-inactive-container-color: #e4002b;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-slider-ripple-color: #e4002b;
  --mat-slider-hover-state-layer-color: rgba(228, 0, 43, 0.05);
  --mat-slider-focus-state-layer-color: rgba(228, 0, 43, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-base-elevation-level: 8;
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: rgba(0, 0, 0, 0.12);
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 4px;
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #64748b;
  --mdc-checkbox-selected-hover-icon-color: #64748b;
  --mdc-checkbox-selected-icon-color: #64748b;
  --mdc-checkbox-selected-pressed-icon-color: #64748b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #64748b;
  --mdc-checkbox-selected-hover-state-layer-color: #64748b;
  --mdc-checkbox-selected-pressed-state-layer-color: #64748b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-checkbox-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
  --mdc-text-button-label-text-color: black;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
  --mdc-icon-button-icon-size: 24px;
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
  --mdc-fab-container-shape: 50%;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-color: white;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-fab-small-container-color: white;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #64748b;
  --mat-table-row-item-outline-width: 1px;
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mdc-circular-progress-active-indicator-color: #e4002b;
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
  --mat-badge-background-color: #e4002b;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-bottom-sheet-container-shape: 4px;
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #e4002b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(228, 0, 43, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(228, 0, 43, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(228, 0, 43, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #e4002b;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(228, 0, 43, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-text-color: black;
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-divider-width: 1px;
  --mat-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
  --mat-icon-color: inherit;
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #e4002b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #e4002b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #e4002b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #c00000;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #c00000;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #757575;
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: rgba(0, 0, 0, 0.87);
}
.svc-E4002B .mat-accent {
  --mat-option-selected-state-label-text-color: #64748b;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.svc-E4002B .mat-warn {
  --mat-option-selected-state-label-text-color: #c00000;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.svc-E4002B .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #e4002b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #e4002b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.svc-E4002B .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #64748b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.svc-E4002B .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #c00000;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #c00000;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.svc-E4002B .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #e4002b;
  --mdc-linear-progress-track-color: rgba(228, 0, 43, 0.25);
}
.svc-E4002B .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #64748b;
  --mdc-linear-progress-track-color: rgba(100, 116, 139, 0.25);
}
.svc-E4002B .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #c00000;
  --mdc-linear-progress-track-color: rgba(192, 0, 0, 0.25);
}
.svc-E4002B .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #64748b;
  --mdc-filled-text-field-focus-active-indicator-color: #64748b;
  --mdc-filled-text-field-focus-label-text-color: rgba(100, 116, 139, 0.87);
  --mdc-outlined-text-field-caret-color: #64748b;
  --mdc-outlined-text-field-focus-outline-color: #64748b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(100, 116, 139, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(100, 116, 139, 0.87);
}
.svc-E4002B .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #c00000;
  --mdc-filled-text-field-focus-active-indicator-color: #c00000;
  --mdc-filled-text-field-focus-label-text-color: rgba(192, 0, 0, 0.87);
  --mdc-outlined-text-field-caret-color: #c00000;
  --mdc-outlined-text-field-focus-outline-color: #c00000;
  --mdc-outlined-text-field-focus-label-text-color: rgba(192, 0, 0, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(192, 0, 0, 0.87);
}
.svc-E4002B .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(100, 116, 139, 0.87);
  --mat-select-invalid-arrow-color: rgba(192, 0, 0, 0.87);
}
.svc-E4002B .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(192, 0, 0, 0.87);
  --mat-select-invalid-arrow-color: rgba(192, 0, 0, 0.87);
}
.svc-E4002B .mat-mdc-standard-chip {
  --mdc-chip-container-shape-radius: 16px;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}
.svc-E4002B .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.svc-E4002B .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .svc-E4002B .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #e4002b;
  --mdc-chip-elevated-selected-container-color: #e4002b;
  --mdc-chip-elevated-disabled-container-color: #e4002b;
  --mdc-chip-flat-disabled-selected-container-color: #e4002b;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.svc-E4002B .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .svc-E4002B .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #64748b;
  --mdc-chip-elevated-selected-container-color: #64748b;
  --mdc-chip-elevated-disabled-container-color: #64748b;
  --mdc-chip-flat-disabled-selected-container-color: #64748b;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.svc-E4002B .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .svc-E4002B .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: #fef2f2;
  --mdc-chip-elevated-container-color: #c00000;
  --mdc-chip-elevated-selected-container-color: #c00000;
  --mdc-chip-elevated-disabled-container-color: #c00000;
  --mdc-chip-flat-disabled-selected-container-color: #c00000;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #fef2f2;
  --mdc-chip-selected-label-text-color: #fef2f2;
  --mdc-chip-with-icon-icon-color: #fef2f2;
  --mdc-chip-with-icon-disabled-icon-color: #fef2f2;
  --mdc-chip-with-icon-selected-icon-color: #fef2f2;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fef2f2;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fef2f2;
  --mat-chip-selected-disabled-trailing-icon-color: #fef2f2;
  --mat-chip-selected-trailing-icon-color: #fef2f2;
}
.svc-E4002B .mat-mdc-slide-toggle {
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-selected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}
.svc-E4002B .mat-mdc-slide-toggle {
  --mat-switch-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-E4002B .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #475569;
  --mdc-switch-selected-handle-color: #475569;
  --mdc-switch-selected-hover-state-layer-color: #475569;
  --mdc-switch-selected-pressed-state-layer-color: #475569;
  --mdc-switch-selected-focus-handle-color: #0f172a;
  --mdc-switch-selected-hover-handle-color: #0f172a;
  --mdc-switch-selected-pressed-handle-color: #0f172a;
  --mdc-switch-selected-focus-track-color: #cbd5e1;
  --mdc-switch-selected-hover-track-color: #cbd5e1;
  --mdc-switch-selected-pressed-track-color: #cbd5e1;
  --mdc-switch-selected-track-color: #cbd5e1;
}
.svc-E4002B .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #dc2626;
  --mdc-switch-selected-handle-color: #dc2626;
  --mdc-switch-selected-hover-state-layer-color: #dc2626;
  --mdc-switch-selected-pressed-state-layer-color: #dc2626;
  --mdc-switch-selected-focus-handle-color: #7f1d1d;
  --mdc-switch-selected-hover-handle-color: #7f1d1d;
  --mdc-switch-selected-pressed-handle-color: #7f1d1d;
  --mdc-switch-selected-focus-track-color: #fca5a5;
  --mdc-switch-selected-hover-track-color: #fca5a5;
  --mdc-switch-selected-pressed-track-color: #fca5a5;
  --mdc-switch-selected-track-color: #fca5a5;
}
.svc-E4002B .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #e4002b;
  --mdc-radio-selected-hover-icon-color: #e4002b;
  --mdc-radio-selected-icon-color: #e4002b;
  --mdc-radio-selected-pressed-icon-color: #e4002b;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #e4002b;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-E4002B .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #64748b;
  --mdc-radio-selected-hover-icon-color: #64748b;
  --mdc-radio-selected-icon-color: #64748b;
  --mdc-radio-selected-pressed-icon-color: #64748b;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #64748b;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-E4002B .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #c00000;
  --mdc-radio-selected-hover-icon-color: #c00000;
  --mdc-radio-selected-icon-color: #c00000;
  --mdc-radio-selected-pressed-icon-color: #c00000;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #c00000;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-E4002B .mat-accent {
  --mdc-slider-handle-color: #64748b;
  --mdc-slider-focus-handle-color: #64748b;
  --mdc-slider-hover-handle-color: #64748b;
  --mdc-slider-active-track-color: #64748b;
  --mdc-slider-inactive-track-color: #64748b;
  --mdc-slider-with-tick-marks-inactive-container-color: #64748b;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mat-slider-ripple-color: #64748b;
  --mat-slider-hover-state-layer-color: rgba(100, 116, 139, 0.05);
  --mat-slider-focus-state-layer-color: rgba(100, 116, 139, 0.2);
}
.svc-E4002B .mat-warn {
  --mdc-slider-handle-color: #c00000;
  --mdc-slider-focus-handle-color: #c00000;
  --mdc-slider-hover-handle-color: #c00000;
  --mdc-slider-active-track-color: #c00000;
  --mdc-slider-inactive-track-color: #c00000;
  --mdc-slider-with-tick-marks-inactive-container-color: #c00000;
  --mdc-slider-with-tick-marks-active-container-color: #fef2f2;
  --mat-slider-ripple-color: #c00000;
  --mat-slider-hover-state-layer-color: rgba(192, 0, 0, 0.05);
  --mat-slider-focus-state-layer-color: rgba(192, 0, 0, 0.2);
}
.svc-E4002B .mdc-list-item__start,
.svc-E4002B .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #e4002b;
  --mdc-radio-selected-hover-icon-color: #e4002b;
  --mdc-radio-selected-icon-color: #e4002b;
  --mdc-radio-selected-pressed-icon-color: #e4002b;
}
.svc-E4002B .mat-accent .mdc-list-item__start,
.svc-E4002B .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #64748b;
  --mdc-radio-selected-hover-icon-color: #64748b;
  --mdc-radio-selected-icon-color: #64748b;
  --mdc-radio-selected-pressed-icon-color: #64748b;
}
.svc-E4002B .mat-warn .mdc-list-item__start,
.svc-E4002B .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #c00000;
  --mdc-radio-selected-hover-icon-color: #c00000;
  --mdc-radio-selected-icon-color: #c00000;
  --mdc-radio-selected-pressed-icon-color: #c00000;
}
.svc-E4002B .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #e4002b;
  --mdc-checkbox-selected-hover-icon-color: #e4002b;
  --mdc-checkbox-selected-icon-color: #e4002b;
  --mdc-checkbox-selected-pressed-icon-color: #e4002b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #e4002b;
  --mdc-checkbox-selected-hover-state-layer-color: #e4002b;
  --mdc-checkbox-selected-pressed-state-layer-color: #e4002b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-E4002B .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #64748b;
  --mdc-checkbox-selected-hover-icon-color: #64748b;
  --mdc-checkbox-selected-icon-color: #64748b;
  --mdc-checkbox-selected-pressed-icon-color: #64748b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #64748b;
  --mdc-checkbox-selected-hover-state-layer-color: #64748b;
  --mdc-checkbox-selected-pressed-state-layer-color: #64748b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-E4002B .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fef2f2;
  --mdc-checkbox-selected-focus-icon-color: #c00000;
  --mdc-checkbox-selected-hover-icon-color: #c00000;
  --mdc-checkbox-selected-icon-color: #c00000;
  --mdc-checkbox-selected-pressed-icon-color: #c00000;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #c00000;
  --mdc-checkbox-selected-hover-state-layer-color: #c00000;
  --mdc-checkbox-selected-pressed-state-layer-color: #c00000;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-E4002B .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.svc-E4002B .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__start,
.svc-E4002B .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.svc-E4002B .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__start {
  color: #e4002b;
}
.svc-E4002B .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.svc-E4002B .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.svc-E4002B .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.svc-E4002B .mat-mdc-tab-group, .svc-E4002B .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #e4002b;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #e4002b;
  --mat-tab-header-active-ripple-color: #e4002b;
  --mat-tab-header-inactive-ripple-color: #e4002b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #e4002b;
  --mat-tab-header-active-hover-label-text-color: #e4002b;
  --mat-tab-header-active-focus-indicator-color: #e4002b;
  --mat-tab-header-active-hover-indicator-color: #e4002b;
}
.svc-E4002B .mat-mdc-tab-group.mat-accent, .svc-E4002B .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #64748b;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #64748b;
  --mat-tab-header-active-ripple-color: #64748b;
  --mat-tab-header-inactive-ripple-color: #64748b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #64748b;
  --mat-tab-header-active-hover-label-text-color: #64748b;
  --mat-tab-header-active-focus-indicator-color: #64748b;
  --mat-tab-header-active-hover-indicator-color: #64748b;
}
.svc-E4002B .mat-mdc-tab-group.mat-warn, .svc-E4002B .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #c00000;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #c00000;
  --mat-tab-header-active-ripple-color: #c00000;
  --mat-tab-header-inactive-ripple-color: #c00000;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #c00000;
  --mat-tab-header-active-hover-label-text-color: #c00000;
  --mat-tab-header-active-focus-indicator-color: #c00000;
  --mat-tab-header-active-hover-indicator-color: #c00000;
}
.svc-E4002B .mat-mdc-tab-group.mat-background-primary, .svc-E4002B .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #e4002b;
  --mat-tab-header-with-background-foreground-color: white;
}
.svc-E4002B .mat-mdc-tab-group.mat-background-accent, .svc-E4002B .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #64748b;
  --mat-tab-header-with-background-foreground-color: white;
}
.svc-E4002B .mat-mdc-tab-group.mat-background-warn, .svc-E4002B .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #c00000;
  --mat-tab-header-with-background-foreground-color: #fef2f2;
}
.svc-E4002B .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #e4002b;
  --mdc-checkbox-selected-hover-icon-color: #e4002b;
  --mdc-checkbox-selected-icon-color: #e4002b;
  --mdc-checkbox-selected-pressed-icon-color: #e4002b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #e4002b;
  --mdc-checkbox-selected-hover-state-layer-color: #e4002b;
  --mdc-checkbox-selected-pressed-state-layer-color: #e4002b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-E4002B .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fef2f2;
  --mdc-checkbox-selected-focus-icon-color: #c00000;
  --mdc-checkbox-selected-hover-icon-color: #c00000;
  --mdc-checkbox-selected-icon-color: #c00000;
  --mdc-checkbox-selected-pressed-icon-color: #c00000;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #c00000;
  --mdc-checkbox-selected-hover-state-layer-color: #c00000;
  --mdc-checkbox-selected-pressed-state-layer-color: #c00000;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-E4002B .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #e4002b;
  --mat-text-button-state-layer-color: #e4002b;
  --mat-text-button-ripple-color: rgba(228, 0, 43, 0.1);
}
.svc-E4002B .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #64748b;
  --mat-text-button-state-layer-color: #64748b;
  --mat-text-button-ripple-color: rgba(100, 116, 139, 0.1);
}
.svc-E4002B .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #c00000;
  --mat-text-button-state-layer-color: #c00000;
  --mat-text-button-ripple-color: rgba(192, 0, 0, 0.1);
}
.svc-E4002B .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #e4002b;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-E4002B .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #64748b;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-E4002B .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #c00000;
  --mdc-filled-button-label-text-color: #fef2f2;
  --mat-filled-button-state-layer-color: #fef2f2;
  --mat-filled-button-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-E4002B .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #e4002b;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-E4002B .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #64748b;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-E4002B .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #c00000;
  --mdc-protected-button-label-text-color: #fef2f2;
  --mat-protected-button-state-layer-color: #fef2f2;
  --mat-protected-button-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-E4002B .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #e4002b;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #e4002b;
  --mat-outlined-button-ripple-color: rgba(228, 0, 43, 0.1);
}
.svc-E4002B .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #64748b;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #64748b;
  --mat-outlined-button-ripple-color: rgba(100, 116, 139, 0.1);
}
.svc-E4002B .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #c00000;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #c00000;
  --mat-outlined-button-ripple-color: rgba(192, 0, 0, 0.1);
}
.svc-E4002B .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #e4002b;
  --mat-icon-button-state-layer-color: #e4002b;
  --mat-icon-button-ripple-color: rgba(228, 0, 43, 0.1);
}
.svc-E4002B .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #64748b;
  --mat-icon-button-state-layer-color: #64748b;
  --mat-icon-button-ripple-color: rgba(100, 116, 139, 0.1);
}
.svc-E4002B .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #c00000;
  --mat-icon-button-state-layer-color: #c00000;
  --mat-icon-button-ripple-color: rgba(192, 0, 0, 0.1);
}
.svc-E4002B .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #e4002b;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-E4002B .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #64748b;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-E4002B .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #c00000;
  --mat-fab-foreground-color: #fef2f2;
  --mat-fab-state-layer-color: #fef2f2;
  --mat-fab-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-E4002B .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #e4002b;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-E4002B .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #64748b;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-E4002B .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #c00000;
  --mat-fab-small-foreground-color: #fef2f2;
  --mat-fab-small-state-layer-color: #fef2f2;
  --mat-fab-small-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-E4002B .mat-accent {
  --mdc-circular-progress-active-indicator-color: #64748b;
}
.svc-E4002B .mat-warn {
  --mdc-circular-progress-active-indicator-color: #c00000;
}
.svc-E4002B .mat-badge-accent {
  --mat-badge-background-color: #64748b;
  --mat-badge-text-color: white;
}
.svc-E4002B .mat-badge-warn {
  --mat-badge-background-color: #c00000;
  --mat-badge-text-color: #fef2f2;
}
.svc-E4002B .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #64748b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(100, 116, 139, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(100, 116, 139, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(100, 116, 139, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(100, 116, 139, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.svc-E4002B .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: #fef2f2;
  --mat-datepicker-calendar-date-selected-state-background-color: #c00000;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(192, 0, 0, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #fef2f2;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(192, 0, 0, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(192, 0, 0, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(192, 0, 0, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.svc-E4002B .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #64748b;
}
.svc-E4002B .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #c00000;
}
.svc-E4002B .mat-icon.mat-primary {
  --mat-icon-color: #e4002b;
}
.svc-E4002B .mat-icon.mat-accent {
  --mat-icon-color: #64748b;
}
.svc-E4002B .mat-icon.mat-warn {
  --mat-icon-color: #c00000;
}
.svc-E4002B .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #64748b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #64748b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #64748b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.svc-E4002B .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: #fef2f2;
  --mat-stepper-header-selected-state-icon-background-color: #c00000;
  --mat-stepper-header-selected-state-icon-foreground-color: #fef2f2;
  --mat-stepper-header-done-state-icon-background-color: #c00000;
  --mat-stepper-header-done-state-icon-foreground-color: #fef2f2;
  --mat-stepper-header-edit-state-icon-background-color: #c00000;
  --mat-stepper-header-edit-state-icon-foreground-color: #fef2f2;
}
.svc-E4002B .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #e4002b;
  --mat-toolbar-container-text-color: white;
}
.svc-E4002B .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #64748b;
  --mat-toolbar-container-text-color: white;
}
.svc-E4002B .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #c00000;
  --mat-toolbar-container-text-color: #fef2f2;
}

.svc-E5A812 {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-option-selected-state-label-text-color: #e5a812;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-full-pseudo-checkbox-selected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #64748b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mdc-filled-text-field-caret-color: #e5a812;
  --mdc-filled-text-field-focus-active-indicator-color: #e5a812;
  --mdc-filled-text-field-focus-label-text-color: rgba(229, 168, 18, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #c00000;
  --mdc-filled-text-field-error-focus-label-text-color: #c00000;
  --mdc-filled-text-field-error-label-text-color: #c00000;
  --mdc-filled-text-field-error-caret-color: #c00000;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #c00000;
  --mdc-filled-text-field-error-focus-active-indicator-color: #c00000;
  --mdc-filled-text-field-error-hover-active-indicator-color: #c00000;
  --mdc-outlined-text-field-caret-color: #e5a812;
  --mdc-outlined-text-field-focus-outline-color: #e5a812;
  --mdc-outlined-text-field-focus-label-text-color: rgba(229, 168, 18, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #c00000;
  --mdc-outlined-text-field-error-focus-label-text-color: #c00000;
  --mdc-outlined-text-field-error-label-text-color: #c00000;
  --mdc-outlined-text-field-error-hover-label-text-color: #c00000;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #c00000;
  --mdc-outlined-text-field-error-hover-outline-color: #c00000;
  --mdc-outlined-text-field-error-outline-color: #c00000;
  --mat-form-field-focus-select-arrow-color: rgba(229, 168, 18, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #c00000;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(229, 168, 18, 0.87);
  --mat-select-invalid-arrow-color: rgba(192, 0, 0, 0.87);
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: white;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-selected-focus-state-layer-color: #b7860e;
  --mdc-switch-selected-handle-color: #b7860e;
  --mdc-switch-selected-hover-state-layer-color: #b7860e;
  --mdc-switch-selected-pressed-state-layer-color: #b7860e;
  --mdc-switch-selected-focus-handle-color: #2e2204;
  --mdc-switch-selected-hover-handle-color: #2e2204;
  --mdc-switch-selected-pressed-handle-color: #2e2204;
  --mdc-switch-selected-focus-track-color: #efcb71;
  --mdc-switch-selected-hover-track-color: #efcb71;
  --mdc-switch-selected-pressed-track-color: #efcb71;
  --mdc-switch-selected-track-color: #efcb71;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: #fff;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-handle-color: #e5a812;
  --mdc-slider-focus-handle-color: #e5a812;
  --mdc-slider-hover-handle-color: #e5a812;
  --mdc-slider-active-track-color: #e5a812;
  --mdc-slider-inactive-track-color: #e5a812;
  --mdc-slider-with-tick-marks-inactive-container-color: #e5a812;
  --mdc-slider-with-tick-marks-active-container-color: #2e2204;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-slider-ripple-color: #e5a812;
  --mat-slider-hover-state-layer-color: rgba(229, 168, 18, 0.05);
  --mat-slider-focus-state-layer-color: rgba(229, 168, 18, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-base-elevation-level: 8;
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: rgba(0, 0, 0, 0.12);
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 4px;
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #64748b;
  --mdc-checkbox-selected-hover-icon-color: #64748b;
  --mdc-checkbox-selected-icon-color: #64748b;
  --mdc-checkbox-selected-pressed-icon-color: #64748b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #64748b;
  --mdc-checkbox-selected-hover-state-layer-color: #64748b;
  --mdc-checkbox-selected-pressed-state-layer-color: #64748b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-checkbox-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
  --mdc-text-button-label-text-color: black;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
  --mdc-icon-button-icon-size: 24px;
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
  --mdc-fab-container-shape: 50%;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-color: white;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-fab-small-container-color: white;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #64748b;
  --mat-table-row-item-outline-width: 1px;
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mdc-circular-progress-active-indicator-color: #e5a812;
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
  --mat-badge-background-color: #e5a812;
  --mat-badge-text-color: #2e2204;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-bottom-sheet-container-shape: 4px;
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-date-selected-state-text-color: #2e2204;
  --mat-datepicker-calendar-date-selected-state-background-color: #e5a812;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(229, 168, 18, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #2e2204;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(229, 168, 18, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(229, 168, 18, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #e5a812;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(229, 168, 18, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-text-color: black;
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-divider-width: 1px;
  --mat-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
  --mat-icon-color: inherit;
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
  --mat-stepper-header-icon-foreground-color: #2e2204;
  --mat-stepper-header-selected-state-icon-background-color: #e5a812;
  --mat-stepper-header-selected-state-icon-foreground-color: #2e2204;
  --mat-stepper-header-done-state-icon-background-color: #e5a812;
  --mat-stepper-header-done-state-icon-foreground-color: #2e2204;
  --mat-stepper-header-edit-state-icon-background-color: #e5a812;
  --mat-stepper-header-edit-state-icon-foreground-color: #2e2204;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #c00000;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #c00000;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #757575;
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: rgba(0, 0, 0, 0.87);
}
.svc-E5A812 .mat-accent {
  --mat-option-selected-state-label-text-color: #64748b;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.svc-E5A812 .mat-warn {
  --mat-option-selected-state-label-text-color: #c00000;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.svc-E5A812 .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #e5a812;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #e5a812;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.svc-E5A812 .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #64748b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.svc-E5A812 .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #c00000;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #c00000;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.svc-E5A812 .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #e5a812;
  --mdc-linear-progress-track-color: rgba(229, 168, 18, 0.25);
}
.svc-E5A812 .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #64748b;
  --mdc-linear-progress-track-color: rgba(100, 116, 139, 0.25);
}
.svc-E5A812 .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #c00000;
  --mdc-linear-progress-track-color: rgba(192, 0, 0, 0.25);
}
.svc-E5A812 .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #64748b;
  --mdc-filled-text-field-focus-active-indicator-color: #64748b;
  --mdc-filled-text-field-focus-label-text-color: rgba(100, 116, 139, 0.87);
  --mdc-outlined-text-field-caret-color: #64748b;
  --mdc-outlined-text-field-focus-outline-color: #64748b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(100, 116, 139, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(100, 116, 139, 0.87);
}
.svc-E5A812 .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #c00000;
  --mdc-filled-text-field-focus-active-indicator-color: #c00000;
  --mdc-filled-text-field-focus-label-text-color: rgba(192, 0, 0, 0.87);
  --mdc-outlined-text-field-caret-color: #c00000;
  --mdc-outlined-text-field-focus-outline-color: #c00000;
  --mdc-outlined-text-field-focus-label-text-color: rgba(192, 0, 0, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(192, 0, 0, 0.87);
}
.svc-E5A812 .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(100, 116, 139, 0.87);
  --mat-select-invalid-arrow-color: rgba(192, 0, 0, 0.87);
}
.svc-E5A812 .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(192, 0, 0, 0.87);
  --mat-select-invalid-arrow-color: rgba(192, 0, 0, 0.87);
}
.svc-E5A812 .mat-mdc-standard-chip {
  --mdc-chip-container-shape-radius: 16px;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}
.svc-E5A812 .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.svc-E5A812 .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .svc-E5A812 .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: #2e2204;
  --mdc-chip-elevated-container-color: #e5a812;
  --mdc-chip-elevated-selected-container-color: #e5a812;
  --mdc-chip-elevated-disabled-container-color: #e5a812;
  --mdc-chip-flat-disabled-selected-container-color: #e5a812;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #2e2204;
  --mdc-chip-selected-label-text-color: #2e2204;
  --mdc-chip-with-icon-icon-color: #2e2204;
  --mdc-chip-with-icon-disabled-icon-color: #2e2204;
  --mdc-chip-with-icon-selected-icon-color: #2e2204;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #2e2204;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #2e2204;
  --mat-chip-selected-disabled-trailing-icon-color: #2e2204;
  --mat-chip-selected-trailing-icon-color: #2e2204;
}
.svc-E5A812 .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .svc-E5A812 .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #64748b;
  --mdc-chip-elevated-selected-container-color: #64748b;
  --mdc-chip-elevated-disabled-container-color: #64748b;
  --mdc-chip-flat-disabled-selected-container-color: #64748b;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.svc-E5A812 .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .svc-E5A812 .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: #fef2f2;
  --mdc-chip-elevated-container-color: #c00000;
  --mdc-chip-elevated-selected-container-color: #c00000;
  --mdc-chip-elevated-disabled-container-color: #c00000;
  --mdc-chip-flat-disabled-selected-container-color: #c00000;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #fef2f2;
  --mdc-chip-selected-label-text-color: #fef2f2;
  --mdc-chip-with-icon-icon-color: #fef2f2;
  --mdc-chip-with-icon-disabled-icon-color: #fef2f2;
  --mdc-chip-with-icon-selected-icon-color: #fef2f2;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fef2f2;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fef2f2;
  --mat-chip-selected-disabled-trailing-icon-color: #fef2f2;
  --mat-chip-selected-trailing-icon-color: #fef2f2;
}
.svc-E5A812 .mat-mdc-slide-toggle {
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-selected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}
.svc-E5A812 .mat-mdc-slide-toggle {
  --mat-switch-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-E5A812 .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #475569;
  --mdc-switch-selected-handle-color: #475569;
  --mdc-switch-selected-hover-state-layer-color: #475569;
  --mdc-switch-selected-pressed-state-layer-color: #475569;
  --mdc-switch-selected-focus-handle-color: #0f172a;
  --mdc-switch-selected-hover-handle-color: #0f172a;
  --mdc-switch-selected-pressed-handle-color: #0f172a;
  --mdc-switch-selected-focus-track-color: #cbd5e1;
  --mdc-switch-selected-hover-track-color: #cbd5e1;
  --mdc-switch-selected-pressed-track-color: #cbd5e1;
  --mdc-switch-selected-track-color: #cbd5e1;
}
.svc-E5A812 .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #dc2626;
  --mdc-switch-selected-handle-color: #dc2626;
  --mdc-switch-selected-hover-state-layer-color: #dc2626;
  --mdc-switch-selected-pressed-state-layer-color: #dc2626;
  --mdc-switch-selected-focus-handle-color: #7f1d1d;
  --mdc-switch-selected-hover-handle-color: #7f1d1d;
  --mdc-switch-selected-pressed-handle-color: #7f1d1d;
  --mdc-switch-selected-focus-track-color: #fca5a5;
  --mdc-switch-selected-hover-track-color: #fca5a5;
  --mdc-switch-selected-pressed-track-color: #fca5a5;
  --mdc-switch-selected-track-color: #fca5a5;
}
.svc-E5A812 .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #e5a812;
  --mdc-radio-selected-hover-icon-color: #e5a812;
  --mdc-radio-selected-icon-color: #e5a812;
  --mdc-radio-selected-pressed-icon-color: #e5a812;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #e5a812;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-E5A812 .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #64748b;
  --mdc-radio-selected-hover-icon-color: #64748b;
  --mdc-radio-selected-icon-color: #64748b;
  --mdc-radio-selected-pressed-icon-color: #64748b;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #64748b;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-E5A812 .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #c00000;
  --mdc-radio-selected-hover-icon-color: #c00000;
  --mdc-radio-selected-icon-color: #c00000;
  --mdc-radio-selected-pressed-icon-color: #c00000;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #c00000;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-E5A812 .mat-accent {
  --mdc-slider-handle-color: #64748b;
  --mdc-slider-focus-handle-color: #64748b;
  --mdc-slider-hover-handle-color: #64748b;
  --mdc-slider-active-track-color: #64748b;
  --mdc-slider-inactive-track-color: #64748b;
  --mdc-slider-with-tick-marks-inactive-container-color: #64748b;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mat-slider-ripple-color: #64748b;
  --mat-slider-hover-state-layer-color: rgba(100, 116, 139, 0.05);
  --mat-slider-focus-state-layer-color: rgba(100, 116, 139, 0.2);
}
.svc-E5A812 .mat-warn {
  --mdc-slider-handle-color: #c00000;
  --mdc-slider-focus-handle-color: #c00000;
  --mdc-slider-hover-handle-color: #c00000;
  --mdc-slider-active-track-color: #c00000;
  --mdc-slider-inactive-track-color: #c00000;
  --mdc-slider-with-tick-marks-inactive-container-color: #c00000;
  --mdc-slider-with-tick-marks-active-container-color: #fef2f2;
  --mat-slider-ripple-color: #c00000;
  --mat-slider-hover-state-layer-color: rgba(192, 0, 0, 0.05);
  --mat-slider-focus-state-layer-color: rgba(192, 0, 0, 0.2);
}
.svc-E5A812 .mdc-list-item__start,
.svc-E5A812 .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #e5a812;
  --mdc-radio-selected-hover-icon-color: #e5a812;
  --mdc-radio-selected-icon-color: #e5a812;
  --mdc-radio-selected-pressed-icon-color: #e5a812;
}
.svc-E5A812 .mat-accent .mdc-list-item__start,
.svc-E5A812 .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #64748b;
  --mdc-radio-selected-hover-icon-color: #64748b;
  --mdc-radio-selected-icon-color: #64748b;
  --mdc-radio-selected-pressed-icon-color: #64748b;
}
.svc-E5A812 .mat-warn .mdc-list-item__start,
.svc-E5A812 .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #c00000;
  --mdc-radio-selected-hover-icon-color: #c00000;
  --mdc-radio-selected-icon-color: #c00000;
  --mdc-radio-selected-pressed-icon-color: #c00000;
}
.svc-E5A812 .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #2e2204;
  --mdc-checkbox-selected-focus-icon-color: #e5a812;
  --mdc-checkbox-selected-hover-icon-color: #e5a812;
  --mdc-checkbox-selected-icon-color: #e5a812;
  --mdc-checkbox-selected-pressed-icon-color: #e5a812;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #e5a812;
  --mdc-checkbox-selected-hover-state-layer-color: #e5a812;
  --mdc-checkbox-selected-pressed-state-layer-color: #e5a812;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-E5A812 .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #64748b;
  --mdc-checkbox-selected-hover-icon-color: #64748b;
  --mdc-checkbox-selected-icon-color: #64748b;
  --mdc-checkbox-selected-pressed-icon-color: #64748b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #64748b;
  --mdc-checkbox-selected-hover-state-layer-color: #64748b;
  --mdc-checkbox-selected-pressed-state-layer-color: #64748b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-E5A812 .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fef2f2;
  --mdc-checkbox-selected-focus-icon-color: #c00000;
  --mdc-checkbox-selected-hover-icon-color: #c00000;
  --mdc-checkbox-selected-icon-color: #c00000;
  --mdc-checkbox-selected-pressed-icon-color: #c00000;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #c00000;
  --mdc-checkbox-selected-hover-state-layer-color: #c00000;
  --mdc-checkbox-selected-pressed-state-layer-color: #c00000;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-E5A812 .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.svc-E5A812 .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__start,
.svc-E5A812 .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.svc-E5A812 .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__start {
  color: #e5a812;
}
.svc-E5A812 .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.svc-E5A812 .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.svc-E5A812 .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.svc-E5A812 .mat-mdc-tab-group, .svc-E5A812 .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #e5a812;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #e5a812;
  --mat-tab-header-active-ripple-color: #e5a812;
  --mat-tab-header-inactive-ripple-color: #e5a812;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #e5a812;
  --mat-tab-header-active-hover-label-text-color: #e5a812;
  --mat-tab-header-active-focus-indicator-color: #e5a812;
  --mat-tab-header-active-hover-indicator-color: #e5a812;
}
.svc-E5A812 .mat-mdc-tab-group.mat-accent, .svc-E5A812 .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #64748b;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #64748b;
  --mat-tab-header-active-ripple-color: #64748b;
  --mat-tab-header-inactive-ripple-color: #64748b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #64748b;
  --mat-tab-header-active-hover-label-text-color: #64748b;
  --mat-tab-header-active-focus-indicator-color: #64748b;
  --mat-tab-header-active-hover-indicator-color: #64748b;
}
.svc-E5A812 .mat-mdc-tab-group.mat-warn, .svc-E5A812 .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #c00000;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #c00000;
  --mat-tab-header-active-ripple-color: #c00000;
  --mat-tab-header-inactive-ripple-color: #c00000;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #c00000;
  --mat-tab-header-active-hover-label-text-color: #c00000;
  --mat-tab-header-active-focus-indicator-color: #c00000;
  --mat-tab-header-active-hover-indicator-color: #c00000;
}
.svc-E5A812 .mat-mdc-tab-group.mat-background-primary, .svc-E5A812 .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #e5a812;
  --mat-tab-header-with-background-foreground-color: #2e2204;
}
.svc-E5A812 .mat-mdc-tab-group.mat-background-accent, .svc-E5A812 .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #64748b;
  --mat-tab-header-with-background-foreground-color: white;
}
.svc-E5A812 .mat-mdc-tab-group.mat-background-warn, .svc-E5A812 .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #c00000;
  --mat-tab-header-with-background-foreground-color: #fef2f2;
}
.svc-E5A812 .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #2e2204;
  --mdc-checkbox-selected-focus-icon-color: #e5a812;
  --mdc-checkbox-selected-hover-icon-color: #e5a812;
  --mdc-checkbox-selected-icon-color: #e5a812;
  --mdc-checkbox-selected-pressed-icon-color: #e5a812;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #e5a812;
  --mdc-checkbox-selected-hover-state-layer-color: #e5a812;
  --mdc-checkbox-selected-pressed-state-layer-color: #e5a812;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-E5A812 .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fef2f2;
  --mdc-checkbox-selected-focus-icon-color: #c00000;
  --mdc-checkbox-selected-hover-icon-color: #c00000;
  --mdc-checkbox-selected-icon-color: #c00000;
  --mdc-checkbox-selected-pressed-icon-color: #c00000;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #c00000;
  --mdc-checkbox-selected-hover-state-layer-color: #c00000;
  --mdc-checkbox-selected-pressed-state-layer-color: #c00000;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-E5A812 .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #e5a812;
  --mat-text-button-state-layer-color: #e5a812;
  --mat-text-button-ripple-color: rgba(229, 168, 18, 0.1);
}
.svc-E5A812 .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #64748b;
  --mat-text-button-state-layer-color: #64748b;
  --mat-text-button-ripple-color: rgba(100, 116, 139, 0.1);
}
.svc-E5A812 .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #c00000;
  --mat-text-button-state-layer-color: #c00000;
  --mat-text-button-ripple-color: rgba(192, 0, 0, 0.1);
}
.svc-E5A812 .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #e5a812;
  --mdc-filled-button-label-text-color: #2e2204;
  --mat-filled-button-state-layer-color: #2e2204;
  --mat-filled-button-ripple-color: rgba(46, 34, 4, 0.1);
}
.svc-E5A812 .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #64748b;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-E5A812 .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #c00000;
  --mdc-filled-button-label-text-color: #fef2f2;
  --mat-filled-button-state-layer-color: #fef2f2;
  --mat-filled-button-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-E5A812 .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #e5a812;
  --mdc-protected-button-label-text-color: #2e2204;
  --mat-protected-button-state-layer-color: #2e2204;
  --mat-protected-button-ripple-color: rgba(46, 34, 4, 0.1);
}
.svc-E5A812 .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #64748b;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-E5A812 .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #c00000;
  --mdc-protected-button-label-text-color: #fef2f2;
  --mat-protected-button-state-layer-color: #fef2f2;
  --mat-protected-button-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-E5A812 .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #e5a812;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #e5a812;
  --mat-outlined-button-ripple-color: rgba(229, 168, 18, 0.1);
}
.svc-E5A812 .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #64748b;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #64748b;
  --mat-outlined-button-ripple-color: rgba(100, 116, 139, 0.1);
}
.svc-E5A812 .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #c00000;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #c00000;
  --mat-outlined-button-ripple-color: rgba(192, 0, 0, 0.1);
}
.svc-E5A812 .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #e5a812;
  --mat-icon-button-state-layer-color: #e5a812;
  --mat-icon-button-ripple-color: rgba(229, 168, 18, 0.1);
}
.svc-E5A812 .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #64748b;
  --mat-icon-button-state-layer-color: #64748b;
  --mat-icon-button-ripple-color: rgba(100, 116, 139, 0.1);
}
.svc-E5A812 .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #c00000;
  --mat-icon-button-state-layer-color: #c00000;
  --mat-icon-button-ripple-color: rgba(192, 0, 0, 0.1);
}
.svc-E5A812 .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #e5a812;
  --mat-fab-foreground-color: #2e2204;
  --mat-fab-state-layer-color: #2e2204;
  --mat-fab-ripple-color: rgba(46, 34, 4, 0.1);
}
.svc-E5A812 .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #64748b;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-E5A812 .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #c00000;
  --mat-fab-foreground-color: #fef2f2;
  --mat-fab-state-layer-color: #fef2f2;
  --mat-fab-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-E5A812 .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #e5a812;
  --mat-fab-small-foreground-color: #2e2204;
  --mat-fab-small-state-layer-color: #2e2204;
  --mat-fab-small-ripple-color: rgba(46, 34, 4, 0.1);
}
.svc-E5A812 .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #64748b;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-E5A812 .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #c00000;
  --mat-fab-small-foreground-color: #fef2f2;
  --mat-fab-small-state-layer-color: #fef2f2;
  --mat-fab-small-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-E5A812 .mat-accent {
  --mdc-circular-progress-active-indicator-color: #64748b;
}
.svc-E5A812 .mat-warn {
  --mdc-circular-progress-active-indicator-color: #c00000;
}
.svc-E5A812 .mat-badge-accent {
  --mat-badge-background-color: #64748b;
  --mat-badge-text-color: white;
}
.svc-E5A812 .mat-badge-warn {
  --mat-badge-background-color: #c00000;
  --mat-badge-text-color: #fef2f2;
}
.svc-E5A812 .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #64748b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(100, 116, 139, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(100, 116, 139, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(100, 116, 139, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(100, 116, 139, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.svc-E5A812 .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: #fef2f2;
  --mat-datepicker-calendar-date-selected-state-background-color: #c00000;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(192, 0, 0, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #fef2f2;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(192, 0, 0, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(192, 0, 0, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(192, 0, 0, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.svc-E5A812 .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #64748b;
}
.svc-E5A812 .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #c00000;
}
.svc-E5A812 .mat-icon.mat-primary {
  --mat-icon-color: #e5a812;
}
.svc-E5A812 .mat-icon.mat-accent {
  --mat-icon-color: #64748b;
}
.svc-E5A812 .mat-icon.mat-warn {
  --mat-icon-color: #c00000;
}
.svc-E5A812 .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #64748b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #64748b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #64748b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.svc-E5A812 .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: #fef2f2;
  --mat-stepper-header-selected-state-icon-background-color: #c00000;
  --mat-stepper-header-selected-state-icon-foreground-color: #fef2f2;
  --mat-stepper-header-done-state-icon-background-color: #c00000;
  --mat-stepper-header-done-state-icon-foreground-color: #fef2f2;
  --mat-stepper-header-edit-state-icon-background-color: #c00000;
  --mat-stepper-header-edit-state-icon-foreground-color: #fef2f2;
}
.svc-E5A812 .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #e5a812;
  --mat-toolbar-container-text-color: #2e2204;
}
.svc-E5A812 .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #64748b;
  --mat-toolbar-container-text-color: white;
}
.svc-E5A812 .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #c00000;
  --mat-toolbar-container-text-color: #fef2f2;
}

.svc-FF7900 {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-option-selected-state-label-text-color: #ff7900;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-full-pseudo-checkbox-selected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #64748b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mdc-filled-text-field-caret-color: #ff7900;
  --mdc-filled-text-field-focus-active-indicator-color: #ff7900;
  --mdc-filled-text-field-focus-label-text-color: rgba(255, 121, 0, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #c00000;
  --mdc-filled-text-field-error-focus-label-text-color: #c00000;
  --mdc-filled-text-field-error-label-text-color: #c00000;
  --mdc-filled-text-field-error-caret-color: #c00000;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #c00000;
  --mdc-filled-text-field-error-focus-active-indicator-color: #c00000;
  --mdc-filled-text-field-error-hover-active-indicator-color: #c00000;
  --mdc-outlined-text-field-caret-color: #ff7900;
  --mdc-outlined-text-field-focus-outline-color: #ff7900;
  --mdc-outlined-text-field-focus-label-text-color: rgba(255, 121, 0, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #c00000;
  --mdc-outlined-text-field-error-focus-label-text-color: #c00000;
  --mdc-outlined-text-field-error-label-text-color: #c00000;
  --mdc-outlined-text-field-error-hover-label-text-color: #c00000;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #c00000;
  --mdc-outlined-text-field-error-hover-outline-color: #c00000;
  --mdc-outlined-text-field-error-outline-color: #c00000;
  --mat-form-field-focus-select-arrow-color: rgba(255, 121, 0, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #c00000;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(255, 121, 0, 0.87);
  --mat-select-invalid-arrow-color: rgba(192, 0, 0, 0.87);
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: white;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-selected-focus-state-layer-color: #cc6100;
  --mdc-switch-selected-handle-color: #cc6100;
  --mdc-switch-selected-hover-state-layer-color: #cc6100;
  --mdc-switch-selected-pressed-state-layer-color: #cc6100;
  --mdc-switch-selected-focus-handle-color: #331800;
  --mdc-switch-selected-hover-handle-color: #331800;
  --mdc-switch-selected-pressed-handle-color: #331800;
  --mdc-switch-selected-focus-track-color: #ffaf66;
  --mdc-switch-selected-hover-track-color: #ffaf66;
  --mdc-switch-selected-pressed-track-color: #ffaf66;
  --mdc-switch-selected-track-color: #ffaf66;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: #fff;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-handle-color: #ff7900;
  --mdc-slider-focus-handle-color: #ff7900;
  --mdc-slider-hover-handle-color: #ff7900;
  --mdc-slider-active-track-color: #ff7900;
  --mdc-slider-inactive-track-color: #ff7900;
  --mdc-slider-with-tick-marks-inactive-container-color: #ff7900;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-slider-ripple-color: #ff7900;
  --mat-slider-hover-state-layer-color: rgba(255, 121, 0, 0.05);
  --mat-slider-focus-state-layer-color: rgba(255, 121, 0, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-base-elevation-level: 8;
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: rgba(0, 0, 0, 0.12);
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 4px;
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #64748b;
  --mdc-checkbox-selected-hover-icon-color: #64748b;
  --mdc-checkbox-selected-icon-color: #64748b;
  --mdc-checkbox-selected-pressed-icon-color: #64748b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #64748b;
  --mdc-checkbox-selected-hover-state-layer-color: #64748b;
  --mdc-checkbox-selected-pressed-state-layer-color: #64748b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-checkbox-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
  --mdc-text-button-label-text-color: black;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
  --mdc-icon-button-icon-size: 24px;
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
  --mdc-fab-container-shape: 50%;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-color: white;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-fab-small-container-color: white;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #64748b;
  --mat-table-row-item-outline-width: 1px;
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mdc-circular-progress-active-indicator-color: #ff7900;
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
  --mat-badge-background-color: #ff7900;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-bottom-sheet-container-shape: 4px;
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #ff7900;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(255, 121, 0, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(255, 121, 0, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(255, 121, 0, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #ff7900;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(255, 121, 0, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-text-color: black;
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-divider-width: 1px;
  --mat-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
  --mat-icon-color: inherit;
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #ff7900;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #ff7900;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #ff7900;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #c00000;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #c00000;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #757575;
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: rgba(0, 0, 0, 0.87);
}
.svc-FF7900 .mat-accent {
  --mat-option-selected-state-label-text-color: #64748b;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.svc-FF7900 .mat-warn {
  --mat-option-selected-state-label-text-color: #c00000;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.svc-FF7900 .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #ff7900;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #ff7900;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.svc-FF7900 .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #64748b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.svc-FF7900 .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #c00000;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #c00000;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.svc-FF7900 .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #ff7900;
  --mdc-linear-progress-track-color: rgba(255, 121, 0, 0.25);
}
.svc-FF7900 .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #64748b;
  --mdc-linear-progress-track-color: rgba(100, 116, 139, 0.25);
}
.svc-FF7900 .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #c00000;
  --mdc-linear-progress-track-color: rgba(192, 0, 0, 0.25);
}
.svc-FF7900 .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #64748b;
  --mdc-filled-text-field-focus-active-indicator-color: #64748b;
  --mdc-filled-text-field-focus-label-text-color: rgba(100, 116, 139, 0.87);
  --mdc-outlined-text-field-caret-color: #64748b;
  --mdc-outlined-text-field-focus-outline-color: #64748b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(100, 116, 139, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(100, 116, 139, 0.87);
}
.svc-FF7900 .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #c00000;
  --mdc-filled-text-field-focus-active-indicator-color: #c00000;
  --mdc-filled-text-field-focus-label-text-color: rgba(192, 0, 0, 0.87);
  --mdc-outlined-text-field-caret-color: #c00000;
  --mdc-outlined-text-field-focus-outline-color: #c00000;
  --mdc-outlined-text-field-focus-label-text-color: rgba(192, 0, 0, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(192, 0, 0, 0.87);
}
.svc-FF7900 .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(100, 116, 139, 0.87);
  --mat-select-invalid-arrow-color: rgba(192, 0, 0, 0.87);
}
.svc-FF7900 .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(192, 0, 0, 0.87);
  --mat-select-invalid-arrow-color: rgba(192, 0, 0, 0.87);
}
.svc-FF7900 .mat-mdc-standard-chip {
  --mdc-chip-container-shape-radius: 16px;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}
.svc-FF7900 .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.svc-FF7900 .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .svc-FF7900 .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #ff7900;
  --mdc-chip-elevated-selected-container-color: #ff7900;
  --mdc-chip-elevated-disabled-container-color: #ff7900;
  --mdc-chip-flat-disabled-selected-container-color: #ff7900;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.svc-FF7900 .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .svc-FF7900 .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #64748b;
  --mdc-chip-elevated-selected-container-color: #64748b;
  --mdc-chip-elevated-disabled-container-color: #64748b;
  --mdc-chip-flat-disabled-selected-container-color: #64748b;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.svc-FF7900 .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .svc-FF7900 .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: #fef2f2;
  --mdc-chip-elevated-container-color: #c00000;
  --mdc-chip-elevated-selected-container-color: #c00000;
  --mdc-chip-elevated-disabled-container-color: #c00000;
  --mdc-chip-flat-disabled-selected-container-color: #c00000;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #fef2f2;
  --mdc-chip-selected-label-text-color: #fef2f2;
  --mdc-chip-with-icon-icon-color: #fef2f2;
  --mdc-chip-with-icon-disabled-icon-color: #fef2f2;
  --mdc-chip-with-icon-selected-icon-color: #fef2f2;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fef2f2;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fef2f2;
  --mat-chip-selected-disabled-trailing-icon-color: #fef2f2;
  --mat-chip-selected-trailing-icon-color: #fef2f2;
}
.svc-FF7900 .mat-mdc-slide-toggle {
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-selected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}
.svc-FF7900 .mat-mdc-slide-toggle {
  --mat-switch-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-FF7900 .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #475569;
  --mdc-switch-selected-handle-color: #475569;
  --mdc-switch-selected-hover-state-layer-color: #475569;
  --mdc-switch-selected-pressed-state-layer-color: #475569;
  --mdc-switch-selected-focus-handle-color: #0f172a;
  --mdc-switch-selected-hover-handle-color: #0f172a;
  --mdc-switch-selected-pressed-handle-color: #0f172a;
  --mdc-switch-selected-focus-track-color: #cbd5e1;
  --mdc-switch-selected-hover-track-color: #cbd5e1;
  --mdc-switch-selected-pressed-track-color: #cbd5e1;
  --mdc-switch-selected-track-color: #cbd5e1;
}
.svc-FF7900 .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #dc2626;
  --mdc-switch-selected-handle-color: #dc2626;
  --mdc-switch-selected-hover-state-layer-color: #dc2626;
  --mdc-switch-selected-pressed-state-layer-color: #dc2626;
  --mdc-switch-selected-focus-handle-color: #7f1d1d;
  --mdc-switch-selected-hover-handle-color: #7f1d1d;
  --mdc-switch-selected-pressed-handle-color: #7f1d1d;
  --mdc-switch-selected-focus-track-color: #fca5a5;
  --mdc-switch-selected-hover-track-color: #fca5a5;
  --mdc-switch-selected-pressed-track-color: #fca5a5;
  --mdc-switch-selected-track-color: #fca5a5;
}
.svc-FF7900 .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ff7900;
  --mdc-radio-selected-hover-icon-color: #ff7900;
  --mdc-radio-selected-icon-color: #ff7900;
  --mdc-radio-selected-pressed-icon-color: #ff7900;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #ff7900;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-FF7900 .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #64748b;
  --mdc-radio-selected-hover-icon-color: #64748b;
  --mdc-radio-selected-icon-color: #64748b;
  --mdc-radio-selected-pressed-icon-color: #64748b;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #64748b;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-FF7900 .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #c00000;
  --mdc-radio-selected-hover-icon-color: #c00000;
  --mdc-radio-selected-icon-color: #c00000;
  --mdc-radio-selected-pressed-icon-color: #c00000;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #c00000;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-FF7900 .mat-accent {
  --mdc-slider-handle-color: #64748b;
  --mdc-slider-focus-handle-color: #64748b;
  --mdc-slider-hover-handle-color: #64748b;
  --mdc-slider-active-track-color: #64748b;
  --mdc-slider-inactive-track-color: #64748b;
  --mdc-slider-with-tick-marks-inactive-container-color: #64748b;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mat-slider-ripple-color: #64748b;
  --mat-slider-hover-state-layer-color: rgba(100, 116, 139, 0.05);
  --mat-slider-focus-state-layer-color: rgba(100, 116, 139, 0.2);
}
.svc-FF7900 .mat-warn {
  --mdc-slider-handle-color: #c00000;
  --mdc-slider-focus-handle-color: #c00000;
  --mdc-slider-hover-handle-color: #c00000;
  --mdc-slider-active-track-color: #c00000;
  --mdc-slider-inactive-track-color: #c00000;
  --mdc-slider-with-tick-marks-inactive-container-color: #c00000;
  --mdc-slider-with-tick-marks-active-container-color: #fef2f2;
  --mat-slider-ripple-color: #c00000;
  --mat-slider-hover-state-layer-color: rgba(192, 0, 0, 0.05);
  --mat-slider-focus-state-layer-color: rgba(192, 0, 0, 0.2);
}
.svc-FF7900 .mdc-list-item__start,
.svc-FF7900 .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ff7900;
  --mdc-radio-selected-hover-icon-color: #ff7900;
  --mdc-radio-selected-icon-color: #ff7900;
  --mdc-radio-selected-pressed-icon-color: #ff7900;
}
.svc-FF7900 .mat-accent .mdc-list-item__start,
.svc-FF7900 .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #64748b;
  --mdc-radio-selected-hover-icon-color: #64748b;
  --mdc-radio-selected-icon-color: #64748b;
  --mdc-radio-selected-pressed-icon-color: #64748b;
}
.svc-FF7900 .mat-warn .mdc-list-item__start,
.svc-FF7900 .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #c00000;
  --mdc-radio-selected-hover-icon-color: #c00000;
  --mdc-radio-selected-icon-color: #c00000;
  --mdc-radio-selected-pressed-icon-color: #c00000;
}
.svc-FF7900 .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #ff7900;
  --mdc-checkbox-selected-hover-icon-color: #ff7900;
  --mdc-checkbox-selected-icon-color: #ff7900;
  --mdc-checkbox-selected-pressed-icon-color: #ff7900;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ff7900;
  --mdc-checkbox-selected-hover-state-layer-color: #ff7900;
  --mdc-checkbox-selected-pressed-state-layer-color: #ff7900;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-FF7900 .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #64748b;
  --mdc-checkbox-selected-hover-icon-color: #64748b;
  --mdc-checkbox-selected-icon-color: #64748b;
  --mdc-checkbox-selected-pressed-icon-color: #64748b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #64748b;
  --mdc-checkbox-selected-hover-state-layer-color: #64748b;
  --mdc-checkbox-selected-pressed-state-layer-color: #64748b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-FF7900 .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fef2f2;
  --mdc-checkbox-selected-focus-icon-color: #c00000;
  --mdc-checkbox-selected-hover-icon-color: #c00000;
  --mdc-checkbox-selected-icon-color: #c00000;
  --mdc-checkbox-selected-pressed-icon-color: #c00000;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #c00000;
  --mdc-checkbox-selected-hover-state-layer-color: #c00000;
  --mdc-checkbox-selected-pressed-state-layer-color: #c00000;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-FF7900 .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.svc-FF7900 .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__start,
.svc-FF7900 .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.svc-FF7900 .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__start {
  color: #ff7900;
}
.svc-FF7900 .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.svc-FF7900 .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.svc-FF7900 .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.svc-FF7900 .mat-mdc-tab-group, .svc-FF7900 .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #ff7900;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #ff7900;
  --mat-tab-header-active-ripple-color: #ff7900;
  --mat-tab-header-inactive-ripple-color: #ff7900;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #ff7900;
  --mat-tab-header-active-hover-label-text-color: #ff7900;
  --mat-tab-header-active-focus-indicator-color: #ff7900;
  --mat-tab-header-active-hover-indicator-color: #ff7900;
}
.svc-FF7900 .mat-mdc-tab-group.mat-accent, .svc-FF7900 .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #64748b;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #64748b;
  --mat-tab-header-active-ripple-color: #64748b;
  --mat-tab-header-inactive-ripple-color: #64748b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #64748b;
  --mat-tab-header-active-hover-label-text-color: #64748b;
  --mat-tab-header-active-focus-indicator-color: #64748b;
  --mat-tab-header-active-hover-indicator-color: #64748b;
}
.svc-FF7900 .mat-mdc-tab-group.mat-warn, .svc-FF7900 .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #c00000;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #c00000;
  --mat-tab-header-active-ripple-color: #c00000;
  --mat-tab-header-inactive-ripple-color: #c00000;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #c00000;
  --mat-tab-header-active-hover-label-text-color: #c00000;
  --mat-tab-header-active-focus-indicator-color: #c00000;
  --mat-tab-header-active-hover-indicator-color: #c00000;
}
.svc-FF7900 .mat-mdc-tab-group.mat-background-primary, .svc-FF7900 .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #ff7900;
  --mat-tab-header-with-background-foreground-color: white;
}
.svc-FF7900 .mat-mdc-tab-group.mat-background-accent, .svc-FF7900 .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #64748b;
  --mat-tab-header-with-background-foreground-color: white;
}
.svc-FF7900 .mat-mdc-tab-group.mat-background-warn, .svc-FF7900 .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #c00000;
  --mat-tab-header-with-background-foreground-color: #fef2f2;
}
.svc-FF7900 .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #ff7900;
  --mdc-checkbox-selected-hover-icon-color: #ff7900;
  --mdc-checkbox-selected-icon-color: #ff7900;
  --mdc-checkbox-selected-pressed-icon-color: #ff7900;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ff7900;
  --mdc-checkbox-selected-hover-state-layer-color: #ff7900;
  --mdc-checkbox-selected-pressed-state-layer-color: #ff7900;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-FF7900 .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fef2f2;
  --mdc-checkbox-selected-focus-icon-color: #c00000;
  --mdc-checkbox-selected-hover-icon-color: #c00000;
  --mdc-checkbox-selected-icon-color: #c00000;
  --mdc-checkbox-selected-pressed-icon-color: #c00000;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #c00000;
  --mdc-checkbox-selected-hover-state-layer-color: #c00000;
  --mdc-checkbox-selected-pressed-state-layer-color: #c00000;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-FF7900 .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #ff7900;
  --mat-text-button-state-layer-color: #ff7900;
  --mat-text-button-ripple-color: rgba(255, 121, 0, 0.1);
}
.svc-FF7900 .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #64748b;
  --mat-text-button-state-layer-color: #64748b;
  --mat-text-button-ripple-color: rgba(100, 116, 139, 0.1);
}
.svc-FF7900 .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #c00000;
  --mat-text-button-state-layer-color: #c00000;
  --mat-text-button-ripple-color: rgba(192, 0, 0, 0.1);
}
.svc-FF7900 .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #ff7900;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-FF7900 .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #64748b;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-FF7900 .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #c00000;
  --mdc-filled-button-label-text-color: #fef2f2;
  --mat-filled-button-state-layer-color: #fef2f2;
  --mat-filled-button-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-FF7900 .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #ff7900;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-FF7900 .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #64748b;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-FF7900 .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #c00000;
  --mdc-protected-button-label-text-color: #fef2f2;
  --mat-protected-button-state-layer-color: #fef2f2;
  --mat-protected-button-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-FF7900 .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #ff7900;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #ff7900;
  --mat-outlined-button-ripple-color: rgba(255, 121, 0, 0.1);
}
.svc-FF7900 .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #64748b;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #64748b;
  --mat-outlined-button-ripple-color: rgba(100, 116, 139, 0.1);
}
.svc-FF7900 .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #c00000;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #c00000;
  --mat-outlined-button-ripple-color: rgba(192, 0, 0, 0.1);
}
.svc-FF7900 .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #ff7900;
  --mat-icon-button-state-layer-color: #ff7900;
  --mat-icon-button-ripple-color: rgba(255, 121, 0, 0.1);
}
.svc-FF7900 .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #64748b;
  --mat-icon-button-state-layer-color: #64748b;
  --mat-icon-button-ripple-color: rgba(100, 116, 139, 0.1);
}
.svc-FF7900 .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #c00000;
  --mat-icon-button-state-layer-color: #c00000;
  --mat-icon-button-ripple-color: rgba(192, 0, 0, 0.1);
}
.svc-FF7900 .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #ff7900;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-FF7900 .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #64748b;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-FF7900 .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #c00000;
  --mat-fab-foreground-color: #fef2f2;
  --mat-fab-state-layer-color: #fef2f2;
  --mat-fab-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-FF7900 .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #ff7900;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-FF7900 .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #64748b;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-FF7900 .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #c00000;
  --mat-fab-small-foreground-color: #fef2f2;
  --mat-fab-small-state-layer-color: #fef2f2;
  --mat-fab-small-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-FF7900 .mat-accent {
  --mdc-circular-progress-active-indicator-color: #64748b;
}
.svc-FF7900 .mat-warn {
  --mdc-circular-progress-active-indicator-color: #c00000;
}
.svc-FF7900 .mat-badge-accent {
  --mat-badge-background-color: #64748b;
  --mat-badge-text-color: white;
}
.svc-FF7900 .mat-badge-warn {
  --mat-badge-background-color: #c00000;
  --mat-badge-text-color: #fef2f2;
}
.svc-FF7900 .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #64748b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(100, 116, 139, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(100, 116, 139, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(100, 116, 139, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(100, 116, 139, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.svc-FF7900 .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: #fef2f2;
  --mat-datepicker-calendar-date-selected-state-background-color: #c00000;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(192, 0, 0, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #fef2f2;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(192, 0, 0, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(192, 0, 0, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(192, 0, 0, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.svc-FF7900 .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #64748b;
}
.svc-FF7900 .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #c00000;
}
.svc-FF7900 .mat-icon.mat-primary {
  --mat-icon-color: #ff7900;
}
.svc-FF7900 .mat-icon.mat-accent {
  --mat-icon-color: #64748b;
}
.svc-FF7900 .mat-icon.mat-warn {
  --mat-icon-color: #c00000;
}
.svc-FF7900 .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #64748b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #64748b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #64748b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.svc-FF7900 .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: #fef2f2;
  --mat-stepper-header-selected-state-icon-background-color: #c00000;
  --mat-stepper-header-selected-state-icon-foreground-color: #fef2f2;
  --mat-stepper-header-done-state-icon-background-color: #c00000;
  --mat-stepper-header-done-state-icon-foreground-color: #fef2f2;
  --mat-stepper-header-edit-state-icon-background-color: #c00000;
  --mat-stepper-header-edit-state-icon-foreground-color: #fef2f2;
}
.svc-FF7900 .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #ff7900;
  --mat-toolbar-container-text-color: white;
}
.svc-FF7900 .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #64748b;
  --mat-toolbar-container-text-color: white;
}
.svc-FF7900 .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #c00000;
  --mat-toolbar-container-text-color: #fef2f2;
}

.svc-FFD30F {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-option-selected-state-label-text-color: #ffd30f;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-full-pseudo-checkbox-selected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #64748b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mdc-filled-text-field-caret-color: #ffd30f;
  --mdc-filled-text-field-focus-active-indicator-color: #ffd30f;
  --mdc-filled-text-field-focus-label-text-color: rgba(255, 211, 15, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #c00000;
  --mdc-filled-text-field-error-focus-label-text-color: #c00000;
  --mdc-filled-text-field-error-label-text-color: #c00000;
  --mdc-filled-text-field-error-caret-color: #c00000;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #c00000;
  --mdc-filled-text-field-error-focus-active-indicator-color: #c00000;
  --mdc-filled-text-field-error-hover-active-indicator-color: #c00000;
  --mdc-outlined-text-field-caret-color: #ffd30f;
  --mdc-outlined-text-field-focus-outline-color: #ffd30f;
  --mdc-outlined-text-field-focus-label-text-color: rgba(255, 211, 15, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #c00000;
  --mdc-outlined-text-field-error-focus-label-text-color: #c00000;
  --mdc-outlined-text-field-error-label-text-color: #c00000;
  --mdc-outlined-text-field-error-hover-label-text-color: #c00000;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #c00000;
  --mdc-outlined-text-field-error-hover-outline-color: #c00000;
  --mdc-outlined-text-field-error-outline-color: #c00000;
  --mat-form-field-focus-select-arrow-color: rgba(255, 211, 15, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #c00000;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(255, 211, 15, 0.87);
  --mat-select-invalid-arrow-color: rgba(192, 0, 0, 0.87);
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: white;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-selected-focus-state-layer-color: #cca90c;
  --mdc-switch-selected-handle-color: #cca90c;
  --mdc-switch-selected-hover-state-layer-color: #cca90c;
  --mdc-switch-selected-pressed-state-layer-color: #cca90c;
  --mdc-switch-selected-focus-handle-color: #332a03;
  --mdc-switch-selected-hover-handle-color: #332a03;
  --mdc-switch-selected-pressed-handle-color: #332a03;
  --mdc-switch-selected-focus-track-color: #ffe56f;
  --mdc-switch-selected-hover-track-color: #ffe56f;
  --mdc-switch-selected-pressed-track-color: #ffe56f;
  --mdc-switch-selected-track-color: #ffe56f;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: #fff;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-handle-color: #ffd30f;
  --mdc-slider-focus-handle-color: #ffd30f;
  --mdc-slider-hover-handle-color: #ffd30f;
  --mdc-slider-active-track-color: #ffd30f;
  --mdc-slider-inactive-track-color: #ffd30f;
  --mdc-slider-with-tick-marks-inactive-container-color: #ffd30f;
  --mdc-slider-with-tick-marks-active-container-color: #332a03;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-slider-ripple-color: #ffd30f;
  --mat-slider-hover-state-layer-color: rgba(255, 211, 15, 0.05);
  --mat-slider-focus-state-layer-color: rgba(255, 211, 15, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-base-elevation-level: 8;
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: rgba(0, 0, 0, 0.12);
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 4px;
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #64748b;
  --mdc-checkbox-selected-hover-icon-color: #64748b;
  --mdc-checkbox-selected-icon-color: #64748b;
  --mdc-checkbox-selected-pressed-icon-color: #64748b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #64748b;
  --mdc-checkbox-selected-hover-state-layer-color: #64748b;
  --mdc-checkbox-selected-pressed-state-layer-color: #64748b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-checkbox-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
  --mdc-text-button-label-text-color: black;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
  --mdc-icon-button-icon-size: 24px;
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
  --mdc-fab-container-shape: 50%;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-color: white;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-fab-small-container-color: white;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #64748b;
  --mat-table-row-item-outline-width: 1px;
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mdc-circular-progress-active-indicator-color: #ffd30f;
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
  --mat-badge-background-color: #ffd30f;
  --mat-badge-text-color: #332a03;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-bottom-sheet-container-shape: 4px;
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-date-selected-state-text-color: #332a03;
  --mat-datepicker-calendar-date-selected-state-background-color: #ffd30f;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(255, 211, 15, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #332a03;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(255, 211, 15, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(255, 211, 15, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #ffd30f;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(255, 211, 15, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-text-color: black;
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-divider-width: 1px;
  --mat-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
  --mat-icon-color: inherit;
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
  --mat-stepper-header-icon-foreground-color: #332a03;
  --mat-stepper-header-selected-state-icon-background-color: #ffd30f;
  --mat-stepper-header-selected-state-icon-foreground-color: #332a03;
  --mat-stepper-header-done-state-icon-background-color: #ffd30f;
  --mat-stepper-header-done-state-icon-foreground-color: #332a03;
  --mat-stepper-header-edit-state-icon-background-color: #ffd30f;
  --mat-stepper-header-edit-state-icon-foreground-color: #332a03;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #c00000;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #c00000;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #757575;
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: rgba(0, 0, 0, 0.87);
}
.svc-FFD30F .mat-accent {
  --mat-option-selected-state-label-text-color: #64748b;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.svc-FFD30F .mat-warn {
  --mat-option-selected-state-label-text-color: #c00000;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.svc-FFD30F .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #ffd30f;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #ffd30f;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.svc-FFD30F .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #64748b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.svc-FFD30F .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #c00000;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #c00000;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.svc-FFD30F .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #ffd30f;
  --mdc-linear-progress-track-color: rgba(255, 211, 15, 0.25);
}
.svc-FFD30F .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #64748b;
  --mdc-linear-progress-track-color: rgba(100, 116, 139, 0.25);
}
.svc-FFD30F .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #c00000;
  --mdc-linear-progress-track-color: rgba(192, 0, 0, 0.25);
}
.svc-FFD30F .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #64748b;
  --mdc-filled-text-field-focus-active-indicator-color: #64748b;
  --mdc-filled-text-field-focus-label-text-color: rgba(100, 116, 139, 0.87);
  --mdc-outlined-text-field-caret-color: #64748b;
  --mdc-outlined-text-field-focus-outline-color: #64748b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(100, 116, 139, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(100, 116, 139, 0.87);
}
.svc-FFD30F .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #c00000;
  --mdc-filled-text-field-focus-active-indicator-color: #c00000;
  --mdc-filled-text-field-focus-label-text-color: rgba(192, 0, 0, 0.87);
  --mdc-outlined-text-field-caret-color: #c00000;
  --mdc-outlined-text-field-focus-outline-color: #c00000;
  --mdc-outlined-text-field-focus-label-text-color: rgba(192, 0, 0, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(192, 0, 0, 0.87);
}
.svc-FFD30F .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(100, 116, 139, 0.87);
  --mat-select-invalid-arrow-color: rgba(192, 0, 0, 0.87);
}
.svc-FFD30F .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(192, 0, 0, 0.87);
  --mat-select-invalid-arrow-color: rgba(192, 0, 0, 0.87);
}
.svc-FFD30F .mat-mdc-standard-chip {
  --mdc-chip-container-shape-radius: 16px;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}
.svc-FFD30F .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.svc-FFD30F .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .svc-FFD30F .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: #332a03;
  --mdc-chip-elevated-container-color: #ffd30f;
  --mdc-chip-elevated-selected-container-color: #ffd30f;
  --mdc-chip-elevated-disabled-container-color: #ffd30f;
  --mdc-chip-flat-disabled-selected-container-color: #ffd30f;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #332a03;
  --mdc-chip-selected-label-text-color: #332a03;
  --mdc-chip-with-icon-icon-color: #332a03;
  --mdc-chip-with-icon-disabled-icon-color: #332a03;
  --mdc-chip-with-icon-selected-icon-color: #332a03;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #332a03;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #332a03;
  --mat-chip-selected-disabled-trailing-icon-color: #332a03;
  --mat-chip-selected-trailing-icon-color: #332a03;
}
.svc-FFD30F .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .svc-FFD30F .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #64748b;
  --mdc-chip-elevated-selected-container-color: #64748b;
  --mdc-chip-elevated-disabled-container-color: #64748b;
  --mdc-chip-flat-disabled-selected-container-color: #64748b;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.svc-FFD30F .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .svc-FFD30F .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: #fef2f2;
  --mdc-chip-elevated-container-color: #c00000;
  --mdc-chip-elevated-selected-container-color: #c00000;
  --mdc-chip-elevated-disabled-container-color: #c00000;
  --mdc-chip-flat-disabled-selected-container-color: #c00000;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #fef2f2;
  --mdc-chip-selected-label-text-color: #fef2f2;
  --mdc-chip-with-icon-icon-color: #fef2f2;
  --mdc-chip-with-icon-disabled-icon-color: #fef2f2;
  --mdc-chip-with-icon-selected-icon-color: #fef2f2;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fef2f2;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fef2f2;
  --mat-chip-selected-disabled-trailing-icon-color: #fef2f2;
  --mat-chip-selected-trailing-icon-color: #fef2f2;
}
.svc-FFD30F .mat-mdc-slide-toggle {
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-selected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}
.svc-FFD30F .mat-mdc-slide-toggle {
  --mat-switch-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-FFD30F .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #475569;
  --mdc-switch-selected-handle-color: #475569;
  --mdc-switch-selected-hover-state-layer-color: #475569;
  --mdc-switch-selected-pressed-state-layer-color: #475569;
  --mdc-switch-selected-focus-handle-color: #0f172a;
  --mdc-switch-selected-hover-handle-color: #0f172a;
  --mdc-switch-selected-pressed-handle-color: #0f172a;
  --mdc-switch-selected-focus-track-color: #cbd5e1;
  --mdc-switch-selected-hover-track-color: #cbd5e1;
  --mdc-switch-selected-pressed-track-color: #cbd5e1;
  --mdc-switch-selected-track-color: #cbd5e1;
}
.svc-FFD30F .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #dc2626;
  --mdc-switch-selected-handle-color: #dc2626;
  --mdc-switch-selected-hover-state-layer-color: #dc2626;
  --mdc-switch-selected-pressed-state-layer-color: #dc2626;
  --mdc-switch-selected-focus-handle-color: #7f1d1d;
  --mdc-switch-selected-hover-handle-color: #7f1d1d;
  --mdc-switch-selected-pressed-handle-color: #7f1d1d;
  --mdc-switch-selected-focus-track-color: #fca5a5;
  --mdc-switch-selected-hover-track-color: #fca5a5;
  --mdc-switch-selected-pressed-track-color: #fca5a5;
  --mdc-switch-selected-track-color: #fca5a5;
}
.svc-FFD30F .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ffd30f;
  --mdc-radio-selected-hover-icon-color: #ffd30f;
  --mdc-radio-selected-icon-color: #ffd30f;
  --mdc-radio-selected-pressed-icon-color: #ffd30f;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #ffd30f;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-FFD30F .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #64748b;
  --mdc-radio-selected-hover-icon-color: #64748b;
  --mdc-radio-selected-icon-color: #64748b;
  --mdc-radio-selected-pressed-icon-color: #64748b;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #64748b;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-FFD30F .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #c00000;
  --mdc-radio-selected-hover-icon-color: #c00000;
  --mdc-radio-selected-icon-color: #c00000;
  --mdc-radio-selected-pressed-icon-color: #c00000;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #c00000;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-FFD30F .mat-accent {
  --mdc-slider-handle-color: #64748b;
  --mdc-slider-focus-handle-color: #64748b;
  --mdc-slider-hover-handle-color: #64748b;
  --mdc-slider-active-track-color: #64748b;
  --mdc-slider-inactive-track-color: #64748b;
  --mdc-slider-with-tick-marks-inactive-container-color: #64748b;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mat-slider-ripple-color: #64748b;
  --mat-slider-hover-state-layer-color: rgba(100, 116, 139, 0.05);
  --mat-slider-focus-state-layer-color: rgba(100, 116, 139, 0.2);
}
.svc-FFD30F .mat-warn {
  --mdc-slider-handle-color: #c00000;
  --mdc-slider-focus-handle-color: #c00000;
  --mdc-slider-hover-handle-color: #c00000;
  --mdc-slider-active-track-color: #c00000;
  --mdc-slider-inactive-track-color: #c00000;
  --mdc-slider-with-tick-marks-inactive-container-color: #c00000;
  --mdc-slider-with-tick-marks-active-container-color: #fef2f2;
  --mat-slider-ripple-color: #c00000;
  --mat-slider-hover-state-layer-color: rgba(192, 0, 0, 0.05);
  --mat-slider-focus-state-layer-color: rgba(192, 0, 0, 0.2);
}
.svc-FFD30F .mdc-list-item__start,
.svc-FFD30F .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ffd30f;
  --mdc-radio-selected-hover-icon-color: #ffd30f;
  --mdc-radio-selected-icon-color: #ffd30f;
  --mdc-radio-selected-pressed-icon-color: #ffd30f;
}
.svc-FFD30F .mat-accent .mdc-list-item__start,
.svc-FFD30F .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #64748b;
  --mdc-radio-selected-hover-icon-color: #64748b;
  --mdc-radio-selected-icon-color: #64748b;
  --mdc-radio-selected-pressed-icon-color: #64748b;
}
.svc-FFD30F .mat-warn .mdc-list-item__start,
.svc-FFD30F .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #c00000;
  --mdc-radio-selected-hover-icon-color: #c00000;
  --mdc-radio-selected-icon-color: #c00000;
  --mdc-radio-selected-pressed-icon-color: #c00000;
}
.svc-FFD30F .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #332a03;
  --mdc-checkbox-selected-focus-icon-color: #ffd30f;
  --mdc-checkbox-selected-hover-icon-color: #ffd30f;
  --mdc-checkbox-selected-icon-color: #ffd30f;
  --mdc-checkbox-selected-pressed-icon-color: #ffd30f;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ffd30f;
  --mdc-checkbox-selected-hover-state-layer-color: #ffd30f;
  --mdc-checkbox-selected-pressed-state-layer-color: #ffd30f;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-FFD30F .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #64748b;
  --mdc-checkbox-selected-hover-icon-color: #64748b;
  --mdc-checkbox-selected-icon-color: #64748b;
  --mdc-checkbox-selected-pressed-icon-color: #64748b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #64748b;
  --mdc-checkbox-selected-hover-state-layer-color: #64748b;
  --mdc-checkbox-selected-pressed-state-layer-color: #64748b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-FFD30F .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fef2f2;
  --mdc-checkbox-selected-focus-icon-color: #c00000;
  --mdc-checkbox-selected-hover-icon-color: #c00000;
  --mdc-checkbox-selected-icon-color: #c00000;
  --mdc-checkbox-selected-pressed-icon-color: #c00000;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #c00000;
  --mdc-checkbox-selected-hover-state-layer-color: #c00000;
  --mdc-checkbox-selected-pressed-state-layer-color: #c00000;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-FFD30F .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.svc-FFD30F .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__start,
.svc-FFD30F .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.svc-FFD30F .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__start {
  color: #ffd30f;
}
.svc-FFD30F .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.svc-FFD30F .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.svc-FFD30F .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.svc-FFD30F .mat-mdc-tab-group, .svc-FFD30F .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #ffd30f;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #ffd30f;
  --mat-tab-header-active-ripple-color: #ffd30f;
  --mat-tab-header-inactive-ripple-color: #ffd30f;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #ffd30f;
  --mat-tab-header-active-hover-label-text-color: #ffd30f;
  --mat-tab-header-active-focus-indicator-color: #ffd30f;
  --mat-tab-header-active-hover-indicator-color: #ffd30f;
}
.svc-FFD30F .mat-mdc-tab-group.mat-accent, .svc-FFD30F .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #64748b;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #64748b;
  --mat-tab-header-active-ripple-color: #64748b;
  --mat-tab-header-inactive-ripple-color: #64748b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #64748b;
  --mat-tab-header-active-hover-label-text-color: #64748b;
  --mat-tab-header-active-focus-indicator-color: #64748b;
  --mat-tab-header-active-hover-indicator-color: #64748b;
}
.svc-FFD30F .mat-mdc-tab-group.mat-warn, .svc-FFD30F .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #c00000;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #c00000;
  --mat-tab-header-active-ripple-color: #c00000;
  --mat-tab-header-inactive-ripple-color: #c00000;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #c00000;
  --mat-tab-header-active-hover-label-text-color: #c00000;
  --mat-tab-header-active-focus-indicator-color: #c00000;
  --mat-tab-header-active-hover-indicator-color: #c00000;
}
.svc-FFD30F .mat-mdc-tab-group.mat-background-primary, .svc-FFD30F .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #ffd30f;
  --mat-tab-header-with-background-foreground-color: #332a03;
}
.svc-FFD30F .mat-mdc-tab-group.mat-background-accent, .svc-FFD30F .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #64748b;
  --mat-tab-header-with-background-foreground-color: white;
}
.svc-FFD30F .mat-mdc-tab-group.mat-background-warn, .svc-FFD30F .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #c00000;
  --mat-tab-header-with-background-foreground-color: #fef2f2;
}
.svc-FFD30F .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #332a03;
  --mdc-checkbox-selected-focus-icon-color: #ffd30f;
  --mdc-checkbox-selected-hover-icon-color: #ffd30f;
  --mdc-checkbox-selected-icon-color: #ffd30f;
  --mdc-checkbox-selected-pressed-icon-color: #ffd30f;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ffd30f;
  --mdc-checkbox-selected-hover-state-layer-color: #ffd30f;
  --mdc-checkbox-selected-pressed-state-layer-color: #ffd30f;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-FFD30F .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fef2f2;
  --mdc-checkbox-selected-focus-icon-color: #c00000;
  --mdc-checkbox-selected-hover-icon-color: #c00000;
  --mdc-checkbox-selected-icon-color: #c00000;
  --mdc-checkbox-selected-pressed-icon-color: #c00000;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #c00000;
  --mdc-checkbox-selected-hover-state-layer-color: #c00000;
  --mdc-checkbox-selected-pressed-state-layer-color: #c00000;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-FFD30F .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #ffd30f;
  --mat-text-button-state-layer-color: #ffd30f;
  --mat-text-button-ripple-color: rgba(255, 211, 15, 0.1);
}
.svc-FFD30F .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #64748b;
  --mat-text-button-state-layer-color: #64748b;
  --mat-text-button-ripple-color: rgba(100, 116, 139, 0.1);
}
.svc-FFD30F .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #c00000;
  --mat-text-button-state-layer-color: #c00000;
  --mat-text-button-ripple-color: rgba(192, 0, 0, 0.1);
}
.svc-FFD30F .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #ffd30f;
  --mdc-filled-button-label-text-color: #332a03;
  --mat-filled-button-state-layer-color: #332a03;
  --mat-filled-button-ripple-color: rgba(51, 42, 3, 0.1);
}
.svc-FFD30F .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #64748b;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-FFD30F .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #c00000;
  --mdc-filled-button-label-text-color: #fef2f2;
  --mat-filled-button-state-layer-color: #fef2f2;
  --mat-filled-button-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-FFD30F .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #ffd30f;
  --mdc-protected-button-label-text-color: #332a03;
  --mat-protected-button-state-layer-color: #332a03;
  --mat-protected-button-ripple-color: rgba(51, 42, 3, 0.1);
}
.svc-FFD30F .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #64748b;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-FFD30F .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #c00000;
  --mdc-protected-button-label-text-color: #fef2f2;
  --mat-protected-button-state-layer-color: #fef2f2;
  --mat-protected-button-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-FFD30F .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #ffd30f;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #ffd30f;
  --mat-outlined-button-ripple-color: rgba(255, 211, 15, 0.1);
}
.svc-FFD30F .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #64748b;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #64748b;
  --mat-outlined-button-ripple-color: rgba(100, 116, 139, 0.1);
}
.svc-FFD30F .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #c00000;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #c00000;
  --mat-outlined-button-ripple-color: rgba(192, 0, 0, 0.1);
}
.svc-FFD30F .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #ffd30f;
  --mat-icon-button-state-layer-color: #ffd30f;
  --mat-icon-button-ripple-color: rgba(255, 211, 15, 0.1);
}
.svc-FFD30F .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #64748b;
  --mat-icon-button-state-layer-color: #64748b;
  --mat-icon-button-ripple-color: rgba(100, 116, 139, 0.1);
}
.svc-FFD30F .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #c00000;
  --mat-icon-button-state-layer-color: #c00000;
  --mat-icon-button-ripple-color: rgba(192, 0, 0, 0.1);
}
.svc-FFD30F .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #ffd30f;
  --mat-fab-foreground-color: #332a03;
  --mat-fab-state-layer-color: #332a03;
  --mat-fab-ripple-color: rgba(51, 42, 3, 0.1);
}
.svc-FFD30F .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #64748b;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-FFD30F .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #c00000;
  --mat-fab-foreground-color: #fef2f2;
  --mat-fab-state-layer-color: #fef2f2;
  --mat-fab-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-FFD30F .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #ffd30f;
  --mat-fab-small-foreground-color: #332a03;
  --mat-fab-small-state-layer-color: #332a03;
  --mat-fab-small-ripple-color: rgba(51, 42, 3, 0.1);
}
.svc-FFD30F .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #64748b;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-FFD30F .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #c00000;
  --mat-fab-small-foreground-color: #fef2f2;
  --mat-fab-small-state-layer-color: #fef2f2;
  --mat-fab-small-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-FFD30F .mat-accent {
  --mdc-circular-progress-active-indicator-color: #64748b;
}
.svc-FFD30F .mat-warn {
  --mdc-circular-progress-active-indicator-color: #c00000;
}
.svc-FFD30F .mat-badge-accent {
  --mat-badge-background-color: #64748b;
  --mat-badge-text-color: white;
}
.svc-FFD30F .mat-badge-warn {
  --mat-badge-background-color: #c00000;
  --mat-badge-text-color: #fef2f2;
}
.svc-FFD30F .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #64748b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(100, 116, 139, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(100, 116, 139, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(100, 116, 139, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(100, 116, 139, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.svc-FFD30F .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: #fef2f2;
  --mat-datepicker-calendar-date-selected-state-background-color: #c00000;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(192, 0, 0, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #fef2f2;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(192, 0, 0, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(192, 0, 0, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(192, 0, 0, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.svc-FFD30F .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #64748b;
}
.svc-FFD30F .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #c00000;
}
.svc-FFD30F .mat-icon.mat-primary {
  --mat-icon-color: #ffd30f;
}
.svc-FFD30F .mat-icon.mat-accent {
  --mat-icon-color: #64748b;
}
.svc-FFD30F .mat-icon.mat-warn {
  --mat-icon-color: #c00000;
}
.svc-FFD30F .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #64748b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #64748b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #64748b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.svc-FFD30F .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: #fef2f2;
  --mat-stepper-header-selected-state-icon-background-color: #c00000;
  --mat-stepper-header-selected-state-icon-foreground-color: #fef2f2;
  --mat-stepper-header-done-state-icon-background-color: #c00000;
  --mat-stepper-header-done-state-icon-foreground-color: #fef2f2;
  --mat-stepper-header-edit-state-icon-background-color: #c00000;
  --mat-stepper-header-edit-state-icon-foreground-color: #fef2f2;
}
.svc-FFD30F .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #ffd30f;
  --mat-toolbar-container-text-color: #332a03;
}
.svc-FFD30F .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #64748b;
  --mat-toolbar-container-text-color: white;
}
.svc-FFD30F .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #c00000;
  --mat-toolbar-container-text-color: #fef2f2;
}

.svc-006400 {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-option-selected-state-label-text-color: darkgreen;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-full-pseudo-checkbox-selected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #64748b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mdc-filled-text-field-caret-color: darkgreen;
  --mdc-filled-text-field-focus-active-indicator-color: darkgreen;
  --mdc-filled-text-field-focus-label-text-color: rgba(0, 100, 0, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #c00000;
  --mdc-filled-text-field-error-focus-label-text-color: #c00000;
  --mdc-filled-text-field-error-label-text-color: #c00000;
  --mdc-filled-text-field-error-caret-color: #c00000;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #c00000;
  --mdc-filled-text-field-error-focus-active-indicator-color: #c00000;
  --mdc-filled-text-field-error-hover-active-indicator-color: #c00000;
  --mdc-outlined-text-field-caret-color: darkgreen;
  --mdc-outlined-text-field-focus-outline-color: darkgreen;
  --mdc-outlined-text-field-focus-label-text-color: rgba(0, 100, 0, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #c00000;
  --mdc-outlined-text-field-error-focus-label-text-color: #c00000;
  --mdc-outlined-text-field-error-label-text-color: #c00000;
  --mdc-outlined-text-field-error-hover-label-text-color: #c00000;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #c00000;
  --mdc-outlined-text-field-error-hover-outline-color: #c00000;
  --mdc-outlined-text-field-error-outline-color: #c00000;
  --mat-form-field-focus-select-arrow-color: rgba(0, 100, 0, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #c00000;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(0, 100, 0, 0.87);
  --mat-select-invalid-arrow-color: rgba(192, 0, 0, 0.87);
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: white;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-selected-focus-state-layer-color: #005c00;
  --mdc-switch-selected-handle-color: #005c00;
  --mdc-switch-selected-hover-state-layer-color: #005c00;
  --mdc-switch-selected-pressed-state-layer-color: #005c00;
  --mdc-switch-selected-focus-handle-color: #003600;
  --mdc-switch-selected-hover-handle-color: #003600;
  --mdc-switch-selected-pressed-handle-color: #003600;
  --mdc-switch-selected-focus-track-color: #4d934d;
  --mdc-switch-selected-hover-track-color: #4d934d;
  --mdc-switch-selected-pressed-track-color: #4d934d;
  --mdc-switch-selected-track-color: #4d934d;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: #fff;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-handle-color: darkgreen;
  --mdc-slider-focus-handle-color: darkgreen;
  --mdc-slider-hover-handle-color: darkgreen;
  --mdc-slider-active-track-color: darkgreen;
  --mdc-slider-inactive-track-color: darkgreen;
  --mdc-slider-with-tick-marks-inactive-container-color: darkgreen;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-slider-ripple-color: darkgreen;
  --mat-slider-hover-state-layer-color: rgba(0, 100, 0, 0.05);
  --mat-slider-focus-state-layer-color: rgba(0, 100, 0, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-base-elevation-level: 8;
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: rgba(0, 0, 0, 0.12);
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 4px;
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #64748b;
  --mdc-checkbox-selected-hover-icon-color: #64748b;
  --mdc-checkbox-selected-icon-color: #64748b;
  --mdc-checkbox-selected-pressed-icon-color: #64748b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #64748b;
  --mdc-checkbox-selected-hover-state-layer-color: #64748b;
  --mdc-checkbox-selected-pressed-state-layer-color: #64748b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-checkbox-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
  --mdc-text-button-label-text-color: black;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
  --mdc-icon-button-icon-size: 24px;
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
  --mdc-fab-container-shape: 50%;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-color: white;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-fab-small-container-color: white;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #64748b;
  --mat-table-row-item-outline-width: 1px;
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mdc-circular-progress-active-indicator-color: darkgreen;
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
  --mat-badge-background-color: darkgreen;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-bottom-sheet-container-shape: 4px;
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: darkgreen;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(0, 100, 0, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(0, 100, 0, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(0, 100, 0, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: darkgreen;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(0, 100, 0, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-text-color: black;
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-divider-width: 1px;
  --mat-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
  --mat-icon-color: inherit;
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: darkgreen;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: darkgreen;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: darkgreen;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #c00000;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #c00000;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #757575;
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: rgba(0, 0, 0, 0.87);
}
.svc-006400 .mat-accent {
  --mat-option-selected-state-label-text-color: #64748b;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.svc-006400 .mat-warn {
  --mat-option-selected-state-label-text-color: #c00000;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.svc-006400 .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: darkgreen;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: darkgreen;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.svc-006400 .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #64748b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.svc-006400 .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #c00000;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #c00000;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.svc-006400 .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: darkgreen;
  --mdc-linear-progress-track-color: rgba(0, 100, 0, 0.25);
}
.svc-006400 .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #64748b;
  --mdc-linear-progress-track-color: rgba(100, 116, 139, 0.25);
}
.svc-006400 .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #c00000;
  --mdc-linear-progress-track-color: rgba(192, 0, 0, 0.25);
}
.svc-006400 .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #64748b;
  --mdc-filled-text-field-focus-active-indicator-color: #64748b;
  --mdc-filled-text-field-focus-label-text-color: rgba(100, 116, 139, 0.87);
  --mdc-outlined-text-field-caret-color: #64748b;
  --mdc-outlined-text-field-focus-outline-color: #64748b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(100, 116, 139, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(100, 116, 139, 0.87);
}
.svc-006400 .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #c00000;
  --mdc-filled-text-field-focus-active-indicator-color: #c00000;
  --mdc-filled-text-field-focus-label-text-color: rgba(192, 0, 0, 0.87);
  --mdc-outlined-text-field-caret-color: #c00000;
  --mdc-outlined-text-field-focus-outline-color: #c00000;
  --mdc-outlined-text-field-focus-label-text-color: rgba(192, 0, 0, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(192, 0, 0, 0.87);
}
.svc-006400 .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(100, 116, 139, 0.87);
  --mat-select-invalid-arrow-color: rgba(192, 0, 0, 0.87);
}
.svc-006400 .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(192, 0, 0, 0.87);
  --mat-select-invalid-arrow-color: rgba(192, 0, 0, 0.87);
}
.svc-006400 .mat-mdc-standard-chip {
  --mdc-chip-container-shape-radius: 16px;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}
.svc-006400 .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.svc-006400 .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .svc-006400 .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: darkgreen;
  --mdc-chip-elevated-selected-container-color: darkgreen;
  --mdc-chip-elevated-disabled-container-color: darkgreen;
  --mdc-chip-flat-disabled-selected-container-color: darkgreen;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.svc-006400 .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .svc-006400 .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #64748b;
  --mdc-chip-elevated-selected-container-color: #64748b;
  --mdc-chip-elevated-disabled-container-color: #64748b;
  --mdc-chip-flat-disabled-selected-container-color: #64748b;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.svc-006400 .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .svc-006400 .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: #fef2f2;
  --mdc-chip-elevated-container-color: #c00000;
  --mdc-chip-elevated-selected-container-color: #c00000;
  --mdc-chip-elevated-disabled-container-color: #c00000;
  --mdc-chip-flat-disabled-selected-container-color: #c00000;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #fef2f2;
  --mdc-chip-selected-label-text-color: #fef2f2;
  --mdc-chip-with-icon-icon-color: #fef2f2;
  --mdc-chip-with-icon-disabled-icon-color: #fef2f2;
  --mdc-chip-with-icon-selected-icon-color: #fef2f2;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fef2f2;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fef2f2;
  --mat-chip-selected-disabled-trailing-icon-color: #fef2f2;
  --mat-chip-selected-trailing-icon-color: #fef2f2;
}
.svc-006400 .mat-mdc-slide-toggle {
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-selected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}
.svc-006400 .mat-mdc-slide-toggle {
  --mat-switch-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-006400 .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #475569;
  --mdc-switch-selected-handle-color: #475569;
  --mdc-switch-selected-hover-state-layer-color: #475569;
  --mdc-switch-selected-pressed-state-layer-color: #475569;
  --mdc-switch-selected-focus-handle-color: #0f172a;
  --mdc-switch-selected-hover-handle-color: #0f172a;
  --mdc-switch-selected-pressed-handle-color: #0f172a;
  --mdc-switch-selected-focus-track-color: #cbd5e1;
  --mdc-switch-selected-hover-track-color: #cbd5e1;
  --mdc-switch-selected-pressed-track-color: #cbd5e1;
  --mdc-switch-selected-track-color: #cbd5e1;
}
.svc-006400 .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #dc2626;
  --mdc-switch-selected-handle-color: #dc2626;
  --mdc-switch-selected-hover-state-layer-color: #dc2626;
  --mdc-switch-selected-pressed-state-layer-color: #dc2626;
  --mdc-switch-selected-focus-handle-color: #7f1d1d;
  --mdc-switch-selected-hover-handle-color: #7f1d1d;
  --mdc-switch-selected-pressed-handle-color: #7f1d1d;
  --mdc-switch-selected-focus-track-color: #fca5a5;
  --mdc-switch-selected-hover-track-color: #fca5a5;
  --mdc-switch-selected-pressed-track-color: #fca5a5;
  --mdc-switch-selected-track-color: #fca5a5;
}
.svc-006400 .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: darkgreen;
  --mdc-radio-selected-hover-icon-color: darkgreen;
  --mdc-radio-selected-icon-color: darkgreen;
  --mdc-radio-selected-pressed-icon-color: darkgreen;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: darkgreen;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-006400 .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #64748b;
  --mdc-radio-selected-hover-icon-color: #64748b;
  --mdc-radio-selected-icon-color: #64748b;
  --mdc-radio-selected-pressed-icon-color: #64748b;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #64748b;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-006400 .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #c00000;
  --mdc-radio-selected-hover-icon-color: #c00000;
  --mdc-radio-selected-icon-color: #c00000;
  --mdc-radio-selected-pressed-icon-color: #c00000;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #c00000;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.svc-006400 .mat-accent {
  --mdc-slider-handle-color: #64748b;
  --mdc-slider-focus-handle-color: #64748b;
  --mdc-slider-hover-handle-color: #64748b;
  --mdc-slider-active-track-color: #64748b;
  --mdc-slider-inactive-track-color: #64748b;
  --mdc-slider-with-tick-marks-inactive-container-color: #64748b;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mat-slider-ripple-color: #64748b;
  --mat-slider-hover-state-layer-color: rgba(100, 116, 139, 0.05);
  --mat-slider-focus-state-layer-color: rgba(100, 116, 139, 0.2);
}
.svc-006400 .mat-warn {
  --mdc-slider-handle-color: #c00000;
  --mdc-slider-focus-handle-color: #c00000;
  --mdc-slider-hover-handle-color: #c00000;
  --mdc-slider-active-track-color: #c00000;
  --mdc-slider-inactive-track-color: #c00000;
  --mdc-slider-with-tick-marks-inactive-container-color: #c00000;
  --mdc-slider-with-tick-marks-active-container-color: #fef2f2;
  --mat-slider-ripple-color: #c00000;
  --mat-slider-hover-state-layer-color: rgba(192, 0, 0, 0.05);
  --mat-slider-focus-state-layer-color: rgba(192, 0, 0, 0.2);
}
.svc-006400 .mdc-list-item__start,
.svc-006400 .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: darkgreen;
  --mdc-radio-selected-hover-icon-color: darkgreen;
  --mdc-radio-selected-icon-color: darkgreen;
  --mdc-radio-selected-pressed-icon-color: darkgreen;
}
.svc-006400 .mat-accent .mdc-list-item__start,
.svc-006400 .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #64748b;
  --mdc-radio-selected-hover-icon-color: #64748b;
  --mdc-radio-selected-icon-color: #64748b;
  --mdc-radio-selected-pressed-icon-color: #64748b;
}
.svc-006400 .mat-warn .mdc-list-item__start,
.svc-006400 .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #c00000;
  --mdc-radio-selected-hover-icon-color: #c00000;
  --mdc-radio-selected-icon-color: #c00000;
  --mdc-radio-selected-pressed-icon-color: #c00000;
}
.svc-006400 .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: darkgreen;
  --mdc-checkbox-selected-hover-icon-color: darkgreen;
  --mdc-checkbox-selected-icon-color: darkgreen;
  --mdc-checkbox-selected-pressed-icon-color: darkgreen;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: darkgreen;
  --mdc-checkbox-selected-hover-state-layer-color: darkgreen;
  --mdc-checkbox-selected-pressed-state-layer-color: darkgreen;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-006400 .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #64748b;
  --mdc-checkbox-selected-hover-icon-color: #64748b;
  --mdc-checkbox-selected-icon-color: #64748b;
  --mdc-checkbox-selected-pressed-icon-color: #64748b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #64748b;
  --mdc-checkbox-selected-hover-state-layer-color: #64748b;
  --mdc-checkbox-selected-pressed-state-layer-color: #64748b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-006400 .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fef2f2;
  --mdc-checkbox-selected-focus-icon-color: #c00000;
  --mdc-checkbox-selected-hover-icon-color: #c00000;
  --mdc-checkbox-selected-icon-color: #c00000;
  --mdc-checkbox-selected-pressed-icon-color: #c00000;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #c00000;
  --mdc-checkbox-selected-hover-state-layer-color: #c00000;
  --mdc-checkbox-selected-pressed-state-layer-color: #c00000;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-006400 .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.svc-006400 .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__start,
.svc-006400 .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.svc-006400 .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__start {
  color: darkgreen;
}
.svc-006400 .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.svc-006400 .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.svc-006400 .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.svc-006400 .mat-mdc-tab-group, .svc-006400 .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: darkgreen;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: darkgreen;
  --mat-tab-header-active-ripple-color: darkgreen;
  --mat-tab-header-inactive-ripple-color: darkgreen;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: darkgreen;
  --mat-tab-header-active-hover-label-text-color: darkgreen;
  --mat-tab-header-active-focus-indicator-color: darkgreen;
  --mat-tab-header-active-hover-indicator-color: darkgreen;
}
.svc-006400 .mat-mdc-tab-group.mat-accent, .svc-006400 .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #64748b;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #64748b;
  --mat-tab-header-active-ripple-color: #64748b;
  --mat-tab-header-inactive-ripple-color: #64748b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #64748b;
  --mat-tab-header-active-hover-label-text-color: #64748b;
  --mat-tab-header-active-focus-indicator-color: #64748b;
  --mat-tab-header-active-hover-indicator-color: #64748b;
}
.svc-006400 .mat-mdc-tab-group.mat-warn, .svc-006400 .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #c00000;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #c00000;
  --mat-tab-header-active-ripple-color: #c00000;
  --mat-tab-header-inactive-ripple-color: #c00000;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #c00000;
  --mat-tab-header-active-hover-label-text-color: #c00000;
  --mat-tab-header-active-focus-indicator-color: #c00000;
  --mat-tab-header-active-hover-indicator-color: #c00000;
}
.svc-006400 .mat-mdc-tab-group.mat-background-primary, .svc-006400 .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: darkgreen;
  --mat-tab-header-with-background-foreground-color: white;
}
.svc-006400 .mat-mdc-tab-group.mat-background-accent, .svc-006400 .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #64748b;
  --mat-tab-header-with-background-foreground-color: white;
}
.svc-006400 .mat-mdc-tab-group.mat-background-warn, .svc-006400 .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #c00000;
  --mat-tab-header-with-background-foreground-color: #fef2f2;
}
.svc-006400 .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: darkgreen;
  --mdc-checkbox-selected-hover-icon-color: darkgreen;
  --mdc-checkbox-selected-icon-color: darkgreen;
  --mdc-checkbox-selected-pressed-icon-color: darkgreen;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: darkgreen;
  --mdc-checkbox-selected-hover-state-layer-color: darkgreen;
  --mdc-checkbox-selected-pressed-state-layer-color: darkgreen;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-006400 .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fef2f2;
  --mdc-checkbox-selected-focus-icon-color: #c00000;
  --mdc-checkbox-selected-hover-icon-color: #c00000;
  --mdc-checkbox-selected-icon-color: #c00000;
  --mdc-checkbox-selected-pressed-icon-color: #c00000;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #c00000;
  --mdc-checkbox-selected-hover-state-layer-color: #c00000;
  --mdc-checkbox-selected-pressed-state-layer-color: #c00000;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.svc-006400 .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: darkgreen;
  --mat-text-button-state-layer-color: darkgreen;
  --mat-text-button-ripple-color: rgba(0, 100, 0, 0.1);
}
.svc-006400 .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #64748b;
  --mat-text-button-state-layer-color: #64748b;
  --mat-text-button-ripple-color: rgba(100, 116, 139, 0.1);
}
.svc-006400 .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #c00000;
  --mat-text-button-state-layer-color: #c00000;
  --mat-text-button-ripple-color: rgba(192, 0, 0, 0.1);
}
.svc-006400 .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: darkgreen;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-006400 .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #64748b;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-006400 .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #c00000;
  --mdc-filled-button-label-text-color: #fef2f2;
  --mat-filled-button-state-layer-color: #fef2f2;
  --mat-filled-button-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-006400 .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: darkgreen;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-006400 .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #64748b;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-006400 .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #c00000;
  --mdc-protected-button-label-text-color: #fef2f2;
  --mat-protected-button-state-layer-color: #fef2f2;
  --mat-protected-button-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-006400 .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: darkgreen;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: darkgreen;
  --mat-outlined-button-ripple-color: rgba(0, 100, 0, 0.1);
}
.svc-006400 .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #64748b;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #64748b;
  --mat-outlined-button-ripple-color: rgba(100, 116, 139, 0.1);
}
.svc-006400 .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #c00000;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #c00000;
  --mat-outlined-button-ripple-color: rgba(192, 0, 0, 0.1);
}
.svc-006400 .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: darkgreen;
  --mat-icon-button-state-layer-color: darkgreen;
  --mat-icon-button-ripple-color: rgba(0, 100, 0, 0.1);
}
.svc-006400 .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #64748b;
  --mat-icon-button-state-layer-color: #64748b;
  --mat-icon-button-ripple-color: rgba(100, 116, 139, 0.1);
}
.svc-006400 .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #c00000;
  --mat-icon-button-state-layer-color: #c00000;
  --mat-icon-button-ripple-color: rgba(192, 0, 0, 0.1);
}
.svc-006400 .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: darkgreen;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-006400 .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #64748b;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-006400 .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #c00000;
  --mat-fab-foreground-color: #fef2f2;
  --mat-fab-state-layer-color: #fef2f2;
  --mat-fab-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-006400 .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: darkgreen;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-006400 .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #64748b;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.svc-006400 .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #c00000;
  --mat-fab-small-foreground-color: #fef2f2;
  --mat-fab-small-state-layer-color: #fef2f2;
  --mat-fab-small-ripple-color: rgba(254, 242, 242, 0.1);
}
.svc-006400 .mat-accent {
  --mdc-circular-progress-active-indicator-color: #64748b;
}
.svc-006400 .mat-warn {
  --mdc-circular-progress-active-indicator-color: #c00000;
}
.svc-006400 .mat-badge-accent {
  --mat-badge-background-color: #64748b;
  --mat-badge-text-color: white;
}
.svc-006400 .mat-badge-warn {
  --mat-badge-background-color: #c00000;
  --mat-badge-text-color: #fef2f2;
}
.svc-006400 .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #64748b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(100, 116, 139, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(100, 116, 139, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(100, 116, 139, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(100, 116, 139, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.svc-006400 .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: #fef2f2;
  --mat-datepicker-calendar-date-selected-state-background-color: #c00000;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(192, 0, 0, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #fef2f2;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(192, 0, 0, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(192, 0, 0, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(192, 0, 0, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.svc-006400 .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #64748b;
}
.svc-006400 .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #c00000;
}
.svc-006400 .mat-icon.mat-primary {
  --mat-icon-color: darkgreen;
}
.svc-006400 .mat-icon.mat-accent {
  --mat-icon-color: #64748b;
}
.svc-006400 .mat-icon.mat-warn {
  --mat-icon-color: #c00000;
}
.svc-006400 .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #64748b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #64748b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #64748b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.svc-006400 .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: #fef2f2;
  --mat-stepper-header-selected-state-icon-background-color: #c00000;
  --mat-stepper-header-selected-state-icon-foreground-color: #fef2f2;
  --mat-stepper-header-done-state-icon-background-color: #c00000;
  --mat-stepper-header-done-state-icon-foreground-color: #fef2f2;
  --mat-stepper-header-edit-state-icon-background-color: #c00000;
  --mat-stepper-header-edit-state-icon-foreground-color: #fef2f2;
}
.svc-006400 .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: darkgreen;
  --mat-toolbar-container-text-color: white;
}
.svc-006400 .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #64748b;
  --mat-toolbar-container-text-color: white;
}
.svc-006400 .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #c00000;
  --mat-toolbar-container-text-color: #fef2f2;
}

@media print {
  body * {
    visibility: hidden;
  }
  #printable * {
    visibility: visible;
    print-color-adjust: exact;
  }
  .printable-part,
  .printable-part * {
    visibility: visible;
    print-color-adjust: exact;
  }
  .page-break {
    page-break-after: always;
  }
  .page-avoid-break {
    page-break-inside: avoid;
  }
}
#notification-modal-integration {
  position: fixed;
  z-index: 99999;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.fadeIn,
.fadeOut {
  animation-duration: 300ms;
  animation-fill-mode: both;
}

.fadeIn {
  animation-name: fadeIn;
}

.fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.droplist-shimmer {
  width: 100%;
  max-width: 200px;
  height: 22px;
  background: #f6f7f8;
  border-radius: 5px;
  background-image: linear-gradient(to right, #f6f7f8 0%, #FFFFFF 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: droplsitShimmer;
  animation-timing-function: linear;
}

@keyframes droplsitShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
/* ----------------------------------------------------------------------------------------------------- */
/*  @ Quill editor overrides
/* ----------------------------------------------------------------------------------------------------- */
.ql-toolbar {
  border-radius: 6px 6px 0 0;
  padding: 0 !important;
  @apply bg-gray-100;
  @apply border-gray-300 border-opacity-100 !important;
}
.dark .ql-toolbar {
  background-color: rgba(0, 0, 0, 0.05);
  @apply border-gray-500 !important;
}
.ql-toolbar .ql-formats {
  margin: 11px 8px !important;
}
.ql-toolbar .ql-picker.ql-expanded .ql-picker-label {
  @apply border-gray-300;
}
.dark .ql-toolbar .ql-picker.ql-expanded .ql-picker-label {
  @apply border-gray-500;
}
.ql-toolbar .ql-picker.ql-expanded .ql-picker-options {
  z-index: 10 !important;
  @apply border-gray-300 bg-card;
}
.dark .ql-toolbar .ql-picker.ql-expanded .ql-picker-options {
  @apply border-gray-500;
}
.ql-toolbar .ql-picker .ql-picker-label {
  @apply text-default;
}
.ql-toolbar .ql-picker .ql-picker-options .ql-picker-item {
  @apply text-default;
}
.ql-toolbar .ql-stroke,
.ql-toolbar .ql-stroke-mitter {
  stroke: #000;
}
.ql-toolbar .ql-fill {
  fill: #000;
}
.ql-toolbar button:hover,
.ql-toolbar button:focus,
.ql-toolbar button.ql-active,
.ql-toolbar .ql-picker-label:hover,
.ql-toolbar .ql-picker-label.ql-active,
.ql-toolbar .ql-picker-item:hover,
.ql-toolbar .ql-picker-item.ql-selected {
  @apply text-primary !important;
}
.ql-toolbar button:hover .ql-stroke,
.ql-toolbar button:hover .ql-stroke-mitter,
.ql-toolbar button:focus .ql-stroke,
.ql-toolbar button:focus .ql-stroke-mitter,
.ql-toolbar button.ql-active .ql-stroke,
.ql-toolbar button.ql-active .ql-stroke-mitter,
.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-toolbar .ql-picker-label:hover .ql-stroke-mitter,
.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-toolbar .ql-picker-label.ql-active .ql-stroke-mitter,
.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-toolbar .ql-picker-item:hover .ql-stroke-mitter,
.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-mitter {
  stroke: var(--svc-color-primary) !important;
}
.ql-toolbar button:hover .ql-fill,
.ql-toolbar button:focus .ql-fill,
.ql-toolbar button.ql-active .ql-fill,
.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
  fill: var(--svc-color-primary) !important;
}

.ql-container {
  overflow: auto;
  min-height: 160px;
  max-height: 400px;
  border-radius: 0 0 6px 6px;
  @apply border-gray-300 border-opacity-100 shadow-sm !important;
}
.dark .ql-container {
  @apply border-gray-500 !important;
}
.ql-container .ql-editor {
  word-wrap: break-word;
  word-break: break-word;
}
.ql-container .ql-editor.ql-blank::before {
  @apply text-hint;
}
.ql-container .ql-tooltip {
  @apply px-3 py-1 shadow-sm rounded-md bg-gray-100 border-gray-300;
}
.dark .ql-container .ql-tooltip {
  @apply shadow-lg bg-gray-700 border-gray-700 !important;
}
.ql-container .ql-tooltip:before {
  @apply text-secondary;
}
.ql-container .ql-tooltip .ql-action,
.ql-container .ql-tooltip .ql-remove {
  @apply text-primary border-gray-300;
}
.dark .ql-container .ql-tooltip .ql-action,
.dark .ql-container .ql-tooltip .ql-remove {
  @apply text-primary-400 border-gray-300;
}
.ql-container .ql-tooltip .ql-action:after {
  @apply border-r border-r-gray-300 !important;
}
.dark .ql-container .ql-tooltip .ql-action:after {
  @apply border-r-gray-500 !important;
}
.ql-container .ql-tooltip input {
  @apply rounded-sm text-default bg-white border-gray-300 !important;
}
.dark .ql-container .ql-tooltip input {
  @apply bg-gray-700 border-gray-500 !important;
}

.mentions-editor .mentions-container .mention {
  display: inline;
  font-weight: 500 !important;
  color: var(--svc-color-primary) !important;
  background-color: var(--svc-bg-card);
}
.mentions-editor .mentions-container .mention[data-denotation-char="#"] {
  color: var(--svc-color-primary-700) !important;
  background-color: var(--svc-color-primary-200) !important;
}

.mention-editor-list .ql-mention-list-item {
  @apply leading-tight text-base text-default font-normal py-2 pl-2 pr-2;
}
.mention-editor-list .ql-mention-list-item.selected {
  @apply bg-gray-100;
}
.mention-editor-list .ql-mention-list-item.disabled {
  @apply text-base text-default font-bold border-y border-gray-300 pl-2 py-2;
}
.mention-editor-list .ql-mention-list-item.disabled[data-target=level-1] ~ .ql-mention-list-item:not(.disabled) {
  @apply pl-4;
}
.mention-editor-list .ql-mention-list-item.disabled ~ .ql-mention-list-item.disabled[data-target=level-2] {
  @apply font-normal border-y-0 pl-6;
}
.mention-editor-list .ql-mention-list-item.disabled ~ .ql-mention-list-item.disabled[data-target=level-2] ~ .ql-mention-list-item:not(.disabled) {
  @apply pl-10;
}
.mention-editor-list .ql-mention-list-item:first-child {
  @apply border-t-0;
}

.ql-mention-list-container {
  width: 400px;
}
.ql-mention-list-container .ql-mention-loading {
  @apply text-md;
  padding: 10px 20px;
  line-height: 1rem;
  color: var(--svc-text-default);
}

/* -------------------------------------------------------------------------- */
/*  @ Overlay
/* -------------------------------------------------------------------------- */
.svc-backdrop-on-mobile {
  @apply bg-black bg-opacity-60 sm:bg-transparent !important;
}

.svc-backdrop-on-tablet {
  @apply bg-black bg-opacity-60 md:bg-transparent !important;
}

/* -------------------------------------------------------------------------- */
/*  @ Font smoothing
/* -------------------------------------------------------------------------- */
*[class*=mat-],
*[class*=mat-mdc-] {
  -webkit-font-smoothing: auto !important;
  -moz-osx-font-smoothing: auto !important;
}
*[class*=mat-] *,
*[class*=mat-mdc-] * {
  -webkit-font-smoothing: auto !important;
  -moz-osx-font-smoothing: auto !important;
}

/* -------------------------------------------------------------------------- */
/*  @ Accordion
/* -------------------------------------------------------------------------- */
.mat-accordion .mat-expansion-panel {
  margin-bottom: 24px;
  border-radius: 8px !important;
  transition: box-shadow 225ms cubic-bezier(0.4, 0, 0.2, 1);
  @apply shadow !important;
}
.mat-accordion .mat-expansion-panel:last-child {
  margin-bottom: 0;
}
.mat-accordion .mat-expansion-panel.mat-expanded, .mat-accordion .mat-expansion-panel:hover {
  @apply shadow-lg !important;
}
.mat-accordion .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled=true]).cdk-keyboard-focused, .mat-accordion .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled=true]).cdk-program-focused, .mat-accordion .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled=true]):hover {
  background: transparent !important;
}
.mat-accordion .mat-expansion-panel .mat-expansion-panel-header {
  font-size: 14px;
}
.mat-accordion .mat-expansion-panel .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  margin-right: 28px;
}
.mat-accordion .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-indicator {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
  /* Do not override the border color of the expansion panel indicator */
}
.mat-accordion .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-indicator:after {
  border-color: currentColor !important;
}
.mat-accordion .mat-expansion-panel .mat-expansion-panel-body {
  @apply text-secondary !important;
}

/* -------------------------------------------------------------------------- */
/*  @ Buttons
/* -------------------------------------------------------------------------- */
.mat-mdc-button,
.mat-mdc-raised-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button,
.mat-mdc-icon-button,
.mat-mdc-fab,
.mat-mdc-mini-fab {
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  line-height: 1 !important;
  /* Large button */
  /* Lower the icon opacity on disabled buttons */
}
.mat-mdc-button.avatars-mat-button-large,
.mat-mdc-raised-button.avatars-mat-button-large,
.mat-mdc-outlined-button.avatars-mat-button-large,
.mat-mdc-unelevated-button.avatars-mat-button-large,
.mat-mdc-icon-button.avatars-mat-button-large,
.mat-mdc-fab.avatars-mat-button-large,
.mat-mdc-mini-fab.avatars-mat-button-large {
  height: 48px;
  min-height: 48px;
  max-height: 48px;
}
.mat-mdc-button[disabled=true]:not([class*="disabled:opacity-"]) .mat-icon,
.mat-mdc-raised-button[disabled=true]:not([class*="disabled:opacity-"]) .mat-icon,
.mat-mdc-outlined-button[disabled=true]:not([class*="disabled:opacity-"]) .mat-icon,
.mat-mdc-unelevated-button[disabled=true]:not([class*="disabled:opacity-"]) .mat-icon,
.mat-mdc-icon-button[disabled=true]:not([class*="disabled:opacity-"]) .mat-icon,
.mat-mdc-fab[disabled=true]:not([class*="disabled:opacity-"]) .mat-icon,
.mat-mdc-mini-fab[disabled=true]:not([class*="disabled:opacity-"]) .mat-icon {
  opacity: 0.38 !important;
}
.mat-mdc-button .mat-mdc-button-touch-target,
.mat-mdc-raised-button .mat-mdc-button-touch-target,
.mat-mdc-outlined-button .mat-mdc-button-touch-target,
.mat-mdc-unelevated-button .mat-mdc-button-touch-target,
.mat-mdc-icon-button .mat-mdc-button-touch-target,
.mat-mdc-fab .mat-mdc-button-touch-target,
.mat-mdc-mini-fab .mat-mdc-button-touch-target {
  height: inherit !important;
}

/* Icon buttons */
.mat-mdc-icon-button {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  width: 40px !important;
  padding: 0 !important;
}
.mat-mdc-icon-button svg,
.mat-mdc-icon-button img {
  height: auto !important;
}

/* FAB buttons */
.mat-mdc-fab {
  max-height: 56px;
  border-radius: 16px !important;
}
.mat-mdc-fab:not(.mdc-fab--extended) .mdc-fab__ripple {
  border-radius: 16px !important;
}

/* Mini FAB buttons */
.mat-mdc-mini-fab {
  border-radius: 12px !important;
}
.mat-mdc-mini-fab:not(.mdc-fab--extended) .mdc-fab__ripple {
  border-radius: 12px !important;
}
.mat-mdc-mini-fab.no-hover-shadow:hover, .mat-mdc-mini-fab.no-hover-shadow:focus, .mat-mdc-mini-fab.no-hover-shadow:hover, .mat-mdc-mini-fab.no-hover-shadow:focus {
  box-shadow: none !important;
}

/* Rounded design */
.mat-mdc-raised-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button {
  padding: 0 20px;
  border-radius: 9999px !important;
}

/* Fix the alignment of icons when used within buttons */
.mat-mdc-button > .mat-icon,
.mat-mdc-raised-button > .mat-icon,
.mat-mdc-outlined-button > .mat-icon,
.mat-mdc-unelevated-button > .mat-icon {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

/* Adjust the color of mat-progress-spinner when used within indeterminate */
.mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-container circle {
  stroke: currentColor !important;
  animation-duration: 6000ms;
}

/* Adjust the focus, ripple and icon colors of colored background buttons */
.mat-mdc-raised-button,
.mat-mdc-unelevated-button,
.mat-mdc-fab,
.mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: theme("colors.gray[400]") !important;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1) !important;
}
.dark .mat-mdc-raised-button,
.dark .mat-mdc-unelevated-button,
.dark .mat-mdc-fab,
.dark .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: theme("colors.black") !important;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1) !important;
}
.mat-mdc-raised-button .mat-icon,
.mat-mdc-unelevated-button .mat-icon,
.mat-mdc-fab .mat-icon,
.mat-mdc-mini-fab .mat-icon {
  color: currentColor !important;
}
.mat-mdc-raised-button .mat-ripple-element,
.mat-mdc-unelevated-button .mat-ripple-element,
.mat-mdc-fab .mat-ripple-element,
.mat-mdc-mini-fab .mat-ripple-element {
  @apply bg-black/10 !important;
}

/* Color the icons of transparent background buttons */
.mat-mdc-button:not([disabled=true]),
.mat-mdc-icon-button:not([disabled=true]),
.mat-mdc-outlined-button:not([disabled=true]) {
  /* Apply primary color */
  /* Apply accent color */
  /* Apply warn color */
}
.mat-mdc-button:not([disabled=true]).mat-primary .mat-icon,
.mat-mdc-icon-button:not([disabled=true]).mat-primary .mat-icon,
.mat-mdc-outlined-button:not([disabled=true]).mat-primary .mat-icon {
  @apply text-primary;
}
.mat-mdc-button:not([disabled=true]).mat-accent .mat-icon,
.mat-mdc-icon-button:not([disabled=true]).mat-accent .mat-icon,
.mat-mdc-outlined-button:not([disabled=true]).mat-accent .mat-icon {
  @apply text-accent;
}
.mat-mdc-button:not([disabled=true]).mat-warn .mat-icon,
.mat-mdc-icon-button:not([disabled=true]).mat-warn .mat-icon,
.mat-mdc-outlined-button:not([disabled=true]).mat-warn .mat-icon {
  @apply text-warn;
}

/* Adjust the border color of outlined buttons */
.mat-mdc-outlined-button {
  /* Not disabled */
  /* Disabled */
}
.mat-mdc-outlined-button:not([disabled=true]).mat-primary {
  @apply border-primary-500 !important;
}
.mat-mdc-outlined-button:not([disabled=true]).mat-accent {
  @apply border-accent-500 !important;
}
.mat-mdc-outlined-button:not([disabled=true]).mat-warn {
  @apply border-warn-500 !important;
}
.mat-mdc-outlined-button:not([disabled=true]).mat-on-primary {
  @apply border-on-primary-500 !important;
  --mdc-outlined-button-label-text-color: var(--svc-color-on-primary);
}
.mat-mdc-outlined-button[disabled=true] {
  @apply border-gray-300/70 !important;
}

/* .mat-mdc-unelevated-button.mat-primary {
    --mdc-filled-button-label-text-color: #FFF !important;
} */
/* Don't wrap the button label text */
.mdc-button .mdc-button__label {
  white-space: nowrap;
}
.mdc-button .mat-mdc-unelevated-button:not(:disabled) {
  color: var(--mdc-filled-button-label-text-color);
}
.mdc-button.mat-mdc-unelevated-button:not(:disabled).mat-on-primary {
  --mdc-filled-button-container-color: var(--svc-color-on-primary);
  --mdc-filled-button-label-text-color: var(--svc-color-primary);
}

.mat-mdc-button:not(:disabled).mat-on-primary {
  --mdc-text-button-label-text-color: var(--svc-color-on-primary);
}

/* -------------------------------------------------------------------------- */
/*  @ Button Toggle
/* -------------------------------------------------------------------------- */
.mat-button-toggle-group {
  border: none !important;
  @apply space-x-1;
}
.mat-button-toggle-group.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  background-clip: padding-box;
}
.mat-button-toggle-group .mat-button-toggle {
  border-radius: 4px !important;
  border-width: 1px !important;
  border-style: solid !important;
}
.mat-button-toggle-group .mat-button-toggle .mat-pseudo-checkbox {
  display: none;
}
.mat-button-toggle-group .mat-button-toggle .mat-button-toggle-label-content {
  padding: 0px 3px !important;
  line-height: 30px !important;
}
.mat-button-toggle-group .mat-button-toggle .mat-icon {
  margin-top: -1px;
}
.mat-button-toggle-group .mat-button-toggle:not(.mat-button-toggle-disabled) {
  @apply border-primary !important;
  @apply bg-white !important;
}
.mat-button-toggle-group .mat-button-toggle:not(.mat-button-toggle-disabled).mat-button-toggle-checked {
  @apply bg-primary !important;
}
.mat-button-toggle-group .mat-button-toggle:not(.mat-button-toggle-disabled).mat-button-toggle-checked .mat-button-toggle-label-content {
  @apply text-on-primary !important;
}
.mat-button-toggle-group .mat-button-toggle:not(.mat-button-toggle-disabled).mat-button-toggle-checked .mat-icon {
  @apply text-on-primary !important;
}
.mat-button-toggle-group .mat-button-toggle:not(.mat-button-toggle-disabled) .mat-button-toggle-label-content {
  @apply text-primary !important;
}
.mat-button-toggle-group .mat-button-toggle:not(.mat-button-toggle-disabled) .mat-icon {
  @apply text-primary !important;
}
.mat-button-toggle-group .mat-button-toggle.mat-button-toggle-disabled.mat-button-toggle-checked {
  @apply bg-gray-200 !important;
}

/* -------------------------------------------------------------------------- */
/*  @ Checkbox
/* -------------------------------------------------------------------------- */
.mat-mdc-checkbox {
  display: inline-flex !important;
}
.mat-mdc-checkbox .mdc-form-field {
  padding-right: 12px;
  @apply text-default !important;
}
.mat-mdc-checkbox .mdc-form-field > label {
  padding-left: 0 !important;
  @apply text-sm !important;
  @apply font-semibold !important;
}
.mat-mdc-checkbox .mdc-checkbox__background {
  border-radius: 4px !important;
  border-width: 1px !important;
  @apply border-text-default !important;
}
.mat-mdc-checkbox .mdc-checkbox__background > svg {
  width: 12px;
  height: 12px;
  top: 50% !important;
  left: 50% !important;
  right: auto !important;
  bottom: auto !important;
  transform: translate(-50%, -50%) !important;
}
.mat-mdc-checkbox .mdc-checkbox__native-control {
  opacity: 0 !important;
}
.mat-mdc-checkbox.mat-mdc-checkbox-checked .mdc-checkbox__background {
  @apply border-primary !important;
  @apply bg-transparent !important;
}
.mat-mdc-checkbox.mat-mdc-checkbox-checked .mdc-checkbox__background > svg {
  @apply text-primary !important;
}
.mat-mdc-checkbox.mat-mdc-checkbox-disabled .mdc-checkbox__background {
  @apply border-gray-400 !important;
  @apply bg-transparent !important;
}
.mat-mdc-checkbox.mat-mdc-checkbox-disabled .mdc-checkbox__background > svg {
  @apply text-gray-400 !important;
}
.mat-mdc-checkbox .mdc-checkbox {
  align-self: start;
}
.mat-mdc-checkbox .mdc-checkbox ~ label {
  word-wrap: break-word;
  white-space: initial;
  word-break: break-all;
}

/* -------------------------------------------------------------------------- */
/*  @ SlideToggle
/* -------------------------------------------------------------------------- */
.mat-mdc-slide-toggle .mdc-form-field .mdc-switch + label:empty {
  display: none;
}

/* -------------------------------------------------------------------------- */
/*  @ Chip
/* -------------------------------------------------------------------------- */
.mat-mdc-chip-set .mdc-evolution-chip-set__chips {
  max-width: 100% !important;
}

.mat-mdc-chip {
  height: auto !important;
  border-radius: 8px !important;
  font-weight: 400 !important;
  border-radius: 8px !important;
  background: #E4E7EA !important;
}
.mat-mdc-chip .mat-mdc-chip-action {
  padding-left: 4px !important;
}
.mat-mdc-chip .mat-mdc-chip-action::before {
  border: none !important;
  border-radius: none !important;
}
.mat-mdc-chip .mat-mdc-chip-remove {
  padding-right: 2px !important;
  opacity: 1 !important;
}
.mat-mdc-chip.mat-mdc-chip-disabled .mdc-evolution-chip__text-label {
  color: var(--svc-text-default) !important;
}
.mat-mdc-chip.mat-mdc-standard-chip {
  font-size: 1rem !important;
}
.mat-mdc-chip.mat-mdc-standard-chip .mdc-evolution-chip__cell--primary,
.mat-mdc-chip.mat-mdc-standard-chip .mdc-evolution-chip__action--primary,
.mat-mdc-chip.mat-mdc-standard-chip .mat-mdc-chip-action-label {
  overflow: hidden !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
}
.mat-mdc-chip.mat-mdc-standard-chip.mdc-evolution-chip--disabled {
  opacity: 1 !important;
}

/* -------------------------------------------------------------------------- */
/*  @ Dialog
/* -------------------------------------------------------------------------- */
.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 8px !important;
  padding: 0 !important;
}
.mat-mdc-dialog-container .mdc-dialog__title::before {
  height: auto !important;
  content: none !important;
}
.mat-mdc-dialog-container .mdc-dialog__content {
  padding: 0 !important;
}

.mat-dialog-fullscreen .cdk-overlay-pane,
.mat-dialog-fullscreen .cdk-overlay-pane .mat-mdc-dialog-container {
  padding: 0;
  height: 100% !important;
  width: 100% !important;
  max-width: 100% !important;
}
.mat-dialog-fullscreen .mat-mdc-dialog-surface {
  border-radius: 0 !important;
  box-shadow: none !important;
}

.cdk-global-overlay-wrapper {
  overflow: auto;
}

/* -------------------------------------------------------------------------- */
/*  @ Drawer
/* -------------------------------------------------------------------------- */
.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6) !important;
}

/* -------------------------------------------------------------------------- */
/*  @ Form fields
/* -------------------------------------------------------------------------- */
/* "fill" appearance */
.mat-mdc-form-field.mat-form-field-appearance-fill {
  /* Disabled */
  /* Invalid */
  /* Hover */
  /* Focused */
  /* Remove the default arrow for native select */
  /* Default style tweaks and enhancements */
  /* Subscript tweaks */
  /* Adds better alignment for textarea inputs */
  /* Rounded */
  /* Dense */
  /* Emphasized affix */
  /* Bolder border width */
}
.mat-mdc-form-field.mat-form-field-appearance-fill .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  @apply text-default;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.mat-form-field-disabled .mat-mdc-text-field-wrapper, .mat-mdc-form-field.mat-form-field-appearance-fill.mat-form-field-disable .mat-mdc-text-field-wrapper {
  @apply bg-zinc-100 !important;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.mat-form-field-disabled .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex, .mat-mdc-form-field.mat-form-field-appearance-fill.mat-form-field-disable .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
  opacity: 0.7 !important;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.mat-form-field-invalid {
  /* Select */
}
.mat-mdc-form-field.mat-form-field-appearance-fill.mat-form-field-invalid .mat-mdc-select {
  /* Placeholder color */
}
.mat-mdc-form-field.mat-form-field-appearance-fill.mat-form-field-invalid .mat-mdc-select .mat-mdc-select-placeholder {
  @apply text-warn !important;
}
.mat-mdc-form-field.mat-form-field-appearance-fill:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0 !important;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0 !important;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix select {
  top: auto;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-right: 18px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2364748B' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5H7z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right -7px center;
  background-size: 24px;
}
.dark .mat-mdc-form-field.mat-form-field-appearance-fill.mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix select {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2397a6ba' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5H7z'/%3E%3C/svg%3E");
}
.mat-mdc-form-field.mat-form-field-appearance-fill.mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix:after {
  display: none;
}
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper {
  padding: 0;
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  @apply shadow-sm bg-white border-gray-300 !important;
  /* Adjust the top spacing and overflow when mat-label present */
  /* Form field */
  /* Remove the underline */
}
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper:not(.mdc-text-field--no-label) {
  margin-top: 24px;
  overflow: visible;
}
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper.mdc-text-field--focused {
  @apply border-gray-300 !important;
}
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-input-element::placeholder {
  font-weight: 300 !important;
  opacity: 1 !important;
  @apply text-hint !important;
}
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-input-element::-moz-placeholder {
  font-weight: 300 !important;
  opacity: 1 !important;
  @apply text-hint !important;
}
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-input-element::-webkit-input-placeholder {
  font-weight: 300 !important;
  opacity: 1 !important;
  @apply text-hint !important;
}
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-input-element:-ms-input-placeholder {
  font-weight: 300 !important;
  opacity: 1 !important;
  @apply text-hint !important;
}
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-focus-overlay {
  border-radius: 6px;
}
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
  position: relative;
  display: flex;
  align-items: stretch;
  border-radius: 6px;
  padding: 0 8px;
  /* Infix */
}
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-prefix {
  padding: 0 !important;
}
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix > .mat-icon,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-prefix > .mat-icon {
  margin-right: 12px;
  padding: 0 !important;
}
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix > .mat-mdc-icon-button,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-prefix > .mat-mdc-icon-button {
  margin: 0 4px 0 -10px;
}
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix > .mat-mdc-select,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-prefix > .mat-mdc-select {
  margin-right: 10px;
}
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix > .mat-datepicker-toggle,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-prefix > .mat-datepicker-toggle {
  margin-left: -8px;
}
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-suffix {
  padding: 0 !important;
}
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix > .mat-icon,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-suffix > .mat-icon {
  margin-left: 12px;
  padding: 0 !important;
}
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix > .mat-mdc-icon-button,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-suffix > .mat-mdc-icon-button {
  margin: 0 -10px 0 4px;
}
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix > .mat-mdc-select,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-suffix > .mat-mdc-select {
  margin-left: 10px;
}
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix > .mat-datepicker-toggle,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-suffix > .mat-datepicker-toggle {
  margin-right: -8px;
}
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-prefix,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-suffix {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  @apply text-hint !important;
  /* Datepicker default icon size */
  /* Make mat-select usable as prefix and suffix */
}
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix .mat-mdc-icon-button,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-prefix .mat-mdc-icon-button,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix .mat-mdc-icon-button,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-suffix .mat-mdc-icon-button {
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
}
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix .mat-icon,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix .mat-mdc-icon-button:not([disabled]),
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix .mat-mdc-select-value,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-prefix .mat-icon,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-prefix .mat-mdc-icon-button:not([disabled]),
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-prefix .mat-mdc-select-value,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix .mat-icon,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix .mat-mdc-icon-button:not([disabled]),
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix .mat-mdc-select-value,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-suffix .mat-icon,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-suffix .mat-mdc-icon-button:not([disabled]),
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-suffix .mat-mdc-select-value {
  @apply text-hint;
}
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix .mat-datepicker-toggle-default-icon,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-prefix .mat-datepicker-toggle-default-icon,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix .mat-datepicker-toggle-default-icon,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-suffix .mat-datepicker-toggle-default-icon {
  @apply icon-size-6;
}
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix .mat-mdc-select,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-prefix .mat-mdc-select,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix .mat-mdc-select,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-suffix .mat-mdc-select {
  display: flex;
  align-items: center;
}
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix .mat-mdc-select:focus .mat-mdc-select-trigger .mat-mdc-select-value,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-prefix .mat-mdc-select:focus .mat-mdc-select-trigger .mat-mdc-select-value,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix .mat-mdc-select:focus .mat-mdc-select-trigger .mat-mdc-select-value,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-suffix .mat-mdc-select:focus .mat-mdc-select-trigger .mat-mdc-select-value {
  @apply text-primary !important;
}
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix .mat-mdc-select:focus .mat-mdc-select-trigger .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-prefix .mat-mdc-select:focus .mat-mdc-select-trigger .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix .mat-mdc-select:focus .mat-mdc-select-trigger .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-suffix .mat-mdc-select:focus .mat-mdc-select-trigger .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow {
  border-top-color: var(--svc-primary) !important;
}
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix .mat-mdc-select .mat-mdc-select-trigger,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-prefix .mat-mdc-select .mat-mdc-select-trigger,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix .mat-mdc-select .mat-mdc-select-trigger,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-suffix .mat-mdc-select .mat-mdc-select-trigger {
  display: flex;
  align-items: center;
}
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix .mat-mdc-select .mat-mdc-select-trigger .mat-mdc-select-value,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-prefix .mat-mdc-select .mat-mdc-select-trigger .mat-mdc-select-value,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix .mat-mdc-select .mat-mdc-select-trigger .mat-mdc-select-value,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-suffix .mat-mdc-select .mat-mdc-select-trigger .mat-mdc-select-value {
  display: flex;
  max-width: none;
}
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix .mat-mdc-select .mat-mdc-select-trigger .mat-mdc-select-value mat-mdc-select-trigger .mat-icon,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-prefix .mat-mdc-select .mat-mdc-select-trigger .mat-mdc-select-value mat-mdc-select-trigger .mat-icon,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix .mat-mdc-select .mat-mdc-select-trigger .mat-mdc-select-value mat-mdc-select-trigger .mat-icon,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-suffix .mat-mdc-select .mat-mdc-select-trigger .mat-mdc-select-value mat-mdc-select-trigger .mat-icon {
  margin: 0 !important;
}
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix .mat-mdc-select .mat-mdc-select-trigger .mat-mdc-select-arrow-wrapper,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-prefix .mat-mdc-select .mat-mdc-select-trigger .mat-mdc-select-arrow-wrapper,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix .mat-mdc-select .mat-mdc-select-trigger .mat-mdc-select-arrow-wrapper,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-suffix .mat-mdc-select .mat-mdc-select-trigger .mat-mdc-select-arrow-wrapper {
  display: flex;
  align-items: center;
  transform: none;
  margin-left: 4px;
}
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix .mat-mdc-select .mat-mdc-select-trigger .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-prefix .mat-mdc-select .mat-mdc-select-trigger .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix .mat-mdc-select .mat-mdc-select-trigger .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-suffix .mat-mdc-select .mat-mdc-select-trigger .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow {
  min-height: 0;
  @apply text-gray-500 !important;
}
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
  position: static;
  display: flex;
  align-items: center;
  width: 88px;
  min-height: 48px;
  padding: 0;
  border: 0;
  /* Floating label - disable floating action */
  /* Textarea */
  /* Chips */
}
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-floating-label {
  top: -25px !important;
  left: 0 !important;
  width: 100% !important;
  transform: none !important;
  pointer-events: auto;
  font-weight: 500;
  @apply text-default !important;
}
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix textarea.mat-mdc-input-element {
  margin: 12px 0;
  padding: 0 6px 0 0;
}
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-chip-set {
  width: 100%;
}
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-form-field-required-marker {
  display: none;
}
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mdc-line-ripple {
  display: none;
}
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-form-field-subscript-wrapper {
  font-size: 12px;
  font-weight: 500;
}
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-form-field-subscript-wrapper .mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-form-field-subscript-wrapper .mat-mdc-form-field-error-wrapper {
  padding: 0;
}
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-form-field-subscript-wrapper .mat-mdc-form-field-hint {
  @apply text-hint !important;
}
.mat-mdc-form-field.mat-form-field-appearance-fill:has(textarea.mat-mdc-input-element) .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix,
.mat-mdc-form-field.mat-form-field-appearance-fill:has(textarea.mat-mdc-input-element) .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-prefix,
.mat-mdc-form-field.mat-form-field-appearance-fill:has(textarea.mat-mdc-input-element) .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix,
.mat-mdc-form-field.mat-form-field-appearance-fill:has(textarea.mat-mdc-input-element) .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-suffix {
  align-self: flex-start;
  padding-top: 14px !important;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-rounded {
  /* Emphasized affix */
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-rounded .mat-mdc-text-field-wrapper {
  border-radius: 24px;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-rounded.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix,
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-rounded.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-prefix {
  border-radius: 24px 0 0 24px;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-rounded.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix > .mat-icon,
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-rounded.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-prefix > .mat-icon {
  margin-right: 12px;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-rounded.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix > .mat-mdc-icon-button,
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-rounded.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-prefix > .mat-mdc-icon-button {
  margin: 0 2px 0 -10px !important;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-rounded.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix > .mat-mdc-select,
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-rounded.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-prefix > .mat-mdc-select {
  margin-right: 8px;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-rounded.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix > .mat-datepicker-toggle,
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-rounded.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-prefix > .mat-datepicker-toggle {
  margin-right: 4px;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-rounded.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix > *:not(.mat-icon):not(.mat-mdc-icon-button):not(.mat-mdc-select):not(.mat-datepicker-toggle),
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-rounded.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-prefix > *:not(.mat-icon):not(.mat-mdc-icon-button):not(.mat-mdc-select):not(.mat-datepicker-toggle) {
  margin-right: 12px;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-rounded.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix,
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-rounded.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-suffix {
  border-radius: 0 24px 24px 0;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-rounded.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix > .mat-icon,
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-rounded.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-suffix > .mat-icon {
  margin-left: 12px !important;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-rounded.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix > .mat-mdc-icon-button,
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-rounded.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-suffix > .mat-mdc-icon-button {
  margin: 0 -10px 0 2px !important;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-rounded.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix > .mat-mdc-select,
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-rounded.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-suffix > .mat-mdc-select {
  margin-left: 12px !important;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-rounded.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix > .mat-datepicker-toggle,
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-rounded.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-suffix > .mat-datepicker-toggle {
  margin-left: 4px !important;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-rounded.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix > *:not(.mat-icon):not(.mat-mdc-icon-button):not(.mat-mdc-select):not(.mat-datepicker-toggle),
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-rounded.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-suffix > *:not(.mat-icon):not(.mat-mdc-icon-button):not(.mat-mdc-select):not(.mat-datepicker-toggle) {
  margin-left: 12px !important;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-dense {
  /* Adds better alignment for textarea inputs */
  /* Rounded */
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-dense .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix .mat-mdc-icon-button,
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-dense .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-prefix .mat-mdc-icon-button,
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-dense .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix .mat-mdc-icon-button,
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-dense .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-suffix .mat-mdc-icon-button {
  width: 32px !important;
  min-width: 32px;
  height: 32px;
  min-height: 32px;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-dense .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix > .mat-mdc-icon-button,
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-dense .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-prefix > .mat-mdc-icon-button {
  margin-left: -6px;
  margin-right: 12px;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-dense .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix > .mat-mdc-icon-button,
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-dense .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-suffix > .mat-mdc-icon-button {
  margin-left: 12px;
  margin-right: -6px;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-dense .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
  min-height: 40px;
  /* Textarea */
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-dense .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix textarea.mat-mdc-input-element {
  margin: 8px 0;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-dense:has(textarea.mat-mdc-input-element) .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix,
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-dense:has(textarea.mat-mdc-input-element) .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-prefix,
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-dense:has(textarea.mat-mdc-input-element) .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix,
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-dense:has(textarea.mat-mdc-input-element) .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-suffix {
  padding-top: 10px !important;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-dense.svc-mat-rounded {
  /* Emphasized affix */
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-dense.svc-mat-rounded .mat-mdc-text-field-wrapper {
  border-radius: 20px;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-dense.svc-mat-rounded.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix,
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-dense.svc-mat-rounded.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-prefix {
  border-radius: 20px 0 0 20px !important;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-dense.svc-mat-rounded.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix,
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-dense.svc-mat-rounded.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-suffix {
  border-radius: 0 20px 20px 0 !important;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-emphasized-affix {
  /* with Textarea */
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix,
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-prefix {
  align-self: stretch !important;
  margin: 0 16px 0 -16px !important;
  padding-left: 16px !important;
  border-radius: 6px 0 0 6px;
  border-right-width: 1px;
  border-style: solid;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix > .mat-icon,
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-prefix > .mat-icon {
  margin-right: 16px;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix > .mat-mdc-icon-button,
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-prefix > .mat-mdc-icon-button {
  margin: 0 6px 0 -10px !important;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix > .mat-mdc-select,
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-prefix > .mat-mdc-select {
  margin-right: 12px !important;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix > .mat-datepicker-toggle,
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-prefix > .mat-datepicker-toggle {
  margin-right: 8px;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix > *:not(.mat-icon):not(.mat-mdc-icon-button):not(.mat-mdc-select):not(.mat-datepicker-toggle),
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-prefix > *:not(.mat-icon):not(.mat-mdc-icon-button):not(.mat-mdc-select):not(.mat-datepicker-toggle) {
  margin-right: 16px;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix,
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-suffix {
  align-self: stretch !important;
  margin: 0 -16px 0 16px !important;
  padding-right: 16px !important;
  border-radius: 0 6px 6px 0;
  border-left-width: 1px;
  border-style: solid;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix > .mat-icon,
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-suffix > .mat-icon {
  margin-left: 16px;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix > .mat-mdc-icon-button,
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-suffix > .mat-mdc-icon-button {
  margin: 0 -10px 0 6px !important;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix > .mat-mdc-select,
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-suffix > .mat-mdc-select {
  margin: 0 -4px 0 16px !important;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix > .mat-datepicker-toggle,
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-suffix > .mat-datepicker-toggle {
  margin-left: 8px;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix > *:not(.mat-icon):not(.mat-mdc-icon-button):not(.mat-mdc-select):not(.mat-datepicker-toggle),
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-suffix > *:not(.mat-icon):not(.mat-mdc-icon-button):not(.mat-mdc-select):not(.mat-datepicker-toggle) {
  margin-left: 16px;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix,
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-prefix,
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix,
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-emphasized-affix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-suffix {
  @apply bg-default border-gray-300 !important;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-emphasized-affix:has(textarea.mat-mdc-input-element) .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix,
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-emphasized-affix:has(textarea.mat-mdc-input-element) .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-prefix,
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-emphasized-affix:has(textarea.mat-mdc-input-element) .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix,
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-emphasized-affix:has(textarea.mat-mdc-input-element) .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-suffix {
  align-items: flex-start;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-bold .mat-mdc-text-field-wrapper {
  border-width: 2px !important;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.none-label .mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--filled {
  margin-top: 0 !important;
}

.mat-mdc-form-field {
  --mat-form-field-container-text-size: 1rem;
}

/* "outline" appearance */
.mat-mdc-form-field.mat-form-field-appearance-outline {
  /* Invalid */
  /* Focused */
  /* Remove the extra border on the right side of the notch */
  /* Tailwind's global border setter causes this issue */
}
.mat-mdc-form-field.mat-form-field-appearance-outline.mat-form-field-invalid .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-form-field-appearance-outline.mat-form-field-invalid .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-form-field-appearance-outline.mat-form-field-invalid .mdc-notched-outline__trailing {
  border-color: var(--svc-warn) !important;
}
.mat-mdc-form-field.mat-form-field-appearance-outline.mat-focused:not(.mat-form-field-invalid) {
  /* Primary */
  /* Accent */
}
.mat-mdc-form-field.mat-form-field-appearance-outline.mat-focused:not(.mat-form-field-invalid).mat-primary .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-form-field-appearance-outline.mat-focused:not(.mat-form-field-invalid).mat-primary .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-form-field-appearance-outline.mat-focused:not(.mat-form-field-invalid).mat-primary .mdc-notched-outline__trailing {
  border-color: var(--svc-primary) !important;
}
.mat-mdc-form-field.mat-form-field-appearance-outline.mat-focused:not(.mat-form-field-invalid).mat-accent .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-form-field-appearance-outline.mat-focused:not(.mat-form-field-invalid).mat-accent .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-form-field-appearance-outline.mat-focused:not(.mat-form-field-invalid).mat-accent .mdc-notched-outline__trailing {
  border-color: var(--svc-accent) !important;
}
.mat-mdc-form-field.mat-form-field-appearance-outline:not(.mat-focused):not(.mat-form-field-invalid) .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-form-field-appearance-outline:not(.mat-focused):not(.mat-form-field-invalid) .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-form-field-appearance-outline:not(.mat-focused):not(.mat-form-field-invalid) .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__trailing {
  @apply border-slate-300 !important;
}
.mat-mdc-form-field.mat-form-field-appearance-outline .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__notch {
  border-right-style: none !important;
}

/* -------------------------------------------------------------------------- */
/*  @ Datepicker
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/*  @ Icon
/* -------------------------------------------------------------------------- */
.mat-icon {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  width: 24px;
  min-width: 24px;
  height: 24px;
  min-height: 24px;
  font-size: 24px;
  line-height: 24px;
  -webkit-appearance: none !important;
}

/* -------------------------------------------------------------------------- */
/*  @ Inputs
/* -------------------------------------------------------------------------- */
.mat-mdc-input-element::placeholder {
  transition: none !important;
  @apply text-hint !important;
}
.mat-mdc-input-element::-moz-placeholder {
  transition: none !important;
  @apply text-hint !important;
}
.mat-mdc-input-element::-webkit-input-placeholder {
  transition: none !important;
  @apply text-hint !important;
}
.mat-mdc-input-element:-ms-input-placeholder {
  transition: none !important;
  @apply text-hint !important;
}

/* Invalid */
.mat-form-field-invalid .mat-mdc-input-element {
  /* Placeholder color */
}
.mat-form-field-invalid .mat-mdc-input-element::placeholder {
  @apply text-warn !important;
}
.mat-form-field-invalid .mat-mdc-input-element::-moz-placeholder {
  @apply text-warn !important;
}
.mat-form-field-invalid .mat-mdc-input-element::-webkit-input-placeholder {
  @apply text-warn !important;
}
.mat-form-field-invalid .mat-mdc-input-element:-ms-input-placeholder {
  @apply text-warn !important;
}

/* -------------------------------------------------------------------------- */
/*  @ Menu Surface
/* -------------------------------------------------------------------------- */
.mdc-menu-surface {
  border-radius: 0.5rem !important;
}

/* -------------------------------------------------------------------------- */
/*  @ Menu
/* -------------------------------------------------------------------------- */
.mat-mdc-menu-panel {
  min-width: 144px !important;
  border-radius: 15px !important;
}
.mat-mdc-menu-panel .mat-mdc-menu-content {
  padding: 0 !important;
  /* Divider within mat-menu */
}
.mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item {
  display: flex;
  align-items: center;
  @apply text-default;
}
.mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item > span {
  display: inline-flex;
  align-items: center;
}
.mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text {
  @apply text-default text-base;
}
.mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.mat-mdc-menu-item-submenu-trigger {
  padding-right: 40px;
}
.mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item .mat-icon {
  margin-right: 8px;
}
.mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item .mat-icon-no-color {
  --tw-text-opacity: 1;
  color: rgba(var(--svc-text-default-rgb), var(--tw-text-opacity));
}
.mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item:not(:last-of-type), .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.bordered {
  border-bottom: 1px solid rgb(226, 232, 240);
}
.mat-mdc-menu-panel .mat-mdc-menu-content mat-divider {
  margin: 8px 0;
}

/* -------------------------------------------------------------------------- */
/*  @ Paginator
/* -------------------------------------------------------------------------- */
.mat-mdc-paginator .mat-mdc-paginator-container {
  padding: 8px 16px;
  justify-content: space-between;
  /* Page size select */
  /* Range actions */
}
@screen sm {
  .mat-mdc-paginator .mat-mdc-paginator-container {
    justify-content: normal;
  }
}
.mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-paginator-page-size {
  align-items: center;
  min-height: 40px;
  margin: 8px;
}
.mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-paginator-page-size .mat-mdc-paginator-page-size-label {
  display: none;
  margin-right: 12px;
}
@screen sm {
  .mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-paginator-page-size .mat-mdc-paginator-page-size-label {
    display: block;
  }
}
.mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-paginator-page-size .mat-mdc-paginator-page-size-select {
  margin: 0;
}
.mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-paginator-page-size .mat-mdc-paginator-page-size-select .mat-mdc-text-field-wrapper {
  padding: 0 10px;
}
.mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-paginator-page-size .mat-mdc-paginator-page-size-select .mat-mdc-text-field-wrapper .mat-form-field-flex {
  min-height: 32px;
}
.mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-paginator-range-actions {
  margin: 8px 0;
}
.mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-paginator-range-actions .mat-mdc-paginator-range-label {
  margin-right: 16px;
}

/* -------------------------------------------------------------------------- */
/*  @ Select
/* -------------------------------------------------------------------------- */
.mat-mdc-select {
  display: inline-flex !important;
}
.mat-mdc-select .mat-mdc-select-placeholder {
  transition: none !important;
  font-weight: 300;
  @apply text-hint !important;
}
.mat-mdc-select .mat-mdc-select-trigger .mat-mdc-select-value {
  position: relative;
  display: flex;
  max-width: none;
}
.mat-mdc-select .mat-mdc-select-trigger .mat-mdc-select-value .mat-mdc-select-value-text {
  display: inline-flex;
}
.mat-mdc-select .mat-mdc-select-trigger .mat-mdc-select-value .mat-mdc-select-value-text > * {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.mat-mdc-select .mat-mdc-select-arrow-wrapper {
  transform: translateY(0) !important;
}
.mat-mdc-select .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow {
  margin: 0 0 0 8px;
  color: rgba(0, 0, 0, 0.54) !important;
}

/* -------------------------------------------------------------------------- */
/*  @ Mat Icon
/* -------------------------------------------------------------------------- */
.mat-icon > svg *[stroke],
.mat-icon > svg *[stroke-width] {
  stroke: currentColor;
}

/* -------------------------------------------------------------------------- */
/*  @ Stepper
/* -------------------------------------------------------------------------- */
.mat-step-icon {
  /* Do not override the mat-icon color */
}
.mat-step-icon .mat-icon {
  color: currentColor !important;
}

.mat-step-label,
.mat-step-label-selected {
  font-weight: 500 !important;
}

/* -------------------------------------------------------------------------- */
/*  @ Table
/* -------------------------------------------------------------------------- */
.mat-mdc-table .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background: none !important;
}

/* -------------------------------------------------------------------------- */
/*  @ Tabs
/* -------------------------------------------------------------------------- */
.mat-mdc-tab-group {
  /* No header */
  /* No overflow */
}
.mat-mdc-tab-group .mat-mdc-tab {
  height: 37px !important;
  min-width: 48px !important;
  padding: 0 8px !important;
}
.mat-mdc-tab-group .mat-mdc-tab .mdc-tab__text-label {
  @apply text-base;
  @apply font-normal;
  color: #9ba0a3;
}
.mat-mdc-tab-group .mat-mdc-tab .mdc-tab__text-label mat-icon {
  color: #9ba0a3;
}
.mat-mdc-tab-group.svc-mat-no-header .mat-mdc-tab-header {
  height: 0 !important;
  max-height: 0 !important;
  border: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
}
.mat-mdc-tab-group.svc-mat-unset-overflow {
  overflow: unset !important;
}
.mat-mdc-tab-group.svc-mat-unset-overflow .mat-mdc-tab-body-wrapper,
.mat-mdc-tab-group.svc-mat-unset-overflow .mat-mdc-tab-body,
.mat-mdc-tab-group.svc-mat-unset-overflow .mat-mdc-tab-body-content {
  overflow: unset !important;
}

.mat-mdc-tab-header {
  width: 100%;
}
.mat-mdc-tab-header .mat-mdc-tab-label-container {
  margin: 0 24px;
}
.mat-mdc-tab-header .mat-mdc-tab-labels .mdc-tab,
.mat-mdc-tab-header .mat-mdc-tab-links .mdc-tab {
  height: 37px !important;
  min-width: 48px !important;
  padding: 0 8px !important;
}
.mat-mdc-tab-header .mat-mdc-tab-labels .mdc-tab .mdc-tab__text-label,
.mat-mdc-tab-header .mat-mdc-tab-links .mdc-tab .mdc-tab__text-label {
  @apply text-base;
  @apply font-normal;
  color: #9ba0a3;
}
.mat-mdc-tab-header .mat-mdc-tab-labels .mdc-tab .mdc-tab__text-label mat-icon,
.mat-mdc-tab-header .mat-mdc-tab-links .mdc-tab .mdc-tab__text-label mat-icon {
  color: #9ba0a3;
}
.mat-mdc-tab-header .mat-mdc-tab-labels .mdc-tab.mdc-tab--active .mdc-tab__text-label,
.mat-mdc-tab-header .mat-mdc-tab-links .mdc-tab.mdc-tab--active .mdc-tab__text-label {
  @apply text-primary;
}
.mat-mdc-tab-header .mat-mdc-tab-labels .mdc-tab.mdc-tab--active .mdc-tab__text-label mat-icon,
.mat-mdc-tab-header .mat-mdc-tab-links .mdc-tab.mdc-tab--active .mdc-tab__text-label mat-icon {
  @apply text-primary;
}
.mat-mdc-tab-header::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #9BA0A3;
}

/* -------------------------------------------------------------------------- */
/*  @ Textarea
/* -------------------------------------------------------------------------- */
textarea.mat-mdc-input-element {
  box-sizing: content-box !important;
}

/* -------------------------------------------------------------------------- */
/*  @ Toolbar
/* -------------------------------------------------------------------------- */
.mat-toolbar {
  /* Apply primary contrast color */
  /* Apply accent contrast color */
  /* Apply warn contrast color */
}
.mat-toolbar.mat-primary .mat-icon {
  @apply text-on-primary !important;
}
.mat-toolbar.mat-primary .text-secondary {
  @apply text-on-primary text-opacity-60 !important;
}
.mat-toolbar.mat-primary .text-hint {
  @apply text-on-primary text-opacity-38 !important;
}
.mat-toolbar.mat-primary .text-disabled {
  @apply text-on-primary text-opacity-38 !important;
}
.mat-toolbar.mat-primary .divider {
  @apply text-on-primary text-opacity-12 !important;
}
.mat-toolbar.mat-accent .mat-icon {
  @apply text-on-accent !important;
}
.mat-toolbar.mat-accent .text-secondary {
  @apply text-on-accent text-opacity-60 !important;
}
.mat-toolbar.mat-accent .text-hint {
  @apply text-on-accent text-opacity-38 !important;
}
.mat-toolbar.mat-accent .text-disabled {
  @apply text-on-accent text-opacity-38 !important;
}
.mat-toolbar.mat-accent .divider {
  @apply text-on-accent text-opacity-12 !important;
}
.mat-toolbar.mat-warn .mat-icon {
  @apply text-on-warn !important;
}
.mat-toolbar.mat-warn .text-secondary {
  @apply text-on-warn text-opacity-60 !important;
}
.mat-toolbar.mat-warn .text-hint {
  @apply text-on-warn text-opacity-38 !important;
}
.mat-toolbar.mat-warn .text-disabled {
  @apply text-on-warn text-opacity-38 !important;
}
.mat-toolbar.mat-warn .divider {
  @apply text-on-warn text-opacity-12 !important;
}

.mdc-linear-progress__buffer .mdc-linear-progress__buffer-bar {
  background-color: #e6e6e6 !important;
}

/* -------------------------------------------------------------------------- */
/*  @ mat-optgroup
/* -------------------------------------------------------------------------- */
.mat-mdc-autocomplete-panel .mat-mdc-optgroup .mat-mdc-optgroup-label {
  @apply border-y;
  @apply border-gray-300;
}
.mat-mdc-autocomplete-panel .mat-mdc-optgroup:first-child .mat-mdc-optgroup-label {
  @apply border-t-0;
}

/* -------------------------------------------------------------------------- */
/*  @ mat-tooltip
/* -------------------------------------------------------------------------- */
.mat-mdc-tooltip > div {
  background-color: #000;
}

.svc-field-in-primary .mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper {
  @apply border-transparent bg-primary-100 !important;
  /* Invalid */
}
.svc-field-in-primary .mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-input-element {
  @apply text-primary;
}
.svc-field-in-primary .mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-input-element::placeholder {
  @apply font-normal text-primary-400 !important;
}
.svc-field-in-primary .mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-input-element::-moz-placeholder {
  @apply font-normal text-primary-400 !important;
}
.svc-field-in-primary .mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-input-element::-webkit-input-placeholder {
  @apply font-normal text-primary-400 !important;
}
.svc-field-in-primary .mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-input-element:-ms-input-placeholder {
  @apply font-normal text-primary-400 !important;
}
.svc-field-in-primary .mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-form-field-invalid .mat-mdc-input-element::placeholder {
  @apply text-primary-400 !important;
}
.svc-field-in-primary .mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-form-field-invalid .mat-mdc-input-element::-moz-placeholder {
  @apply text-primary-400 !important;
}
.svc-field-in-primary .mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-form-field-invalid .mat-mdc-input-element::-webkit-input-placeholder {
  @apply text-primary-400 !important;
}
.svc-field-in-primary .mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-form-field-invalid .mat-mdc-input-element:-ms-input-placeholder {
  @apply text-primary-400 !important;
}
.svc-field-in-primary .mat-mdc-chip {
  @apply text-primary;
}
.svc-field-in-primary .mat-mdc-chip:not(.mat-mdc-chip-row) {
  @apply bg-primary-100 !important;
}
.svc-field-in-primary .mat-mdc-chip.mat-mdc-chip-row {
  @apply bg-primary-200 !important;
}
.svc-field-in-primary .mat-mdc-chip .mdc-evolution-chip__text-label {
  @apply text-primary !important;
}

/* "fill" appearance */
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-dense-custom {
  /* Default style tweaks and enhancements */
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-dense-custom .mat-mdc-text-field-wrapper {
  margin-top: 0px !important;
  /* Form field */
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-dense-custom .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
  /* Infix */
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-dense-custom .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
  min-height: 56px !important;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-dense-custom .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-floating-label {
  opacity: 0.7 !important;
  top: 28px !important;
  left: auto !important;
  right: auto !important;
  transform: translateY(-50%) !important;
  font-weight: 400 !important;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-dense-custom .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mdc-floating-label--float-above {
  transform: translateY(-106%) scale(0.75) !important;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-dense-custom .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-chip-set {
  margin: inherit !important;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-dense-custom .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix input.mat-mdc-input-element,
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-dense-custom .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix textarea.mat-mdc-input-element {
  font-weight: 600 !important;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-dense-custom .mat-mdc-text-field-wrapper:not(.mdc-text-field--outline) .mat-mdc-form-field-infix {
  padding-top: 24px !important;
  padding-bottom: 8px !important;
}

.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-dense .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align::before,
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-dense-custom .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align::before {
  display: none !important;
}

/* Base button */
.mdc-button {
  min-width: 48px;
  min-height: 44px;
}
.mdc-button.text-ellipsis .mdc-button__label {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

/* FAB buttons */
.mat-mdc-fab {
  max-height: 56px;
}
.mat-mdc-fab:not(.mdc-fab--extended) {
  height: 50px;
  width: 50px;
  border-radius: 50% !important;
}
.mat-mdc-fab:not(.mdc-fab--extended) .mdc-fab__ripple {
  border-radius: inherit !important;
}
.mat-mdc-fab.mdc-fab--extended {
  border-radius: 24px !important;
}
.mat-mdc-fab.mdc-fab--extended .mdc-fab__ripple {
  border-radius: inherit !important;
}

/* Mini FAB buttons */
.mat-mdc-mini-fab:not(.mdc-fab--extended) {
  border-radius: 50% !important;
}
.mat-mdc-mini-fab:not(.mdc-fab--extended) .mdc-fab__ripple {
  border-radius: inherit !important;
}

/* Rounded design */
.mat-mdc-raised-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button {
  padding: 0 20px;
  border-radius: 6px !important;
}
.mat-mdc-raised-button.icon-only,
.mat-mdc-outlined-button.icon-only,
.mat-mdc-unelevated-button.icon-only {
  padding: 0;
}

body svc-splash-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #21295C;
  color: #F9FAFB;
  z-index: 999999;
  pointer-events: none;
  opacity: 1;
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1);
}
body svc-splash-screen .animated-logo {
  height: 60px;
  width: 55px;
  margin: auto;
  transform: scale(3);
  background-image: url("https://solvacelabs-webcomponents.s3.amazonaws.com/images/company-logo-ng.svg");
  background-repeat: no-repeat;
  background-position: left top;
  animation: resizing 0.5s ease-out 0.5s forwards, writing 0.4s ease-out 1.2s forwards, pulse 0.8s ease 2s alternate infinite;
}
body:not(.svc-splash-screen-hidden) {
  overflow: hidden;
}
body.svc-splash-screen-hidden svc-splash-screen {
  opacity: 0;
}

@keyframes resizing {
  100% {
    transform: scale(1);
  }
}
@keyframes writing {
  100% {
    width: 260px;
  }
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
ag-virtual-scroll {
  overflow-y: scroll !important;
}

.hljs {
  font-size: 0.875rem;
}

ngx-skeleton-loader.white .skeleton-loader {
  background: rgba(255, 255, 255, 0.4) no-repeat !important;
}
.mention {
  display: inline;
  color: var(--svc-color-primary);
  background-color: var(--svc-color-primary-100);
}

.svc-drawer-without-overflow {
  display: block;
  height: 100%;
  @apply -mb-30;
}
.svc-drawer-without-overflow svc-drawer {
  margin-bottom: 0 !important;
}
.svc-drawer-without-overflow svc-drawer > .svc-drawer-content {
  overflow-y: hidden !important;
}
.svc-drawer-without-overflow svc-drawer > .svc-drawer-content::after {
  display: none !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  :root {
    --svc-color-primary: #3b82f6;
    --svc-color-primary-rgb: 59,130,246;
    --svc-color-primary-50: #eff6ff;
    --svc-color-primary-50-rgb: 239,246,255;
    --svc-color-primary-100: #dbeafe;
    --svc-color-primary-100-rgb: 219,234,254;
    --svc-color-primary-200: #bfdbfe;
    --svc-color-primary-200-rgb: 191,219,254;
    --svc-color-primary-300: #93c5fd;
    --svc-color-primary-300-rgb: 147,197,253;
    --svc-color-primary-400: #60a5fa;
    --svc-color-primary-400-rgb: 96,165,250;
    --svc-color-primary-500: #3b82f6;
    --svc-color-primary-500-rgb: 59,130,246;
    --svc-color-primary-600: #2563eb;
    --svc-color-primary-600-rgb: 37,99,235;
    --svc-color-primary-700: #1d4ed8;
    --svc-color-primary-700-rgb: 29,78,216;
    --svc-color-primary-800: #1e40af;
    --svc-color-primary-800-rgb: 30,64,175;
    --svc-color-primary-900: #1e3a8a;
    --svc-color-primary-900-rgb: 30,58,138;
    --svc-color-on-primary: #FFFFFF;
    --svc-color-on-primary-rgb: 255,255,255;
    --svc-color-on-primary-50: #1a0010;
    --svc-color-on-primary-50-rgb: 26,0,16;
    --svc-color-on-primary-100: #1a0010;
    --svc-color-on-primary-100-rgb: 26,0,16;
    --svc-color-on-primary-200: #1a0010;
    --svc-color-on-primary-200-rgb: 26,0,16;
    --svc-color-on-primary-300: #1a0010;
    --svc-color-on-primary-300-rgb: 26,0,16;
    --svc-color-on-primary-400: #FFFFFF;
    --svc-color-on-primary-400-rgb: 255,255,255;
    --svc-color-on-primary-500: #FFFFFF;
    --svc-color-on-primary-500-rgb: 255,255,255;
    --svc-color-on-primary-600: #FFFFFF;
    --svc-color-on-primary-600-rgb: 255,255,255;
    --svc-color-on-primary-700: #FFFFFF;
    --svc-color-on-primary-700-rgb: 255,255,255;
    --svc-color-on-primary-800: #FFFFFF;
    --svc-color-on-primary-800-rgb: 255,255,255;
    --svc-color-on-primary-900: #FFFFFF;
    --svc-color-on-primary-900-rgb: 255,255,255;
    --svc-accent: #1e293b;
    --svc-accent-rgb: 30,41,59;
    --svc-accent-50: #f8fafc;
    --svc-accent-50-rgb: 248,250,252;
    --svc-accent-100: #f1f5f9;
    --svc-accent-100-rgb: 241,245,249;
    --svc-accent-200: #e2e8f0;
    --svc-accent-200-rgb: 226,232,240;
    --svc-accent-300: #cbd5e1;
    --svc-accent-300-rgb: 203,213,225;
    --svc-accent-400: #94a3b8;
    --svc-accent-400-rgb: 148,163,184;
    --svc-accent-500: #64748b;
    --svc-accent-500-rgb: 100,116,139;
    --svc-accent-600: #475569;
    --svc-accent-600-rgb: 71,85,105;
    --svc-accent-700: #334155;
    --svc-accent-700-rgb: 51,65,85;
    --svc-accent-800: #1e293b;
    --svc-accent-800-rgb: 30,41,59;
    --svc-accent-900: #0f172a;
    --svc-accent-900-rgb: 15,23,42;
    --svc-on-accent: #FFFFFF;
    --svc-on-accent-rgb: 255,255,255;
    --svc-on-accent-50: #0f172a;
    --svc-on-accent-50-rgb: 15,23,42;
    --svc-on-accent-100: #0f172a;
    --svc-on-accent-100-rgb: 15,23,42;
    --svc-on-accent-200: #0f172a;
    --svc-on-accent-200-rgb: 15,23,42;
    --svc-on-accent-300: #0f172a;
    --svc-on-accent-300-rgb: 15,23,42;
    --svc-on-accent-400: #0f172a;
    --svc-on-accent-400-rgb: 15,23,42;
    --svc-on-accent-500: #FFFFFF;
    --svc-on-accent-500-rgb: 255,255,255;
    --svc-on-accent-600: #FFFFFF;
    --svc-on-accent-600-rgb: 255,255,255;
    --svc-on-accent-700: #FFFFFF;
    --svc-on-accent-700-rgb: 255,255,255;
    --svc-on-accent-800: #FFFFFF;
    --svc-on-accent-800-rgb: 255,255,255;
    --svc-on-accent-900: #FFFFFF;
    --svc-on-accent-900-rgb: 255,255,255;
    --svc-warn: #dc2626;
    --svc-warn-rgb: 220,38,38;
    --svc-warn-50: #fef2f2;
    --svc-warn-50-rgb: 254,242,242;
    --svc-warn-100: #fee2e2;
    --svc-warn-100-rgb: 254,226,226;
    --svc-warn-200: #fecaca;
    --svc-warn-200-rgb: 254,202,202;
    --svc-warn-300: #fca5a5;
    --svc-warn-300-rgb: 252,165,165;
    --svc-warn-400: #f87171;
    --svc-warn-400-rgb: 248,113,113;
    --svc-warn-500: #ef4444;
    --svc-warn-500-rgb: 239,68,68;
    --svc-warn-600: #dc2626;
    --svc-warn-600-rgb: 220,38,38;
    --svc-warn-700: #b91c1c;
    --svc-warn-700-rgb: 185,28,28;
    --svc-warn-800: #991b1b;
    --svc-warn-800-rgb: 153,27,27;
    --svc-warn-900: #7f1d1d;
    --svc-warn-900-rgb: 127,29,29;
    --svc-on-warn: #FFFFFF;
    --svc-on-warn-rgb: 255,255,255;
    --svc-on-warn-50: #7f1d1d;
    --svc-on-warn-50-rgb: 127,29,29;
    --svc-on-warn-100: #7f1d1d;
    --svc-on-warn-100-rgb: 127,29,29;
    --svc-on-warn-200: #7f1d1d;
    --svc-on-warn-200-rgb: 127,29,29;
    --svc-on-warn-300: #7f1d1d;
    --svc-on-warn-300-rgb: 127,29,29;
    --svc-on-warn-400: #7f1d1d;
    --svc-on-warn-400-rgb: 127,29,29;
    --svc-on-warn-500: #fef2f2;
    --svc-on-warn-500-rgb: 254,242,242;
    --svc-on-warn-600: #FFFFFF;
    --svc-on-warn-600-rgb: 255,255,255;
    --svc-on-warn-700: #FFFFFF;
    --svc-on-warn-700-rgb: 255,255,255;
    --svc-on-warn-800: #FFFFFF;
    --svc-on-warn-800-rgb: 255,255,255;
    --svc-on-warn-900: #FFFFFF;
    --svc-on-warn-900-rgb: 255,255,255;
    --svc-success: #466900;
    --svc-success-rgb: 70,105,0;
  }
  :root .svc-default {
    --svc-color-primary-50: #eff6ff;
    --svc-color-primary-50-rgb: 239,246,255;
    --svc-color-primary-100: #dbeafe;
    --svc-color-primary-100-rgb: 219,234,254;
    --svc-color-primary-200: #bfdbfe;
    --svc-color-primary-200-rgb: 191,219,254;
    --svc-color-primary-300: #93c5fd;
    --svc-color-primary-300-rgb: 147,197,253;
    --svc-color-primary-400: #60a5fa;
    --svc-color-primary-400-rgb: 96,165,250;
    --svc-color-primary-500: #3b82f6;
    --svc-color-primary-500-rgb: 59,130,246;
    --svc-color-primary-600: #2563eb;
    --svc-color-primary-600-rgb: 37,99,235;
    --svc-color-primary-700: #1d4ed8;
    --svc-color-primary-700-rgb: 29,78,216;
    --svc-color-primary-800: #1e40af;
    --svc-color-primary-800-rgb: 30,64,175;
    --svc-color-primary-900: #1e3a8a;
    --svc-color-primary-900-rgb: 30,58,138;
    --svc-color-primary: #3b82f6;
    --svc-color-primary-rgb: 59,130,246;
    --svc-color-on-primary-50: #000A1F;
    --svc-color-on-primary-50-rgb: 0,10,31;
    --svc-color-on-primary-100: #000A1F;
    --svc-color-on-primary-100-rgb: 0,10,31;
    --svc-color-on-primary-200: #000A1F;
    --svc-color-on-primary-200-rgb: 0,10,31;
    --svc-color-on-primary-300: #000A1F;
    --svc-color-on-primary-300-rgb: 0,10,31;
    --svc-color-on-primary-400: #FFFFFF;
    --svc-color-on-primary-400-rgb: 255,255,255;
    --svc-color-on-primary-500: #FFFFFF;
    --svc-color-on-primary-500-rgb: 255,255,255;
    --svc-color-on-primary-600: #FFFFFF;
    --svc-color-on-primary-600-rgb: 255,255,255;
    --svc-color-on-primary-700: #FFFFFF;
    --svc-color-on-primary-700-rgb: 255,255,255;
    --svc-color-on-primary-800: #FFFFFF;
    --svc-color-on-primary-800-rgb: 255,255,255;
    --svc-color-on-primary-900: #FFFFFF;
    --svc-color-on-primary-900-rgb: 255,255,255;
    --svc-color-on-primary: #FFFFFF;
    --svc-color-on-primary-rgb: 255,255,255;
  }
  :root .svc-00274C {
    --svc-color-primary-50: #e6e9ed;
    --svc-color-primary-50-rgb: 230,233,237;
    --svc-color-primary-100: #ccd4db;
    --svc-color-primary-100-rgb: 204,212,219;
    --svc-color-primary-200: #99a9b7;
    --svc-color-primary-200-rgb: 153,169,183;
    --svc-color-primary-300: #667d94;
    --svc-color-primary-300-rgb: 102,125,148;
    --svc-color-primary-400: #335270;
    --svc-color-primary-400-rgb: 51,82,112;
    --svc-color-primary-500: #00274c;
    --svc-color-primary-500-rgb: 0,39,76;
    --svc-color-primary-600: #001f3d;
    --svc-color-primary-600-rgb: 0,31,61;
    --svc-color-primary-700: #00172e;
    --svc-color-primary-700-rgb: 0,23,46;
    --svc-color-primary-800: #00101e;
    --svc-color-primary-800-rgb: 0,16,30;
    --svc-color-primary-900: #00080f;
    --svc-color-primary-900-rgb: 0,8,15;
    --svc-color-primary: #00274c;
    --svc-color-primary-rgb: 0,39,76;
    --svc-color-on-primary-50: #00080f;
    --svc-color-on-primary-50-rgb: 0,8,15;
    --svc-color-on-primary-100: #00080f;
    --svc-color-on-primary-100-rgb: 0,8,15;
    --svc-color-on-primary-200: #00080f;
    --svc-color-on-primary-200-rgb: 0,8,15;
    --svc-color-on-primary-300: #00080f;
    --svc-color-on-primary-300-rgb: 0,8,15;
    --svc-color-on-primary-400: #FFFFFF;
    --svc-color-on-primary-400-rgb: 255,255,255;
    --svc-color-on-primary-500: #FFFFFF;
    --svc-color-on-primary-500-rgb: 255,255,255;
    --svc-color-on-primary-600: #FFFFFF;
    --svc-color-on-primary-600-rgb: 255,255,255;
    --svc-color-on-primary-700: #FFFFFF;
    --svc-color-on-primary-700-rgb: 255,255,255;
    --svc-color-on-primary-800: #FFFFFF;
    --svc-color-on-primary-800-rgb: 255,255,255;
    --svc-color-on-primary-900: #FFFFFF;
    --svc-color-on-primary-900-rgb: 255,255,255;
    --svc-color-on-primary: #FFFFFF;
    --svc-color-on-primary-rgb: 255,255,255;
  }
  :root .svc-003366 {
    --svc-color-primary-50: #e6ebf0;
    --svc-color-primary-50-rgb: 230,235,240;
    --svc-color-primary-100: #ccd6e0;
    --svc-color-primary-100-rgb: 204,214,224;
    --svc-color-primary-200: #99adc2;
    --svc-color-primary-200-rgb: 153,173,194;
    --svc-color-primary-300: #6685a3;
    --svc-color-primary-300-rgb: 102,133,163;
    --svc-color-primary-400: #335c85;
    --svc-color-primary-400-rgb: 51,92,133;
    --svc-color-primary-500: #003366;
    --svc-color-primary-500-rgb: 0,51,102;
    --svc-color-primary-600: #002952;
    --svc-color-primary-600-rgb: 0,41,82;
    --svc-color-primary-700: #001f3d;
    --svc-color-primary-700-rgb: 0,31,61;
    --svc-color-primary-800: #001429;
    --svc-color-primary-800-rgb: 0,20,41;
    --svc-color-primary-900: #000a14;
    --svc-color-primary-900-rgb: 0,10,20;
    --svc-color-primary: #003366;
    --svc-color-primary-rgb: 0,51,102;
    --svc-color-on-primary-50: #000a14;
    --svc-color-on-primary-50-rgb: 0,10,20;
    --svc-color-on-primary-100: #000a14;
    --svc-color-on-primary-100-rgb: 0,10,20;
    --svc-color-on-primary-200: #000a14;
    --svc-color-on-primary-200-rgb: 0,10,20;
    --svc-color-on-primary-300: #000a14;
    --svc-color-on-primary-300-rgb: 0,10,20;
    --svc-color-on-primary-400: #FFFFFF;
    --svc-color-on-primary-400-rgb: 255,255,255;
    --svc-color-on-primary-500: #FFFFFF;
    --svc-color-on-primary-500-rgb: 255,255,255;
    --svc-color-on-primary-600: #FFFFFF;
    --svc-color-on-primary-600-rgb: 255,255,255;
    --svc-color-on-primary-700: #FFFFFF;
    --svc-color-on-primary-700-rgb: 255,255,255;
    --svc-color-on-primary-800: #FFFFFF;
    --svc-color-on-primary-800-rgb: 255,255,255;
    --svc-color-on-primary-900: #FFFFFF;
    --svc-color-on-primary-900-rgb: 255,255,255;
    --svc-color-on-primary: #FFFFFF;
    --svc-color-on-primary-rgb: 255,255,255;
  }
  :root .svc-003399 {
    --svc-color-primary-50: #e6ebf5;
    --svc-color-primary-50-rgb: 230,235,245;
    --svc-color-primary-100: #ccd6eb;
    --svc-color-primary-100-rgb: 204,214,235;
    --svc-color-primary-200: #99add6;
    --svc-color-primary-200-rgb: 153,173,214;
    --svc-color-primary-300: #6685c2;
    --svc-color-primary-300-rgb: 102,133,194;
    --svc-color-primary-400: #335cad;
    --svc-color-primary-400-rgb: 51,92,173;
    --svc-color-primary-500: #003399;
    --svc-color-primary-500-rgb: 0,51,153;
    --svc-color-primary-600: #00297a;
    --svc-color-primary-600-rgb: 0,41,122;
    --svc-color-primary-700: #001f5c;
    --svc-color-primary-700-rgb: 0,31,92;
    --svc-color-primary-800: #00143d;
    --svc-color-primary-800-rgb: 0,20,61;
    --svc-color-primary-900: #000a1f;
    --svc-color-primary-900-rgb: 0,10,31;
    --svc-color-primary: #003399;
    --svc-color-primary-rgb: 0,51,153;
    --svc-color-on-primary-50: #000a1f;
    --svc-color-on-primary-50-rgb: 0,10,31;
    --svc-color-on-primary-100: #000a1f;
    --svc-color-on-primary-100-rgb: 0,10,31;
    --svc-color-on-primary-200: #000a1f;
    --svc-color-on-primary-200-rgb: 0,10,31;
    --svc-color-on-primary-300: #000a1f;
    --svc-color-on-primary-300-rgb: 0,10,31;
    --svc-color-on-primary-400: #FFFFFF;
    --svc-color-on-primary-400-rgb: 255,255,255;
    --svc-color-on-primary-500: #FFFFFF;
    --svc-color-on-primary-500-rgb: 255,255,255;
    --svc-color-on-primary-600: #FFFFFF;
    --svc-color-on-primary-600-rgb: 255,255,255;
    --svc-color-on-primary-700: #FFFFFF;
    --svc-color-on-primary-700-rgb: 255,255,255;
    --svc-color-on-primary-800: #FFFFFF;
    --svc-color-on-primary-800-rgb: 255,255,255;
    --svc-color-on-primary-900: #FFFFFF;
    --svc-color-on-primary-900-rgb: 255,255,255;
    --svc-color-on-primary: #FFFFFF;
    --svc-color-on-primary-rgb: 255,255,255;
  }
  :root .svc-005F86 {
    --svc-color-primary-50: #e6eff3;
    --svc-color-primary-50-rgb: 230,239,243;
    --svc-color-primary-100: #ccdfe7;
    --svc-color-primary-100-rgb: 204,223,231;
    --svc-color-primary-200: #99bfcf;
    --svc-color-primary-200-rgb: 153,191,207;
    --svc-color-primary-300: #669fb6;
    --svc-color-primary-300-rgb: 102,159,182;
    --svc-color-primary-400: #337f9e;
    --svc-color-primary-400-rgb: 51,127,158;
    --svc-color-primary-500: #005f86;
    --svc-color-primary-500-rgb: 0,95,134;
    --svc-color-primary-600: #004c6b;
    --svc-color-primary-600-rgb: 0,76,107;
    --svc-color-primary-700: #003950;
    --svc-color-primary-700-rgb: 0,57,80;
    --svc-color-primary-800: #002636;
    --svc-color-primary-800-rgb: 0,38,54;
    --svc-color-primary-900: #00131b;
    --svc-color-primary-900-rgb: 0,19,27;
    --svc-color-primary: #005f86;
    --svc-color-primary-rgb: 0,95,134;
    --svc-color-on-primary-50: #00131b;
    --svc-color-on-primary-50-rgb: 0,19,27;
    --svc-color-on-primary-100: #00131b;
    --svc-color-on-primary-100-rgb: 0,19,27;
    --svc-color-on-primary-200: #00131b;
    --svc-color-on-primary-200-rgb: 0,19,27;
    --svc-color-on-primary-300: #00131b;
    --svc-color-on-primary-300-rgb: 0,19,27;
    --svc-color-on-primary-400: #FFFFFF;
    --svc-color-on-primary-400-rgb: 255,255,255;
    --svc-color-on-primary-500: #FFFFFF;
    --svc-color-on-primary-500-rgb: 255,255,255;
    --svc-color-on-primary-600: #FFFFFF;
    --svc-color-on-primary-600-rgb: 255,255,255;
    --svc-color-on-primary-700: #FFFFFF;
    --svc-color-on-primary-700-rgb: 255,255,255;
    --svc-color-on-primary-800: #FFFFFF;
    --svc-color-on-primary-800-rgb: 255,255,255;
    --svc-color-on-primary-900: #FFFFFF;
    --svc-color-on-primary-900-rgb: 255,255,255;
    --svc-color-on-primary: #FFFFFF;
    --svc-color-on-primary-rgb: 255,255,255;
  }
  :root .svc-0F58D6 {
    --svc-color-primary-50: #e7eefb;
    --svc-color-primary-50-rgb: 231,238,251;
    --svc-color-primary-100: #cfdef7;
    --svc-color-primary-100-rgb: 207,222,247;
    --svc-color-primary-200: #9fbcef;
    --svc-color-primary-200-rgb: 159,188,239;
    --svc-color-primary-300: #6f9be6;
    --svc-color-primary-300-rgb: 111,155,230;
    --svc-color-primary-400: #3f79de;
    --svc-color-primary-400-rgb: 63,121,222;
    --svc-color-primary-500: #0f58d6;
    --svc-color-primary-500-rgb: 15,88,214;
    --svc-color-primary-600: #0c46ab;
    --svc-color-primary-600-rgb: 12,70,171;
    --svc-color-primary-700: #093580;
    --svc-color-primary-700-rgb: 9,53,128;
    --svc-color-primary-800: #062356;
    --svc-color-primary-800-rgb: 6,35,86;
    --svc-color-primary-900: #03122b;
    --svc-color-primary-900-rgb: 3,18,43;
    --svc-color-primary: #0f58d6;
    --svc-color-primary-rgb: 15,88,214;
    --svc-color-on-primary-50: #03122b;
    --svc-color-on-primary-50-rgb: 3,18,43;
    --svc-color-on-primary-100: #03122b;
    --svc-color-on-primary-100-rgb: 3,18,43;
    --svc-color-on-primary-200: #03122b;
    --svc-color-on-primary-200-rgb: 3,18,43;
    --svc-color-on-primary-300: #03122b;
    --svc-color-on-primary-300-rgb: 3,18,43;
    --svc-color-on-primary-400: #FFFFFF;
    --svc-color-on-primary-400-rgb: 255,255,255;
    --svc-color-on-primary-500: #FFFFFF;
    --svc-color-on-primary-500-rgb: 255,255,255;
    --svc-color-on-primary-600: #FFFFFF;
    --svc-color-on-primary-600-rgb: 255,255,255;
    --svc-color-on-primary-700: #FFFFFF;
    --svc-color-on-primary-700-rgb: 255,255,255;
    --svc-color-on-primary-800: #FFFFFF;
    --svc-color-on-primary-800-rgb: 255,255,255;
    --svc-color-on-primary-900: #FFFFFF;
    --svc-color-on-primary-900-rgb: 255,255,255;
    --svc-color-on-primary: #FFFFFF;
    --svc-color-on-primary-rgb: 255,255,255;
  }
  :root .svc-161718 {
    --svc-color-primary-50: #e8e8e8;
    --svc-color-primary-50-rgb: 232,232,232;
    --svc-color-primary-100: #d0d1d1;
    --svc-color-primary-100-rgb: 208,209,209;
    --svc-color-primary-200: #a2a2a3;
    --svc-color-primary-200-rgb: 162,162,163;
    --svc-color-primary-300: #737474;
    --svc-color-primary-300-rgb: 115,116,116;
    --svc-color-primary-400: #454546;
    --svc-color-primary-400-rgb: 69,69,70;
    --svc-color-primary-500: #161718;
    --svc-color-primary-500-rgb: 22,23,24;
    --svc-color-primary-600: #121213;
    --svc-color-primary-600-rgb: 18,18,19;
    --svc-color-primary-700: #0d0e0e;
    --svc-color-primary-700-rgb: 13,14,14;
    --svc-color-primary-800: #09090a;
    --svc-color-primary-800-rgb: 9,9,10;
    --svc-color-primary-900: #040505;
    --svc-color-primary-900-rgb: 4,5,5;
    --svc-color-primary: #161718;
    --svc-color-primary-rgb: 22,23,24;
    --svc-color-on-primary-50: #040505;
    --svc-color-on-primary-50-rgb: 4,5,5;
    --svc-color-on-primary-100: #040505;
    --svc-color-on-primary-100-rgb: 4,5,5;
    --svc-color-on-primary-200: #040505;
    --svc-color-on-primary-200-rgb: 4,5,5;
    --svc-color-on-primary-300: #040505;
    --svc-color-on-primary-300-rgb: 4,5,5;
    --svc-color-on-primary-400: #FFFFFF;
    --svc-color-on-primary-400-rgb: 255,255,255;
    --svc-color-on-primary-500: #FFFFFF;
    --svc-color-on-primary-500-rgb: 255,255,255;
    --svc-color-on-primary-600: #FFFFFF;
    --svc-color-on-primary-600-rgb: 255,255,255;
    --svc-color-on-primary-700: #FFFFFF;
    --svc-color-on-primary-700-rgb: 255,255,255;
    --svc-color-on-primary-800: #FFFFFF;
    --svc-color-on-primary-800-rgb: 255,255,255;
    --svc-color-on-primary-900: #FFFFFF;
    --svc-color-on-primary-900-rgb: 255,255,255;
    --svc-color-on-primary: #FFFFFF;
    --svc-color-on-primary-rgb: 255,255,255;
  }
  :root .svc-3A8DD2 {
    --svc-color-primary-50: #ebf4fb;
    --svc-color-primary-50-rgb: 235,244,251;
    --svc-color-primary-100: #d8e8f6;
    --svc-color-primary-100-rgb: 216,232,246;
    --svc-color-primary-200: #b0d1ed;
    --svc-color-primary-200-rgb: 176,209,237;
    --svc-color-primary-300: #89bbe4;
    --svc-color-primary-300-rgb: 137,187,228;
    --svc-color-primary-400: #61a4db;
    --svc-color-primary-400-rgb: 97,164,219;
    --svc-color-primary-500: #3a8dd2;
    --svc-color-primary-500-rgb: 58,141,210;
    --svc-color-primary-600: #2e71a8;
    --svc-color-primary-600-rgb: 46,113,168;
    --svc-color-primary-700: #23557e;
    --svc-color-primary-700-rgb: 35,85,126;
    --svc-color-primary-800: #173854;
    --svc-color-primary-800-rgb: 23,56,84;
    --svc-color-primary-900: #0c1c2a;
    --svc-color-primary-900-rgb: 12,28,42;
    --svc-color-primary: #3a8dd2;
    --svc-color-primary-rgb: 58,141,210;
    --svc-color-on-primary-50: #0c1c2a;
    --svc-color-on-primary-50-rgb: 12,28,42;
    --svc-color-on-primary-100: #0c1c2a;
    --svc-color-on-primary-100-rgb: 12,28,42;
    --svc-color-on-primary-200: #0c1c2a;
    --svc-color-on-primary-200-rgb: 12,28,42;
    --svc-color-on-primary-300: #0c1c2a;
    --svc-color-on-primary-300-rgb: 12,28,42;
    --svc-color-on-primary-400: #FFFFFF;
    --svc-color-on-primary-400-rgb: 255,255,255;
    --svc-color-on-primary-500: #FFFFFF;
    --svc-color-on-primary-500-rgb: 255,255,255;
    --svc-color-on-primary-600: #FFFFFF;
    --svc-color-on-primary-600-rgb: 255,255,255;
    --svc-color-on-primary-700: #FFFFFF;
    --svc-color-on-primary-700-rgb: 255,255,255;
    --svc-color-on-primary-800: #FFFFFF;
    --svc-color-on-primary-800-rgb: 255,255,255;
    --svc-color-on-primary-900: #FFFFFF;
    --svc-color-on-primary-900-rgb: 255,255,255;
    --svc-color-on-primary: #FFFFFF;
    --svc-color-on-primary-rgb: 255,255,255;
  }
  :root .svc-512072 {
    --svc-color-primary-50: #eee9f1;
    --svc-color-primary-50-rgb: 238,233,241;
    --svc-color-primary-100: #dcd2e3;
    --svc-color-primary-100-rgb: 220,210,227;
    --svc-color-primary-200: #b9a6c7;
    --svc-color-primary-200-rgb: 185,166,199;
    --svc-color-primary-300: #9779aa;
    --svc-color-primary-300-rgb: 151,121,170;
    --svc-color-primary-400: #744d8e;
    --svc-color-primary-400-rgb: 116,77,142;
    --svc-color-primary-500: #512072;
    --svc-color-primary-500-rgb: 81,32,114;
    --svc-color-primary-600: #411a5b;
    --svc-color-primary-600-rgb: 65,26,91;
    --svc-color-primary-700: #311344;
    --svc-color-primary-700-rgb: 49,19,68;
    --svc-color-primary-800: #200d2e;
    --svc-color-primary-800-rgb: 32,13,46;
    --svc-color-primary-900: #100617;
    --svc-color-primary-900-rgb: 16,6,23;
    --svc-color-primary: #512072;
    --svc-color-primary-rgb: 81,32,114;
    --svc-color-on-primary-50: #100617;
    --svc-color-on-primary-50-rgb: 16,6,23;
    --svc-color-on-primary-100: #100617;
    --svc-color-on-primary-100-rgb: 16,6,23;
    --svc-color-on-primary-200: #100617;
    --svc-color-on-primary-200-rgb: 16,6,23;
    --svc-color-on-primary-300: #100617;
    --svc-color-on-primary-300-rgb: 16,6,23;
    --svc-color-on-primary-400: #FFFFFF;
    --svc-color-on-primary-400-rgb: 255,255,255;
    --svc-color-on-primary-500: #FFFFFF;
    --svc-color-on-primary-500-rgb: 255,255,255;
    --svc-color-on-primary-600: #FFFFFF;
    --svc-color-on-primary-600-rgb: 255,255,255;
    --svc-color-on-primary-700: #FFFFFF;
    --svc-color-on-primary-700-rgb: 255,255,255;
    --svc-color-on-primary-800: #FFFFFF;
    --svc-color-on-primary-800-rgb: 255,255,255;
    --svc-color-on-primary-900: #FFFFFF;
    --svc-color-on-primary-900-rgb: 255,255,255;
    --svc-color-on-primary: #FFFFFF;
    --svc-color-on-primary-rgb: 255,255,255;
  }
  :root .svc-7B7521 {
    --svc-color-primary-50: #eee9f1;
    --svc-color-primary-50-rgb: 238,233,241;
    --svc-color-primary-100: #dcd2e3;
    --svc-color-primary-100-rgb: 220,210,227;
    --svc-color-primary-200: #b9a6c7;
    --svc-color-primary-200-rgb: 185,166,199;
    --svc-color-primary-300: #9779aa;
    --svc-color-primary-300-rgb: 151,121,170;
    --svc-color-primary-400: #744d8e;
    --svc-color-primary-400-rgb: 116,77,142;
    --svc-color-primary-500: #512072;
    --svc-color-primary-500-rgb: 81,32,114;
    --svc-color-primary-600: #411a5b;
    --svc-color-primary-600-rgb: 65,26,91;
    --svc-color-primary-700: #311344;
    --svc-color-primary-700-rgb: 49,19,68;
    --svc-color-primary-800: #200d2e;
    --svc-color-primary-800-rgb: 32,13,46;
    --svc-color-primary-900: #100617;
    --svc-color-primary-900-rgb: 16,6,23;
    --svc-color-primary: #512072;
    --svc-color-primary-rgb: 81,32,114;
    --svc-color-on-primary-50: #100617;
    --svc-color-on-primary-50-rgb: 16,6,23;
    --svc-color-on-primary-100: #100617;
    --svc-color-on-primary-100-rgb: 16,6,23;
    --svc-color-on-primary-200: #100617;
    --svc-color-on-primary-200-rgb: 16,6,23;
    --svc-color-on-primary-300: #100617;
    --svc-color-on-primary-300-rgb: 16,6,23;
    --svc-color-on-primary-400: #FFFFFF;
    --svc-color-on-primary-400-rgb: 255,255,255;
    --svc-color-on-primary-500: #FFFFFF;
    --svc-color-on-primary-500-rgb: 255,255,255;
    --svc-color-on-primary-600: #FFFFFF;
    --svc-color-on-primary-600-rgb: 255,255,255;
    --svc-color-on-primary-700: #FFFFFF;
    --svc-color-on-primary-700-rgb: 255,255,255;
    --svc-color-on-primary-800: #FFFFFF;
    --svc-color-on-primary-800-rgb: 255,255,255;
    --svc-color-on-primary-900: #FFFFFF;
    --svc-color-on-primary-900-rgb: 255,255,255;
    --svc-color-on-primary: #FFFFFF;
    --svc-color-on-primary-rgb: 255,255,255;
  }
  :root .svc-830051 {
    --svc-color-primary-50: #f3e6ee;
    --svc-color-primary-50-rgb: 243,230,238;
    --svc-color-primary-100: #e6ccdc;
    --svc-color-primary-100-rgb: 230,204,220;
    --svc-color-primary-200: #cd99b9;
    --svc-color-primary-200-rgb: 205,153,185;
    --svc-color-primary-300: #b56697;
    --svc-color-primary-300-rgb: 181,102,151;
    --svc-color-primary-400: #9c3374;
    --svc-color-primary-400-rgb: 156,51,116;
    --svc-color-primary-500: #830051;
    --svc-color-primary-500-rgb: 131,0,81;
    --svc-color-primary-600: #690041;
    --svc-color-primary-600-rgb: 105,0,65;
    --svc-color-primary-700: #4f0031;
    --svc-color-primary-700-rgb: 79,0,49;
    --svc-color-primary-800: #340020;
    --svc-color-primary-800-rgb: 52,0,32;
    --svc-color-primary-900: #1a0010;
    --svc-color-primary-900-rgb: 26,0,16;
    --svc-color-primary: #830051;
    --svc-color-primary-rgb: 131,0,81;
    --svc-color-on-primary-50: #1a0010;
    --svc-color-on-primary-50-rgb: 26,0,16;
    --svc-color-on-primary-100: #1a0010;
    --svc-color-on-primary-100-rgb: 26,0,16;
    --svc-color-on-primary-200: #1a0010;
    --svc-color-on-primary-200-rgb: 26,0,16;
    --svc-color-on-primary-300: #1a0010;
    --svc-color-on-primary-300-rgb: 26,0,16;
    --svc-color-on-primary-400: #FFFFFF;
    --svc-color-on-primary-400-rgb: 255,255,255;
    --svc-color-on-primary-500: #FFFFFF;
    --svc-color-on-primary-500-rgb: 255,255,255;
    --svc-color-on-primary-600: #FFFFFF;
    --svc-color-on-primary-600-rgb: 255,255,255;
    --svc-color-on-primary-700: #FFFFFF;
    --svc-color-on-primary-700-rgb: 255,255,255;
    --svc-color-on-primary-800: #FFFFFF;
    --svc-color-on-primary-800-rgb: 255,255,255;
    --svc-color-on-primary-900: #FFFFFF;
    --svc-color-on-primary-900-rgb: 255,255,255;
    --svc-color-on-primary: #FFFFFF;
    --svc-color-on-primary-rgb: 255,255,255;
  }
  :root .svc-E4002B {
    --svc-color-primary-50: #fce6ea;
    --svc-color-primary-50-rgb: 252,230,234;
    --svc-color-primary-100: #faccd5;
    --svc-color-primary-100-rgb: 250,204,213;
    --svc-color-primary-200: #f499aa;
    --svc-color-primary-200-rgb: 244,153,170;
    --svc-color-primary-300: #ef6680;
    --svc-color-primary-300-rgb: 239,102,128;
    --svc-color-primary-400: #e93355;
    --svc-color-primary-400-rgb: 233,51,85;
    --svc-color-primary-500: #e4002b;
    --svc-color-primary-500-rgb: 228,0,43;
    --svc-color-primary-600: #b60022;
    --svc-color-primary-600-rgb: 182,0,34;
    --svc-color-primary-700: #89001a;
    --svc-color-primary-700-rgb: 137,0,26;
    --svc-color-primary-800: #5b0011;
    --svc-color-primary-800-rgb: 91,0,17;
    --svc-color-primary-900: #2e0009;
    --svc-color-primary-900-rgb: 46,0,9;
    --svc-color-primary: #e4002b;
    --svc-color-primary-rgb: 228,0,43;
    --svc-color-on-primary-50: #2e0009;
    --svc-color-on-primary-50-rgb: 46,0,9;
    --svc-color-on-primary-100: #2e0009;
    --svc-color-on-primary-100-rgb: 46,0,9;
    --svc-color-on-primary-200: #2e0009;
    --svc-color-on-primary-200-rgb: 46,0,9;
    --svc-color-on-primary-300: #2e0009;
    --svc-color-on-primary-300-rgb: 46,0,9;
    --svc-color-on-primary-400: #FFFFFF;
    --svc-color-on-primary-400-rgb: 255,255,255;
    --svc-color-on-primary-500: #FFFFFF;
    --svc-color-on-primary-500-rgb: 255,255,255;
    --svc-color-on-primary-600: #FFFFFF;
    --svc-color-on-primary-600-rgb: 255,255,255;
    --svc-color-on-primary-700: #FFFFFF;
    --svc-color-on-primary-700-rgb: 255,255,255;
    --svc-color-on-primary-800: #FFFFFF;
    --svc-color-on-primary-800-rgb: 255,255,255;
    --svc-color-on-primary-900: #FFFFFF;
    --svc-color-on-primary-900-rgb: 255,255,255;
    --svc-color-on-primary: #FFFFFF;
    --svc-color-on-primary-rgb: 255,255,255;
  }
  :root .svc-E5A812 {
    --svc-color-primary-50: #fcf6e7;
    --svc-color-primary-50-rgb: 252,246,231;
    --svc-color-primary-100: #faeed0;
    --svc-color-primary-100-rgb: 250,238,208;
    --svc-color-primary-200: #f5dca0;
    --svc-color-primary-200-rgb: 245,220,160;
    --svc-color-primary-300: #efcb71;
    --svc-color-primary-300-rgb: 239,203,113;
    --svc-color-primary-400: #eab941;
    --svc-color-primary-400-rgb: 234,185,65;
    --svc-color-primary-500: #e5a812;
    --svc-color-primary-500-rgb: 229,168,18;
    --svc-color-primary-600: #b7860e;
    --svc-color-primary-600-rgb: 183,134,14;
    --svc-color-primary-700: #89650b;
    --svc-color-primary-700-rgb: 137,101,11;
    --svc-color-primary-800: #5c4307;
    --svc-color-primary-800-rgb: 92,67,7;
    --svc-color-primary-900: #2e2204;
    --svc-color-primary-900-rgb: 46,34,4;
    --svc-color-primary: #e5a812;
    --svc-color-primary-rgb: 229,168,18;
    --svc-color-on-primary-50: #2E2204;
    --svc-color-on-primary-50-rgb: 46,34,4;
    --svc-color-on-primary-100: #2E2204;
    --svc-color-on-primary-100-rgb: 46,34,4;
    --svc-color-on-primary-200: #2E2204;
    --svc-color-on-primary-200-rgb: 46,34,4;
    --svc-color-on-primary-300: #2E2204;
    --svc-color-on-primary-300-rgb: 46,34,4;
    --svc-color-on-primary-400: #2E2204;
    --svc-color-on-primary-400-rgb: 46,34,4;
    --svc-color-on-primary-500: #2E2204;
    --svc-color-on-primary-500-rgb: 46,34,4;
    --svc-color-on-primary-600: #2E2204;
    --svc-color-on-primary-600-rgb: 46,34,4;
    --svc-color-on-primary-700: #FFFFFF;
    --svc-color-on-primary-700-rgb: 255,255,255;
    --svc-color-on-primary-800: #FFFFFF;
    --svc-color-on-primary-800-rgb: 255,255,255;
    --svc-color-on-primary-900: #FFFFFF;
    --svc-color-on-primary-900-rgb: 255,255,255;
    --svc-color-on-primary: #2E2204;
    --svc-color-on-primary-rgb: 46,34,4;
  }
  :root .svc-FF7900 {
    --svc-color-primary-50: #fff2e6;
    --svc-color-primary-50-rgb: 255,242,230;
    --svc-color-primary-100: #ffe4cc;
    --svc-color-primary-100-rgb: 255,228,204;
    --svc-color-primary-200: #ffc999;
    --svc-color-primary-200-rgb: 255,201,153;
    --svc-color-primary-300: #ffaf66;
    --svc-color-primary-300-rgb: 255,175,102;
    --svc-color-primary-400: #ff9433;
    --svc-color-primary-400-rgb: 255,148,51;
    --svc-color-primary-500: #ff7900;
    --svc-color-primary-500-rgb: 255,121,0;
    --svc-color-primary-600: #cc6100;
    --svc-color-primary-600-rgb: 204,97,0;
    --svc-color-primary-700: #994900;
    --svc-color-primary-700-rgb: 153,73,0;
    --svc-color-primary-800: #663000;
    --svc-color-primary-800-rgb: 102,48,0;
    --svc-color-primary-900: #331800;
    --svc-color-primary-900-rgb: 51,24,0;
    --svc-color-primary: #ff7900;
    --svc-color-primary-rgb: 255,121,0;
    --svc-color-on-primary-50: #331800;
    --svc-color-on-primary-50-rgb: 51,24,0;
    --svc-color-on-primary-100: #331800;
    --svc-color-on-primary-100-rgb: 51,24,0;
    --svc-color-on-primary-200: #331800;
    --svc-color-on-primary-200-rgb: 51,24,0;
    --svc-color-on-primary-300: #331800;
    --svc-color-on-primary-300-rgb: 51,24,0;
    --svc-color-on-primary-400: #FFFFFF;
    --svc-color-on-primary-400-rgb: 255,255,255;
    --svc-color-on-primary-500: #FFFFFF;
    --svc-color-on-primary-500-rgb: 255,255,255;
    --svc-color-on-primary-600: #FFFFFF;
    --svc-color-on-primary-600-rgb: 255,255,255;
    --svc-color-on-primary-700: #FFFFFF;
    --svc-color-on-primary-700-rgb: 255,255,255;
    --svc-color-on-primary-800: #FFFFFF;
    --svc-color-on-primary-800-rgb: 255,255,255;
    --svc-color-on-primary-900: #FFFFFF;
    --svc-color-on-primary-900-rgb: 255,255,255;
    --svc-color-on-primary: #FFFFFF;
    --svc-color-on-primary-rgb: 255,255,255;
  }
  :root .svc-FFD30F {
    --svc-color-primary-50: #fffbe7;
    --svc-color-primary-50-rgb: 255,251,231;
    --svc-color-primary-100: #fff6cf;
    --svc-color-primary-100-rgb: 255,246,207;
    --svc-color-primary-200: #ffed9f;
    --svc-color-primary-200-rgb: 255,237,159;
    --svc-color-primary-300: #ffe56f;
    --svc-color-primary-300-rgb: 255,229,111;
    --svc-color-primary-400: #ffdc3f;
    --svc-color-primary-400-rgb: 255,220,63;
    --svc-color-primary-500: #ffd30f;
    --svc-color-primary-500-rgb: 255,211,15;
    --svc-color-primary-600: #cca90c;
    --svc-color-primary-600-rgb: 204,169,12;
    --svc-color-primary-700: #997f09;
    --svc-color-primary-700-rgb: 153,127,9;
    --svc-color-primary-800: #665406;
    --svc-color-primary-800-rgb: 102,84,6;
    --svc-color-primary-900: #332a03;
    --svc-color-primary-900-rgb: 51,42,3;
    --svc-color-primary: #ffd30f;
    --svc-color-primary-rgb: 255,211,15;
    --svc-color-on-primary-50: #332A03;
    --svc-color-on-primary-50-rgb: 51,42,3;
    --svc-color-on-primary-100: #332A03;
    --svc-color-on-primary-100-rgb: 51,42,3;
    --svc-color-on-primary-200: #332A03;
    --svc-color-on-primary-200-rgb: 51,42,3;
    --svc-color-on-primary-300: #332A03;
    --svc-color-on-primary-300-rgb: 51,42,3;
    --svc-color-on-primary-400: #332A03;
    --svc-color-on-primary-400-rgb: 51,42,3;
    --svc-color-on-primary-500: #332A03;
    --svc-color-on-primary-500-rgb: 51,42,3;
    --svc-color-on-primary-600: #332A03;
    --svc-color-on-primary-600-rgb: 51,42,3;
    --svc-color-on-primary-700: #332A03;
    --svc-color-on-primary-700-rgb: 51,42,3;
    --svc-color-on-primary-800: #FFFFFF;
    --svc-color-on-primary-800-rgb: 255,255,255;
    --svc-color-on-primary-900: #FFFFFF;
    --svc-color-on-primary-900-rgb: 255,255,255;
    --svc-color-on-primary: #332A03;
    --svc-color-on-primary-rgb: 51,42,3;
  }
  :root .svc-006400 {
    --svc-color-primary-50: #e0ece0;
    --svc-color-primary-50-rgb: 224,236,224;
    --svc-color-primary-100: #b3d1b3;
    --svc-color-primary-100-rgb: 179,209,179;
    --svc-color-primary-200: #80b280;
    --svc-color-primary-200-rgb: 128,178,128;
    --svc-color-primary-300: #4d934d;
    --svc-color-primary-300-rgb: 77,147,77;
    --svc-color-primary-400: #267b26;
    --svc-color-primary-400-rgb: 38,123,38;
    --svc-color-primary-500: #006400;
    --svc-color-primary-500-rgb: 0,100,0;
    --svc-color-primary-600: #005c00;
    --svc-color-primary-600-rgb: 0,92,0;
    --svc-color-primary-700: #005200;
    --svc-color-primary-700-rgb: 0,82,0;
    --svc-color-primary-800: #004800;
    --svc-color-primary-800-rgb: 0,72,0;
    --svc-color-primary-900: #003600;
    --svc-color-primary-900-rgb: 0,54,0;
    --svc-color-primary: #006400;
    --svc-color-primary-rgb: 0,100,0;
    --svc-color-on-primary-50: #1a0010;
    --svc-color-on-primary-50-rgb: 26,0,16;
    --svc-color-on-primary-100: #1a0010;
    --svc-color-on-primary-100-rgb: 26,0,16;
    --svc-color-on-primary-200: #1a0010;
    --svc-color-on-primary-200-rgb: 26,0,16;
    --svc-color-on-primary-300: #FFFFFF;
    --svc-color-on-primary-300-rgb: 255,255,255;
    --svc-color-on-primary-400: #FFFFFF;
    --svc-color-on-primary-400-rgb: 255,255,255;
    --svc-color-on-primary-500: #FFFFFF;
    --svc-color-on-primary-500-rgb: 255,255,255;
    --svc-color-on-primary-600: #FFFFFF;
    --svc-color-on-primary-600-rgb: 255,255,255;
    --svc-color-on-primary-700: #FFFFFF;
    --svc-color-on-primary-700-rgb: 255,255,255;
    --svc-color-on-primary-800: #FFFFFF;
    --svc-color-on-primary-800-rgb: 255,255,255;
    --svc-color-on-primary-900: #FFFFFF;
    --svc-color-on-primary-900-rgb: 255,255,255;
    --svc-color-on-primary: #FFFFFF;
    --svc-color-on-primary-rgb: 255,255,255;
  }
  * {
    /* Text rendering */
    text-rendering: optimizeLegibility;
    -o-text-rendering: optimizeLegibility;
    -ms-text-rendering: optimizeLegibility;
    -moz-text-rendering: optimizeLegibility;
    -webkit-text-rendering: optimizeLegibility;
    -webkit-tap-highlight-color: transparent;
    /* Remove the focus ring */
  }
  *:focus {
    outline: none !important;
  }
  /* HTML and Body default styles */
  html,
  body {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;
    min-height: 100%;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }
  /* Font size */
  html {
    font-size: 16px;
    overflow: hidden;
  }
  body {
    @apply text-default bg-default !important;
  }
  /* Stylistic alternates for Inter */
  body {
    font-feature-settings: "salt";
  }
  /* Better spacing and border for horizontal rule */
  hr {
    margin: 32px 0;
    border-bottom-width: 1px;
  }
  /* Make images and videos to take up all the available space */
  img {
    width: 100%;
    vertical-align: top;
  }
  /* Fix: Disabled placeholder color is too faded on Safari */
  input[disabled] {
    opacity: 1;
    -webkit-text-fill-color: currentColor;
  }
  /* Set the border color */
  *,
  ::before,
  ::after {
    --tw-border-opacity: 1 !important;
    border-color: rgba(var(--svc-border-rgb), var(--tw-border-opacity));
  }
  input::placeholder {
    font-weight: 300 !important;
    opacity: 1 !important;
    @apply text-hint !important;
  }
  input::-moz-placeholder {
    font-weight: 300 !important;
    opacity: 1 !important;
    @apply text-hint !important;
  }
  input::-webkit-input-placeholder {
    font-weight: 300 !important;
    opacity: 1 !important;
    @apply text-hint !important;
  }
  input:-ms-input-placeholder {
    font-weight: 300 !important;
    opacity: 1 !important;
    @apply text-hint !important;
  }
  /* Style scrollbars on platforms other than MacOS and iOS */
  @media only screen and (min-width: 960px) {
    body:not(.os-mac) ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background-color: rgba(0, 0, 0, 0);
    }
    body:not(.os-mac) ::-webkit-scrollbar:hover {
      width: 8px;
      height: 8px;
      background-color: rgba(0, 0, 0, 0.06);
    }
    body:not(.os-mac) ::-webkit-scrollbar-thumb {
      border: 2px solid transparent;
      border-radius: 20px;
      box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.24);
    }
    body:not(.os-mac) ::-webkit-scrollbar-thumb:active {
      border-radius: 20px;
      box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.37);
    }
    body:not(.os-mac).dark ::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 0 20px rgba(255, 255, 255, 0.24);
    }
    body:not(.os-mac).dark ::-webkit-scrollbar-thumb:active {
      box-shadow: inset 0 0 0 20px rgba(255, 255, 255, 0.37);
    }
  }
  /* Set the foreground color for disabled elements */
  [disabled]:not(.remove-text-disabled-color):not(.svc-btn):not(.svc-filled-btn):not(.svc-outlined-btn) * {
    @apply text-disabled !important;
  }
  @media (max-width: 599px) {
    input {
      font-size: 1rem !important;
    }
  }
}