body {
    svc-splash-screen {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #21295C;
        color: #F9FAFB;
        z-index: 999999;
        pointer-events: none;
        opacity: 1;
        transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1);


        .animated-logo{
            height: 60px;
            width: 55px;
            margin: auto;
            transform: scale(3);
            background-image: url('https://solvacelabs-webcomponents.s3.amazonaws.com/images/company-logo-ng.svg');
            background-repeat: no-repeat;
            background-position: left top;
            animation: resizing 0.5s ease-out 0.5s forwards,
                        writing 0.4s ease-out 1.2s forwards,
                        pulse 0.8s ease 2s alternate infinite;
        }
    }

    &:not(.svc-splash-screen-hidden) {
        overflow: hidden;
    }

    &.svc-splash-screen-hidden svc-splash-screen {
        opacity: 0;
    }
}


@keyframes resizing {
    100% {
        transform: scale(1);
    }
}

@keyframes writing {
    100% {
        width: 260px;
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.1);
    }
}
