ngx-skeleton-loader {
  &.white {
    .skeleton-loader {
      background: rgb(255,255,255, 0.4) no-repeat !important;
    }
  }
  &.on-primary {
    .skeleton-loader {
    }
  }
}