@media print {

	body * {
		visibility: hidden;
	}

	#printable * {
		visibility: visible;
		print-color-adjust: exact;
	}

	.printable-part,
	.printable-part * {
		visibility: visible;
		print-color-adjust: exact;
	}

	.page-break {
		page-break-after: always;
	}

	.page-avoid-break {
		page-break-inside: avoid;
	}

}