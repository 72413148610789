/* "fill" appearance */
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-dense-custom {

    /* Default style tweaks and enhancements */
    .mat-mdc-text-field-wrapper {

        margin-top: 0px!important;

        /* Form field */
        .mat-mdc-form-field-flex {

            /* Infix */
            .mat-mdc-form-field-infix {
                min-height: 56px!important;

                .mat-mdc-floating-label {
                    opacity: 0.7!important;
                    top: 28px!important;
                    left: auto !important;
                    right: auto !important;
                    transform: translateY(-50%)!important;
                    font-weight: 400!important;
                }

                .mdc-floating-label--float-above {
                    transform: translateY(-106%) scale(0.75)!important
                };
 
                .mat-mdc-chip-set {
                    margin: inherit!important;
                }

                input.mat-mdc-input-element,
                textarea.mat-mdc-input-element {
                    font-weight: 600!important;
                }
            }
        }

        &:not(.mdc-text-field--outline){

            .mat-mdc-form-field-infix {
                padding-top: 24px!important;
                padding-bottom: 8px!important;
            }
        }
    }
}

.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-dense,
.mat-mdc-form-field.mat-form-field-appearance-fill.svc-mat-dense-custom {
    .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align::before{
        display: none !important;
    }
}