.droplist-shimmer {
  $color: #f6f7f8;
  width: 100%;
  max-width: 200px;
  height: 22px;
  background: #{$color};
  border-radius: 5px;
  background-image: linear-gradient(to right, #{$color} 0%, #FFFFFF 20%, #{$color} 40%, #{$color} 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px; 
  animation-duration: 1s;
  animation-fill-mode: forwards; 
  animation-iteration-count: infinite;
  animation-name: droplsitShimmer;
  animation-timing-function: linear;
}

@keyframes droplsitShimmer {
  0% { background-position: -468px 0; }
  100% { background-position: 468px 0;  }
}