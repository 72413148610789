@import 'mention';

.svc-drawer-without-overflow {
  display: block;
  height: 100%;
  @apply -mb-30;
  
  svc-drawer {
    margin-bottom: 0 !important;
    
    > .svc-drawer-content {
      overflow-y: hidden !important;
  
      &::after {
        display: none !important;
      }
    }
  }
}
