/* ----------------------------------------------------------------------------------------------------- */
/*  @ Quill editor overrides
/* ----------------------------------------------------------------------------------------------------- */
.ql-toolbar {
	border-radius: 6px 6px 0 0;
	padding: 0 !important;
	@apply bg-gray-100;
	@apply border-gray-300 border-opacity-100 #{'!important'};

	.dark & {
		background-color: rgba(0, 0, 0, 0.05);
		@apply border-gray-500 #{'!important'};
	}

	.ql-formats {
		margin: 11px 8px !important;
	}

	.ql-picker {

		&.ql-expanded {

			.ql-picker-label {
				@apply border-gray-300;

				.dark & {
					@apply border-gray-500;
				}
			}

			.ql-picker-options {
				z-index: 10 !important;
				@apply border-gray-300 bg-card;

				.dark & {
					@apply border-gray-500;
				}
			}
		}

		.ql-picker-label {
			@apply text-default;
		}

		.ql-picker-options {

			.ql-picker-item {
				@apply text-default;
			}
		}
	}

	.ql-stroke,
	.ql-stroke-mitter {
		stroke: #000;
	}

	.ql-fill {
		fill: #000;
	}

	button:hover,
	button:focus,
	button.ql-active,
	.ql-picker-label:hover,
	.ql-picker-label.ql-active,
	.ql-picker-item:hover,
	.ql-picker-item.ql-selected {
		@apply text-primary #{'!important'};

		.ql-stroke,
		.ql-stroke-mitter {
			stroke: var(--svc-color-primary) !important;
		}

		.ql-fill {
			fill: var(--svc-color-primary) !important;
		}
	}
}

.ql-container {
	overflow: auto;
	min-height: 160px;
	max-height: 400px;
	border-radius: 0 0 6px 6px;
	@apply border-gray-300 border-opacity-100 shadow-sm #{'!important'};

	.dark & {
		@apply border-gray-500 #{'!important'};
	}

	.ql-editor {
		word-wrap: break-word;
		word-break: break-word;

		&.ql-blank::before {
			@apply text-hint;
		}
	}

	.ql-tooltip {
		@apply px-3 py-1 shadow-sm rounded-md bg-gray-100 border-gray-300;

		.dark & {
			@apply shadow-lg bg-gray-700 border-gray-700 #{'!important'};
		}

		// Label
		&:before {
			@apply text-secondary;
		}

		.ql-action,
		.ql-remove {
			@apply text-primary border-gray-300;

			.dark & {
				@apply text-primary-400 border-gray-300;
			}
		}

		.ql-action:after {
			@apply border-r border-r-gray-300 #{'!important'};

			.dark & {
				@apply border-r-gray-500 #{'!important'};
			}
		}

		input {
			@apply rounded-sm text-default bg-white border-gray-300 #{'!important'};

			.dark & {
				@apply bg-gray-700 border-gray-500 #{'!important'};
			}
		}
	}
}