.mentions-editor {

  .mentions-container {
    .mention {
      display: inline;
      font-weight: 500 !important;
      color: var(--svc-color-primary) !important;
      background-color: var(--svc-bg-card);
    }
    
    .mention[data-denotation-char="#"] {
      color: var(--svc-color-primary-700) !important;
      background-color: var(--svc-color-primary-200) !important;
    }
  }
}

.mention-editor-list {

  .ql-mention-list-item {
    @apply leading-tight text-base text-default font-normal py-2 pl-2 pr-2;

    &.selected {
      @apply bg-gray-100;
    }

    &.disabled {
      @apply text-base text-default font-bold border-y border-gray-300 pl-2 py-2;

      &[data-target="level-1"] {
        & ~ .ql-mention-list-item:not(.disabled) {
          @apply pl-4;
        }
      }

      & ~ .ql-mention-list-item.disabled {
        &[data-target="level-2"] {
          @apply font-normal border-y-0 pl-6;

          & ~ .ql-mention-list-item:not(.disabled) {
            @apply pl-10;
          }
        }
      }
    }

    &:first-child {
      @apply border-t-0;
    }
  }
}

.ql-mention-list-container {
  width: 400px;

  .ql-mention-loading {
    @apply text-md;
    padding: 10px 20px;
    line-height: 1rem;
    color: var(--svc-text-default);
  }
}