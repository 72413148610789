@use "sass:map";
@use '@angular/material' as mat;
@use 'theme-colors' as theme;

@include mat.core();
mat.$theme-ignore-duplication-warnings: true;

@include mat.all-component-themes((
  color: null,
  density: 0,
  typography: mat.m2-define-typography-config(
    $font-family: Roboto,
    $headline-1: mat.m2-define-typography-level(1.875rem, 2.25rem, 800, Roboto),
    $headline-2: mat.m2-define-typography-level(1.25rem, 1.75rem, 700, Roboto),
    $headline-3: mat.m2-define-typography-level(1.125rem, 1.75rem, 600, Roboto),
    $headline-4: mat.m2-define-typography-level(0.875rem, 1.25rem, 600, Roboto),
    $headline-5: mat.m2-define-typography-level(0.875rem, 1.5rem, 400, Roboto),
    $headline-6: mat.m2-define-typography-level(0.875rem, 1.5rem, 400, Roboto),
    $subtitle-1: mat.m2-define-typography-level(1rem, 1.75rem, 400, Roboto),
    $subtitle-2: mat.m2-define-typography-level(0.875rem, 1.25rem, 600, Roboto),
    $body-1: mat.m2-define-typography-level(0.875rem, 1.5rem, 400, Roboto),
    $body-2: mat.m2-define-typography-level(0.875rem, 1.5rem, 400, Roboto),
    $caption: mat.m2-define-typography-level(0.75rem, 1rem, 400, Roboto),
    $button: mat.m2-define-typography-level(0.875rem, 0.875rem, 500, Roboto),
    $overline: mat.m2-define-typography-level(0.75rem, 2rem, 500, Roboto)
  )
));

@each $name, $theme in theme.$theme-colors {
  $primary: (
    50: map.get($theme, 50),
    100: map.get($theme, 100),
    200: map.get($theme, 200),
    300: map.get($theme, 300),
    400: map.get($theme, 400),
    500: map.get($theme, 500),
    600: map.get($theme, 600),
    700: map.get($theme, 700),
    800: map.get($theme, 800),
    900: map.get($theme, 900),
    contrast: (
      50: map.get(map.get($theme, contrast), 50),
      100: map.get(map.get($theme, contrast), 100),
      200: map.get(map.get($theme, contrast), 200),
      300: map.get(map.get($theme, contrast), 300),
      400: map.get(map.get($theme, contrast), 400),
      500: map.get(map.get($theme, contrast), 500),
      600: map.get(map.get($theme, contrast), 600),
      700: map.get(map.get($theme, contrast), 700),
      800: map.get(map.get($theme, contrast), 800),
      900: map.get(map.get($theme, contrast), 900)
    )
  );

  $accent: (
    50: #f8fafc,
    100: #f1f5f9,
    200: #e2e8f0,
    300: #cbd5e1,
    400: #94a3b8,
    500: #64748b,
    600: #475569,
    700: #334155,
    800: #1e293b,
    900: #0f172a,
    contrast: (
      50: #0f172a,
      100: #0f172a,
      200: #0f172a,
      300: #0f172a,
      400: #0f172a,
      500: #FFFFFF,
      600: #FFFFFF,
      700: #FFFFFF,
      800: #FFFFFF,
      900: #FFFFFF
    )
  );

  $warn: (
    50: #fef2f2,
    100: #fee2e2,
    200: #fecaca,
    300: #fca5a5,
    400: #f87171,
    500: #C00000,
    600: #dc2626,
    700: #b91c1c,
    800: #991b1b,
    900: #7f1d1d,
    contrast: (
      50: #7f1d1d,
      100: #7f1d1d,
      200: #7f1d1d,
      300: #7f1d1d,
      400: #7f1d1d,
      500: #fef2f2,
      600: #FFFFFF,
      700: #FFFFFF,
      800: #FFFFFF,
      900: #FFFFFF
    )
  );

  $primary-palette: mat.m2-define-palette($primary);
  $accent-palette: mat.m2-define-palette($accent);
  $warn-palette: mat.m2-define-palette($warn);

  $palette: mat.m2-define-light-theme((
    color: (
      primary: $primary-palette,
      accent: $accent-palette,
      warn: $warn-palette,
    )
  ));

  .#{$name} {
    @include mat.all-component-colors($palette);
  }
}
