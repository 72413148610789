@use '../theme-colors' as theme;
@tailwind base;
@tailwind components;
@tailwind utilities;

@mixin generateColor($name, $color) {
  --svc-#{$name}: #{$color};
  --svc-#{$name}-rgb: #{red($color)},#{green($color)},#{blue($color)};
}

@layer base {
  :root {
    @include generateColor(color-primary, #3b82f6);
    @include generateColor(color-primary-50, #eff6ff);
    @include generateColor(color-primary-100, #dbeafe);
    @include generateColor(color-primary-200, #bfdbfe);
    @include generateColor(color-primary-300, #93c5fd);
    @include generateColor(color-primary-400, #60a5fa);
    @include generateColor(color-primary-500, #3b82f6);
    @include generateColor(color-primary-600, #2563eb);
    @include generateColor(color-primary-700, #1d4ed8);
    @include generateColor(color-primary-800, #1e40af);
    @include generateColor(color-primary-900, #1e3a8a);

    @include generateColor(color-on-primary, #FFFFFF);
    @include generateColor(color-on-primary-50, #1a0010);
    @include generateColor(color-on-primary-100, #1a0010);
    @include generateColor(color-on-primary-200, #1a0010);
    @include generateColor(color-on-primary-300, #1a0010);
    @include generateColor(color-on-primary-400, #FFFFFF);
    @include generateColor(color-on-primary-500, #FFFFFF);
    @include generateColor(color-on-primary-600, #FFFFFF);
    @include generateColor(color-on-primary-700, #FFFFFF);
    @include generateColor(color-on-primary-800, #FFFFFF);
    @include generateColor(color-on-primary-900, #FFFFFF);

    @include generateColor(accent, #1e293b);
    @include generateColor(accent-50, #f8fafc);
    @include generateColor(accent-100, #f1f5f9);
    @include generateColor(accent-200, #e2e8f0);
    @include generateColor(accent-300, #cbd5e1);
    @include generateColor(accent-400, #94a3b8);
    @include generateColor(accent-500, #64748b);
    @include generateColor(accent-600, #475569);
    @include generateColor(accent-700, #334155);
    @include generateColor(accent-800, #1e293b);
    @include generateColor(accent-900, #0f172a);
    @include generateColor(on-accent, #FFFFFF);
    @include generateColor(on-accent-50, #0f172a);
    @include generateColor(on-accent-100, #0f172a);
    @include generateColor(on-accent-200, #0f172a);
    @include generateColor(on-accent-300, #0f172a);
    @include generateColor(on-accent-400, #0f172a);
    @include generateColor(on-accent-500, #FFFFFF);
    @include generateColor(on-accent-600, #FFFFFF);
    @include generateColor(on-accent-700, #FFFFFF);
    @include generateColor(on-accent-800, #FFFFFF);
    @include generateColor(on-accent-900, #FFFFFF);
    @include generateColor(warn, #dc2626);
    @include generateColor(warn-50, #fef2f2);
    @include generateColor(warn-100, #fee2e2);
    @include generateColor(warn-200, #fecaca);
    @include generateColor(warn-300, #fca5a5);
    @include generateColor(warn-400, #f87171);
    @include generateColor(warn-500, #ef4444);
    @include generateColor(warn-600, #dc2626);
    @include generateColor(warn-700, #b91c1c);
    @include generateColor(warn-800, #991b1b);
    @include generateColor(warn-900, #7f1d1d);
    @include generateColor(on-warn, #FFFFFF);
    @include generateColor(on-warn-50, #7f1d1d);
    @include generateColor(on-warn-100, #7f1d1d);
    @include generateColor(on-warn-200, #7f1d1d);
    @include generateColor(on-warn-300, #7f1d1d);
    @include generateColor(on-warn-400, #7f1d1d);
    @include generateColor(on-warn-500, #fef2f2);
    @include generateColor(on-warn-600, #FFFFFF);
    @include generateColor(on-warn-700, #FFFFFF);
    @include generateColor(on-warn-800, #FFFFFF);
    @include generateColor(on-warn-900, #FFFFFF);

    @include generateColor(success, #466900);

    @each $name, $theme in theme.$theme-colors {
      .#{$name} {
        @each $type, $color in $theme {
          @if $type == contrast {
            @each $constratType, $contrastColor in $color {
              @if $constratType == default {
                @include generateColor(color-on-primary, $contrastColor);
              }
              @else {
                @include generateColor(color-on-primary-#{$constratType}, $contrastColor);
              }
            }
          }
          @else if $type == default {
            @include generateColor(color-primary, $color);
          }
          @else{
            @include generateColor(color-primary-#{$type}, $color);
          }
        }
      }
    }
  }

  * {
    /* Text rendering */
    text-rendering: optimizeLegibility;
    -o-text-rendering: optimizeLegibility;
    -ms-text-rendering: optimizeLegibility;
    -moz-text-rendering: optimizeLegibility;
    -webkit-text-rendering: optimizeLegibility;
    -webkit-tap-highlight-color: transparent;

    /* Remove the focus ring */
    &:focus {
      outline: none !important;
    }
  }

  /* HTML and Body default styles */
  html,
  body {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;
    min-height: 100%;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  /* Font size */
  html {
    font-size: 16px;
    overflow: hidden;
  }
  
  body {
    @apply text-default bg-default #{'!important'};
  }

  /* Stylistic alternates for Inter */
  body {
    font-feature-settings: 'salt';
  }

  /* Better spacing and border for horizontal rule */
  hr {
    margin: 32px 0;
    border-bottom-width: 1px;
  }

  /* Make images and videos to take up all the available space */
  img {
    width: 100%;
    vertical-align: top;
  }

  /* Fix: Disabled placeholder color is too faded on Safari */
  input[disabled] {
    opacity: 1;
    -webkit-text-fill-color: currentColor;
  }

  /* Set the border color */
  *,
  ::before,
  ::after {
    --tw-border-opacity: 1 !important;
    border-color: rgba(var(--svc-border-rgb), var(--tw-border-opacity));
  }

  input {
    &::placeholder {
      font-weight: 300 !important;
      opacity: 1 #{'!important'};
      @apply text-hint #{'!important'};
    }
    &::-moz-placeholder {
      font-weight: 300 !important;
      opacity: 1 #{'!important'};
      @apply text-hint #{'!important'};
    }
    &::-webkit-input-placeholder {
      font-weight: 300 !important;
      opacity: 1 #{'!important'};
      @apply text-hint #{'!important'};
    }
    &:-ms-input-placeholder {
      font-weight: 300 !important;
      opacity: 1 #{'!important'};
      @apply text-hint #{'!important'};
    }
  }

  /* Style scrollbars on platforms other than MacOS and iOS */
  @media only screen and (min-width: 960px) {

    body:not(.os-mac) {

      ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background-color: rgba(0, 0, 0, 0);
      }

      ::-webkit-scrollbar:hover {
        width: 8px;
        height: 8px;
        background-color: rgba(0, 0, 0, 0.06);
      }

      ::-webkit-scrollbar-thumb {
        border: 2px solid transparent;
        border-radius: 20px;
        box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.24);
      }

      ::-webkit-scrollbar-thumb:active {
        border-radius: 20px;
        box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.37);
      }

      &.dark {
        ::-webkit-scrollbar-thumb {
          box-shadow: inset 0 0 0 20px rgba(255, 255, 255, 0.24);
        }

        ::-webkit-scrollbar-thumb:active {
          box-shadow: inset 0 0 0 20px rgba(255, 255, 255, 0.37);
        }
      }
    }
  }

  /* Set the foreground color for disabled elements */
  [disabled]:not(.remove-text-disabled-color):not(.svc-btn):not(.svc-filled-btn):not(.svc-outlined-btn) * {
    @apply text-disabled #{'!important'};
  }

  @media (max-width: 599px) {
    input {
      font-size: 1rem !important;
    }
  }
}
